.component-front-home {
  background-color: var(--primaryColor) !important;
  box-sizing: border-box;
}
.content-view-start {
  position: relative;
}

.banner-liste-projet {
  background: var(--tertiareColore);
  padding: 5rem;
}
.titreH1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 140%;
  color: #0f111d;
}
.liste-ordonnee {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 200%;
  color: #0f111d;
  padding-top: 5px;
  padding-bottom: 5px;

}
.color-btn {
  margin-left: 1rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 18px 23px;
  background: var(--secondary-color);
  border-radius: 10px;
  color: var(--primaryColor);
}
.color-btn:hover {
  color: var(--secondary-color);
  border-color: var(--secondary-color);
  background-color: var(--primaryColor);
  text-decoration: none;
}

.pointretour{
  
  transform: rotate(360deg);
}
.retour {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24%;
  color: var(--secondary-color);
}

