.component-front-register-infos-perso-nine{
  padding: 200px;
  background: var(--quinaryColor);
  border-radius: 10px;
  
}


.paragraph-mail-scroll {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  text-align: left;
  color: rgba(0, 0, 0, 0.6);
  overflow:auto;
  height: 350px;
  border:0px solid #ccc;
}

.paragraph-mail-scroll2{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  text-align: left;
  color: rgba(0, 0, 0, 0.6);
  border:0px solid #ccc;

}

.div-btn-accepter{
  display: flex !important;
  justify-content: flex-start !important;
}
