
.div-compte {
    background: var(--primaryColor);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
}
.text-gray-tb {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.025em;
    color: rgba(0, 0, 0, 0.6);
}
.text-gray-tb-sous-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: -0.025em;
    color: rgba(1, 1, 1, 0.5);
}