@import url('../../../../colors.css');

.profile-containers {
  background-color: var(--primaryColor);
  padding: 1rem 2rem;
  border-radius: 10px;
  margin-top: 1rem;
}

.admin-container,
.liste-porteur-projet-container {
  background-color: var(--primaryColor);
  padding: 1rem 2rem;
  border-radius: 10px;
  margin-top: 3rem;
}

.bloc-principal-body-content-profile {
  width: calc(100% - 17%);
}

.mon-profil {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: var( --decimalTextColor);
}
.information-personnelle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  text-align: right;
  letter-spacing: -0.025em;
  color: var(--secondary-color);
}
.text-jones {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  text-align: right;
  letter-spacing: -0.025em;
  color: var( --decimalTextColor);
}
.text-admin {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  text-align: right;
  letter-spacing: -0.025em;
  color: var( --decimalTextColor);
  text-align: center;
}
.pp-admin {
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  object-fit: fill;
}


.profile-avatar-container {
  width: 150px !important;
  height: 150px !important;
  overflow: hidden !important;
  position: relative !important;
  margin: 15px auto !important;
  border: 1px solid transparent !important;
  border-radius: 50% !important;
  cursor: pointer !important;
}

.profile-avatar-container img {
  width: 100% !important;
  height: 100% !important;
  display: block !important;
  object-fit: cover !important;
}

.profile-avatar-container span {
  position: absolute !important;
  bottom: -100% !important;
  left: 0 !important;
  width: 100% !important;
  height: 50% !important;
  background: var(--primaryColor)8 !important;
  text-align: center !important;
  font-weight: 400 !important;
  color: var(--secondary-color) !important;
  transition: 0.3 ease-in-out !important;
}

.profile-avatar-container span p {
    color: var(--secondary-color) !important;
}
.profile-avatar-container:hover span {
  bottom: -15% !important;
  
}

.profile-avatar-container span.charge {
  bottom: -15% !important;
}

.profile-avatar-container #file_up {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  opacity: 0 !important;
  cursor: pointer !important;
}
::-webkit-file-upload-button {
  cursor: pointer !important;
}

/* --------------les input dans component profile------------------ */
.form-control-profil {
  position: relative;
  display: block;
  width: 100%;
  height: 50px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  color: var(--quinaryTextColor);
  background-color: var(--primaryColor);
  background-image: none;
  background: var(--primaryColor);
  border: 2px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 10px;
}

.form-input-profile {
  padding-top: 70px;
}
.container-input-address {
  padding-top: 50px;
}

.form-label-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var( --decimalTextColor);
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 6px 0px;
}
.infos-perso-input {
  margin-bottom: 3.5rem !important;
}
.btn-modifier {
  width: 92px;
  height: 38px;
  background: var(--secondary-color);
  border-radius: 4px;
  color: var(--primaryColor);
}
