@import url('../../../../../colors.css');
.banner-details-projet {
  background-image: url('../../../../../images/image2.png');
  padding: 10%;
  background-size: cover;
  background-repeat: no-repeat;
}
.component-details-projet .img-details {
  width: 100%;
  height: 370px;
}
.colorBorderBottom{
  border-bottom: 5px solid white;
}
.image-card-container {
  margin-bottom: 30px;
}
.link-button {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--secondary-color);
  background: var(--primaryColor);
  padding: 1rem 2rem 1rem 2rem;
  border-radius: 10px;
  border: 1px solid var(--secondary-color);
}
.text-person {
  margin: 12px;
}
.recevoir-person {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--quinaryTextColor);
  margin: 10px;
}
.button-text {
  padding: 15px;
  display: flex;
  align-items: center;
}
.text-person {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  color: var(--secondary-color);
}
.suivre-button {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--primarytextColor);
}
/* .project-funding-type.dette-items {
  background: #2f80ed;
  border: 1px solid #2f80ed;
  box-sizing: border-box;
  border-radius: 4px 0px;
  margin: 0px;
  width: fit-content;
  height: 30px;
} */
/* .project-funding-type.don-items {
  background: #219653;
  border: 1px solid #219653;
  box-sizing: border-box;
  border-radius: 4px 0px;
  margin: 0px;
  width: fit-content;
  height: 30px;
} */
/* .project-funding-type.equity-items {
  background: #f2994a;
  border: 1px solid #f2994a;
  box-sizing: border-box;
  border-radius: 4px 0px;
  margin: 0px;
  width: fit-content;
  height: 30px;
} */
.text-pourcentage {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  color: var(--quinaryTextColor);
}
/* .text-det,
.text-det p {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 10px;
  letter-spacing: -0.025em;
  color: #737373;
} */
.text-lance {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: var(--quinaryTextColor);
}
.text-lance-cloture {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: var(--quinaryTextColor);
}
.color-text {
  color: var(--secondary-color);
}
.text-participez {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: var(--quinaryTextColor);
}


.bordure-deux-cartes {
  border-radius: 8px;
}
.carte-texte {
  align-items: center;
  padding: 5px;
}
.carte-profile-text {
  background: var(--primaryColor);
  border: 0.4px solid var(--tertiaryBorderColor);
  box-sizing: border-box;
  border-radius: 12px;
  transform: rotate(180deg);
  margin-top: 15px;
}
.text-aicha-porteur {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 10px;
  display: flex;
  align-items: center;
  letter-spacing: -0.025em;
  color: var(--quinaryTextColor);
}
.text-pctg-fcfa {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: var(--secondary-color);
}
.text-aicha {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.025em;
  color: var(--tertiodenaryTextColor);
}

/* -----------------ancien css-------------------------- */

.photo-card {
  background: var(--primaryColor);
  box-shadow: 0px 4px 43px var(--primaryBoxShadowColor);
  border-radius: 8px;
}
.text-titre-objectif {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.025em;
  color: var(--primarytextColor);
}
.text-details-bold {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: var(--primarytextColor);
}
.text-details-objectif {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
  color: var(--primarytextColor);
}
.texte-para {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: -0.025em;
  color: var(--quaterioTextColor);
  text-align: justify;
}
.text-aicha-small {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 100;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.025em;

  color: var(--tertiodenaryTextColor);
}
.title-description {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 47px;
  letter-spacing: -0.025em;
}
.bg-whites {
  background: var(--primaryColor);
  box-shadow: 0px 4px 43px var(--primaryBoxShadowColor);
}
.titre-banner-liste-projet {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 42px !important;
  color: var(--secondaryTextColor) !important;
}
.titre-banner-liste-proj {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 42px !important;
  color: var(--primaryColor) !important;
}
.text-detail {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.025em;
  color: var(--quinaryTextColor);
}
.video-detail {
  width: 100% !important;
}
.component-details-projet .card {
  background: var(--primaryColor);
  box-shadow: 0px 4px 20px var(--secondaryBoxShadowColor);
  border-radius: 10px;
  max-width: 160%;
}

.component-details-projet .card-header{
  background: transparent;
  cursor: pointer;
}

/* .component-details-projet .card-icon {
  background: var(--primaryColor);
  box-shadow: 0px 4px 20px var(--secondaryBoxShadowColor);
  border-radius: 10px;
  max-width: 160%;
  margin: 5px;
} */

.component-details-projet svg{
  font-size: 40px !important;
  cursor: pointer;
}
.component-details-projet .card-title {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 26px;
  color: var(--quinaryTextColor);
}
.vjs-poster{
  background: transparent !important;
}
.component-details-projet .icon-social-detail {
  color: var(--primaryColor);
  width: 18px;
  height: 18px;
  margin-top: -16px;
  margin-left: -7px;
}
.component-details-projet .nav > li a {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 26px;
  color: var(--quinaryTextColor) !important;
}
.component-details-projet .nav-pills .nav-link.active {
  background: var(--secondary-color) !important;
  border-radius: 10px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  color: var(--primaryColor) !important;
}
.nav-pills>li {
  float: none !important;
}
.component-details-projet .bloc-list1 .list-group-item {
  border: none !important;
}
.li-bg {
  background: var(--quinaryColor) !important;
}
.text-li-details {
  font-family: "DM Sans" !important;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  align-items: center;
  letter-spacing: -0.025em;
  color: var(--tertiodenaryTextColor);
}
.li-bg {
  background: var(--quinaryColor);
}
.title-detail-h3 {
  font-family: " Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  display: flex;
  align-items: center;
  letter-spacing: -0.025em;
  color: var(--secondary-color);
}
.img-details-li {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}
.nom-title {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: var(--primarytextColor);
}
.sub-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: var(--quinaryTextColor);
}
.text-small-faq {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.025em;
  color: var(--nonaryTextColor);
}
.text-comment-auteur {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: var(--primarytextColor);
}
.bloc-comment-detail p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--nonaryTextColor);
}
.img-icon-video {
  width: 56.67px;
  height: 56.67px;
}
.modal-content-tutorer {
  border-radius: 10px 10px 0px 0px;
}
.modal-tutorer {
  z-index: 9999999999999;
}
.title-montant {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 47px;
  color: var(--primarytextColor);
  text-align: center;
}
.header-contribution {
  background: #bb4411;
  border-radius: 10px 10px 0px 0px;
  height: 60px;
  padding: 1rem;
}
.title-contribution {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.025em;
  color: var(--primaryColor);
  text-align: center;
}
.modal-confirmation .modal-header {
  border-bottom: none !important;
}
.modal-confirmation .modal-footer {
  border-top: none !important;
}
.cursor-p {
  cursor: pointer;
}
.bg-color-selected {
  padding: 15px;
}
/* -----------------modal style----------------- */
.text-securiser {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 54px;
  text-align: center;
  color: var(--primarytextColor);
  padding: 20px;
}
.button-detail-securiser {
  margin-top: 5rem;
  text-align: center;
}
.securiser-btn-green {
  background: var(--secondary-color);
  width: 20%;
  border-radius: 10px;
  color: var(--primaryColor);
  height: 50px;
}
.form-input-telephone {
  border-bottom: 1px solid #e0e0e0;
  margin-top: 5rem;
  width: 30%;
  margin-left: 160px;
}

.text-modal-compte {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin: 22px;
  text-align: justify;
  padding: 32px;
  color: var(--quinaryTextColor);
}

.disabled-btn{
	cursor: not-allowed !important;
}
.pictureInteresser{
  border-radius: 100%;
  max-width: 50px;
  min-width: 50px;
  max-height: 50px;
  margin-left: -12px;
}