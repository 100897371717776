@import url('../../../../colors.css');

.type-action-investisseur {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    color: var(--primarytextColor);

}
.nombre-action-investisseur {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 74px;
    line-height: 111px;
    color: var(--primarytextColor);

}
.margin-top-sidebar-mobile {
    margin-top: 70%;
}