.users-container {
	background-color: var(--quinaryColor);
	padding: 2rem;
}

.liste-utilisateurs {
	background-color: var(--primaryColor);
    padding: 1rem 2rem;
    border-radius: 10px;
}

.liste-utilisateurs-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 25px;
	color: #2d3748;
    margin: 0;
}

.users-table {
    margin-top: 3rem;
}

.users-table .modal-body, .users-table .modal-content, .crud-form{
	background-color: var(--primaryColor) !important;
	opacity: 1 !important;
}

.user-img {
    width: 40px;
    height: 40px;
    border-radius: 35%;
}
