:root{
    --primaryColor: #fff;
    --secondary-color: rgba(54, 158, 138, 1);
    --tertiaryColor: #E9F3F4;
    --quaternary-color: #F6F8F9;
    --quinaryColor: rgba(189, 189, 189, 0.4);
    --senaryColor: rgb(0 0 0 / 0%);
    --septanaryColor: #f5f5f5;
    --octonaryColor: #FFF8F8;
    --primarytextColor: #000;
    --decimalTextColor: #010101;
    --secondaryTextColor: #0F111D;
    --tertiaryTextColor: #091638;
    --quaternaryTextColor: #141B2DB2;
    --quinaryTextColor: rgba(0, 0, 0, 0.5);
    --senaryTextColor: #141b2d6e;
    --septenaryTextColor: #5C6574;
    --octonaryTextColor: #25B7CC;
    --nonaryTextColor: rgba(0, 0, 0, 0.65);
    --denaryTextColor: #f2994a;
    --undenaryTextColor: #219653;
    --investisseurBackgroundColor: #E87930;
    --duodenary: red;
    --tertiodenaryTextColor: #010101;
    --quaterioTextColor: #2D2D2D;
    --quinarioTextColor: #171d0f;
    --senarioTextColor: #580000B2;
    --septanarioTextColor: #737373;
    --octanarioTextColor: #1C1C1C;
    --primaryBorderColor: #DDDDDD;
    --secondaryBorderColor: #DEDEE7;
    --tertiaryBorderColor: #EEEEEE99;
    --quaternaryBorderColor:#c4c4c4;
    --quinaryBorderColor: #DE0000;
    --senaryBorderColor: #959595;
    --septanaryBorderColor: #C4C4C4;
    --exaColor: #AAB8C6;
    --exaTextColor: #b2c7e1;
    --primaryBoxShadowColor: rgba(0, 0, 0, 0.1);
    --secondaryBoxShadowColor: rgba(0, 0, 0, 0.19);
    --tertiaryBoxShadowColor: rgba(51, 68, 99, 0.16);
    --quinaryBoxShadowColor: rgb(0 0 0 / 5%);
    --senaryBoxShadowColor: rgba(220, 219, 222, 0.31);
    --septanaryBoxShadowColor: rgb(0 0 0 / 10%);
    --octonaryBoxShadowColor: rgba(0, 0, 0, 0.08);
    --primaryIconColor: rgb(0 0 0 / 15%);
    --tertiareColore: rgba(233, 243, 244, 1);
    --grennColor: #369E8A1A ;
}