@import url('../../../../colors.css');

.admin-contacts-title {
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 140% !important;
    color: var(--decimalTextColor) !important;
}

.card-contacts-main-content {
    width: 98%;
    margin: 20px auto;
    padding-bottom: 60px;
}

.contact-btn {
    /* background: #F3BB67; */
    border-radius: 20px !important; 
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 13px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: var( --primarytextColor);  
    box-shadow: none !important;
    outline: none !important;
}
.contact-btn-nouveau {
    background: #ee8181 !important;
}

.contact-btn-encours {
    background: #f3bb67d3 !important;
}

.contact-btn-done {
    background: var(--secondary-color) !important;
}

.btn-retour-faq-modal:hover {
    border: 1px solid #E53E3E;
    color: #E53E3E;
}

.div-nouveau-name {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.div-nouveau-name {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.contact-name-para {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: 0.4px;
    color: var(--secondary-color);
}

.contact-modal-para {
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 15px !important;
    line-height: 22px !important;
    letter-spacing: 0.4px !important;
    color: var(--secondary-color) !important;
}

.modal-faq-contact {
    overflow: scroll;
}

.contact-modal-hr {
    width: 96% !important;
}

.contact-modal-form {
    border-top: 1px solid #E0E0E0;
    padding-top: 20px !important;
}

.statut-modal-form {
    padding-top: 20px !important;
}

.contact-card-para {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: 0.4px;
    color: var(--quaternaryTextColor);
    
}

.select-statut {
    height: 45px !important;
    border-radius: 6px !important;
}

@media only screen and (max-width: 768px) {
    .div-nouveau-name {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px 0px;
    }

    .contact-btn-nouveau {
        font-size: 8px !important; 
    }

    .contact-name-para {
        font-size: 14px;
    }

    .div-para-contact-modal {
        height: 150px;
        overflow: scroll;
    }
}