.contenuetitreidentite {
  background: var(--tertiareColore);
}
.titreidentite {
  display: flex;
  justify-content: center;
  height: 180px;
  margin: auto;
  font-size: 32px;
  padding-top: 45px;
}
.texteinvestir {
  background: var(--primaryColor);
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 10px;
  border-radius: 10px;
  margin: 10px;
  width: 90% !important;
}
.link-personne-physique {
  text-decoration: none !important;
}
.hover-link-physique:hover {
  background: var(--secondary-color) !important;
  border-radius: 10px !important;
  color: white;
}
.hover-link-physique:hover p {
  color: white;
}

.iconeinvestir {
  border-radius: 50%;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  margin-bottom: 10px;
  width: 100px;
  height: 100px;
  margin: 20px;
}
.iconeinvestir {
  color: gray;
}
.hover-link-physique:hover .iconeinvestir {
  color: var(--secondary-color);
  background: white;
}
.texteinvestircontent {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  letter-spacing: -0.025em;
  color: #cccccc;
}
.btninvestir {
  background: var(--secondary-color);
  border-radius: 10px;
  color: var(--primaryColor) !important;
  padding: 18px 10px 15px 15px;
  text-decoration: none !important;
  padding: 18px 23px;
  border-radius: 10px;
}
.contentchoixpersonne {
  padding-top: -92px;
}
.btninvestir:hover {
  color: var(--secondary-color) !important;
  border: 1px solid var(--secondary-color);
  text-decoration: none;
  background: var(--primaryColor);
}
.contentinvestir {
  height: 650px;
  padding-top: 29px;
}
