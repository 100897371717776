.arow-pos {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
}
.title-questionnaire {
	margin-left: 1.5em !important;
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	line-height: 140%;
	color: #535757;
	margin-left: 0 !important;
}
.input-part-du-project {
	margin-top: 4em !important;
	margin-left: 10px !important;
	border: none;
	border-bottom: 1px solid var(--secondary-color);
	padding: 5px 10px;
	outline: none;
	width: 85%;
}
.input-part-du-project-other {
	margin-top: 2em !important;
	border: none;
	border-bottom: 1px solid var(--secondary-color);
	padding: 5px 10px;
	outline: none;
	width: 85%;
}

.input-part-du-project > [placeholder]:focus::-webkit-input-placeholder {
	transition: text-indent 0.4s 0.4s ease;
	text-indent: -100%;
	opacity: 1;
}
.btn-se-financer-part {
	background: var(--secondary-color) !important;
	padding: 16px !important;
	border: 1px solid var(--secondary-color);

	border-radius: 10px !important;
	color: var(--primaryColor) !important;
}
.btn-se-financer-part:hover {
	text-decoration: none !important;
	background: transparent !important;
	border: 1px solid var(--secondary-color);
	color: var(--secondary-color) !important;
}
.btn-se-financer-part8 {
	text-align: center;
	background: transparent;
	border-radius: 10px;
	color: var(--secondary-color) !important;
	border: 1px solid var(--secondary-color);
}
.btn-se-financer-part8:hover {
	text-align: center;
	background: var(--secondary-color);
	border-radius: 10px;
	border: 1px solid var(--secondary-color);
	color: var(--primaryColor) !important;
}
.btn-se {
	color: var(--primaryColor);
}
.play {
	position: absolute;
	top: 120px;
	left: 190px;
}
.a-link > a:hover {
	text-decoration: none !important;
}
.mt-input-part-project {
	margin-top: 2em !important;
}
.mb-input-part-project {
	margin-bottom: 2em !important;
}
.input-directions {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	padding-left: 5px;
	font-size: 15px;
	line-height: 19px;
	color: rgba(0, 0, 0, 0.6);
}
.marge-questionaire-projet {
	margin-top: 4em;
}
.customModal-questionnaire {
	max-width: 500px !important;
	height: 400px !important;
	width: 100%;
	background: var(--primaryColor);
	border-radius: 10px;
}

.modal-mode-financement-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-top: 5rem;
}
.modal-mode-financement-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 40px;
	text-align: center;
	color: var(--primarytextColor);
}
.btn-questionnaire-confimation-non {
	background: transparent !important;
	padding: 16px !important;
	border-radius: 10px !important;
	border: 1px solid var(--secondary-color);
	color: var(--secondary-color) !important;
}
.btn-questionnaire-confimation-non:hover {
	text-decoration: none !important;
	background: var(--secondary-color) !important;
	border: 1px solid var(--secondary-color);
	color: var(--primaryColor) !important;
}
.design-boutton {
	background: none;
}

/* Ameliorations  styles */
.questionnaire-form-check-container {
	display: flex;
	align-items: center !important;
	gap: 10px;
	margin-bottom: 14px;
}

.questionnaire-button-container {
	margin-top: 4rem;
}

.questionnaire-form-check-input {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	background-color: var(--primaryColor) !important;
	box-sizing: border-box !important;
	cursor: pointer;
	outline: none !important;
	width: 15px !important;
	height: 15px !important;
	border-radius: 4px;
	border: 1px solid #bdbdbd;
	background-color: var(--primaryColor) !important;
	box-sizing: border-box !important;
	cursor: pointer;
	outline: none !important;
}

.questionnaire-form-check-input:checked {
	background-color: var(--secondary-color) !important;
	border: 1px solid var(--secondary-color);
}

.questionnaire-form-check-input:before {
	font: 10pt "FontAwesome";
	content: "\f00c";
	display: inline;
	color: var(--primaryColor);
	position: absolute;
}

.questionnaire-form-check-label {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 19px;
	color: rgba(0, 0, 0, 0.6);
	margin: 0;
}

.questionnaire-container {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.soumettre-projet .informations{
	width: 100% !important;
}

.quest-control-input {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	background-color: var(--primaryColor) !important;
	box-sizing: border-box !important;
	cursor: pointer;
	outline: none !important;
	width: 18px !important;
	height: 18px !important;
	border-radius: 4px;
	border: 1.5px solid #BDBDBD;
}

.quest-control-input:before {
	font: 10pt "FontAwesome";
	content: "\f00c";
	display: inline;
	padding-left: 1px;
	color: var(--primaryColor);
}

.quest-control-input:checked {
	background-color: var(--secondary-color) !important;
	border-color: var(--secondary-color) !important;
}

.quest-label {
	font-family: Poppins;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	color: rgba(0, 0, 0, 0.6);
	margin-left: 20px;
}

.text-black-50 {
	color: var(--primarytextColor) !important;
	opacity: 0.5 !important;
}

.dzu-previewContainer {
	margin-top: 2rem;
}
.react-calendar {
    width: 100% !important;
    border: none !important;
}

.react-calendar__tile--active {
    background: var(--secondary-color) !important;
    color: white;
}
.description-length {
	font-size: 15px;
    font-weight: 400;
}
.inputCV{
	border-radius: 10px;
    padding: 10px;
    margin: 27px 0 0 0;
}
