.sidebar-customisation {
  /* padding: 40px 40px 150px 0; */
  height: 100%;
  max-width: 100%;
  width: 100%;
  background-color: var(--primaryColor);
  z-index: 999;
}

.sidebar-customisation-content {
  width: 100% !important;
}
.nav-link-sidebar-custom {
  font-family: "DM Sans" !important;
  font-style: normal;
  font-weight: normal;
  font-size: 25px !important;
  line-height: 33px !important;
  color: rgba(0, 0, 0, 0.5) !important;
  padding: 15px 20px 15px 0 !important;
  margin-left: 32px !important;
}

.nav-link-sidebar-custom:hover {
  color: var(--secondary-color) !important;
  background-color: transparent !important;
  transition: 0.2s ease;
}
.nav-link-sidebar-custom:focus {
  border: none !important;
}
.nav-link-sidebar-custom.actived {
  color: var(--secondary-color) !important;
  border-left: 2px solid var(--secondary-color) !important;
  font-weight: 800;
  text-decoration: none !important;
}
.nav-sidebar-items-custom {
  margin-top: 30px;
}
.component-sidebar-tableau-bord {
  /* position: fixed; */
  width: 100% !important;
  height: 100%;
}
.nav-link-sidebar-tb-custom {
  font-family: "DM Sans" !important;
  font-style: normal;
  font-weight: normal;
  font-size: 25px !important;
  line-height: 33px !important;
  color: rgba(0, 0, 0, 0.5) !important;
  padding: 15px 50px 15px 50px !important;
  white-space: nowrap;
}
.nav-link-sidebar-tb-custom.actived {
  color: var(--secondary-color) !important;
  /* border-bottom: 2px solid var(--secondary-color) !important; */
  font-weight: 800;
  text-decoration: none !important;
}
.component-sidebar-tableau-bord .nav-item {
  width: 100%;
}
.nav-items-tb-custom {
  width: 100%;
  min-height: 50px;
  flex-wrap: inherit !important;
  overflow: auto;
}
.nav-items-tb-custom::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 1024px) {
  .component-sidebar-tableau-bord {
    height: 100vh;
  }
}

.sidebar-customisation-content {
  padding-top: 30px !important;
  position: relative;
}

.nav.nav-items-custom:before {
  content: "";
  background: #c4c4c4;
  width: 1px;
  position: absolute;
  height: 100%;
  left: 32px !important;
}

.nav-link-sidebar-custom span {
  padding-left: 15px;
}
