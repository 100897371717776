@import url('../../../colors.css');

footer {
  background-color: var(--secondary-color);
}

footer .logo {
  width: 160px;
}
.link-top-footer {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.231818px;
  color: var(--primaryColor);
  background: var(--secondary-color);
  border-radius: 8px;
  padding: 0.7rem 2rem 0.7rem 2rem;
}
.text-footer-span {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 29px;
  letter-spacing: -0.3px;
  color: var(--primaryColor);
}
.link-top-footer:hover {
  color: var(--secondary-color);
  border: var(--secondary-color);
  background-color: var(--primaryColor);
  text-decoration: none;
}
.footer-grid .title-footer {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.231818px;
  color: var(--primaryColor);
}
/* .input-email-footer {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.204545px;
  color: var(--primaryColor);
  background: var(--secondary-color);
  border: none;
  border-bottom: 1px solid #5a5a5a !important;
  padding: 1rem;
} */
.btn-submit-footer {
  background: var(--secondary-color);
  border-radius: 8px 8px 0px 0px;
  padding: 1rem;
}
.icon-submit-footer {
  color: var(--primaryColor);
}
.text-footer {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.204545px;
  color: var(--primaryColor);
}
.icon-social-footer {
  width: 9px;
  height: 17px;
}
.footer-grid .text-content {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 21px !important;
  color: var(--primaryColor) !important;
}
.footer-grid .link-footer {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 21px !important;
  color: var(--primaryColor) !important;
}
.footer-grid .link-footer:hover {
  text-decoration: none;
  color: #f9ca24;
}
.footer-grid .contact-text {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.1em;
  color: var(--primaryColor) !important;
}
.footer-grid .contact-text:hover {
  color: var(--primaryColor) !important;
  text-decoration: none;
}
.footer-grid .contact-text > svg {
  width: 14px;
  position: absolute;
  left: 15px;
}
.social-networking p {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: var(--primaryColor);
}
.social-networking a {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.social-networking span {
  padding-left: 2rem;
  padding-right: 2rem;
}
.social-networking a:hover {
  transform: scale(1.2);
  opacity: 1;
}
.social-networking img {
  width: 100%;
}

footer .copyright {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--primaryColor);
  display: inline-block;
}
/* .link-end-footer {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.75);
} */
/* .link-end-footer:hover {
  text-decoration: none;
  color: #f9ca24;
} */
.footer-reseaux-sociaux-container {
  display: flex;
  justify-content: space-around;
}

.title-media-sociaux {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--primaryColor);
  margin: 0;
}
.footer-social-media-label-container {
  padding-right: 55px;
  opacity: .5;
}
