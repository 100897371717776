@import url('../../../../colors.css');

.component-investisseur {
    background: var(--quinaryColor);
}
.content-title-page-tb-investisseur {
    width: 100%;
    z-index: 999;
}
.titre-repartition-detail {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: var(--secondary-color);

}
.text-progress-investisseur {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.6);
}
.width-bloc {
    width: 60%;
}
.repartition-pret {
    padding: 50px;
}
.child-table-containe{
    background-color: transparent !important;
}
.table-containe .table >thead{
    background-color: var(--tertiareColore) !important;
}
.containTitreInteresser{
    font-family: "Poppins" !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    color: var(--senaryBorderColor) !important;
    background-color: var(--tertiareColore) !important;
}
.tableInteresser{
    --bs-table-bg: var(--bs-body-bg);
    --bs-table-striped-bg: white;
}
.table td, .table th {
    padding: 23px;
    vertical-align: top;
    border-top: 4px solid var(--tertiareColore);
    font-weight: 400;
    font-size: 13px;
}
.textSecteur{
    color:  var(--secondary-color) !important;
}