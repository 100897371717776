@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
:root{
    --primaryColor: #fff;
    --secondary-color: rgba(54, 158, 138, 1);
    --tertiaryColor: #E9F3F4;
    --quaternary-color: #F6F8F9;
    --quinaryColor: rgba(189, 189, 189, 0.4);
    --senaryColor: rgb(0 0 0 / 0%);
    --septanaryColor: #f5f5f5;
    --octonaryColor: #FFF8F8;
    --primarytextColor: #000;
    --decimalTextColor: #010101;
    --secondaryTextColor: #0F111D;
    --tertiaryTextColor: #091638;
    --quaternaryTextColor: #141B2DB2;
    --quinaryTextColor: rgba(0, 0, 0, 0.5);
    --senaryTextColor: #141b2d6e;
    --septenaryTextColor: #5C6574;
    --octonaryTextColor: #25B7CC;
    --nonaryTextColor: rgba(0, 0, 0, 0.65);
    --denaryTextColor: #f2994a;
    --undenaryTextColor: #219653;
    --investisseurBackgroundColor: #E87930;
    --duodenary: red;
    --tertiodenaryTextColor: #010101;
    --quaterioTextColor: #2D2D2D;
    --quinarioTextColor: #171d0f;
    --senarioTextColor: #580000B2;
    --septanarioTextColor: #737373;
    --octanarioTextColor: #1C1C1C;
    --primaryBorderColor: #DDDDDD;
    --secondaryBorderColor: #DEDEE7;
    --tertiaryBorderColor: #EEEEEE99;
    --quaternaryBorderColor:#c4c4c4;
    --quinaryBorderColor: #DE0000;
    --senaryBorderColor: #959595;
    --septanaryBorderColor: #C4C4C4;
    --exaColor: #AAB8C6;
    --exaTextColor: #b2c7e1;
    --primaryBoxShadowColor: rgba(0, 0, 0, 0.1);
    --secondaryBoxShadowColor: rgba(0, 0, 0, 0.19);
    --tertiaryBoxShadowColor: rgba(51, 68, 99, 0.16);
    --quinaryBoxShadowColor: rgb(0 0 0 / 5%);
    --senaryBoxShadowColor: rgba(220, 219, 222, 0.31);
    --septanaryBoxShadowColor: rgb(0 0 0 / 10%);
    --octonaryBoxShadowColor: rgba(0, 0, 0, 0.08);
    --primaryIconColor: rgb(0 0 0 / 15%);
    --tertiareColore: rgba(233, 243, 244, 1);
    --grennColor: #369E8A1A ;
}
/* .header {
    background: var(--primaryColor);
} */

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1080;
    left: 0;
    right: 0;
}

.sticky + .content {
    padding-top: 102px;
}
.auth-btn-container {
    display: flex;
}


.link-top-header{
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    /* color: var(--primaryColor)cc; */
}

.link-top-header:hover{
    text-decoration: none;
    color: var(--primaryColor);
}

.component-front-header .nav-link{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    text-transform: uppercase;
    color: var(--secondary-color);
    padding: 10px 15px;
    border-bottom: 2px solid transparent;
    white-space: nowrap;
}

.component-front-header .nav-link:hover, .nav-link:focus{
    border-bottom: 2px solid var(--secondary-color);
}

.component-front-header .nav-link.active{
    font-family: 'DM Sans';
    font-weight: 700;
    border-bottom: 2px solid var(--secondary-color);
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.291667px;
    color: var(--secondary-color) !important;

}

.dropdown-header {
    position: relative;
    display: inline-block;
}

.dropdown-content-header {
    display: none;
    position: absolute;
    /* background-color: #f9f9f9; */
    min-width: 160px;
    /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
    padding: 12px 16px;
    z-index: 1;
    right: 0;
    top: 3.5rem;
}

.dropdown-header:hover .dropdown-content-header {
    display: block;
}
.dropdown-icon-top-header{
    width: 28px;
    height: 28px;
    /* color: #787878; */
    /* background-color: var(--primaryColor); */
    /* border-radius: 50%; */
}

.dropdown-icon-top-header svg{
    width: 18px;
}

.link-top-header.dropdown-toggle::after{
    position: absolute;
    top: 70%;
    right: -14px;
}

.link-header-login{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 175%;
    text-align: center;
    color: var(--primaryColor);
    background: var(--secondary-color);
    border-radius: 4px;
    padding: 1rem !important;
    border: 1px solid var(--secondary-color);
}
.link-header-login:hover{
    text-decoration: none;
    color: var(--secondary-color);
    background-color: transparent;
    border: 1px solid var(--secondary-color);
}
.link-header-register {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 175%;
    color: var(--nonaryTextColor);
    /* background: var(--primaryColor); */
    padding: .5rem 1rem;
    border-radius: 4px;
    border: 1px solid var(--secondary-color);
}
.link-header-register:hover {
    background-color: var(--secondary-color);
    color: var(--primaryColor);
    text-decoration: none;
}
.header-scrolling-container{
    z-index: 9999999;
    box-shadow: 0px 8px 15px var(--quinaryBoxShadowColor);
}

.connexion-dropdown .dropdown-menu {
    right: 0;
    left: auto;
    top: 33px !important;
    min-width: 13rem;
    padding: 0.5rem 21px;
    margin: 0.125rem 0 0;
}

.connexion-dropdown .dropdown-toggle{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: var(--secondary-color);
}

.connexion-dropdown .dropdown-toggle:hover, .connexion-dropdown .dropdown-toggle:focus{
    text-decoration: none;
    color: var(--secondary-color);
}

.connexion-dropdown .icon{
    width: 35px;
    height: 35px;
    border-radius: 8px;
    border: 1.5px solid var(--primaryColor);
    background: var(--tertiaryColor);
}

.connexion-dropdown .dropdown-item{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 12px;
    padding: 1rem;
    color: var(--primarytextColor);
}

.connexion-dropdown .dropdown-item .icon{
    border: 1.5px solid var(--secondaryTextColor);
}
.no-bg.active {
    background-color: inherit !important;
}
.no-bg:hover {
    background-color: var(--secondary-color) !important;
    color: var(--primaryColor);
}
.titreHeader{
    font-size: 11px;
}

.navbar-brand{
    padding: 0px !important;
}

.container-aem{
    padding-bottom: 0px !important;
}
.ulNavScroll{
    --bs-scroll-height: 100px;
}
footer {
  background-color: var(--secondary-color);
}

footer .logo {
  width: 160px;
}
.link-top-footer {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.231818px;
  color: var(--primaryColor);
  background: var(--secondary-color);
  border-radius: 8px;
  padding: 0.7rem 2rem 0.7rem 2rem;
}
.text-footer-span {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 29px;
  letter-spacing: -0.3px;
  color: var(--primaryColor);
}
.link-top-footer:hover {
  color: var(--secondary-color);
  border: var(--secondary-color);
  background-color: var(--primaryColor);
  text-decoration: none;
}
.footer-grid .title-footer {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.231818px;
  color: var(--primaryColor);
}
/* .input-email-footer {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.204545px;
  color: var(--primaryColor);
  background: var(--secondary-color);
  border: none;
  border-bottom: 1px solid #5a5a5a !important;
  padding: 1rem;
} */
.btn-submit-footer {
  background: var(--secondary-color);
  border-radius: 8px 8px 0px 0px;
  padding: 1rem;
}
.icon-submit-footer {
  color: var(--primaryColor);
}
.text-footer {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.204545px;
  color: var(--primaryColor);
}
.icon-social-footer {
  width: 9px;
  height: 17px;
}
.footer-grid .text-content {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 21px !important;
  color: var(--primaryColor) !important;
}
.footer-grid .link-footer {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 21px !important;
  color: var(--primaryColor) !important;
}
.footer-grid .link-footer:hover {
  text-decoration: none;
  color: #f9ca24;
}
.footer-grid .contact-text {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.1em;
  color: var(--primaryColor) !important;
}
.footer-grid .contact-text:hover {
  color: var(--primaryColor) !important;
  text-decoration: none;
}
.footer-grid .contact-text > svg {
  width: 14px;
  position: absolute;
  left: 15px;
}
.social-networking p {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: var(--primaryColor);
}
.social-networking a {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.social-networking span {
  padding-left: 2rem;
  padding-right: 2rem;
}
.social-networking a:hover {
  transform: scale(1.2);
  opacity: 1;
}
.social-networking img {
  width: 100%;
}

footer .copyright {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--primaryColor);
  display: inline-block;
}
/* .link-end-footer {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.75);
} */
/* .link-end-footer:hover {
  text-decoration: none;
  color: #f9ca24;
} */
.footer-reseaux-sociaux-container {
  display: flex;
  justify-content: space-around;
}

.title-media-sociaux {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--primaryColor);
  margin: 0;
}
.footer-social-media-label-container {
  padding-right: 55px;
  opacity: .5;
}

.investir-banner {
	background-color: var(--tertiareColore) !important;
	margin-bottom: 60px;
}

/* .imgContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}
.imgContainer img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%
} */

.button1 {
	background-color: var(--secondary-color) !important;
	border: 1px solid var(--secondary-color) !important;
	color: var(--primaryColor) !important;
	margin-right: 6px;
}

.button1:hover {
	background-color: var(--tertiareColore) !important;
	border: 1px solid var(--secondary-color) !important;
	color: #000 !important;
	text-decoration: none;
}

.button2 {
	background-color: var(--tertiareColore) !important;
	border: solid 1px var(--secondary-color) !important;
	color: #000 !important;
}
.button2:hover {
	background-color: var(--secondary-color) !important;
	border: solid 1px var(--secondary-color) !important;
	color: var(--primaryColor) !important;
	text-decoration: none;
}

.button-container > div > p {
	padding-right: 335px;
}

.afterBanner > div > h2 {
	text-align: center;
	color: #0f111d;
	font-weight: 700;
	line-height: 44.8px;
	margin: auto;
	padding-bottom: 60px;
}

.caroussel {
	margin-top: 60px;
	height: 355px;
}

.afterCaroussel {
	margin-bottom: 10%;
}

.afterCaroussel > div > h2 {
	color: #000;
	line-height: 35px;
	font-weight: 500;
	text-align: center;
	margin: auto;
	padding: 20px;
}

.afterCarousselCard {
	display: flex;
	margin: auto 5px;
	margin-bottom: 10px;
}

.afterCarousselCard > div {
	margin: auto;
}

.afterCaroussel2 {
	margin-bottom: 120px;
}

.afterCaroussel2 > div > h2 {
	font-size: 32px;
	font-weight: 700;
	line-height: 48px;
	color: #000;
	padding-bottom: 96px;
	margin: auto;
}

.afterCarousselCard2Element {
	box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 43px;
	/* width: 30rem; */
	border-radius: 10px;
	margin: auto;
}

.afterCarousselCard2Element > div img {
	float: left;
	position: absolute;
	border-radius: 50%;
	bottom: 46%;
	left: -3%;
	z-index: 1000;
}

.afterCarousselCard2Element > div {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	position: relative;
}

.afterCarousselCard2Element > div > div h3 {
	font-size: 18px;
	font-weight: 500;
	line-height: 32px;
	color: rgba(0, 0, 0, 1);
}

.afterCarousselCard2Element > div > div h4 {
	font-size: 15px;
	font-weight: 400;
	line-height: 20px;
	color: rgba(0, 0, 0, 0.5);
}

.afterCarousselCard2Element > p {
	font-size: 14px;
	line-height: 15px;
	font-weight: 500;
	color: #000;
	padding: 15px;
	text-align: justify;
}

.afterCarousselCard2 > div:nth-child(1),
.afterCarousselCard2 > div:nth-child(2),
.afterCarousselCard2 > div:nth-child(3) {
	margin-top: 85px;
}
.afterCarousselCard > div:nth-child(1),
.afterCarousselCard > div:nth-child(2),
.afterCarousselCard > div:nth-child(3) {
	margin-top: 2.5rem;
}

.afterCaroussel2 {
	margin-left: 1%;
}

@media (min-width: 320px) {
	.afterCaroussel > div > h2 {
		color: #000;
		line-height: 35px;
	}

	.investir-banner > div > div > p,
	.afterBanner > div > p {
		font-size: 80%;
		font-weight: 700;
		color: #000 !important;
	}
	.afterCarousselCard2Element {
		margin: auto 1rem;
	}
	.bannerPar1 {
		padding-top: 72px;
		padding-bottom: 49px;
		padding-left: 10px;
		white-space: nowrap;
	}

	.button-container {
		display: flex;
		margin-top: 20px;
		font-size: 50%;
		line-height: 32px;
		text-align: center;
		font-weight: 500;
	}

	.button1,
	.button2 {
		padding: 2px 10px;
		border-radius: 4px;
	}

	.afterBanner > div > h2 {
		font-size: 22px;
	}

	.afterCaroussel2 > div > h2 {
		font-size: 1.5rem;
		font-weight: 700;
		line-height: 48px;
	}
}

@media (min-width: 440px) {
	.afterCaroussel > div > h2 {
		color: #000;
		line-height: 35px;
	}
	.afterCarousselCard2Element > div > div h3 {
		font-size: 18px;
		font-weight: 500;
		line-height: 32px;
		color: rgba(0, 0, 0, 1);
	}

	.afterCarousselCard2Element > div > div h4 {
		font-size: 15px;
		font-weight: 400;
		line-height: 20px;
		color: rgba(0, 0, 0, 0.5);
	}

	.afterCarousselCard2Element > p {
		font-size: 14px;
		line-height: 18px;
		font-weight: 500;
		color: #000;
		padding: 15px;
		text-align: justify;
	}

	.afterCarousselCard2 > div:nth-child(1),
	.afterCarousselCard2 > div:nth-child(2),
	.afterCarousselCard2 > div:nth-child(3) {
		margin-top: 85px;
	}

	.afterCarousselCard2Element {
		margin: auto 10%;
	}
	.afterCaroussel2 > div > h2 {
		font-size: 32px;
		font-weight: 700;
		line-height: 48px;
	}
}

@media (min-width: 577px) {
	.investir-banner > div > div > p,
	.afterBanner > div > p {
		font-size: 95%;
		font-weight: 700;
		color: #000 !important;
	}

	.bannerPar1 {
		padding-top: 72px;
		padding-bottom: 49px;
		padding-left: 10px;
		white-space: nowrap;
	}

	.imgContainer {
		background-size: cover;
	}
}

@media (min-width: 768px) {
	.investir-banner > div > div > p,
	.afterBanner > div > p {
		font-size: 18px;
		font-weight: 700;
		color: #000 !important;
	}

	.bannerPar1 {
		padding-top: 72px;
		padding-bottom: 49px;
		padding-left: 55px;
		white-space: nowrap;
	}

	.button-container {
		display: flex;
		margin-top: 35px;
		font-size: 10px;
		line-height: 32px;
		text-align: center;
		font-weight: 500;
	}

	.afterBanner > div > h2 {
		font-size: 28px;
	}

	/* .afterCaroussel > div > h2 {
        font-size: 20px;
        line-height: 38px;
    } */

	.afterCarousselCard > div:nth-child(1),
	.afterCarousselCard > div:nth-child(2) {
		margin-right: -27px;
	}

	.afterCarousselCard2 > div:nth-child(1),
	.afterCarousselCard2 > div:nth-child(2),
	.afterCarousselCard2 > div:nth-child(3) {
		margin-top: 0;
	}

	.afterCarousselCard2 > div:nth-child(1),
	.afterCarousselCard2 > div:nth-child(2) {
		margin-right: 4%;
	}

	.afterCarousselCard2Element {
		margin: auto auto;
	}

	.afterCarousselCard2Element > div > div h3 {
		font-size: 20px;
		font-weight: 500;
		line-height: 32px;
	}

	.afterCarousselCard2Element > div > div h4 {
		font-size: 12px;
		font-weight: 400;
		line-height: 20px;
	}

	.afterCarousselCard2Element > p {
		font-size: 11px;
		line-height: 15px;
		font-weight: 500;
		color: #000;
		padding: 15px;
		text-align: justify;
	}

	.afterCarousselCard2Element > div img {
		float: left;
		position: absolute;
		border-radius: 50%;
		bottom: 50%;
		left: 2%;
		z-index: 1000;
		width: 70px;
		height: 70px;
	}

	.afterCarousselCard2 > div:nth-child(1),
	.afterCarousselCard2 > div:nth-child(2) {
		margin-right: -27px;
		margin: auto;
	}
}

@media (min-width: 1024px) {
	.investir-banner > div > div > p,
	.afterBanner > div > p {
		font-size: 20px;
		font-weight: 700;
		color: #000 !important;
	}

	.bannerPar1 {
		padding-top: 72px;
		padding-bottom: 49px;
		padding-left: 100px;
		white-space: nowrap;
	}

	.button-container {
		display: flex;
		margin-top: 35px;
		font-size: 15px;
		line-height: 32px;
		text-align: center;
		font-weight: 500;
	}

	.afterBanner > div > h2 {
		font-size: 26px;
	}

	/* .afterCaroussel > div > h2 {
        font-size: 28px;
        line-height: 48px;
    } */

	.afterCarousselCard > div:nth-child(1),
	.afterCarousselCard > div:nth-child(2) {
		margin-right: -27px;
	}

	.afterCarousselCard2Element > div > div h3 {
		font-size: 20px;
		font-weight: 500;
		line-height: 32px;
	}

	.afterCarousselCard2Element > div > div h4 {
		font-size: 12px;
		font-weight: 400;
		line-height: 20px;
	}

	.afterCarousselCard2Element > p {
		font-size: 11px;
		line-height: 15px;
		font-weight: 500;
		color: #000;
		padding: 15px;
		text-align: justify;
	}

	.afterCarousselCard2Element > div img {
		float: left;
		position: absolute;
		border-radius: 50%;
		bottom: 46%;
		left: -3%;
		z-index: 1000;
		width: 90px;
		height: 90px;
	}
}

@media (min-width: 1440px) {
	.imgContainer {
		background-size: contain;
	}

	.investir-banner > div > div > p,
	.afterBanner > div > p {
		font-size: 32px;
		font-weight: 700;
		color: #000 !important;
	}

	.bannerPar1 {
		padding-top: 72px;
		padding-bottom: 49px;
		padding-left: 150px;
		white-space: nowrap;
	}

	.button-container {
		display: flex;
		margin-top: 35px;
		font-size: 18px;
		line-height: 32px;
		text-align: center;
		font-weight: 500;
	}

	.button1,
	.button2 {
		padding: 12px 24px;
		border-radius: 4px;
	}

	.afterBanner > div > h2 {
		font-size: 32px;
	}

	/* .afterCaroussel > div > h2 {
        font-size: 28px;
        line-height: 48px;
    } */

	.afterCarousselCard > div:nth-child(1),
	.afterCarousselCard > div:nth-child(2) {
		margin-right: -27px;
	}

	.afterCarousselCard2Element > div > div h3 {
		font-size: 25px;
		font-weight: 500;
		line-height: 37.5px;
	}

	.afterCarousselCard2Element > div > div h4 {
		font-size: 18px;
		font-weight: 400;
		line-height: 24px;
	}

	.afterCarousselCard2Element > p {
		font-size: 14px;
		line-height: 23.82px;
		font-weight: 500;
		color: #000;
		padding: 15px;
		text-align: justify;
	}

	.afterCarousselCard2Element > div img {
		width: 105px;
		height: 105px;
	}
}

.section-container {
	margin-top: 0px;
	margin-bottom: 50px;
}
.qr-card-container {
	padding: 20px;
	background: var(--primaryColor);
	box-shadow: 0px 4px 43px rgba(0, 0, 0, 0.08);
}
.qr-card-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 19px;
	line-height: 28px;
	text-align: center;
	height: 60px;
    margin-left: 0;
    margin-top: 0;
}
.qr-card-textual-container,
.qr-card-textual-container p {
	height: 250px;
	font-family: Poppins;
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 24px;
	text-align: justify;
	color: var(--primarytextColor);
}
.qr-card-btn-container {
	text-align: center;
}
.qr-card-btn {
	background: var(--secondary-color) !important;
	border-radius: 4px !important;
	padding: 15px !important;
	border-color: var(--secondary-color) !important;
	font-family: DM Sans !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 18px !important;
	line-height: 175% !important;
}
.qr-card-col {
	margin-bottom: 30px;
}
.investir-page-couverture-container {
    background: var(--tertiareColore);
}
.investir-couverture-left-side {
	padding: 3% 0;
}
.investir-couverture-textual-container,
.investir-couverture-textual-container p {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 140%;
	color: #0f111d;
	margin-bottom: 22px;
}
.investir-couverture-btn-container {
    display: flex;
    margin-top: 3%;
}
.preference-link-container {
	padding-right: 25px;
}
.preference-link-btn {
	background: var(--secondary-color) !important;
	border-radius: 4px !important;
	border-color: var(--secondary-color) !important;
	padding: 13px !important;
	font-family: DM Sans !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 15px !important;
	line-height: 20px !important;
}
.preference-link-btn:hover {
	background: transparent !important;
	color: var(--secondary-color) !important;
}
.opportunities-link-btn {
	background: transparent !important;
	border-radius: 4px !important;
	border-color: var(--secondary-color) !important;
	padding: 13px !important;
	font-family: DM Sans !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 15px !important;
	line-height: 20px !important;
	color: var(--primarytextColor) !important;
}
.opportunities-link-btn:hover {
	background: var(--secondary-color) !important;
	color: var(--primaryColor) !important;
}
.offer-section-title-container{
	margin:6rem auto;
}
.offer-section-title {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 32px;
	line-height: 44px;
	text-align: center;
	color: var(--secondary-color);
}
.funding-donors-title {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	line-height: 28px;
	text-align: center;
	margin-bottom: 50px;
}
.funding-donors-section-container {
	padding-bottom: 90px;
}

/* Ameliorations */

.investir-offer-section-container {
	margin-top: 3rem;
	margin-bottom: 5rem;
}

.investir-offer-card,
.offer-card-header,
.offer-card-body {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.offer-card-profile-container {
	background-color: var(--tertiareColore);
	width: 100px;
	height: 100px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.offer-card-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 31px;
	text-align: center;
	color: var(--primarytextColor);
	margin-left: 0;
	margin-top: 1.3rem;
	margin-bottom: 1.3rem;
}

.offer-card-text {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 25px;
	text-align: center;
	color: var(--primarytextColor);
}

.offer-card-numero-container {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.offer-card-numero {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 22px;
	line-height: 31px;
	text-align: center;
	color: var(--secondary-color);
}

.offer-card-numero::after {
	content: "";
	display: block;
	height: 2px;
	width: 16px;
	background-color: var(--secondary-color);
	position: absolute;
	bottom: 13px;
}
.offer-card-numero-after-small::after {
	left: -4px;
}

.offer-card-numero-after-medium::after {
	left: -2px;
}

.participate-project-finance-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 36px;
	line-height: 44px;
	text-align: center;
	color: #0f111d;
	text-align: center !important;
	margin-bottom: 5rem;
}
.participate-project-finance-step {
	margin-bottom: 4rem;
}
.participate-project-finance-step-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 24px;
	text-align: center;
	color: var(--primarytextColor);
	margin-left: 0;
}

.participate-project-finance-step-text-container {
	padding-left: 2rem;
}

.participate-project-finance-step-text {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: var(--primarytextColor);
	text-align: justify;
}

.participate-project-finance-button-container {
    margin: 6rem 0;
    display: flex;
    justify-content: center;
}

.participate-project-finance-button  {
    background-color: var(--secondary-color);
	border: 1px solid var(--secondary-color);
    padding: 1.1rem 2.3rem;
    border-radius: 4px;
	font-family: DM Sans;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 175%;
	text-align: center;
	color: var(--primaryColor);
    text-transform: uppercase;
    text-decoration: none;
	transition: all ease 1s;

}

.participate-project-finance-button:hover {
	background-color: var(--primaryColor);
	color: var(--secondary-color);
    text-decoration: none;
}
.funding-donors-row{
	margin: 7rem auto;
}

@media screen and (max-width:767px) {

	.offer-section-title-container{
		margin:5rem auto;
	}
	.offer-section-title, participate-project-finance-title {
		font-size: 26px;
		line-height: 40px;
	}
}
.ccm-section-container {
    background: #F6F8F9;
    padding-top: 3%;
    padding-bottom: 3%;
}
.titre-ccm-investir {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 140%;
    text-align: center;
    color: var(--secondary-color);
}
.component-front-home {
	background-color: var(--primaryColor) !important;
}
.bloc-div-chiffre {
	background: var(--primaryColor);
	/* border: 1px solid #bb4411; */
	box-sizing: border-box;
	border-radius: 10px;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 1rem;
}
.icon-chiffre {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: bold;
	font-size: 48px;
	line-height: 62px;
	display: flex;
	align-items: center;
	letter-spacing: -0.025em;
	color: var(--primaryBorderColor);
}
.texte-chiffre {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 23px;
	display: flex;
	align-items: center;
	letter-spacing: -0.025em;
	color: var(--nonaryTextColor);
}
.bloc-icon-a-propos {
	position: absolute;
	padding: 3rem 0;
}
.bloc-image-a-propos {
	width: 100%;
}
.titre-a-propos-text {
	font-family: "Poppins";
	font-weight: 700;
	font-size: 55px;
	color: var(--secondary-color);
}
.sous-titre-ap-propos-text {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 19px;
	line-height: 10px;
	letter-spacing: -0.025em;
	color: var(--secondary-color);
}
.text-ap-propos-text {
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 27px;
	letter-spacing: -0.025em;
	color: var(--primarytextColor);
	text-align: justify;
}
.rs-progress-circle-info {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 50px;
	line-height: 75px;
	letter-spacing: -0.025em;
	color: var(--primarytextColor);
}
.text-progress-bar {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 30px;
	letter-spacing: -0.025em;
	color: var(--primarytextColor);
}
.bloc-info-team {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.text-info-team {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 15px;
	letter-spacing: -0.025em;
	/* color: gray; */
}
.team-item-social-container {
	display: flex;
	justify-content: space-between;
	width: 23%;
}
.titre-info-team {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 10px;
	letter-spacing: -0.025em;
	color: var(--primarytextColor);
}
.cards {
	background: var(--primaryColor);
}
.titre {
	font-family: "Poppins";
	font-size: "normal";
	font-weight: 700;
	line-height: 75px;
	letter-spacing: -2.5%;
	color: var(--primarytextColor) 70%;
	margin-left: 200px;
}
.titre {
	font-family: "Poppins";
	font-size: "normal";
	font-weight: 700;
	line-height: 75px;
	letter-spacing: -2.5%;
	color: var(--primarytextColor) 70%;
}

.circulaire {
	margin-top: 150px;
}
.card-back h4 {
	font-family: "Poppins";
	font-weight: 400;

	font-style: normal;
	line-height: 30px;
	letter-spacing: -2.5%;
	margin-top: 40px;
}
.team {
	margin: 100px;
	background-color: var(--primaryColor);
}

.img-cov {
	width: 900px;
}
/* circ */
.flex {
	flex: 1 1 auto;
}

@media (max-width: 991.98px) {
	.padding {
		padding: 1.5rem;
	}
}

@media (max-width: 767.98px) {
	.padding {
		padding: 1rem;
	}
}

.padding {
	padding: 5rem;
}

.progress.progress-md {
	height: 8px;
}
.template-demo .progress {
	margin-top: 1.5rem;
}

.progress {
	border-radius: 3px;
	height: 8px;
}

.pl-2,
.px-2,
.template-demo .circle-progress-block {
	padding-left: 0.5rem !important;
}

.pr-2,
.px-2,
.template-demo .circle-progress-block {
	padding-right: 0.5rem !important;
}

.mb-3,
.template-demo .circle-progress-block,
.my-3 {
	margin-bottom: 1rem !important;
}
.vjs-poster {
	border-radius: 20px;
}
.site-partenaire {
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.025em;
	color: var(--secondary-color);
}
.propos-sous-titre {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 30px;
	line-height: 45px;
	letter-spacing: -0.025em;
	color: var(--quaternaryTextColor);
}
.dessin-container {
	display: flex;
	justify-content: center;
}
.img-part{
	max-width: 50%;
}
.containerIn{
	background: var(--quaternary-color);
}
.rec.rec-dot {
    border-radius: 5px !important;
    border: 4px solid var(--secondary-color) !important;
	width: 7px !important;
	height: 1px;
	box-shadow: none !important;
}
.rec.rec-dot_active {
	border: 4px solid var(--quaternaryBorderColor);
	background: var(--quaternaryBorderColor) !important;
	box-shadow: none !important;
}
.containerIncubateur{
	background: var(--tertiaryColor);
}
.partiContact{
	margin-left: 12%;
}
.cardsPropos{
	background: var(--tertiareColore);
    padding: 5rem;
}
.projets-en-cours {
	padding-bottom: 10rem;
}
.activite-table-title {
	font-family: Poppins !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 22px;
	line-height: 30px;
	letter-spacing: -0.025em;
	color: var(--primarytextColor);
	margin-bottom: 20px;
}

.activite-table-title-no-finish {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 30px;
	letter-spacing: -0.025em;
	color: var(--primarytextColor);
	padding: 0%;
}

.activite table thead tr > th {
	font-family: Poppins !important;
	font-style: normal !important;
	font-weight: bold !important;
	font-size: 16px !important;
	line-height: 24px !important;
	color: var(--secondary-color) !important;
	padding: 13px;
}

.activite table tbody tr > td {
	font-family: Poppins;
	font-style: normal !important;
	font-size: 16px !important;
	line-height: 24px !important;
	color: gray !important;
	padding-top: 14px !important;
	padding-bottom: 12px !important;
}

.projets-en-cours h4 {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 30px;
	letter-spacing: -0.025em;
	color: var(--primarytextColor);
}

.activite-table-button {
	background: var(--primaryColor);
	border: 1px solid var(--secondary-color);
	box-sizing: border-box;
	border-radius: 5px;
	font-family: DM Sans;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	letter-spacing: -0.025em;
	color: var(--secondary-color);
	height: 30px;
	margin: 0;
}

.activite-table-button-detail {
	width: 90px;
}

.activite-table-button-detail {
	width: 63px;
}

.solde h3 {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 28px;
	line-height: 42px;
	letter-spacing: -0.025em;
	color: var(--primarytextColor);
}

.solde h4,
.statistiques h4 {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 21px;
	color: var(--secondary-color);
}

.solde h6,
.statistiques h6 {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: normal !important;
	font-size: 16px;
	line-height: 21px;
	color: rgba(0, 0, 0, 0.5);
}

.soldeContent,
.statistiques-prets-content,
.statistiques-gains-content {
	width: 220px !important;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	text-align: center;
}
.statistiques-gains {
	width: 220px;
}
.projets-en-cours-button {
	padding: 2px 5px;
	border: 1px solid var(--secondary-color);
	box-sizing: border-box;
	border-radius: 5px;
	font-family: DM Sans;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	letter-spacing: -0.025em;
	color: var(--secondary-color);
	text-align: center !important;
	cursor: pointer;
	background-color: transparent;
}

.projets-en-cours-button:hover {
	text-decoration: none;
}

.projets-en-cours-button-close {
	color: var(--secondary-color);
	text-align: center !important;
	background-color: transparent;
}

.modal-activite-container {
	width: 50% !important;
	top: 25% !important;
	left: 25% !important;
}

.activite-modal-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 25px;
	line-height: 30px;
	text-align: center !important;
	color: var(--primarytextColor);
}

.form-activite-modal-input-group {
	display: flex;
	justify-content: center;
	margin: 3rem 0;
}
.form-activite-modal-input {
	border: none;
	border-bottom: 1px solid #e0e0e0;
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 19px;
	color: rgba(0, 0, 0, 0.5);
	padding: 0;
}

.button-modal-activite-security,
.button-modal-activite-security:hover {
	background: var(--secondary-color);
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: var(--primaryColor);
	padding: 1.3rem 3.5rem;
	border-radius: 8px;
	text-decoration: none;
}

.activite-tabs-container {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
}

.activite-tab {
	font-family: Poppins;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 30px;
	color: var(--primarytextColor);
	opacity: 0.5;
	list-style: none;
	cursor: pointer;
}

.activite-tab.active {
	opacity: 1 !important;
	color: var(--primarytextColor) !important;
}

.project-img {
	width: 90%;
}

.project-text {
	width: 120%;
}

.project-finish-title {
	font-family: Poppins;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 28px;
}


.project-stat {
	font-family: Poppins;
	font-size: 13px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	letter-spacing: -0.025em;
	color: var(--secondary-color);
}
.project-statis {
	font-family: DM Sans;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 14px;
}

.color-pri {
	color: var(--secondary-color);
}

.color-sec {
	color: #999CA9;
}

.title-block h1 {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	color: rgba(0, 0, 0, 0.7);
	margin: 0;
}
@media screen and (max-width: 767px) {
	.modal-activite-container {
		width: 90% !important;
	}
	.activite-tabs-container {
		display: flex;
		align-items: flex-start;
		flex-direction: column !important;
	}
	.project-img {
		width: 100% !important;
	}
	
	.project-text {
		width: 100% !important;
	}
}

@media screen and (max-width: 1100px) {
	.modal-activite-container {
		width: 70% !important;
	}
}

.sidebar-customisation {
  /* padding: 40px 40px 150px 0; */
  height: 100%;
  max-width: 100%;
  width: 100%;
  background-color: var(--primaryColor);
  z-index: 999;
}

.sidebar-customisation-content {
  width: 100% !important;
}
.nav-link-sidebar-custom {
  font-family: "DM Sans" !important;
  font-style: normal;
  font-weight: normal;
  font-size: 25px !important;
  line-height: 33px !important;
  color: rgba(0, 0, 0, 0.5) !important;
  padding: 15px 20px 15px 0 !important;
  margin-left: 32px !important;
}

.nav-link-sidebar-custom:hover {
  color: var(--secondary-color) !important;
  background-color: transparent !important;
  transition: 0.2s ease;
}
.nav-link-sidebar-custom:focus {
  border: none !important;
}
.nav-link-sidebar-custom.actived {
  color: var(--secondary-color) !important;
  border-left: 2px solid var(--secondary-color) !important;
  font-weight: 800;
  text-decoration: none !important;
}
.nav-sidebar-items-custom {
  margin-top: 30px;
}
.component-sidebar-tableau-bord {
  /* position: fixed; */
  width: 100% !important;
  height: 100%;
}
.nav-link-sidebar-tb-custom {
  font-family: "DM Sans" !important;
  font-style: normal;
  font-weight: normal;
  font-size: 25px !important;
  line-height: 33px !important;
  color: rgba(0, 0, 0, 0.5) !important;
  padding: 15px 50px 15px 50px !important;
  white-space: nowrap;
}
.nav-link-sidebar-tb-custom.actived {
  color: var(--secondary-color) !important;
  /* border-bottom: 2px solid var(--secondary-color) !important; */
  font-weight: 800;
  text-decoration: none !important;
}
.component-sidebar-tableau-bord .nav-item {
  width: 100%;
}
.nav-items-tb-custom {
  width: 100%;
  min-height: 50px;
  flex-wrap: inherit !important;
  overflow: auto;
}
.nav-items-tb-custom::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 1024px) {
  .component-sidebar-tableau-bord {
    height: 100vh;
  }
}

.sidebar-customisation-content {
  padding-top: 30px !important;
  position: relative;
}

.nav.nav-items-custom:before {
  content: "";
  background: #c4c4c4;
  width: 1px;
  position: absolute;
  height: 100%;
  left: 32px !important;
}

.nav-link-sidebar-custom span {
  padding-left: 15px;
}

.actived {
	color: var(--secondary-color) !important;
}

.tabs-title {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
    line-height: 25px;
	display: flex;
	align-items: center;
	letter-spacing: -0.025em;
	color: var(--decimalTextColor);
	text-transform: uppercase;
}

.navLink {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 26px;
    color: rgba(0, 0, 0, 0.5);
    text-decoration: none;
}

.active {
	color: var(--secondary-color);
    text-decoration: none;
}

.navLink:hover, .navLink:focus {
    color: var(--secondary-color);
    text-decoration: none;
}


@media screen and (max-width: 1024px) {
    .tabsTitle {
        font-size: 20px;
    }
}

.redirection-project {
    font-family: Poppins;
    font-style: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: gray !important;
    padding-top: 14px !important;
    padding-bottom: 12px !important;
}

.redirection-project:hover {
    font-weight: 700 !important;
}
body{
  padding: 0;
  margin: 0 !important;
}

html{
  width: 100%;
  height: 100%;
}
.component-front-login {
  padding: 100px;
  background: rgba(245, 245, 245, 255);
  border-radius: 10px;
}

.connexion{
  height: 100vh;
  background: var(--septanaryColor);
}

.divLogo{
  background: var(--secondary-color);
  border-radius: 14px;
  /* height: 90vh; */
}

.divLogo p{
  color: var(--primaryColor);
}

.welcome-aem-front {
  width: 100%;
  height: 100%;
  background: var(--secondary-color);
  border-radius: 10px;
}

.formLogin-aem-front {
  width: 100%;
  height: 100%;
  background: var(--primaryColor);
  border-radius: 10px;
}
.title-welcome {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  line-height: 101.7%;
  letter-spacing: 0.315em;
  color: var(--primaryColor);
  font-size: 18px;
}

.bienvenue-style {
  padding: 46px;
}

.text-welcome-aem {
  font-family: "Poppins";
  font-style: bold;
  font-weight: 500;
  font-size: 54px;
  line-height: 101.7%;
  /* display: flex;
      align-items: center; */
  letter-spacing: 0.315em;
  color: var(--primaryColor);
}
.paragraphe-aem {
  width: 369px;
  height: 42px;
  left: 200px;
  top: 401px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 130.2%;
  display: flex;
  align-items: center;
  letter-spacing: -0.025em;
  color: var(--primaryColor);
}
.title-inscrivez-vous {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 47px;
  text-align: center;
  color: var(--primarytextColor);
}

.clean-border {
  border-left: 0px solid transparent !important;
  border-right: 0px solid transparent !important;
  border-top: 0px solid transparent !important;
  border-bottom: 1px solid var(--primaryBoxShadowColor) !important;
}

/* .border-input {
  width: 22px;
  height: 22px;
  border: 1px solid transparent var(--denaryTextColor) !important;
  box-sizing: border-box;
  border-radius: 5px;
} */

/* .btn-creer-compte {
  height: 50px !important;
  width: 200px !important;
  background: var(--denaryTextColor);
  color: var(--primaryColor);
  background-color: var(--denaryTextColor) !important;
  border-color: var(--denaryTextColor) !important;
  border-radius: 5px;
} */

.inscrivez-vous {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  text-align: center;
  color: var(--nonaryTextColor);
}

.position-formLogin {
  position: relative;
}

.position-icon-identifiant {
  position: absolute;
}
/* .mdpOublie-form-login {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 12px;
  color: #206285;
  text-decoration: none !important;
} */
/* .mdpOublie-form-login:hover {
  text-decoration: none !important;
  color: var(--nonaryTextColor) !important;
} */
/* .mdpOublie-form-login:hover {
  color: var(--denaryTextColor) !important;
  text-decoration: none !important;
} */

/* .color-icon {
  color: #848484;
} */
.position-forget {
  margin-left: 385px;
}

.decoration-ici:hover {
  text-decoration: none !important;
  color: var(--nonaryTextColor) !important;
}

/* .color-part2 {
  text-decoration: none !important;
  color: var(--denaryTextColor) !important;
} */
.position-btn {
  text-align: center;
  width: 100%;
}
/* .facebook-social-style {
  background-color: var(--denaryTextColor);
  color: white;
  padding: 21px;
  height: 70px;
  width: 70px;
  margin: 0 auto;
  text-align: center;
  border-radius: 50px;
} */
/* .facebook-social-style:hover {
  background-color: white;
  border: 1px solid var(--denaryTextColor);
  color: var(--denaryTextColor);
  padding: 21px;
  height: 70px;
  width: 70px;
  margin: 0 auto;
  text-align: center;
  border-radius: 50px;
  transform: scale(0.8);
} */
.google-social-style {
  background-color: var(--denaryTextColor);
  color: var(--primaryColor);
  padding: 21px;
  height: 70px;
  width: 70px;
  border-radius: 50px;
  margin: 0 auto;
  text-align: center;
  /* padding: 0 auto;
    padding-left: 29px;
    padding-top: 27px; */
  cursor: pointer;
}

/* .google-social-style:hover {
  background-color: white;
  border: 1px solid var(--denaryTextColor);
  color: var(--denaryTextColor);
  padding: 21px;
  height: 70px;
  width: 70px;
  margin: 0 auto;
  text-align: center;
  border-radius: 50px;
  /* padding: 0 auto;
    padding-left: 29px;
    padding-top: 27px; */
  /* cursor: pointer; */
  /* transform: scale(0.8); */


/* .twitter-social-style {
  background-color: var(--denaryTextColor);
  color: white;
  padding: 21px;
  height: 70px;
  width: 70px;
  margin: 0 auto;
  text-align: center;
  border-radius: 50px;
  /* padding: 0 auto;
    padding-left: 29px;
    padding-top: 27px; */
  /* cursor: pointer; */

/* .twitter-social-style:hover {
  background-color: white;
  border: 1px solid var(--denaryTextColor);
  color: var(--denaryTextColor);
  padding: 21px;
  height: 70px;
  width: 70px;
  margin: 0 auto;
  text-align: center;
  border-radius: 50px;
  /* padding: 0 auto;
    padding-left: 29px;
    padding-top: 27px; */
  /* cursor: pointer; */
  /* transform: scale(0.8); */

.in-progress-btn {
  width: 300px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  /* line-height: 21px; */
  /* letter-spacing: -0.025em; */
  color: var(--primaryColor);
  background: var(--denaryTextColor);
  box-shadow: 0px 4px 20px var(--quinaryBoxShadowColor);
  border-radius: 10px;
  padding: 1rem;
  white-space: nowrap;
}

.auth-form-container {
  background: var(--primaryColor);
  border-radius: 14px;
  height: 90vh;
}

.auth-form-title {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 47px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 60px;
}
.input-group.auth-form-group {
  position: relative;
  margin-bottom: 23px;
}
.input-group.auth-form-group .input-group-prepend {
  position: absolute;
  z-index: 999;
  top: 20%;
  left: 1%;
  background: transparent;
}
.input-with-icon {
  padding: 20px 45px;
  border: none;
}
.cv-input-with-icon {
  padding: 20px 31px !important;
  box-shadow: none !important;
  border: none;
  border-bottom: 1px solid var(--septanaryBorderColor);
}
.connexion .form-control{
  border: none;
  border-bottom: 1px solid var(--septanaryBorderColor);
  border-radius: 0;
  outline: none;
}

.connexion .form-control:focus{
  outline: none !important;
  box-shadow: none !important;
}



.span-input-icon {
  background: transparent !important;
  border: none !important;
  padding-top: 8px;
}
.auth-icon{
  width: 18px;
}
.mdpOublie{
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
}
.auth-submit-btn-container {
  margin-top: 30px;
}
.auth-submit-btn {
  background: var(--secondary-color);
  border-color: none;
  width: 50% !important;
  padding: 10px !important;
  font-family: Montserrat !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 17px !important;
  text-transform: uppercase !important;
}
.auth-submit-btn2 {
  background: var(--denaryTextColor) !important;
  border-color: var(--denaryTextColor) !important;
  width: 50% !important;
  padding: 10px !important;
  font-family: DM Sans !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 13px !important;
  line-height: 17px !important;
  text-transform: uppercase !important;
}
.auth-submit-btn:hover {
  background: transparent !important;
  color: var(--secondary-color) !important;
  border: 1px solid var(--secondary-color);
}
.auth-submit-btn2:hover {
  background: transparent !important;
  color: var(--secondary-color) !important;
}
.other-auth-action-row {
  margin-top: 20px;
}
.auth-alternative-action-container {
  text-align: center;
}
.auth-action-textual {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
}

.auth-other-action-text-container {
	display: flex;
	justify-content: center;
  position: relative;
}

.auth-other-action-text {
	font-family: Poppins;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	text-align: center;
  color: var(--octanarioTextColor);
}

.auth-other-action-text::before, .auth-other-action-text::after {
  content: "";
  display: block;
  width: 45% !important;
  height: 1px !important;
  background-color: var(--tertiaryColor);
  position: absolute;
  top: 10px;
  padding: 1px;
}
.auth-other-action-text::before {
  left: 0;
}
.auth-other-action-text::after {
  right: 0;
}

.auth-other-links-subscribe {
	margin-top: 2rem;
	display: flex;
	justify-content: center;
	grid-gap: 15%;
	gap: 15%;
}

.auth-other-link, .auth-social-btn {
	list-style: none;
  background: var(--quaternary-color);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.titreConnexion{
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 600;
  color: var(--primarytextColor);
}
/* .textConnectez{
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  color: #6B707B;
} */
.positionImageBleu{
  margin-left: 65%;
  margin-top: 38%;
}
.positionImageBleuInsc{
  margin-left: 18%;
  margin-top: 46%;
}
/* .labelAuth{
  color: #0A2141;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
} */
/* .span-icon-input{
  padding-top: 23px;
} */
@media (min-width:320px) and (max-width:767px){
    .positionImageBleu, .positionImageBleuInsc{
        display: none;
    }
}
@media (min-width:767px) and (max-width:991px){
   
      .positionImageBleu, .positionImageBleuInsc{
        display: none;
      }
}
@media (min-width:992px) and (max-width:1024px){
    .positionImageBleu {
        margin-left: 11%;
    }
    .positionImageBleuInsc {
        margin-top: 62rem;
    }
    .auth-form-container {
        padding: 0%;
    }
}
@media (min-width:320px) and (max-width:465px){
    /* .span-input-icon {
        padding-left: 2rem ;
    } */
}
@media (min-width:466px) and (max-width:678px){
    /* .span-input-icon {
        padding-left: 3rem ;
    } */
}
@media (min-width:679px) and (max-width:768px){
    /* .span-input-icon {
        padding-left: 4rem ;
    } */
}
/* @media (min-width:769px) and (max-width:789px){
    .span-input-icon {
        padding-left: 0rem ;
    }
} */
body{
	padding: 0;
	margin: 0 !important;
}
  
html{
	width: 100%;
	height: 100%;
}
.component-front-register {
	padding: 100px;
	background: rgba(245, 245, 245, 255);
	border-radius: 10px;
}

.inscription, .connexions{
	background: var(--septanaryColor);
}
.connexions{
	height: 100vh;
}  
.inscription .divLogo{
	background: var(--secondary-color);
	border-radius: 14px;
}

.inscription .auth-form-container {
  background: var(--primaryColor);
  border-radius: 14px;
}
.container-secondaire{
	background: var(--primaryColor);
}
.color-part1 {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 17px;
	color: var(--nonaryTextColor);
}

.color-part2 {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 17px;
	text-align: center;
	color: var(--secondary-color);
}

.color-part2:hover {
	color: var(--secondary-color);
}
.btn-inscription {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 21px;
	letter-spacing: -0.025em;
	color: var(--primaryColor);
	background: var(--secondary-color);
	box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
	border-radius: 10px;
	padding: 12px;
}
.btn-inscription:hover {
	text-decoration: none;
	color: var(--secondary-color);
	border: 1px solid var(--secondary-color);
	background: var(--primaryColor);
}
.position-icon-identifiant {
	position: absolute;
	margin-bottom: 5px;
}
/* .color-icon {
	color: #c4c4c4;
} */

.btn-theme-inscription {
	background: var(--secondary-color);
	font-family: "DM Sans";
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	line-height: 17px;
	text-align: center;
	color: var(--primaryColor);
	padding: 1rem !important;
	width: 100%;
	border-radius: 10px;
	outline: none !important;
}

.auth-checkbox-group {
	display: flex;
	align-items: center;
	margin-bottom: 25px;
}
input.auth-checkbox-input {
	width: 25px;
	height: 25px;
	padding-top: 2px !important;
	cursor: pointer;
}
input.auth-checkbox-input:before {
	font: 14pt "FontAwesome";
	content: "\f00c";
	display: inline;
	padding-left: 4px;
	color: var(--primaryColor);
}
.auth-checkbox-input:checked {
	background-color: var(--secondary-color); 
  }
.auth-checkbox-input-container {
	padding-right: 15px;
}

.auth-checkbox-label-container {
	font-family: DM Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 17px;
	color: var(--nonaryTextColor);
}
.auth-link {
	color: var(--secondary-color) !important;
	font-weight: 600;
}
.auth-greening {
	color: var(--secondary-color) !important;
	font-weight: 600;
}

.auth-darking {
	color: var(--primarytextColor) !important;
	font-weight: 600;
}

.react-tel-input .flag-dropdown.open {
	z-index: 9999 !important;
}

.password-validation-terms-row {
  border-bottom: none !important;
}
.password-validation-terms-title {
  font-size: 16px;
  color: var(--secondary-color) !important;
}
.min-character-uppercase-ok,
.min-character-lowercase-ok,
.min-character-number-ok,
.min-character-count-ok,
.min-character-special-ok {
  color: var(--secondary-color) !important;
  font-weight: 700;
}
.phonePadding{
	margin-bottom: 23px !important;
	border: none;
	/* border-bottom: 1px solid var(--septanaryBorderColor); */
}
.react-tel-input .form-control{
	border: none !important;
	border-bottom: 1px solid var(--septanaryBorderColor) !important;
	border-radius: 0 !important;
	margin-left: 10px !important;
}

.react-tel-input .selected-flag, .react-tel-input .flag-dropdown{
	border: none !important;
	background: transparent !important;
}

.unMargin{
	padding-left: 6% !important;
}

.inscription .form-control{
	border: none;
	border-bottom: 1px solid var(--septanaryBorderColor);
}
.banner-liste-projet {
	background: var(--tertiaryColor);
	padding: 10rem;
}
.component-projet-item {
	background: var(--primaryColor);
	border-radius: 15px;
	font-family: "Raleway", sans-serif;
	width: 90%;
}
.text-sub-title {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: -0.025em;
	color: var(--septenaryTextColor);
}
/* .card-style {
	background-color: #f2f2f2;
	box-shadow: 0px 4px 10px var(--primaryBoxShadowColor);
} */
.component-projet-item .card-title {
	color: var(--primaryTextColor) !important;
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 31px;
	display: flex;
	align-items: center;
	letter-spacing: -0.025em;
}
.sub-title-liste-projet {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 31px;
	letter-spacing: 0.5em;
	color: var(--primaryTextColor);
}
.text-pourcentage {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	line-height: 19px;
	display: flex;
	align-items: center;
	letter-spacing: -0.025em;
	color: var(--secondary-color);
}
.sub-title-liste-projet-theme {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 31px;
	letter-spacing: 0.5em;
	color: var(--tertiaryColor);
}
.filter-label {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 22px;
	line-height: 33px;
	color: var(--primaryTextColor);
}

.text-progress-2 {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 140%;
	color: var(--septenaryTextColor);
}
.liste-href a {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
	color: var(--secondary-color);
}

.text-voir-plus {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
	color: var(--secondary-color);
}
.text-voir-plus:hover {
	text-decoration: none;
	color: var(--secondary-color);
}
.pagination > li > a,
.pagination > li > span {
	position: relative !important;
	padding: 4px 10px;
	margin-left: -1px;
	line-height: 1.42857143;
	color: var(--secondary-color) !important;
	text-decoration: none;
	background-color: var(--primaryColor);
	border: none !important;
}

.text-progress {
	color: var(--secondary-color);
}
.card-button {
	position: relative;
}
.text-amorcage {
	color: var(--duodenary);
}
.text-amorcage-bleu {
	color: var(--secondary-color);
}
.text-amorcage-verte {
	color: var(--undenaryTextColor);
}
.card-button-text {
	position: relative;
}
.dette-amorcage {
	top: 20px;
	position: absolute;
	display: flex;
	justify-content: space-between;
	width: 100%;
}
.btn-financer-dette {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	color: var(--primaryColor);
	background: var(--denaryTextColor);
	border: 1px solid var(--denaryTextColor);
}
.btn-financer-dette-bleu {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	color: var(--primaryColor);
	background: var(--secondary-color);
	border: 1px solid var(--secondary-color);
}
/* .btn-financer-dette-verte {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	color: var(--primaryColor);
	background: var(--undenaryTextColor);
	border: 1px solid var(--undenaryTextColor);
} */
/* .btn-financer-dette-bleu-clair {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	color: var(--primaryColor);
	background: #56ccf2;
	border: 1px solid #56ccf2;
} */
.participant {
	font-family: DM Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 140%;
	color: var(--secondary-color);
}
.date-text {
	font-family: DM Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 140%;
	color: var(--septenaryTextColor);
}
.btn-financer-projet {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	color: var(--primaryColor);
	background: var(--secondary-color);
	border-radius: 10px;
	padding: 1rem 2rem 1rem 2rem;
	width: 100%;
}
.btn-financer-projet-red {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	color: var(--primaryColor);
	background: var(--secondary-color);
	border-radius: 10px;
	padding: 1rem 2rem 1rem 2rem;
	width: 100%;
}

/* .btn-financer-projet-red {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	color: var(--primaryColor);
	background: rgba(254, 24, 24, 0.7);
	border-radius: 10px;
	padding: 1rem 2rem 1rem 2rem;
	width: 100%;
} */

.selectInput {
	height: 44px;
	border: 1px solid var(--secondaryBorderColor);
	box-sizing: border-box;
	border-radius: 10px;
	outline: none;
	background-color: var(--primaryColor);
	font-family: Poppins !important;
	font-style: normal;
	-webkit-appearance: none;
	        appearance: none;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	color: var(--primaryTextColor);
	cursor: pointer;
	width: 200px;
}

.selectInput option {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;

	color: var(--primarytextColor);
}

.filterLabel {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 22px;
	line-height: 33px;
	/* identical to box height */

	color: var(--primarytextColor);
}

.project-item-card-img-container {
	position: relative;
}
.project-funding-type-label-container {
	position: absolute;
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
    padding: 15px;
}
.project-funding-type {
	padding: 3px 10px;
	text-transform: capitalize;
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 21px;
	color: var(--primaryColor);
}

.project-funding-type.equity-item {
	border: 1px solid var();
	box-sizing: border-box;
	border-radius: 4px 0px;
	background: var(--denaryTextColor);
}
.project-funding-label {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 21px;
	text-transform: capitalize;
}
.project-funding-label.amorcage-item {
	color: var(--denaryTextColor);
}
.project-funded-pourcentage,
.project-funded-pourcentage p {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	line-height: 19px;
	color: var(--secondary-color) !important;
}
.project-funding-container,
.project-funding-container p {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	line-height: 19px;
	color: var(--secondary-color);
}
.project-dates-container,
.project-dates-container p {
	font-family: DM Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 140%;
	color: var(--septenaryTextColor);
}
.project-funding-donor-counting,
.project-funding-donor-counting p {
	font-family: DM Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 140%;
	color: var(--secondary-color);
}
.project-item-name {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 140%;
	color: var(--secondaryTextColor);
	padding: 0 15px;
}
.project-description-container {
    min-height: 100px;
}
.project-description-container,
.project-description-container p {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	text-align: justify;
	color: var(--quinaryTextColor);
	padding: 0 7px;
}
.fund-project-btn {
	background: var(--secondary-color) !important;
	border-radius: 4px !important;
	width: 90% !important;
	padding: 12px !important;
	font-family: Poppins !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 14px !important;
	line-height: 21px !important;
	color: var(--primaryColor) !important;
}

.disabled-btn{
	border-radius: 4px !important;
	width: 90% !important;
	padding: 12px !important;
	font-family: Poppins !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 14px !important;
	line-height: 21px !important;
	color: var(--primaryColor) !important;
}

/* .project-funding-type.don-item {
	border: 1px solid var(--undenaryTextColor);
	box-sizing: border-box;
	border-radius: 4px 0px;
	background: var(--undenaryTextColor);
} */

/* .project-funding-label.early-state-item {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	color: var(--undenaryTextColor);
} */

/* .start-notification-btn {
	background: rgba(254, 24, 24, 0.7);
	border-radius: 4px;
	width: 90%;
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	color: var(--primaryColor);
	padding: 12px !important;
} */

.project-funding-type.dette-item {
	border: 1px solid var(--secondary-color);
	box-sizing: border-box;
	border-radius: 4px 0px;
	background: var(--secondary-color);
}

.project-funding-label.pre-seed-item {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	color: var(--secondary-color);
}
.project-item-container {
	margin-bottom: 1%;
}
/* .project-funding-type.love-money-item {
	border: 1px solid #56ccf2;
	box-sizing: border-box;
	border-radius: 4px 0px;
	background: #56ccf2;
} */
.project-item-card-body {
	background: var(--primaryColor);
	box-shadow: 0px 2px 4px var(--senaryBoxShadowColor);
}
.label-project-filter {
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
	color: var(--nonaryTextColor);
}

/* *{border: 2px solid red} */
.text-justefy {
	text-align: start !important;
}
.border-vide {
	margin-top: 3em;
	margin-bottom: 3em;
	border-right: 2px dashed var(--secondary-color);
	position: absolute;
	left: 45%;
	position: absolute;
	height: 80%;
}
.marche-plateform-container{
	padding-left: 4rem !important;
    padding-right: 4rem;
    max-width: 1500px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
}
.financement-participatif{
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 75px;
	text-align: center;
	color: var(--secondary-color);
}
.container-financement-participatif {
    background: var(--quaternary-color);
}
.couverture-description-text-color,p{
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 27px;
	color: var(--primarytextColor);
}
.nous-sommes-title{
	font-family: "Poppins";
	font-style: italic;
	font-weight: normal;
	font-size: 14px;
	line-height: 27px;
	color: var(--primarytextColor);
	text-align: center;
	margin-left: 5%;
}
.number {
	position: absolute;
	color: var(--secondary-color);
	font-size: 2em;
	font-weight: bold;
	top: -55px;
	right: -16px;
}

.sub-title-theme {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 21px;
	letter-spacing: -0.025em;
	color: var(--secondary-color);
}
.about-item {
	/* background: #f3f3f4; */
	border: 0.25px solid var(--secondary-color);
	box-sizing: border-box;
	border-radius: 10px;
	border-top: 13px solid var(--secondary-color) !important;
	padding: 2rem 32px 5rem;
	width: 300px;
}
/* .ytp-cued-thumbnail-overlay-image {
	background-image: url(../../../images/detail.png) !important;
} */
.item-process-container-mobile {
	border-radius: 10px;
	padding: 2rem 4px 5rem;
	width: 300px;
	float: left;
	position: relative;
}
.process-finanement-mobile {
	width: 100%;
	min-height: 100px;
	flex-wrap: inherit !important;
	overflow: auto;
}
.num-process-mobile {
	background: var(--primaryColor);
	border-radius: 10px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 29px;
	letter-spacing: 0.26em;
	color: var(--secondary-color);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 60.8px;
	height: 60.8px;
}
.title-mobile-process {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 28px;
	color: var(--primarytextColor);
}
.color-theme {
	color: var(--secondary-color) !important;
}
.about-item-mobile {
	padding: 1rem 32px 1rem 32px !important;
}
.img-qsn {
	width: 100%;
}
.img-height {
	height: 700px;
}
.qsn-img-bottom {
	height: 550px;
}
.text-paragraph {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 165.2%;
	letter-spacing: -0.025em;
	color: var(--primarytextColor);
	text-align: justify;
}
.text-hide {
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-box !important;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	white-space: normal;
}
.aprops-page-title {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 30px;
	line-height: 75px;
	text-align: center;
	margin-top: 0;
	color: var(--quaternaryTextColor);
}
.ccm-section-title {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 43px;
	color: var(--secondary-color);
}
.ccm-content {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 165.2%;
	letter-spacing: -0.025em;
	/* color: var(--quaternaryTextColor); */
	margin-bottom: 45px;
}
.ccm-content p {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 165.2%;
	letter-spacing: -0.025em;
	/* color: var(--quaternaryTextColor); */
}
.ccm-video {
	left: 0;
	right: 0;
	/* margin-bottom: 17%; */
	background: transparent !important;
}
.process-container {
	margin-top: 7%;
}
.process-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 40px;
	text-align: center;
	margin-bottom: 20px;
	color: var(--secondary-color);
	margin-bottom: 5rem;
}
.sub-section-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	line-height: 30px;
	color: var(--secondary-color);
}

/**** Ameliorations ****/
.step-how-it-works-img-container {
	display: flex;
	justify-content: center;
}
.w-70 {
	width: 75%;
}
.step-how-it-works-details-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.step-how-it-works-title {
	font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    color: var(--secondary-color);
}

.step-how-it-works-detail-title {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: bold;
	font-size: 26px;
	line-height: 32px;
	letter-spacing: -0.025em;
	color: var(--secondary-color);
	margin-bottom: 2rem;
	margin-top: 0;
}
.step-how-it-works-detail-title-politique {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 32px;
	letter-spacing: -0.025em;
	color: var(--secondary-color);
	margin-bottom: 2rem;
	margin-top: 0;
}


.step-how-it-works-text {
	font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: var(--quaternaryTextColor);
}

/* type investment */
.type-investment-title-container {
	display: flex;
	align-items: center; 
	grid-gap: 5px; 
	gap: 5px;
}
.type-investment-title-number {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 50px;
	letter-spacing: -0.025em;
	color: var(--secondary-color);
	margin-left: 12px;
}
.text-risque{
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 165.2%;
	letter-spacing: -0.025em;
	color: var(--quaternaryTextColor);
}
.works-type-text{
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 165.2%;
	letter-spacing: -0.025em;
	color: var(--quaternaryTextColor);
	list-style: none;
}

.tout-investissement{
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 25px;
	line-height: 51px;
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: -0.025em;
	color: var(--senarioTextColor);
}
.container-investissement-risque{
	background: var(--octonaryColor);
	border: 1px solid var(--quinaryBorderColor);
	box-sizing: border-box;
}

.type-investment-title {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 30px;
	margin-left: 5%;
	letter-spacing: -0.025em;
	color: var(--secondary-color);
	margin:0;
}
.type-investment-sous-title{
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	margin-left: 5%;
	letter-spacing: -0.025em;
	color: var(--secondary-color);
	margin: 13px;
}
.type-investment-title-royal {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	color: var(--secondary-color);
}

.step-how-it-works-type-text-container {
	padding-left: 5rem;
}

.step-how-it-works-type-text {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 27px;
	letter-spacing: -0.025em;
	color: var(--quinaryTextColor);
	text-align: justify;
}
.step-how-it-works-type-text-royal {
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 27px;
	letter-spacing: -0.025em;
	color: var(--quinaryTextColor);
	text-align: justify;
}

.step-works-royaltie-content {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.tab-slide-title {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	color: var(--secondary-color);
}
.table-comparatif th {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	text-align: center;
}

.table-thead {
	--bs-table-bg: var(--secondary-color) !important;
}

.table-comparatif tbody tr th {
	padding-top: 2rem !important;
}

.table-comparatif td {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 25px;
	letter-spacing: -0.025em;
	color: var(--primarytextColor);
	text-align: justify;
}
.table>thead>tr>th{
	color: var(--primaryColor);
}

@media screen and (max-width: 767px) {
	.step-how-it-works-type-text-container {
		padding-left: 0;
	}
	.step-how-it-works-details-container {
		min-height: auto;
	}
	.step-how-it-works-title {
		font-size: 24px;
	}
}
.table-thead tr th:first-child {
    background: var(--primaryColor);
}
/* @import url('../../../colors.css'); */
.component-front-home {
	background-color: var(--primaryColor) !important;
	box-sizing: border-box;
}
.content-view-start {
	position: relative;
}
.ips {
	position: absolute;
	left: 300px;
	height: 500px;
	top: 300px;
	z-index: 199;
}
.hauteurCarousel{
	height: 55rem;
}
.rec.rec-arrow.rec.rec-arrow-right, .rec.rec-arrow.rec.rec-arrow-left{
	display: none;
}
.marginhome {
	margin-bottom: 150px;
}
.titlepatrimoine {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 37px;
	line-height: 55px;
	color: var(--primarytextColor);
}
.texte-patrimoine {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 32px;
	line-height: 140%;
	color: var(--secondaryTextColor);
}
.titre-image-illustration {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 37px;
	line-height: 55px;
	color: var(--primarytextColor);
}
.texte-image-illustration {
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 27px;
	letter-spacing: -0.025em;
	color: var(--quinaryTextColor);
}
.imgpatrimoine {
	margin-top: -110px;
}
.bloc-div-img-right {
	margin-top: -11%;
}
.topimg {
	position: absolute;
	right: 51px;
	top: -34px;
	width: 100px;
	background: var(--primaryColor);
	box-shadow: 0px 13px 34px var(--secondary-color);
	border-radius: 20px;
	padding: 30px;
}

.topimg2 {
	position: absolute;
	left: 55px;
	top: -48px;
	width: 100px;
	background: var(--primaryColor);
	box-shadow: 0px 13px 34px var(--secondary-color);
	border-radius: 20px;
	padding: 30px;
}
.newleter {
	background: var(--tertiaryColor);
	padding-top: 2rem;
	padding-bottom: 2rem;
}
.imgnewleter {
	/* opacity: 0.2; */
	padding: 15px 0;
}
.titrenewleter {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 33.203px;
	line-height: 44px;
	color: var(--quaternaryTextColor);
}
/* .texte-newleter {
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 21.25px;
	line-height: 28px;
	color: #141b2d;
} */

.inputnewleter {
	background: var(--primaryColor);
	border: 1px solid var(--primaryBorderColor);
	box-sizing: border-box;
	box-shadow: inset 0px 1px 5px var(--primaryBoxShadowColor);
	border-radius: 4px;
	height: 48px;
}
.btn-newleter {
	border: 1px solid var(--secondary-color) !important;
	box-sizing: border-box;
	border-radius: 4px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 29px;
	color: var(--secondary-color);
}
.titrepartenaire {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	letter-spacing: 0.505em;
	color: var(--secondary-color);
}
.textepartenaire {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 32px;
	line-height: 21px;
	color: var(--secondaryTextColor);
}
.txtsurlignepartenaire {
	color: var(--secondary-color);
}
.imgpartenaire {
	width: 100%;
	padding: 50px;
}
/* .imgpartenaireparent {
	background: var(--primaryColor);
	box-shadow: 0px 0px 0px 1px var(--tertiaryBoxShadowColor);
	border-radius: 5px;
	margin-bottom: 60px;
	box-sizing: border-box;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
} */

.titrefaq {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 32px;
	line-height: 140%;
	margin: auto;
	color: var(--secondaryTextColor);
	width: 100px;
}
/* .titrefaqquestion {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 36px;
	letter-spacing: -0.025em;
	color: #010101;
} */
/* .faqtexte {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.025em;
	color: #2d2d2d;
} */
/* .faqtextecontent {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.025em;
	text-align: justify;
	color: #2d2d2d;
} */

.home-section-title {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 44px;
	line-height: 140%;
	text-align: center;
	color: var(--secondary-color);
}
.dev-textual-title {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 30px;
	color: var(--primarytextColor);
	margin-left: 0 !important;
}

.dev-textual-content {
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 27px;
	text-align: justify;
	color: var(--quinaryTextColor);
}
.home-section-row {
	display: flex !important;
	align-items: center !important;
}

.dev-illustration {
	position: relative;
}
.dev-image {
	box-shadow: 0px 8px 104px var(--secondaryBoxShadowColor);
	border-radius: 20px;
}
.dev-image1 {
	position: absolute;
	left: 0;
	top: 0;
}
.dev-image2 {
	position: absolute;
	top: -113px;
	left: -8%;
}
.illustration-icon {
	position: absolute;
	top: -30px;
	right: -8%;
	width: 15%;
	background: var(--primaryColor);
	box-shadow: 0px 13px 34px var(--primaryIconColor);
	border-radius: 20px;
	padding: 13px;
}
.illustration-icon-lefty {
	position: absolute;
	left: -8%;
	top: -40px;
	width: 15%;
	background: var(--primaryColor);
	box-shadow: 0px 13px 34px var(--primaryIconColor);
	border-radius: 20px;
	padding: 13px;
}
.newsletter-section-container {
	background: var(--tertiaryColor);
	padding: 40px 100px;
	margin-top: 8%;
	margin-bottom: 4%;
}
.newsletter-row {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}
.newsletter-input-label {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 33.203px;
	line-height: 44px;
	color: var(--quaternaryTextColor);
	margin: 8px 0;
}

.newsletter-textual-body {
	margin-bottom: 25px;
}
.newsletter-form-body {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.newsletter-input-group {
	width: 50%;
	padding-right: 10px;
}
.newsletter-input-form-control {
	background: var(--primaryColor) !important;
	border: 1px solid var(--primaryBorderColor) !important;
	box-sizing: border-box;
	box-shadow: inset 0px 1px 5px var(--primaryBoxShadowColor) !important;
	border-radius: 4px !important;
	padding: 20px 15px !important;
	font-family: Poppins !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 16px !important;
	line-height: 27px !important;
	color: var(--senaryTextColor) !important;
	height: 48px !important;
}
.newsletter-submit-btn {
	padding: 9px 20px !important;
	border-color: var(--secondary-color) !important;
	box-sizing: border-box !important;
	border-radius: 4px !important;
	background: transparent !important;
	color: var(--secondary-color) !important;
	font-family: Poppins !important;
	font-style: normal !important;
	font-weight: bold !important;
	font-size: 16px !important;
	line-height: 24px !important;
}
.newsletter-submit-btn:hover {
	background: var(--secondary-color) !important;
	color: var(--primaryColor) !important;
}
.newsletter-icon {
	width: 80%;
}
.partenaire-section-description {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 140%;
}
.partenaire-section-container {
	padding-top: 5%;
}
.partenaire-card-content {
	margin-bottom: 30px;
}
.partenaire-logo-container {
	background: var(--primaryColor);
	box-shadow: 0px 0px 0px 1px var(--tertiaryBoxShadowColor);
	border-radius: 5px;
	padding: 25px;
	text-align: center;
}
.partenaire-logo {
	height: 40px;
}
.partenaire-logo-col {
	margin-bottom: 40px;
}
/* .faq-section-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 32px;
	line-height: 140%;
	text-align: center;
} */
/* .faq-subtitle {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 36px;
	display: flex;
	align-items: center;
	letter-spacing: -0.025em;
	color: #010101;
} */
.faq-section-container {
	padding-bottom: 100px;
}
/* .faq-item-title {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.025em;
	color: #2d2d2d;
} */
.container.faq-section-container p {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 300;
	font-size: 12px;
	line-height: 22px;
	text-align: justify;
}
/* .faq-action-btn-container {
	text-align: right;
} */
/* .faq-link {
	background: var(--secondary-color) !important;
	border-radius: 10px !important;
	font-family: Poppins !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 20px !important;
	line-height: 30px !important;
	color: var(--primaryColor) !important;
	padding: 10px 45px !important;
	border-color: var(--secondary-color) !important;
} */
/* .faq-link:hover {
	background: transparent !important;
	color: var(--secondary-color) !important;
} */
.section-a-propos-home {
	padding-top: 4%;
	padding-bottom: 4%;
}
/* .text-faq-accueil {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.025em;
	color: #2d2d2d;
} */

/************ Home-project-section ************/

.sous-titre-ccm {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 23px;
    line-height: 34px;
    letter-spacing: -0.025em;
    color: var(--secondary-color);
}
.text-num-ccm {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.025em;
    text-transform: uppercase;
    color: var(--secondary-color);

}
.texte-ccm-home {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 140%;
    color: var(--secondaryTextColor);

}
.btn-en-savoir-plus {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: var(--secondary-color);

}
.btn-en-savoir-plus:hover {
    text-decoration: none;
    color: var(--secondary-color);
    font-weight: bold;
}
.disabled-btn{
	cursor: not-allowed !important;
}
.textEnregistrer{
	font-weight: 700;
	font-size: 29.06px;
	text-align: center;
	color: var(--quaternaryTextColor);
	padding: 0 0 3% 0;
}
.raise-money-container-home{
	background: var(--tertiaryColor);
}
.rec-item-wrapper{
	height: 387px;
    padding: 20px !important;
}
.containerTitreCarousel{
	color: var(--quaternaryTextColor);
	font-weight: 700;
	font-size: 14px;
}
.containerDescCarousel{
	color: var(--quaternaryTextColor);
	font-weight: 400;
	font-size: 10px;
}

.component-home-banner {
  background-image: url(/static/media/unsplash_wYTd-B7BdoQ.ad55e10d.png);
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
}
.close {
  font-size: 32px !important;
  color: black !important;
  background: transparent !important;
}
.ips {
  position: absolute;
  left: 300px;
  height: 500px;
  top: 300px;
  z-index: 199;
}
.customModal {
  width: 100%;
  height: 400px;
  position: absolute;
  top: 100px;
}
.finance {
  padding-left: 3rem !important;
  padding-right: 3rem;
}
/* .bloc-banner-1 {
  background: rgba(230, 110, 59, 0.28);
  border-radius: 10px;
  width: 276.36px;
  height: 223.68px;
  position: absolute;
  top: 157px;
  left: 13%;
  z-index: 1;
}
.bloc-banner-2 {
  background: rgba(230, 110, 59, 0.28);
  border-radius: 10px;
  width: 172.49px;
  height: 139.61px;
  position: absolute;
  top: 75px;
  right: 19%;
  z-index: 11;
}
.bloc-banner-3 {
  background: rgba(230, 110, 59, 0.28);
  border-radius: 10px;
  width: 101.24px;
  height: 81.94px;
  position: absolute;
  top: 604px;
  right: 45%;
  z-index: 1;
} */
.bloc-banner-text {
  flex-wrap: wrap;
  z-index: 99999;
  position: absolute;
  left: 20%;
  top: 140px;
}
.titre-banner {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 140%;
  color: var(--primaryColor);
}
.bloc-banner-btn {
  margin-top: -220px;
  left: -12px;
  z-index: 99;
}
.btn-investir-banner {
  background: var(--secondary-color);
  border-radius: 4px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 175%;
  text-align: center;
  color: var(--primaryColor);
  padding: 1rem !important;

  border: 1px solid var(--secondary-color) !important;
}
.btn-investir-banner:hover {
  color: var(--secondary-color);
  background-color: var(--primaryColor);
  border: 1px solid var(--secondary-color);
  text-decoration: none;
}
.btn-submit-banner {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  text-align: center;
  background: transparent;
  border-radius: 4px;
  padding: 1rem !important;
  border: 1px solid white !important;
  box-sizing: border-box;
  color: var(--primaryColor);
}
.text-light {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: var(--primaryColor);
}

.btn-submit-banner:hover {
  color: var(--primaryColor) !important;
  background-color: var(--secondary-color) !important;
  border: none !important;
  text-decoration: none;
}
.bloc-banner-right-1 {
  border-radius: 10px;
  width: 308px;
  height: 309px;
  background-color: #e66e3b;
  position: absolute;
  top: 357px;
  left: 17%;
  z-index: 11;
}
.bloc-banner-right-2 {
  background: linear-gradient(
      0.2deg,
      var(--primarytextColor) 0.18%,
      rgba(255, 255, 255, 0) 101.72%
    ),
    #c4c4c4;
  border-radius: 10px;
  width: 563px;
  height: 556px;
  position: absolute;
  top: 80px;
  left: 20%;
  z-index: 9999;
}
.bloc-banner-right-3 {
  background: var(--primaryColor);
  border-radius: 10px;
  width: 174px;
  height: 174px;
  position: absolute;
  top: 490px;
  left: 62%;
  z-index: 1;
}
.thumbs-wrapper.axis-vertical {
  display: none;
}
p.carousel-status {
  display: none;
}
.carousel .control-next.control-arrow:before {
  border-left: none !important;
}
.carousel .control-prev.control-arrow:before {
  border-right: none !important;
}
.btn-see-more {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: var(--primaryColor);
  border: 1px solid var(--primaryColor);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0.5rem;
}
.btn-see-more:hover {
  background-color: var(--primaryColor);
  color: #000;
  border: 1px solid #000;
  text-decoration: none;
}
.title-legend {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 29px;
  line-height: 38px;
  letter-spacing: -0.025em;
  color: var(--primaryColor);
}
.text-legend {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.025em;
  color: #dedede;
}
.carousel .slide .legend {
  text-align: left !important;
  background-color: transparent !important;
  padding: 30px !important;
  transition: all 0.5s ease-in-out;
  transition-property: none !important;
}
.bloc-banniere-v2 {
  padding-top: 11%;
}

.couverture-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 140%;
  color: var(--primaryColor);
  text-transform: uppercase;
  width: 60%;
}
.container.couverture-container {
  padding: 50px;
}
.couverture-description-container, 
.couverture-description-container p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  /* width: 60%; */
  color: var(--primaryColor);
  text-align: left;
}
.couverture-content-container {
  margin-bottom: 50px;
  padding-top: 5%;
}
.couverture-actions-btn-container {
  display: flex;
  justify-content: flex-start;
  width: 60%;
}
.funding-btn-container {
  padding-right: 40px;
}
.funding-btn {
  background: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
  padding: 9px 30px !important;
  border-radius: 4px !important;
  font-family: DM Sans !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 175% !important;
  color: var(--primaryColor) !important;
  outline: none !important;
}
.funding-video-btn {
  padding: 9px 30px !important;
  background: var(--primaryColor);
  border-radius: 4px !important;
  font-family: DM Sans !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 175% !important;
  color: var(--secondary-color) !important;
  outline: none !important;
}
.funding-btn:hover {
  color: var(--secondary-color) !important;
  background: var(--primaryColor) !important;
}
.funding-video-btn:hover {
  background: var(--secondary-color) !important;
  color: var(--primaryColor) !important;
}

/**************** banner-icon-down-container *************/
.banner-icon-down-container{
  position: absolute;
  width: 100%;
  bottom: 5px;
}

.banner-icon-container{
  width: 100%;
  display: flex;
  justify-content: center;
}
.text-bold {
    font-weight: bold;
}
.text-et-banniere {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 46px !important;
    line-height: 140%;
    text-transform: uppercase;
    color: var(--primaryColor);
}
.text-small-banniere {
    font-size: 13px !important;
}
@media screen and (max-width: 576px) {
  .titreContacter {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 17px !important;
    line-height: 36px;
    /* identical to box height */
    margin-left: 15%;
    margin-right: auto;
    text-align: center;

    /* bleu */

    color: var(--secondary-color);
  }
  .texteContacter1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px !important;
    line-height: 30px;
    text-align: center;
    color: var(--primarytextColor);
  }
  .textebanniereContacter {
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.025em;
    text-align: justify;
    color: var(--septanarioTextColor);
    width: 290px !important;
    margin-left: 10% !important;
    margin-right: auto;
  }
  .banniereContacter {
    background: var(--tertiaryColor);
    height: 312px !important;
    padding-top: 60px;
    text-align: center;
    width: 100%;
  }
  .titrebanniereContacter{
    font-family: DM Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 21px !important;
    line-height: 30px;
    margin-left: 7% !important;
    margin-right: auto;
    letter-spacing: -0.025em;
    color: var(--quaternaryTextColor);
  }
  .textareaform {
    width: 308px !important;
    height: 229px;
    left: 875px;
    border: 0.25 px solid var(--senaryBorderColor);
    box-sizing: border-box;
    filter: drop-shadow(0px 4px 20px var(--octonaryBoxShadowColor));
    border-radius: 10px;
  }
}
@media (max-width: 768px) {
  .texteContacter1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 20px !important;
    line-height: 30px !important;
    text-align: center !important;
    color: var(--primarytextColor);
  }
  .titrebanniereContacter {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 33px !important;
    line-height: 55px;
    margin-left: 25%;
    margin-right: auto;
    letter-spacing: -0.025em;
    color: var(--quaternaryTextColor);
  }
  .textebanniereContacter {
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 18px;
    letter-spacing: -0.025em;
    text-align: justify;
    color: var(--septanarioTextColor);
    width: 440px;
    margin-left: 25% !important;
    margin-right: auto;
  }
}
.banniereContacter {
  background: var(--tertiaryColor);
  height: 200px;
  padding-top: 60px;
  text-align: center;
  width: 100%;
}
.titrebanniereContacter {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 55px;
  margin-right: auto;
  letter-spacing: -0.025em;
  color: var(--quaternaryTextColor);
}
.textebanniereContacter {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.025em;
  text-align: justify;
  color: var(--septanarioTextColor);
}
.titreContacter {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  color: var(--secondary-color);
}
.texteContacter1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: var(--primarytextColor);
}
.texteContacter2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--primarytextColor);
}
.labelform {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  letter-spacing: -0.025em;
  color: var(--nonaryTextColor);
}
.main-contact {
  margin-top: 60px;
}
.inputform {
  padding: 15px;
  border: 0.25px solid var(--senaryBorderColor);
  box-sizing: border-box;
  border-radius: 10px;
}
.textareaform {
  padding: 15px;
  border: 0.25px solid var(--senaryBorderColor);
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 20px var(--octonaryBoxShadowColor));
  border-radius: 10px;
}
.btnform {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding: 15px;
  color: var(--primaryColor);
  background: var(--secondary-color);
  border-radius: 10px;
}

.tabs-title {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
    line-height: 25px;
	display: flex;
	align-items: center;
	letter-spacing: -0.025em;
	color: var(--decimalTextColor);
	text-transform: uppercase;
}

.navLink {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 26px;
    color: var(--quinaryTextColor) !important;
    text-decoration: none;
}

.active {
	color: var(--primarytextColor) !important;
    text-decoration: none;
}

.navLink:hover, .navLink:focus {
    color: var(--secondary-color);
    text-decoration: none;
}

@media screen and (max-width: 1024px) {
    .tabsTitle {
        font-size: 20px;
    }
}

.editprofilebackground {
  height: auto;
}
.editcontent {
  background: white !important;
}
.imgedit {
  display: flex;
  width: 180px;
  height: 180px;
  margin: 20px;
  background: white;
}
.imgeditcontent {
  display: flex;
  justify-content: center;
}
.inputformedit {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 10px;
  text-align: start;
  color: rgba(0, 0, 0, 0.6);
  border-bottom: 1px solid #e0e0e0 !important;
  border-left: none;
  border-top: none;
  border-right: none;
  margin: 3px;
  width: 100%;
}
.choix {
  border: 1px solid var(--secondary-color);
  padding: 10px;
  margin: 4px;
}
.btnformedit {
  width: 109px;
  height: 57px;
  left: 1224px;
  float: right;
  margin-top: 190px;
  /* bleu */
  margin-right: 6px;
  background: var(--secondary-color);
  border-radius: 10px;
  color: var(--primaryColor);
}
.nav > li a {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 20px;
  line-height: 26px;
  color: var(--quinaryTextColor) !important;
}
.tab-pane .fade .in .active .row {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: var(--primarytextColor);
}

.editprofilebackground {
	height: auto;
}
.containeredite {
	background: white !important;
}
.component-mes-projet-tableau-de-bord {
	background: var(--quinaryColor) !important;
}
.imgedit {
	display: flex;
	width: 180px;
	height: 180px;
	background: white;
}
.imgeditcontent {
	display: flex;
	justify-content: center;
	margin-bottom: 50px;
	background: white;
}
.inputformedit {
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 10px;
	text-align: start;
	color: var( --quaternaryTextColor);
	border-bottom: 1px solid #e0e0e0;
	border-left: none;
	border-top: none;
	border-right: none;
	background: none;
}
.choix {
	width: 46px;
	height: 40px;
	background: #f3f8fe;
	border: 1px solid var(--secondary-color);
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 19px;
	text-align: center;
}

.btnformedits {
	border: 1px solid var(--secondary-color);
	background-color: var(--secondary-color);
	border-radius: 10px;
	color: var(--primaryColor);
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 21px;
	padding: 1rem 2rem;
}
.btnformedits:hover {
	background: var(--primaryColor);
	color: var(--secondary-color);
}
.civilite {
	margin-top: 16px !important;
	margin-right: 3rem !important;
}
.civilite-btn-radio {
	opacity: 0;
	position: absolute;
	width: 100%;
	padding: 20px !important;
	left: -4%;
	bottom: 0;
}
/*  */
.civilite-btn-radio-container {
	display: flex;
	width: 50%;
}
.civilite-btn-radio-item {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 15px;
	background: #f3f8fe;
	width: 50px;
	border: 1px solid var(--secondary-color);
	box-sizing: border-box;
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 19px;
	color: var(--secondary-color);
	position: relative;
}
.civilite-btn-radio-item label,
.civilite-btn-radio-item input {
	margin: 0;
	font-weight: 400 !important;
}
.civilite-btn-radio-item.checked-civilite {
	background: var(--secondary-color) !important;
	color: var(--primaryColor);
}
.user-avatar-img-container {
	margin: 30px 0;
	position: relative;
}
.edit-avatar-btn {
	left: 0;
	top: 0;
	opacity: 0;
	position: absolute;
	opacity: 0;
	width: 100%;
	height: 100%;
}
.user-avatar-label {
	font-family: Poppins;
	font-size: 10px;
	font-style: normal;
	font-weight: 500;
	line-height: 23px;
	color: var(--primaryColor);
	z-index: 999;
	position: absolute;
	top: 45%;
	left: 25%;
}
.overload {
	position: absolute;
	top: 0;
	width: 100%;
	text-align: center;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
	border: 1px dashed var(--secondary-color);
	opacity: 0;
}
.user-avatar-img-container:hover .overload {
	opacity: 1;
}
.user-avatar-img  {
	width: 200px;
	height: 200px;
}
.edit-avatar-btn:hover {
	text-decoration: none !important;
}

.paragraph-mail-info {
	font-family: DM Sans;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 17px;
	letter-spacing: 0px;
	text-align: left;
	color: var(--primarytextColor);
	opacity: 0.5;
}

.component-navbar-mobile-v2{
    
}
.block-mot-de-passe{
	background-color: var(--primaryColor);
	padding: 0 2rem;
}
.button-mot-de-passe,
.button-mot-de-passe:hover,
.button-mot-de-passe:focus {
	padding: 20px;
	background: var(--secondary-color);
	border-radius: 10px;
	margin-top: 3rem;
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: var(--primaryColor);
	text-transform: uppercase;
}

.form-label-custom {
    border-bottom: 1px solid #E0E0E0;
	margin-top: 3rem;
}

.form-label-custom label {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 19px;
	text-align: center;
	color: rgba(0, 0, 0, 0.6);
}


.banner-details-projet {
  background-image: url(/static/media/image2.beda7d2b.png);
  padding: 10%;
  background-size: cover;
  background-repeat: no-repeat;
}
.component-details-projet .img-details {
  width: 100%;
  height: 370px;
}
.colorBorderBottom{
  border-bottom: 5px solid white;
}
.image-card-container {
  margin-bottom: 30px;
}
.link-button {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--secondary-color);
  background: var(--primaryColor);
  padding: 1rem 2rem 1rem 2rem;
  border-radius: 10px;
  border: 1px solid var(--secondary-color);
}
.text-person {
  margin: 12px;
}
.recevoir-person {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--quinaryTextColor);
  margin: 10px;
}
.button-text {
  padding: 15px;
  display: flex;
  align-items: center;
}
.text-person {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  color: var(--secondary-color);
}
.suivre-button {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--primarytextColor);
}
/* .project-funding-type.dette-items {
  background: #2f80ed;
  border: 1px solid #2f80ed;
  box-sizing: border-box;
  border-radius: 4px 0px;
  margin: 0px;
  width: fit-content;
  height: 30px;
} */
/* .project-funding-type.don-items {
  background: #219653;
  border: 1px solid #219653;
  box-sizing: border-box;
  border-radius: 4px 0px;
  margin: 0px;
  width: fit-content;
  height: 30px;
} */
/* .project-funding-type.equity-items {
  background: #f2994a;
  border: 1px solid #f2994a;
  box-sizing: border-box;
  border-radius: 4px 0px;
  margin: 0px;
  width: fit-content;
  height: 30px;
} */
.text-pourcentage {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  color: var(--quinaryTextColor);
}
/* .text-det,
.text-det p {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 10px;
  letter-spacing: -0.025em;
  color: #737373;
} */
.text-lance {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: var(--quinaryTextColor);
}
.text-lance-cloture {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: var(--quinaryTextColor);
}
.color-text {
  color: var(--secondary-color);
}
.text-participez {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: var(--quinaryTextColor);
}


.bordure-deux-cartes {
  border-radius: 8px;
}
.carte-texte {
  align-items: center;
  padding: 5px;
}
.carte-profile-text {
  background: var(--primaryColor);
  border: 0.4px solid var(--tertiaryBorderColor);
  box-sizing: border-box;
  border-radius: 12px;
  transform: rotate(180deg);
  margin-top: 15px;
}
.text-aicha-porteur {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 10px;
  display: flex;
  align-items: center;
  letter-spacing: -0.025em;
  color: var(--quinaryTextColor);
}
.text-pctg-fcfa {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: var(--secondary-color);
}
.text-aicha {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.025em;
  color: var(--tertiodenaryTextColor);
}

/* -----------------ancien css-------------------------- */

.photo-card {
  background: var(--primaryColor);
  box-shadow: 0px 4px 43px var(--primaryBoxShadowColor);
  border-radius: 8px;
}
.text-titre-objectif {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.025em;
  color: var(--primarytextColor);
}
.text-details-bold {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: var(--primarytextColor);
}
.text-details-objectif {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
  color: var(--primarytextColor);
}
.texte-para {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: -0.025em;
  color: var(--quaterioTextColor);
  text-align: justify;
}
.text-aicha-small {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 100;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.025em;

  color: var(--tertiodenaryTextColor);
}
.title-description {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 47px;
  letter-spacing: -0.025em;
}
.bg-whites {
  background: var(--primaryColor);
  box-shadow: 0px 4px 43px var(--primaryBoxShadowColor);
}
.titre-banner-liste-projet {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 42px !important;
  color: var(--secondaryTextColor) !important;
}
.titre-banner-liste-proj {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 42px !important;
  color: var(--primaryColor) !important;
}
.text-detail {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.025em;
  color: var(--quinaryTextColor);
}
.video-detail {
  width: 100% !important;
}
.component-details-projet .card {
  background: var(--primaryColor);
  box-shadow: 0px 4px 20px var(--secondaryBoxShadowColor);
  border-radius: 10px;
  max-width: 160%;
}

.component-details-projet .card-header{
  background: transparent;
  cursor: pointer;
}

/* .component-details-projet .card-icon {
  background: var(--primaryColor);
  box-shadow: 0px 4px 20px var(--secondaryBoxShadowColor);
  border-radius: 10px;
  max-width: 160%;
  margin: 5px;
} */

.component-details-projet svg{
  font-size: 40px !important;
  cursor: pointer;
}
.component-details-projet .card-title {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 26px;
  color: var(--quinaryTextColor);
}
.vjs-poster{
  background: transparent !important;
}
.component-details-projet .icon-social-detail {
  color: var(--primaryColor);
  width: 18px;
  height: 18px;
  margin-top: -16px;
  margin-left: -7px;
}
.component-details-projet .nav > li a {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 26px;
  color: var(--quinaryTextColor) !important;
}
.component-details-projet .nav-pills .nav-link.active {
  background: var(--secondary-color) !important;
  border-radius: 10px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  color: var(--primaryColor) !important;
}
.nav-pills>li {
  float: none !important;
}
.component-details-projet .bloc-list1 .list-group-item {
  border: none !important;
}
.li-bg {
  background: var(--quinaryColor) !important;
}
.text-li-details {
  font-family: "DM Sans" !important;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  align-items: center;
  letter-spacing: -0.025em;
  color: var(--tertiodenaryTextColor);
}
.li-bg {
  background: var(--quinaryColor);
}
.title-detail-h3 {
  font-family: " Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  display: flex;
  align-items: center;
  letter-spacing: -0.025em;
  color: var(--secondary-color);
}
.img-details-li {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}
.nom-title {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: var(--primarytextColor);
}
.sub-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: var(--quinaryTextColor);
}
.text-small-faq {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.025em;
  color: var(--nonaryTextColor);
}
.text-comment-auteur {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: var(--primarytextColor);
}
.bloc-comment-detail p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--nonaryTextColor);
}
.img-icon-video {
  width: 56.67px;
  height: 56.67px;
}
.modal-content-tutorer {
  border-radius: 10px 10px 0px 0px;
}
.modal-tutorer {
  z-index: 9999999999999;
}
.title-montant {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 47px;
  color: var(--primarytextColor);
  text-align: center;
}
.header-contribution {
  background: #bb4411;
  border-radius: 10px 10px 0px 0px;
  height: 60px;
  padding: 1rem;
}
.title-contribution {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.025em;
  color: var(--primaryColor);
  text-align: center;
}
.modal-confirmation .modal-header {
  border-bottom: none !important;
}
.modal-confirmation .modal-footer {
  border-top: none !important;
}
.cursor-p {
  cursor: pointer;
}
.bg-color-selected {
  padding: 15px;
}
/* -----------------modal style----------------- */
.text-securiser {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 54px;
  text-align: center;
  color: var(--primarytextColor);
  padding: 20px;
}
.button-detail-securiser {
  margin-top: 5rem;
  text-align: center;
}
.securiser-btn-green {
  background: var(--secondary-color);
  width: 20%;
  border-radius: 10px;
  color: var(--primaryColor);
  height: 50px;
}
.form-input-telephone {
  border-bottom: 1px solid #e0e0e0;
  margin-top: 5rem;
  width: 30%;
  margin-left: 160px;
}

.text-modal-compte {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin: 22px;
  text-align: justify;
  padding: 32px;
  color: var(--quinaryTextColor);
}

.disabled-btn{
	cursor: not-allowed !important;
}
.titrevalidationidentite {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 42px;
  align-items: center;
  letter-spacing: -0.025em;
  color: #010101;
  padding-top: 40px;
  padding-bottom: 4px;
}
.text-identite {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  letter-spacing: -0.025em;

  color: rgba(1, 1, 1, 0.5);
}
.titreverificationidentite {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 42px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.025em;

  color: #010101;
}
.btnnonidentifie {
  padding: 15px;
  background: var(--primaryColor);
  border: 1px solid rgba(254, 24, 24, 0.7);
  box-sizing: border-box;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: rgba(254, 24, 24, 0.7);
}
.id-validation-container.m-5 {
  display: flex;
  align-items: center;
}
.id-validation-btn-status-container {
  width: 30%;
}
.closing {
  padding-right: 15px;
}
.id-validation-status-textual-container p {
  margin: 0;
}
.texte-non-identifie {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 13px;
  text-align: justify;
  letter-spacing: -0.025em;
  width: 20px;
  color: rgba(1, 1, 1, 0.5);
}
.btnvalidation {
  padding: 18px 23px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 10px !important;
  line-height: 21px;
  color: var(--primaryColor);
  padding: 18px 43px;
  background: var(--secondary-color);
  border-radius: 10px;
  border: 1px solid var(--secondary-color);
}
.btnvalidation:hover {
  background: var(--primaryColor);

  color: var(--secondary-color);

  text-decoration: none;
}
.gab {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  letter-spacing: -0.025em;
  padding-left: 20px;
  color: rgba(1, 1, 1, 0.7);
}
.content-identite {
  background: white;
}

.navbar-vertical-customisation-v2 {
	padding: 0;
	height: 100%;
	min-height: 1000px;
	max-width: 17% !important;
	min-width: 17%;
	background-color: var(--primaryColor)  !important;
	position: fixed;
	left: 0;
	right: 0;
	top: 71px;
	z-index: 999;
}

.nav-items-customs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-item-vertical-custom {
  width: 100%;
  /* padding: 2rem 0 0 0; */
}

.nav-link-vertival-custom {
	font-family: "Poppins" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 14px !important;
	line-height: 18px !important;
	color: var(--quinaryTextColor);
	width: 100%;
	display: flex !important;
	grid-gap: 10px;
	gap: 10px;
	align-items: center;
	padding: 1rem 5% 1rem 18% !important;
}

.navbar-vertical-container-icon {
  width: 30px !important;
  background-color: var(--primaryColor) ;
  text-align: center;
  height: 30px !important;
  display: flex;
  align-items: center;
  justify-content: center !important;
  font-size: 14px;
  color: var(--quinaryTextColor);
  border-radius: 12px;
}

.text-dashbord {
  color: var(--quinaryTextColor) !important;
}

#scrollbar {
  overflow-y: scroll;
}
#scrollbar::-webkit-scrollbar {
  width: 6px;
}
#scrollbar::-webkit-scrollbar-track {
	background: var(--primaryColor);
}
#scrollbar::-webkit-scrollbar-thumb {
	background-color: var(--secondary-color);
	border-radius: 20px;
	border: 3px solid transparent;
}
.contents {
  height: 0px !important;
}

.nav-items-customs .active, .nav-items-customs .active .navbar-vertical-container-icon, .nav-items-customs .active .text-dashbord{
  color: var(--primaryColor)   !important;
  background-color: var(--secondary-color) !important;
}

.contenuetitreidentite {
  background: var(--tertiareColore);
}
.titreidentite {
  display: flex;
  justify-content: center;
  height: 180px;
  margin: auto;
  font-size: 32px;
  padding-top: 45px;
}
.texteinvestir {
  background: var(--primaryColor);
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 10px;
  border-radius: 10px;
  margin: 10px;
  width: 90% !important;
}
.link-personne-physique {
  text-decoration: none !important;
}
.hover-link-physique:hover {
  background: var(--secondary-color) !important;
  border-radius: 10px !important;
  color: white;
}
.hover-link-physique:hover p {
  color: white;
}

.iconeinvestir {
  border-radius: 50%;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  margin-bottom: 10px;
  width: 100px;
  height: 100px;
  margin: 20px;
}
.iconeinvestir {
  color: gray;
}
.hover-link-physique:hover .iconeinvestir {
  color: var(--secondary-color);
  background: white;
}
.texteinvestircontent {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  letter-spacing: -0.025em;
  color: #cccccc;
}
.btninvestir {
  background: var(--secondary-color);
  border-radius: 10px;
  color: var(--primaryColor) !important;
  padding: 18px 10px 15px 15px;
  text-decoration: none !important;
  padding: 18px 23px;
  border-radius: 10px;
}
.contentchoixpersonne {
  padding-top: -92px;
}
.btninvestir:hover {
  color: var(--secondary-color) !important;
  border: 1px solid var(--secondary-color);
  text-decoration: none;
  background: var(--primaryColor);
}
.contentinvestir {
  height: 650px;
  padding-top: 29px;
}

.btnidentite {
  background: var(--secondary-color);
  color: hsl(0, 0%, 100%);
}
.votreidentite {
  background: var(--tertiareColore);
  margin-bottom: -21px;
}
.contentpersonnel {
  margin-top: 0;
  background: white;
}
.btnsave:hover {
  color: var(--secondary-color);
  text-decoration: none;
  background: var(--primaryColor);
}
.btnsave {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  background: var(--secondary-color);
  border-radius: 10px;
  color: var(--primaryColor);
  padding: 18px 23px !important;
  border: 1px solid var(--secondary-color);
}
.modal-de-soumission-containers {
  background: rgba(rgb(253, 253, 253) 0.8);
}
.btn-theme-annuler {
  background: var(--primaryColor);
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 17px;
  text-align: center;
  color: var(--secondary-color);
  padding: 1rem !important;
  width: 100%;
  border-radius: 10px;
}
.btn-theme-annuler:hover {
  text-decoration: none;
  color: var(--primaryColor) !important;
  border: 1px solid var(--secondary-color);
  background: var(--secondary-color);
}
.titreinfoperson {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 42px;
  letter-spacing: -0.025em;
  color: var(--primarytextColor);
}
.btnformedits2 {
  display: flex;
  justify-content: left;
}
.civilite {
  margin-top: 16px !important;
  margin-right: 3rem !important;
  color: var(--primarytextColor);
}

.titreposte {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 19px;
	color: rgba(0, 0, 0, 0.6);
}
.btnrepresentant:hover {
	text-decoration: none !important;
	background: var(--secondary-color) !important;
  color: var(--primaryColor);
}

.auth-form-container input:checked {
	background-color: var(--secondary-color) !important;
}
.btnrepresentant {
	font-family: DM Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 21px;
	letter-spacing: -0.025em;
	text-align: center;
	color: var(--secondary-color);
	background: var(--primaryColor);
	border: 0.25px solid var(--secondary-color);
	box-sizing: border-box;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
	border-radius: 10px;
  padding: 2rem 4rem;
  margin: 5rem 0 !important;
}
.tab-pane {
	background-color: white;
}
.civilite {
	margin-top: 16px !important;
	margin-right: 3rem !important;
}
.inputdirection::before {
	color: var(--secondary-color) !important;
	border-color: var(--primaryColor) !important;
	width: 12px;
	height: 12px;

	background-color: var(--secondary-color) !important;
}
.inputdirection::after {
	color: var(--secondary-color) !important;
	border-color: var(--primaryColor) !important;
	width: 12px;
	height: 12px;

	background-color: var(--secondary-color) !important;
}

.btnsave {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	background: var(--secondary-color);
	border-radius: 10px;
	color: var(--primaryColor);
	padding: 18px 23px !important;
}
.btnsave :hover {
	padding: 15px 10px;
	color: var(--secondary-color);
	border: 1px solid var(--secondary-color);
	text-decoration: none;
	background: var(--primaryColor);
}
.inputdirection {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 19px;
	color: rgba(0, 0, 0, 0.6);
}

.component-front-home {
  background-color: var(--primaryColor) !important;
  box-sizing: border-box;
}
.content-view-start {
  position: relative;
}

.banner-liste-projet {
  background: var(--tertiareColore);
  padding: 5rem;
}
.titreH1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 140%;
  color: #0f111d;
}
.liste-ordonnee {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 200%;
  color: #0f111d;
  padding-top: 5px;
  padding-bottom: 5px;

}
.color-btn {
  margin-left: 1rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 18px 23px;
  background: var(--secondary-color);
  border-radius: 10px;
  color: var(--primaryColor);
}
.color-btn:hover {
  color: var(--secondary-color);
  border-color: var(--secondary-color);
  background-color: var(--primaryColor);
  text-decoration: none;
}

.pointretour{
  
  transform: rotate(360deg);
}
.retour {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24%;
  color: var(--secondary-color);
}


.arow-pos {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
}
.title-questionnaire {
	margin-left: 1.5em !important;
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	line-height: 140%;
	color: #535757;
	margin-left: 0 !important;
}
.input-part-du-project {
	margin-top: 4em !important;
	margin-left: 10px !important;
	border: none;
	border-bottom: 1px solid var(--secondary-color);
	padding: 5px 10px;
	outline: none;
	width: 85%;
}
.input-part-du-project-other {
	margin-top: 2em !important;
	border: none;
	border-bottom: 1px solid var(--secondary-color);
	padding: 5px 10px;
	outline: none;
	width: 85%;
}

.input-part-du-project > [placeholder]:focus::-webkit-input-placeholder {
	-webkit-transition: text-indent 0.4s 0.4s ease;
	transition: text-indent 0.4s 0.4s ease;
	text-indent: -100%;
	opacity: 1;
}
.btn-se-financer-part {
	background: var(--secondary-color) !important;
	padding: 16px !important;
	border: 1px solid var(--secondary-color);

	border-radius: 10px !important;
	color: var(--primaryColor) !important;
}
.btn-se-financer-part:hover {
	text-decoration: none !important;
	background: transparent !important;
	border: 1px solid var(--secondary-color);
	color: var(--secondary-color) !important;
}
.btn-se-financer-part8 {
	text-align: center;
	background: transparent;
	border-radius: 10px;
	color: var(--secondary-color) !important;
	border: 1px solid var(--secondary-color);
}
.btn-se-financer-part8:hover {
	text-align: center;
	background: var(--secondary-color);
	border-radius: 10px;
	border: 1px solid var(--secondary-color);
	color: var(--primaryColor) !important;
}
.btn-se {
	color: var(--primaryColor);
}
.play {
	position: absolute;
	top: 120px;
	left: 190px;
}
.a-link > a:hover {
	text-decoration: none !important;
}
.mt-input-part-project {
	margin-top: 2em !important;
}
.mb-input-part-project {
	margin-bottom: 2em !important;
}
.input-directions {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	padding-left: 5px;
	font-size: 15px;
	line-height: 19px;
	color: rgba(0, 0, 0, 0.6);
}
.marge-questionaire-projet {
	margin-top: 4em;
}
.customModal-questionnaire {
	max-width: 500px !important;
	height: 400px !important;
	width: 100%;
	background: var(--primaryColor);
	border-radius: 10px;
}

.modal-mode-financement-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-top: 5rem;
}
.modal-mode-financement-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 40px;
	text-align: center;
	color: var(--primarytextColor);
}
.btn-questionnaire-confimation-non {
	background: transparent !important;
	padding: 16px !important;
	border-radius: 10px !important;
	border: 1px solid var(--secondary-color);
	color: var(--secondary-color) !important;
}
.btn-questionnaire-confimation-non:hover {
	text-decoration: none !important;
	background: var(--secondary-color) !important;
	border: 1px solid var(--secondary-color);
	color: var(--primaryColor) !important;
}
.design-boutton {
	background: none;
}

/* Ameliorations  styles */
.questionnaire-form-check-container {
	display: flex;
	align-items: center !important;
	grid-gap: 10px;
	gap: 10px;
	margin-bottom: 14px;
}

.questionnaire-button-container {
	margin-top: 4rem;
}

.questionnaire-form-check-input {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	background-color: var(--primaryColor) !important;
	box-sizing: border-box !important;
	cursor: pointer;
	outline: none !important;
	width: 15px !important;
	height: 15px !important;
	border-radius: 4px;
	border: 1px solid #bdbdbd;
	background-color: var(--primaryColor) !important;
	box-sizing: border-box !important;
	cursor: pointer;
	outline: none !important;
}

.questionnaire-form-check-input:checked {
	background-color: var(--secondary-color) !important;
	border: 1px solid var(--secondary-color);
}

.questionnaire-form-check-input:before {
	font: 10pt "FontAwesome";
	content: "\f00c";
	display: inline;
	color: var(--primaryColor);
	position: absolute;
}

.questionnaire-form-check-label {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 19px;
	color: rgba(0, 0, 0, 0.6);
	margin: 0;
}

.questionnaire-container {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.soumettre-projet .informations{
	width: 100% !important;
}

.quest-control-input {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	background-color: var(--primaryColor) !important;
	box-sizing: border-box !important;
	cursor: pointer;
	outline: none !important;
	width: 18px !important;
	height: 18px !important;
	border-radius: 4px;
	border: 1.5px solid #BDBDBD;
}

.quest-control-input:before {
	font: 10pt "FontAwesome";
	content: "\f00c";
	display: inline;
	padding-left: 1px;
	color: var(--primaryColor);
}

.quest-control-input:checked {
	background-color: var(--secondary-color) !important;
	border-color: var(--secondary-color) !important;
}

.quest-label {
	font-family: Poppins;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	color: rgba(0, 0, 0, 0.6);
	margin-left: 20px;
}

.text-black-50 {
	color: var(--primarytextColor) !important;
	opacity: 0.5 !important;
}

.dzu-previewContainer {
	margin-top: 2rem;
}
.react-calendar {
    width: 100% !important;
    border: none !important;
}

.react-calendar__tile--active {
    background: var(--secondary-color) !important;
    color: white;
}
.description-length {
	font-size: 15px;
    font-weight: 400;
}
.inputCV{
	border-radius: 10px;
    padding: 10px;
    margin: 27px 0 0 0;
}

.soumettre-projet h1 {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 30px;
	line-height: 75px;
	text-align: center;
	letter-spacing: -0.025em;
	color: var(--primarytextColor);
}

.se-financer {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.formulaire-de-depot-dossier {
	padding: 4rem 0;
}

.soumettre-projet input[type="radio"] {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	border: 1px solid var(--secondary-color) !important;
	box-sizing: border-box;
	outline: none;
	background-color: var(--primaryColor);
	padding: 5px !important;
}

.soumettre-projet input[type="radio"]:checked {
	background-color: var(--secondary-color) !important;
}

.soumettre-projet small {
	margin-top: 5px;
}

.soumettre-projet input[type="text"],
.soumettre-projet input[type="url"],
.soumettre-projet input[type="date"],
.soumettre-projet input[type="email"],
.soumettre-projet input[type="tel"],
.soumettre-projet input[type="number"] {
	width: 100%;
	height: 55px;
	border: 0.25px solid #959595;
	box-sizing: border-box;
	filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
	border-radius: 10px;
	color: var(--primarytextColor);
	padding-left: 5%;
}

.soumettre-projet input[type="text"]::placeholder,
.soumettre-projet input[type="url"]::placeholder {
	font-family: Poppins;
	font-style: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.025em;
	color: rgba(0, 0, 0, 0.5);
}

.soumettre-projet h5 {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 23px;
	letter-spacing: -0.025em;
	color: rgba(0, 0, 0, 0.7);
}

.soumettre-projet label {
	font-family: DM Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 23px;
	letter-spacing: -0.025em;
	color: rgba(0, 0, 0, 0.7);
}

.soumettre-projet textarea {
	width: 100%;
	height: 150px;
	min-height: 159px;
	max-height: 159px;
	border: 0.5px solid #959595;
	box-sizing: border-box;
	filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.08));
	border-radius: 10px;
	outline: none;
	padding-left: 2%;
}

.soumettre-projet small {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 15px;
	letter-spacing: -0.025em;
	color: rgba(0, 0, 0, 0.7);
}

.soumettre-projet h3{
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 27px;
	letter-spacing: -0.025em;
	color: var(--primarytextColor);
}
.label-title {
	font-family: Poppins !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 18px !important;
	line-height: 27px !important;
	letter-spacing: -0.025em !important;
	color: var(--primarytextColor) !important;
}

.soumettre-projet .form-textarea-group {
	margin-bottom: 4rem;
}

.button-suivant,
.button-soumettre {
	padding: 12px 40px;
	filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.05));
	border-radius: 10px;
	background: var(--secondary-color);
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	line-height: 19px;
	text-align: center;
	color: var(--primaryColor);
	text-transform: uppercase;
}

.button-precedent {
	font-family: DM Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	line-height: 19px;
	text-align: center;
	color: var(--secondary-color);
	background-color: var(--primaryColor);
	outline: none;
	margin-right: 2rem;
	text-transform: uppercase;
}

.modal-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 36px;
	line-height: 54px;
	text-align: center;
	color: var(--primarytextColor);
}

.button-mettre-en-ligne {
	width: 152px;
	height: 57px;
	background: var(--secondary-color);
	border-radius: 10px;
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: var(--primaryColor);
	flex: none;
	order: 0;
	flex-grow: 0;
	margin: 0px 10px;
}

.button-date-lancement {
	width: 295px;
	height: 57px;
	background: var(--primaryColor);
	border: 1px solid var(--secondary-color);
	box-sizing: border-box;
	border-radius: 10px;
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: var(--secondary-color) !important;
	flex: none;
	order: 0;
	flex-grow: 0;
	margin: 0px 10px;
}

.form-group-container {
	margin-bottom: 5rem;
}

.form-title {
	margin-bottom: 3rem;
}

.modal-de-soumission-container {
	width: 656px !important;
	height: 527px;
}



.calendrier {
	width: 100%;
}

.calendrier h2 {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.085em;
	text-transform: uppercase;
	color: rgba(0, 0, 0, 0.7);
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding-bottom: 1rem;
	text-align: center;
}
.calendrier .date-selectionne {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding: 1rem 0;
	margin-bottom: 2rem;
}

.calendrier p {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	color: rgba(0, 0, 0, 0.7);
	text-align: center !important;
	margin: 0;
}

.day {
	font-size: 25px;
	line-height: 28px;
}

.calendrier-de-react-datepicker {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding: 0;
	display: flex;
	justify-content: center;
	margin-bottom: 5rem;
}
.calendrier-de-react-datepicker input {
	border: 0 !important;
	display: inline-flex !important;
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 18px;
	color: rgba(0, 0, 0, 0.5);
	margin: 0;
}

.heures-lancement-container {
	padding: 3%;
}

.heures-lancement-container h3 {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 17px;
	color: rgba(0, 0, 0, 0.7);
}

.heures-lancement {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 5rem;
}

.heure-lancement-item {
	background: #cccccc;
	border: 0.5px solid #737272;
	box-sizing: border-box;
	border-radius: 20px;
	width: 58px;
	height: 35px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	color: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 1rem;
	margin-right: 1rem;
	cursor: pointer;
}

.heures-lancement-active {
	background: var(--secondary-color);
	color: var(--primaryColor);
}

.button-container {
	display: flex;
	justify-content: flex-end;
	padding: 0 25%;
}

.button-enregistrer {
	padding: 1rem;
	background: var(--secondary-color);
	border-radius: 10px;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: var(--primaryColor);
}

@media screen and (max-width:1100px) {
	/* .calendrier-container {
		padding: 0 35%;
	} */

	.button-container {
		padding: 0 35%;
	}

	/* .heures-lancement-container {
		padding: 0 30%;
	} */
}

@media screen and (max-width:767px) {
	/* .calendrier-container {
		padding: 0 25%;
	} */

	.button-container {
		padding: 0 20%;
	}

	/* .heures-lancement-container {
		padding: 0 15%;
	} */
}

.card-choix-pieces{
  background-color: var(--primaryColor);
  padding: 80px;
}

.style-chckbox{
    width: 20px;
    height: 20px;
    border: 1px solid var(--secondary-color) !important;
    box-sizing: border-box;
    position: absolute;
   
    
}
.style-chckbox:checked{
    background: var(--secondary-color) !important;
} 

.style-prev{
  margin: 8px 35px !important;
}

.contenant {
  border: 1px solid grey;
}


.row-content1{
  height: 100vh;
  background: var(--quinaryColor);
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-content1{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80%;
  padding: 0px;
  background: var(--primaryColor);
  box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.07);
}

.titre-choisissez{
  text-align: left !important;
  font-size: 22px !important;
}

.component-front-register-infos-perso-nine{
  padding: 200px;
  background: var(--quinaryColor);
  border-radius: 10px;
  
}


.paragraph-mail-scroll {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  text-align: left;
  color: rgba(0, 0, 0, 0.6);
  overflow:auto;
  height: 350px;
  border:0px solid #ccc;
}

.paragraph-mail-scroll2{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  text-align: left;
  color: rgba(0, 0, 0, 0.6);
  border:0px solid #ccc;

}

.div-btn-accepter{
  display: flex !important;
  justify-content: flex-start !important;
}



.champ-texte-tel{
box-sizing: border-box;
margin-top: 30px;
background: var(--primaryColor);
border: 0.25px solid rgba(6, 72, 184, 0.5);
width: 437px;
height: auto;
font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 13px;
line-height: 19px;
color: rgba(0, 0, 0, 0.5);
}



.color-sms{
color: var(--primarytextColor);
}

.main-content{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 80%;
padding: 0px;
background: var(--primaryColor);
box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.07);
}

.row-content{
height: 100vh;
background: var(--quinaryColor);
display: flex;
justify-content: center;
align-items: center;
}

.title-inscription{
font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 25px;
line-height: 42px;
color: var(--primarytextColor);
}



@media only screen and (max-width: 600px) {
.champ-texte-tel{
  width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
.champ-texte-tel{
  width: 100%;
  height: auto;
  
  }
}



.style-checkbox{
  border: 0.25px solid var(--secondary-color) !important;
  padding: 0px !important;
}

input[type='checkbox']{
  top: 0px !important;
  box-sizing: border-box;

}


.rs-uploader-trigger-btn {
    background: none !important;
    border: none !important;
    padding: 20px !important;
    border-radius: 0 !important;
}

.card-telecharger-photo{
  box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.07);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: var(--primaryColor);
}

.btn-enregistrer{
  width: 136px;
  height: 57px;
  margin-top: -100px;
  margin-bottom: 50px;
}

.title-verification{
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 42px;
  text-align: center;
  color: var(--primarytextColor);
}


.contenu-card{
  width: 80%;
  margin: auto;
}


.boutton-div{
  margin: 0 20px;
}
.component-front-register-infos-perso-three {
  padding: 200px;
  background: var(--quinaryColor);
  border-radius: 10px;
}

.card-photo-identite{
  background-color: var(--primaryColor);
  padding: 50px 10px;
}


.bloc-div-register-form {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 70%;
  margin: 0 20px;
}
.style-checkbox {
  background: var(--primaryColor);
  border: 0.25px solid var(--secondary-color) !important;
  box-sizing: border-box;
  height: 40px;
  display: flex;
  align-items: center;
}


.style-chckbox, .style-radio{
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  background-color: var(--primaryColor) !important;
  box-sizing: border-box !important;
  cursor: pointer;
  outline: none !important;
}

.style-chckbox:checked, .style-radio:checked{
  background-color: var(--secondary-color) !important;
}

.style-chckbox:before{
  font: 12pt 'FontAwesome';
  content: '\f00c';
  display: inline;
  padding-left: 1px;
  margin-right: 0.5em;
  color: var(--primaryColor);
}

.style-radio{
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  background-color: var(--primaryColor) !important;
  box-sizing: border-box !important;
  cursor: pointer;
  outline: none !important;
  width: 20px !important;
  height: 20px !important;
  border-radius: 4px;
  border: 1px solid var(--secondary-color);
}

.style-radio:before{
  font: 12pt 'FontAwesome';
  content: '\f00c';
  display: inline;
  padding-left: 1px;
  color: var(--primaryColor);
}



.color-second-part {
  color: yellow !important;
}

.div-upload {
  background: var(--primaryColor) !important;
  border: 0.25px solid rgba(187, 68, 17, 0.5) !important;
  box-sizing: border-box !important;
  border-radius: 0 !important;
  width: 100%;
}

.component-front-register-infos-perso-three
  .rs-uploader-text
  .rs-uploader-trigger:not(.rs-uploader-trigger-disabled)
  .rs-uploader-trigger-btn,
.rs-uploader-picture-text
  .rs-uploader-trigger:not(.rs-uploader-trigger-disabled)
  .rs-uploader-trigger-btn {
  background: none !important;
  border: none !important;
  box-sizing: border-box !important;
  border-radius: 0 !important;
}
.btn-theme-inscription {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.025em;
  color: var(--primaryColor);
  background: var(--secondary-color);
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  border-radius: 10px;
  padding: 1rem;
  width: 100%;
  white-space: nowrap;
  outline: none;
}
.btn-theme-inscription:hover {
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  background-color: var(--primaryColor);
  text-decoration: none;
}

.start-label{
  width: 100%;
}


.div-contenant{
  width: 70%;
  margin: auto;
  padding: 10px;
}
.boutton-next{
  margin: 0 20px;
  width: 136px;
  height: 57px;
  background: var(--secondary-color);
  border-radius: 10px;  

  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: var(--primaryColor);
}
.boutton-next:hover{
  
  background: var(--primaryColor);
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
}

.titre-password{
  text-align: start !important;
  margin: 0 20px;
}



.component-front-register-infos-perso-seven{
  /* padding: 200px; */
background: var(--primaryColor);
border-radius: 10px;  
}

.champ-texte{

border: 0.25px solid rgba(6, 72, 184, 0.2);

background: var(--primaryColor);
box-sizing: border-box;
}
.style-text-RIB{

font-family: 'DM Sans';
font-style: normal;
font-weight: normal;
font-size: 13px;
line-height: 17px;
width:100%;
color: rgba(0, 0, 0, 0.5);

}


.style-text-RIB2{

font-family: 'DM Sans';
font-style: normal;
font-weight: normal;
font-size: 13px;
line-height: 17px;
color: rgba(0, 0, 0, 0.5);

}

.bloc-div-register{
width: 100%;
display: flex;
justify-content: center;
align-items: center;
}

.main-content{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 80%;
padding: 300px;
background: var(--primaryColor);
box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.07);
}

.row-content{
height: 100vh;
background: var(--quinaryColor);
display: flex;
justify-content: center;
align-items: center;
}


@media only screen and (max-width: 600px) {
.col-space{
  display: none;
}

.main-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 10px;
  /* overflow: scroll; */
  background: var(--primaryColor);
  box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.07);
}

.row-content{
  height: 100vh;
  background: var(--quinaryColor);
  display: flex;
  justify-content: center;
  align-items: center;
}

.bloc-div-register{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
}

  

}

@media only screen and (min-width: 600px) {


}

@media only screen and (min-width: 768px) {
.bloc-div-register{
  width: 170%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -40px;
}
}

@media only screen and (min-width: 992px) {

}

@media only screen and (min-width: 1440px) {
.bloc-div-register{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
}
}


.component-front-register-infos-perso-two{
  background: var(--quinaryColor);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-telecharger-document{
  background: var(--primaryColor) !important;
  box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.07);
  height: 80vh;
  max-width: 70vw;
  display: flex;
  justify-content: center;
  align-items: center;
}


.style-prev{
font-family: 'DM Sans';
font-style: normal;
font-weight: normal;
font-size: 13px;
line-height: 17px;
color: rgba(0, 0, 0, 0.5);
}
.style-next{
font-family: 'DM Sans';
font-style: normal;
font-weight: normal;
font-size: 13px;
line-height: 17px;
color: rgba(0, 0, 0, 0.5);
}


.title-verification{
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 42px;
  color: var(--primarytextColor);
}

.check-input1{
  border: 1px solid #BDBDBD;
  box-sizing: border-box;
  border-radius: 5px;
  width: 20px;
  height: 20px;
}
.check-input1:checked{
  
  background: var(--secondary-color) !important;
}

.check-label1{
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.5);
}

.boutton-continuer{
  background: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
  padding: 9px 30px !important;
  border-radius: 10px !important;
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 175% !important;
  font-size: 18px !important;
  color: var(--primaryColor) !important;
  outline: none !important;
}
.boutton-continuer:hover{
  background: var(--primaryColor) !important;
  color: var(--secondary-color) !important;
  border: 1px solid var(--secondary-color) !important;
  text-decoration: none;

}

.contenant-2{
  width: 100%;
  margin: auto;
}

.btn-continuer-2{
  margin-left: 60px;
}

.titre-document{
  text-align: start !important;
  font-size: 22px !important;
}

.boutton-continuer {
  background: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
  padding: 9px 20px !important;
  border-radius: 8px !important;
  font-family: DM Sans !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 175% !important;
  color: var(--primaryColor) !important;
  text-transform: uppercase;
}

.component-front-home {
	background-color: var(--primaryColor) !important;
	box-sizing: border-box;
}
.content-view-start {
	position: relative;
}
.color-span {
	color: var(--secondary-color);
}
.secondyLogo {
	color: var(--secondary-color);
}
.titre {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 32px;
	line-height: 140%;
	margin-top: 8rem;
	/* or 45px */
	margin-left: 18rem;
	color: var(--quinarioTextColor);
}
.rowBack {
	background: var(--tertiaryColor);
}
.margetitre {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 32px;
	line-height: 140%;

	/* or 45px */

	color: var(--quinarioTextColor);
}
/* .ips {
  position: absolute;
  left: 300px;
  height: 500px;
  top: 300px;
  z-index: 199;
} */
/* .marginhome {
  margin-bottom: 150px;
} */
/* .titlepatrimoine {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 37px;
    line-height: 55px;
    color: var(--primarytextColor);
}
.texte-patrimoine {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 140%;

  /* or 45px */

/* color: var(--secondaryTextColor);
    color: var(--secondaryTextColor);
} */ */
.titre-image-illustration {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 37px;
	line-height: 55px;
	color: var(--primarytextColor);
}
.texte-image-illustration {
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 27px;
	letter-spacing: -0.025em;
	color: var(--quinaryTextColor);
}
/* .imgpatrimoine {
  margin-top: -110px;
} */
/* .bloc-div-imgRight {
    margin-top: 4rem;
} */
.topimg {
	position: absolute;
	right: 51px;
	top: -34px;
	width: 100px;
	background: var(--primaryColor);
	/* box-shadow: 0px 13px 34px rgba(0, 0, 0, 0.15); */
	border-radius: 20px;
	padding: 30px;
}
.topimg2 {
	position: absolute;
	left: 55px;
	top: -48px;
	width: 100px;
	background: var(--primaryColor);
	/* box-shadow: 0px 13px 34px rgba(0, 0, 0, 0.15); */
	border-radius: 20px;
	padding: 30px;
}
.newleter {
	background: var(--tertiaryColor);
	padding-top: 2rem;
	padding-bottom: 2rem;
}
.imgnewleter {
	/* opacity: 0.2; */
	padding: 15px 0;
}
.titrenewleter {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 33.203px;
	line-height: 44px;
	/* color: rgba(20, 27, 45, 0.7); */
}
.texte-newleter {
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 21.25px;
	line-height: 28px;
	/* color: #141b2d; */
}
.inputnewleter {
	background: var(--primaryColor);
	border: 1px solid var(--primaryBorderColor);
	box-sizing: border-box;
	box-shadow: inset 0px 1px 5px var(--primaryBoxShadowColor);
	border-radius: 4px;
	height: 48px;
}
.btn-newleter {
	border: 2px solid var(--secondary-color);
	box-sizing: border-box;
	border-radius: 4px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 29px;
	color: var(--secondary-color);
}
.titrepartenaire {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	letter-spacing: 0.505em;
	/* color: #5c6574; */
}
.textepartenaire {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 32px;
	line-height: 21px;
	color: var(--secondaryTextColor);
}
.txtsurlignepartenaire {
	color: var(--secondary-color);
}
.imgpartenaire {
	width: 100%;
	padding: 50px;
}
.imgpartenaireparent {
	background: var(--primaryColor);
	/* box-shadow: 0px 0px 0px 1px rgba(51, 68, 99, 0.16); */
	border-radius: 5px;
	margin-bottom: 60px;
	box-sizing: border-box;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.titrefaq {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 32px;
	line-height: 140%;
	margin: auto;
	color: var(--secondaryTextColor);
	width: 100px;
}
.titrefaqquestion {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 36px;
	letter-spacing: -0.025em;
	/* color: #010101; */
}
.faqtexte {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.025em;
	color: var(--quaterioTextColor);
}
.faqtextecontent {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.025em;
	text-align: justify;
	color: var(--quaterioTextColor);
}
.parag {
	text-align: center;
	font-family: "DM Sans";
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 140%;
	color: var(--secondaryTextColor);
}
.cardBack {
	background: var(--primaryColor);
	box-shadow: 0px 4px 43px var(--octonaryBoxShadowColor);
	padding: 2rem;
}
/* .second-nav-link {
  padding-top: 5rem;
padding-left: 9rem;
} */

.color-boutton {
  color: var(--primaryColor);
  /* bleu */
  background: var(--secondary-color);
  border-radius: 4px;
}
.btn-theme-financer {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 175%;
	text-align: center;
	color: var(--primaryColor);
	background: var(--secondary-color);
	border-radius: 4px;
	padding: 1rem;
}

.funding-couverture-textual-container,
.funding-couverture-textual-container p {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	line-height: 25px;
	color: var(--secondaryTextColor);
}
.test-link-btn {
	background: var(--secondary-color) !important;
	border-radius: 4px !important;
	border-color: var(--secondary-color) !important;
	font-family: DM Sans !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 18px !important;
	line-height: 175% !important;
	animation: wiggle 2s linear infinite;
	left: calc(50% - 3em);
	top: calc(50% - 2em);
	transform-origin: 50% 5em;
}

/* Keyframes */
@keyframes wiggle {
	0%, 7% {
	  transform: rotateZ(0);
	}
	15% {
	  transform: rotateZ(-15deg);
	}
	20% {
	  transform: rotateZ(10deg);
	}
	25% {
	  transform: rotateZ(-10deg);
	}
	30% {
	  transform: rotateZ(6deg);
	}
	35% {
	  transform: rotateZ(-4deg);
	}
	40%, 100% {
	  transform: rotateZ(0);
	}
}

.test-link-btn:hover {
	background: transparent !important;
	color: var(--secondary-color) !important;
}
.funding-couverture-btn-container {
	margin-top: 30px;
}
.how-funding-section-title-container {
	margin-top: 5%;
	margin-bottom: 5%;
}

.how-funding-section-title {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: bold;
	font-size: 28px;
	line-height: 32px;
	text-align: center;
	color: var(--secondary-color);
}
.how-funding-section-label {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 25px;
	text-align: center;
	display: block;
	color: var(--secondaryTextColor);
}
.funding-process-row {
	display: flex;
	align-items: center;
}
.funding-process-item-col {
	background: var(--primaryColor);
	box-shadow: 0px 4px 43px var(--octonaryBoxShadowColor);
	margin-bottom: 15px;
	padding: 15px;
}
.funding-process-item-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 17px;
	line-height: 25px;
	color: var(--secondary-color);
	margin-left: 0;
	margin-top: 0;
}
.funding-process-item-content,
.funding-process-item-content p {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 25px;
	color: var(--quinaryTextColor);
}
.funding-process-row .test-link-btn {
	margin-top: 45px !important;
	margin-bottom: 30px;
}
.funding-process-row .test-link-btn-container {
	text-align: center;
}
.how-funding-section-container {
	padding-bottom: 50px;
}

.funding-description-section-container {
	padding-top: 50px;
	padding-bottom: 50px;
}
.funding-description-item-row {
	display: flex;
	align-items: center;
	padding-top: 30px;
	padding-bottom: 30px;
	margin-bottom: 6rem;
}
.funding-description-textual-content-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between !important;
	padding: 0 20px;
}
.funding-description-textual-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 32px;
	margin-top: 0;
	margin-bottom: 4rem;
}
.funding-description-textual {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	line-height: 25px;
	color: var(--tertiaryTextColor);
	text-align: justify;
}
.funding-description-textual-content-container .test-link-btn-container {
	margin-top: 30px;
}
.funding-process-illustration-col{
	display: flex;
	align-items: flex-start;
}
.funding-process-illustration-col-content{
	display: flex;
	flex-direction: column;
	align-items: center;
}

/* Lever des fonds container */

.raise-money-title-bulle-container {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 3.5rem;
}

.raise-money-title {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: bold;
	font-size: 30px;
	line-height: 40px;
	text-align: center;
	color: var(--secondary-color);
	padding: 7% 0;
}
.raise-money-titles {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 40px;
	/* text-align: center; */
	color: var(--secondary-color);
	padding: 3% 0;
	margin-top: 50px;
	margin-left: 7px;
}

.raise-money-step {
	margin-bottom: 2.5rem ;
}

.raise-money-step-title-bulle-container,
.raise-money-bulle-container,
.raise-money-step-bulle-container {
	display: flex;
	align-items: center;
}

.raise-money-step-title-bulle-container {
	margin-bottom: 0.8rem;
}	
.text-color {
    font-family: 'Poppins';
}
.text-color-financer {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 140%;
    color: var(--secondaryTextColor);
}
.parag{
    font-family: 'DM Sans';
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 140%;
    color: var(--secondaryTextColor);
}


.padd {
  padding-top: 2.2 rem !important;
}

.raise-money-img {
	width: 40px;
	height: 40px;
	margin-right: 10px;
}
.raise-money-step-container{
	margin: 3rem 0 5rem 0;
}
.raise-money-step-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 30px;
	color: var(--secondaryTextColor);
	margin-top: 9px;
}

.raise-money-step-bulle {
	width: 30px;
	height: 30px;
	margin-right: 10px;
}

.raise-money-step-text-container {
	padding-left: 4rem;
}

.raise-money-step-text {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 25px;
	color: var(--secondaryTextColor);
	text-align: justify;
}

@media screen and (max-width: 767px) {
	.funding-process-illustration-col {
		margin-top: 3rem;
		justify-content: center;
	}
	.funding-description-textual-content-container{
		min-height: auto;
	}
}


.banner-liste-projet-tableau-de-bord {
	background-color: var(--primaryColor);
	padding: 4rem 1.5rem;
	margin-bottom: 3%;
	position: fixed;
	right: 0%;
	width: 100%;
	z-index: 999999;
  }
  
  .banner-liste-projet-tableau-de-bord ul {
	padding: 0;
  }
  
  .titre-banner-tableau-de-bord{
	
  }
  
  .titre-banner-tableau-de-bord {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 30px;
	line-height: 39px;
	letter-spacing: -0.025em;
	color: #010101;
  }
  .projets {
	background: var(--quinaryColor) !important;
  }
  
  .content-view-start-tableau-de-bord {
	margin-top: 165px;
	margin-left: 2%;
  }
  
.redirection-projet {
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 0;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
    color: var(--tertiaryTextColor) !important;
}
.redirection-projet:hover {
	text-decoration: none;
    color: rgba(0, 0, 0, 0.7) !important;
	font-weight: 0;
}

.table-body-container {
	padding: 20px 23px 0px 16px;
	border: 1px solid rgba(204, 204, 204, 0.5);
	padding-left: 6px;
	padding-right: 5px;
}

.table-body-container table thead tr > th {
	font-family: "Poppins" !important;
	font-style: normal !important;
	font-weight: bold !important;
	font-size: 16px !important;
	line-height: 24px !important;
	color: var(--secondary-color) !important;
	padding: 13px;
}

.table-body-container table tbody tr td {
	font-family: "Poppins";
	font-size: 16px !important;
	line-height: 24px !important;
	color: var(--nonaryTextColor) !important;
	padding-top: 14px !important;
	padding-bottom: 12px !important;
}

.en-ligne,
.pas-encore-lance,
.termine {
	font-family: Roboto;
	font-weight: 500 !important;
	font-size: 12px !important;
	line-height: 140% !important;
	letter-spacing: -0.3px;
	flex: none;
	order: 0;
	flex-grow: 0;
	margin: 0px 2px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 4px 12px;

	border-radius: 10px;
}

.en-ligne {
	width: 65px;
	background: var(--tertiareColore) !important;
	color: var(--secondary-color) !important;
}

.pas-encore-lance {
	width: 112px;
	font-weight: bold !important;
	background: #e8f5e9 !important;
	color: #4caf50 !important;
}

.termine {
	width: 67px;
	font-weight: bold !important;
	background: #fbede7 !important;
	color: #fe976b !important;
}

.child-table-container {
	background-color: white;
}

.actived {
	color: var(--secondary-color) !important;
}

.tabsTitle {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
    line-height: 25px;
	display: flex;
	align-items: center;
	letter-spacing: -0.025em;
	color: #010101;
	text-transform: uppercase;
}

.navLink {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 26px;
    color: rgba(0, 0, 0, 0.5);
    text-decoration: none;
}

.active {
	color: var(--secondary-color);
    text-decoration: none;
}

.navLink:hover, .navLink:focus {
    color: var(--secondary-color);
    text-decoration: none;
}
@media screen and (max-width: 1024px) {
    .tabsTitle {
        font-size: 20px;
    }
}

.graphText {
    display: flex;
    justify-content: space-between;
}

.graphPara {
    margin-left: 10px;
}

.graphPara > p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;

    color: var(--primarytextColor);
}

.mes-projets {
    padding-top: 86px;
    padding-bottom: 100px;

}

.selection-progression {
    display: flex;
    justify-content: flex-end;
}

.selection-progression > select {
    height: 60px;
}


body {
    margin-bottom: 24px;
}

label {
    margin-right: 3px;
}
.wj-title {
    display: none;
}
.wj-flexchart .wj-gridline {
    stroke: #EBEDF0 !important;
    
}
.wj-legend {
    position: absolute;
    bottom: 0;
}

.graphProgression{
    margin: auto;
    margin-right: 26px;
    margin-left: 10px;
}
.header {
	background: var(--primaryColor);
}

.sticky {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1080;
	left: 0;
	right: 0;
}

.sticky + .content {
	padding-top: 102px;
}

.component-front-header {
	background: var(--primaryColor);

	height: 85px;
}
.link-top-header {
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	color: var(--primaryColor);
}
.link-top-header:hover {
	text-decoration: none;
	color: var(--primaryColor);
}

.component-front-header .nav-link {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 17px;
	text-transform: uppercase;
	color: var(--decimalTextColor);
	padding: 10px 15px;
	border-bottom: 2px solid transparent;
	white-space: nowrap;
}

.component-front-header .nav-link:hover,
.nav-link:focus {
	border-bottom: 2px solid var(--secondary-color);
}
.component-front-header .nav-link.active {
	font-family: "DM Sans";
	font-weight: 700;
	border-bottom: 2px solid var(--secondary-color);
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 17px;
	letter-spacing: 0.291667px;
	color: var(--secondary-color) !important;
}

.dropdown-header {
	position: relative;
	display: inline-block;
}

.dropdown-content-header {
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	padding: 12px 16px;
	z-index: 1;
	right: 0;
	top: 3.5rem;
}

.dropdown-header:hover .dropdown-content-header {
	display: block;
}
.dropdown-icon-top-header {
	width: 28px;
	height: 28px;
	color: #787878;
	background-color: var(--primaryColor);
	border-radius: 50%;
}
.dropdown-icon-top-header svg {
	width: 18px;
}
.link-top-header.dropdown-toggle::after {
	position: absolute;
	top: 70%;
	right: -14px;
}

.link-header-login {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	color: var(--primaryColor);
	background: var(--secondary-color);
	padding: 1rem 2rem 1rem 2rem;
	border-radius: 10px;
}
.link-header-login:hover {
	text-decoration: none;
	color: var(--secondary-color);
	background-color: transparent;
	border: 1px solid var(--secondary-color);
}
.link-header-register {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	color: var(--secondary-color);
	background: var(--primaryColor);
	padding: 1rem 2rem 1rem 2rem;
	border-radius: 10px;
	border: 1px solid var(--secondary-color);
}
.link-header-register:hover {
	background-color: var(--secondary-color);
	color: var(--primaryColor);
	text-decoration: none;
}
.header-scrolling-container {
	background-color: var(--primaryColor);
	z-index: 9999999;
	box-shadow: 0px 8px 15px rgb(0 0 0 / 5%);
}

.connexion-dropdown .dropdown-menu {
	right: 0;
	left: auto;
	top: 33px !important;
	min-width: 13rem;
	padding: 0.5rem 21px;
	margin: 0.125rem 0 0;
}
.connexion-dropdown .dropdown-toggle {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 22px;
	color: var(--secondary-color);
}
.connexion-dropdown .dropdown-toggle:hover,
.connexion-dropdown .dropdown-toggle:focus {
	text-decoration: none;
	color: var(--secondary-color);
}
.connexion-dropdown .icon {
	width: 35px;
	height: 35px;
	border-radius: 8px;
	border: 1.5px solid var(--primaryColor);
	background: #e3e3e3;
}
.connexion-dropdown .dropdown-item {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 12px;
	padding: 1rem;
	color: var(--primarytextColor);
}

.connexion-dropdown .dropdown-item .icon {
	border: 1.5px solid #130f40;
}
.no-bg.active {
	background-color: inherit !important;
}
.no-bg:hover {
	background-color: var(--secondary-color) !important;
	color: var(--primaryColor);
}
.logo-firsty {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: normal;
	font-size: 23px;
	line-height: 30px;
	color: var(--primarytextColor);
}
.logo-secondy {
	color: var(--secondary-color);
}
.link, .link:hover {
	font-family: Poppins;
	font-style: normal;
	font-size: 18px;
	line-height: 27px;
    color: black;
    text-decoration: none;
}

.data-center-header {
    margin-left: 23.5px;
  }
  .data-center-title {
    display: flex;
    align-items: center;
  }

  .data-center-head h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: var(--primarytextColor);
    text-align: left;
    position: relative;
    right: 1%;
  }
  
  .data-center-head p {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 23px;
    line-height: 34px;
    letter-spacing: -0.025em;
    color: #2D2D2D;
    text-align: left;
    margin-left: 25px;
  }
  
  .data-center-title {
    margin-bottom: -1rem;
  }
  
  .data-center-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: 25px;
    margin-top: 48px;
    margin-bottom: 134px;
  }
  
  .data-center-body > .details > p {
    /*font-family: Poppins;*/
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
  
    /* identical to box height */
    color: var(--primarytextColor);
  }
  
  .data-center-body > .details > p > span {
    /*font-family: Poppins;*/
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */
  
    color: var(--primarytextColor);
  }
  
  .progressBar > p {
    /*font-family: Poppins;*/
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    color: var(--primarytextColor);
  }
  
  .progress {
    position: relative;
    
  }
  
  .progressBarChild {
    position: relative;
  }
  
  .progressBarChild > p {
    display: flex;
    grid-gap: 0;
    gap: 0;
  }
  
  .progressBarChild > p > span:nth-child(1) {
    padding-right: 65px;
    /*font-family: Poppins;*/
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    /* identical to box height */
    display: flex;
    align-items: center;
  
    color: #757575;
  }
  
  .progressBarChild > p > span:nth-child(2) {
    /*font-family: Poppins;*/
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 19px;
    /* identical to box height */
  
    display: flex;
    align-items: center;
  
    /* bleu */
  
    color: var(--secondary-color);
    position: relative;
    right: 4%;
  }
  
  .objectif {
    /*font-family: Poppins;*/
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 15px;
    /* identical to box height */
  
    display: flex;
    align-items: center;
  
    color: #757575;
    padding-left: 95px;
  }
  
  .data-center-table {
    margin-right: 40% !important;
    padding-bottom: 5rem;

  }
  
  .liste-des-donnateurs {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 25px;
    /* identical to box height */
  
    color: var(--primarytextColor);
  }
  
  .info-donnateur {
    border-top: #979595 solid 1px !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
  }
  
  .userData {
    display: flex;
    padding: initial 0 !important;
    width: 75%;
  }
  
  .userData img {
    width: 55px;
    height: 55px;
    border-radius: 100%;
    margin: 0;
  }
  
  .userData p {
    display: flex;
    flex-direction: column;
    padding-left: 22px;
    padding-top: 14px;
  }
  
  .user-data p > span:nth-child(1) {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    /* identical to box height, or 118% */
  
    letter-spacing: 0.2px;
  
    /* black */
  
    color: #171d33;
    text-align: left !important;
  }
  
  .user-data p > span:nth-child(2) {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    /* identical to box height, or 154% */
  
    letter-spacing: 0.2px;
  
    color: rgba(0, 0, 0, 0.7);
    text-align: left !important;
  }
  
  .price {
  }

  .price p {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold !important;
    font-size: 20px;
    line-height: 20px;
    /* identical to box height, or 118% */
  
    letter-spacing: 0.2px;
  
    /* black */
  
    color: #171d33;
  }
  
  .price p {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    margin-top: 24px;
    white-space: nowrap;
  }
  
* {
	-webkit-appearance: none;
	        appearance: none;
}

.alimenter-son-compte-element {
	display: flex;
	justify-content: center;
	background-color: var(--septanaryColor);
	padding-top: 4rem;
	padding-bottom: 7rem;
}

/* .component-details .img-details {
	height: 100px !important;
} */

.alimenter-son-compte-element input[type="radio"] {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	border: 1px solid var(--secondary-color) !important;
	box-sizing: border-box;
	outline: none;
	background-color: var(--primaryColor);
	padding: 5px !important;
}

.alimenter-son-compte-element input[type="radio"]:checked, .alimenter-son-compte-element input[type="radio"]:focus {
	background-color: var(--secondary-color) !important;
}

.choisir-montant,
.mode-paiement {
	width: 631px;
	height: 581px;
	background-color: var(--primaryColor);
	border-radius: 10px;
}

.detail-paiement {
	width: 631px;
	/* height: 581px; */
	background-color: var(--primaryColor);
	border-radius: 10px;
	position: relative;
}

@media screen and (max-width: 767px) {
	.choisir-montant,
	.mode-paiement {
		width: 300px;
	}
	.choisir-montant h2,
	.mode-paiement h2,
	.detail-paiement {
		font-size: 17px !important;
	}
}
.choisir-montant h2,
.mode-paiement h2,
.detail-paiement h2 {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 36px;
	line-height: 54px;
	text-align: center;
	color: var(--primaryTextColor);
}

.choisir-montant label {
	font-weight: 400;
	font-size: 15px;
	line-height: 22.5px;
	font-style: normal;
}

.choisir-montant .form-group-choisir-montant {
	width: 100%;
	border-bottom: 1px solid var(--quaternaryBorderColor);
}

.choisir-montant input.input-choisir-montant {
	width: 100%;
	font-size: 15px;
	line-height: 22.5px;
}
.button-alimenter-son-compte {
	border-radius: 5px;
	border: 1px solid var(--secondary-color);
	width: 130px;
	height: 40px;
	font-family: "Poppins", sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.75px;
	transition: 0.3s ease-in-out;
	outline: none;
}

.button-alimenter-son-compte:focus {
	outline: none;
}
.button-alimenter-son-compte-suivant {
	background: var(--secondary-color);
	color: var(--primaryColor);
}

.button-alimenter-son-compte-precedent {
	color: var(--secondary-color);
	background-color: var(--primaryColor);
}

.button-alimenter-son-compte-precedent:hover,
.button-alimenter-son-compte-precedent:focus {
	color: var(--secondary-color);
}

.button-alimenter-son-compte-suivant:hover {
	color: var(--primaryColor);
}

.mode-paiement-buttons {
	margin-top: 15rem;
}


.mode-paiement-label {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 22px;
	opacity: 0.7;
	margin: 0;
}

.small-radio {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 15px;
	opacity: 0.3;
	margin: 0;
}

.detail-paiement h5 {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 27px;
}


.modal-text {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 19px;
	opacity: 0.7;
}

.text-modal-dark {
	opacity: 1;
	font-weight: 600;
}

.mode-paiement-modal-button-annuler {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
}

.detail-paiement-carte {
	display: flex;
	align-items: center;
	padding: 7px 10px;
	border-radius: 5px;
	margin-bottom: 1rem;
	cursor: pointer;
}

.input-hidden-radio {
	position: absolute;
	left: -9999px;
  }
  input[type=radio]:checked ~ label {
	color: var(--primaryColor);
	background-color: var(--secondary-color);
	padding: 10px;
	border-radius: 10px;
  }
  
.detail-projet-en-cours-de-campagne {
	background-color: var(--primaryColor);
	padding: 2rem;
}

.detail-projet-en-cours-de-campagne-card {
	display: flex;
	align-items: center;
	margin-bottom: 1.5rem;
}
.detail-projet-en-cours-de-campagne-card-info {
	width: 40%;
	display: flex;
	align-items: center;
	margin-right: 5%;
}

.detail-projet-en-cours-de-campagne-card-date {
	width: 25%;
	margin-right: 5%;
	display: flex;
	justify-content: center;
}

.detail-projet-en-cours-de-campagne-card-links {
	width: 25%;
	display: flex;
	justify-content: center;
}

.detail-projet-en-cours-de-campagne-card-img img {
	width: 97px;
	height: 89px;
	border-radius: 8px;
	margin-right: 1rem;
}

.detail-projet-en-cours-de-campagne-card-date h6 {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	letter-spacing: -0.025em;
	color: rgba(0, 0, 0, 0.5);
}

.detail-projet-en-cours-de-campagne-card-text h1 {
	font-family: DM Sans;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 26px;
	letter-spacing: -0.025em;
	color: rgba(0, 0, 0, 0.8);
}

.detail-projet-en-cours-de-campagne-card-body {
	font-family: DM Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 150.7%;
	letter-spacing: -0.025em;
	color: #737373;
	text-align: justify;
}

.detail-projet-en-cours-de-campagne-card-link {
	background: var(--secondary-color);
	border-radius: 10px;
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
    color: var(--primaryColor);
    padding: 1.5rem 2rem;
}

.detail-projet-en-cours-de-campagne-card-link:hover {
    color: var(--primaryColor);
    text-decoration: none;
}

@media screen and (min-width:992px) and (max-width: 1150px) {
	.detail-projet-en-cours-de-campagne-card-info {
		width: 45%;
		display: flex;
		align-items: center;
		margin-right: 3%;
	}
	
	.detail-projet-en-cours-de-campagne-card-date {
		width: 20%;
		margin-right: 2%;
		display: flex;
		justify-content: center;
	}
	
	.detail-projet-en-cours-de-campagne-card-links {
		width: 25%;
		display: flex;
		justify-content: center;
	}
	.detail-projet-en-cours-de-campagne-card-link {
		font-size: 12px;
		line-height: 18px;
	}
	.detail-projet-en-cours-de-campagne-card-date h6 {
		font-size: 11px;
		line-height: 20px;
		letter-spacing: -0.025em;
		color: rgba(0, 0, 0, 0.5);
	}
}

@media screen and (max-width: 767px) {
	.detail-projet-en-cours-de-campagne-card {
		display: flex;
		flex-direction: column;
	}
	.detail-projet-en-cours-de-campagne-card-info {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	.detail-projet-en-cours-de-campagne-card-date {
		width: 100%;
		display: flex;
		justify-content: center;
	}
	
	.detail-projet-en-cours-de-campagne-card-links {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.detail-projet-en-cours-de-campagne-card-img{
		width: 100%;
		margin-bottom: 5px;
	}

	.detail-projet-en-cours-de-campagne-card-img img {
		width: 100%;
		height: 180px;
		border-radius: 8px;
	}

	.detail-projet-en-cours-de-campagne-card-link {
		width: 90%;
		text-align: center;
	}
	.detail-projet-en-cours-de-campagne-card-date {
		width: 90%;
		display: flex;
		justify-content: center;
		margin-bottom: 5px;
	}	
}
.dashboard-card {
	background-color: var(--primaryColor);
	padding: 1.5rem 2rem;
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 15px;
	width: 100%;
}

.dasboard-card-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 150%;
	color: var(--quinaryTextColor);
	margin: 0;
	margin-bottom: 7px;
}

.dasboard-card-montant {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 140%;
	color: var(--secondary-color);
	margin: 0;
}

.dasboard-card-img-container {
	background-color: var(--secondary-color);
	padding: 1rem;
	border-radius: 12px;
	color: var(--primaryColor);
}

.dashboard-chart-container {
	background: var(--primaryColor);
	box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
	border-radius: 15px;
	padding: 1.5rem 2rem;
	margin-top: 3rem;
}

.dashboard-chart-info-filter {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.dashboard-chart-info-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 25px;
	color: #2d3748;
	margin: 0;
	margin-bottom: 10px;
}

.dashboard-chart-info-stat {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: var(--secondary-color);
	margin: 0;
}

.dashboard-chart-filter {
    background: var(--primaryColor);
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.07);
	border-radius: 10px;
    height: 45px;
    position: relative;
}

.dashboard-chart-filter-select {
    background: var(--primaryColor);
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.07);
	border-radius: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.4px;
    color: #252733;
    height: 100%;
    padding: 0 12rem 0 3rem;
    border: none;
    outline: none;
    cursor: pointer;
}

.dashboard-chart-filter-icon {
    position: absolute;
    right: 3px;
    top: 10px;
    color: var(--secondary-color);
}

.child-table-container {
	background-color: white;
}

.name-email-group span {
	display: block;
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
}

.name-email-group span.name {
	color: #2d3748;
}

.name-email-group span.email {
	color: #718096;
}

.table-actions-container {
    display: flex;
    align-items: center;
}

.cell-style {
    display: flex ;
    align-items: center;
}
.easypm-modal-header {
	display: flex !important;
	justify-content: space-between !important;
	align-items: center
}

.crud-form-control {
    padding: 22px !important;
    border-radius: 10px !important;
}


.users-container {
	background-color: var(--quinaryColor);
	padding: 2rem;
}

.liste-utilisateurs {
	background-color: var(--primaryColor);
    padding: 1rem 2rem;
    border-radius: 10px;
}

.liste-utilisateurs-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 25px;
	color: #2d3748;
    margin: 0;
}

.users-table {
    margin-top: 3rem;
}

.users-table .modal-body, .users-table .modal-content, .crud-form{
	background-color: var(--primaryColor) !important;
	opacity: 1 !important;
}

.user-img {
    width: 40px;
    height: 40px;
    border-radius: 35%;
}

.child-table-container {
	background-color: white;
}

.name-email-group span {
	display: block;
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
}

.name-email-group span.name {
	color: #2d3748;
}

.name-email-group span.email {
	color: #718096;
}

.table-actions-container {
    display: flex;
    align-items: center;
}

.cell-style {
    display: flex ;
    align-items: center;
}

.users-container {
	background-color: var(--quinaryColor);
	padding: 2rem;
}

.liste-utilisateurs {
	background-color: var(--primaryColor);
    padding: 1rem 2rem;
    border-radius: 10px;
}

.liste-utilisateurs-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 25px;
	color: var( --decimalTextColor);
    margin: 0;
}

.users-table {
    margin-top: 3rem;
}

.user-img {
    width: 40px;
    height: 40px;
    border-radius: 35%;
}

.projets-container {
	padding: 4rem 0rem !important;
    min-height: 100vh;
}

.liste-projets {
	background-color: var(--primaryColor);
	padding: 1rem 2rem;
	border-radius: 10px;
}

.projets-table {
	margin-top: 3rem;
}

.projets-table .menu .active{
	background-color: var(--secondary-color);
	color: var(--primaryColor) !important;
	border-radius: 10px;
	border-bottom: 2px solid var(--secondary-color) !important;
}

.liste-projets-bar {
	display: flex;
	justify-content: space-between;
	padding-right: 1rem;
	align-items: center;
}

.liste-projets-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 25px;
	color: #2d3748;
	margin: 0;
}

.liste-projets-statistiques {
	margin-top: 10px;
}

.icon-check-container {
	background: var(--secondary-color);
	color: var(--primaryColor);
	margin-right: 5px;
	border-radius: 50%;
	padding: 1px 0.3px;
	font-weight: 500;
}

.number-project-text {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 140%;
	color: #a0aec0;
}

.ajouter-projet-link {
	display: flex;
	align-items: center;
	background-color: var(--secondary-color);
	
}

.ajouter-projet-link-icon {
	color: var(--primaryColor);
	/* margin-right: 5px; */
}

.ajouter-projet-link-text,
.ajouter-projet-link:hover {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 16px;
	color: var(--primaryColor);
	text-decoration: none;
}

/************* Add Project Style *****************/

.add-projet-container {
	background-color: var(--quinaryColor);
	padding: 3rem;
}

.add-projet-content,
.detail-projet-content {
	background-color: var(--primaryColor);
	padding: 1.5rem 4rem;
	border-radius: 10px;
}

.add-project-info-icon-title-container {
	display: flex;
	grid-gap: 10px;
	gap: 10px;
}

.add-project-info-stepper {
	display: flex;
	align-items: center;
	margin-bottom: 4rem;
}

.add-project-info {
	display: flex;
	grid-gap: 8px;
	gap: 8px;
}

.add-project-icon {
	color: var(--decimalTextColor);
	line-height: 20px;
	cursor: pointer !important;
	display: inline-block;
}

.add-project-general-title {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 25px;
	color: #2d3748;
	margin: 0;
}

.add-project-component-title {
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 25px;
	color: var(--secondary-color);
	margin: 0;
}

.add-project-stepper {
	display: flex;
	align-items: center;
	grid-gap: 10px;
	gap: 10px;
	justify-content: flex-end;
}

.stepper {
	height: 3px;
	min-width: 60px;
	border-radius: 25%;
	background-color: #c4c4c4;
}

.stepper-active {
	background-color: var(--secondary-color);
}

/******************** Details de base  ************************************************************/
.form-add-project-group {
	margin-bottom: 4rem;
	width: 100%;
}

.form-add-project-label {
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
	color: #2f3a4a;
}

.form-add-project-input {
	background: var( --primaryColor);
	border: 1px solid #e8e8e8;
	box-sizing: border-box;
	border-radius: 2px;
	height: 46px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #2f3a4a;
	width: 90%;
}

.form-add-project-input::placeholder {
	color: #ababab;
}

.form-add-project-input-url {
	width: 95%;
}

.form-group-add-project-dropzone-container {
	width: 95%;
}

.form-add-project-buttons-container {
	display: flex;
	justify-content: flex-end;
	width: 97.2%;
	padding-top: 2rem;
}

.form-add-project-button-next {
	background: var(--secondary-color);
	border-radius: 4px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 22px;
	text-align: center;
	color: var(--primaryColor);
	outline: none;
	padding: 0.9rem 2rem;
}

.form-add-project-button-previous {
	background: transparent;
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 22px;
	text-align: center;
	color: var(--secondary-color);
	margin-right: 2rem;
}
/***########### Input choose file Style ###################***/

.start-label-input-choose-file {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.025em;
	width: 100% !important;
}

.first_label-input-choose-file {
	width: 100% !important;
	display: block;
	color: var(--secondary-color);
	margin-top: 10px;
	margin-bottom: 3px;
}

.second-label-input-choose-file {
	color: #000;
	opacity: 0.5;
}

/***################ Details de base second Step***************/
.form-add-project-check-input-container {
	width: 90%;
	height: 46px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid #e8e8e8;
	box-sizing: border-box;
	padding: 0 1rem;
}
.form-add-project-group-relative {
	position: relative;
}

.form-add-project-unity {
	display: inline-block;
	width: 60px;
	position: absolute;
	line-height: 35px;
	top: 3.5rem;
	right: 15%;
	color: #ababab;
	border-left: 1px solid #e8e8e8;
	padding-left: 7px;
	text-align: center;
	font-size: 12px;
}

.form-add-project-check-input-group {
	display: flex;
	align-items: center !important;
	grid-gap: 5px;
	gap: 5px;
}

.form-add-project-check-input-label {
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	color: #ababab;
	margin: 0 !important;
}

.form-add-project-check-input {
	margin: 0 !important;
	outline: none !important;
	border: 2px solid var(--secondary-color);
	border-radius: 5px;
}

/* *################## Presentation de projet step 3 ###################*/
.form-add-project-input-textarea {
	height: 130px;
	min-height: 130px;
	max-height: 130px;
	outline: none;
	padding: 5px 10px;
	width: 95%;
}
.form-add-project-ckeditor {
	width: 96%;
}

/* *******Media Querie */
@media screen and (max-width: 767px) {
	.add-project-stepper {
		justify-content: center;
		margin-top: 20px;
	}
}

@media screen and (max-width: 497px) {
	.add-project-info {
		flex-direction: column;
	}
	.add-project-component-title {
		text-align: center;
	}

	.stepper {
		min-width: 30px;
	}

	.form-add-project-input {
		font-size: 10px;
		line-height: 20px;
		width: 100%;
		padding-left: 4px;
	}
	.form-add-project-check-input-container {
		width: 100%;
		flex-direction: column;
		align-items: flex-start;
		height: 90px;
		padding: 5px;
	}

	.form-add-project-unity {
		width: 42px;
		font-size: 8px;
	}

	.form-add-project-label {
		font-size: 10px;
	}
	.form-add-project-group {
		padding: 0 !important;
	}
	.table-body-container-remboursement,
	.form-add-project-input-textarea {
		width: 100% !important;
	}

	.form-add-project-ckeditor {
		width: 100% !important;
		padding-right: 0;
	}
}

/**################### 	Detail-projet ############################*/
.detail-projet-info-actions {
	align-items: center;
}

.detail-projet-icon-title-container {
	display: flex;
	align-items: center;
}

.detail-projet-name-projet {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 27px;
	letter-spacing: -0.025em;
	color: var(--decimalTextColor);
}

.detail-projet-text {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 17px;
	letter-spacing: -0.025em;
	color: #737373;
}

.detail-projet-actions {
	display: flex;
	justify-content: flex-end;
}

.detail-projet-text-container {
	padding-left: 2.5rem;
}

.link-detail-projet {
	color: var(--secondary-color);
}

.detail-projet-info-label {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 27px;
	color: var(--secondary-color);
}

.detail-projet-info-val {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 27px;
	color: var(--secondary-color);
}

.detail-projet-resume-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: var(--secondary-color);
}

.detail-projet-resume-progress-text {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.detail-projet-resume-percentage {
	font-family: DM Sans;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 17px;
	color: var(--quinaryTextColor);
}

.detail-projet-resume-solde {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	line-height: 19px;
	color: var(--secondary-color);
}
.detail-projet-resume-objectif-text {
	font-family: DM Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	color: var(--quinaryTextColor);
}

.detail-projet-resume-info-porteur-projet {
	display: flex;
	align-items: center;
	grid-gap: 8px;
	gap: 8px;
}

.detail-projet-resume-avatar-porteur-projet {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	margin: 0;
}

.detail-projet-resume-name-porteur-projet {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 27px;
	letter-spacing: -0.025em;
	color: var(--decimalTextColor);
}

.detail-projet-resume-cat-porteur-projet {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: -0.025em;
	color: var(--decimalTextColor);
}

.detail-projets-tabs-container {
	margin-top: 3rem;
	border-bottom: 1px solid #eaeaea;
	padding-bottom: 0.4rem;
}

.detail-projets-tabs {
	display: flex;
	align-items: center;
	grid-gap: 12px;
	gap: 12px;
}

.detail-projets-tabs-item {
	list-style: none;
	font-family: "DM Sans";
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 21px;
	color: var(--quinaryTextColor);
	cursor: pointer;
	padding: 0 1rem;
}

.detail-projet-desc-content{
	background: var( --primaryColor);
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
	border-radius: 10px;
	max-width: 160%;	  
}

.description .card-header{
	background: transparent;
	cursor: pointer;
}

.description svg{
	font-size: 40px !important;
	cursor: pointer;
}

.detail-projets-tabs-item.active-tab {
	background: var(--secondary-color);
	border-radius: 10px;
	color: var( --primaryColor);
	font-weight: bold;
	padding: 1rem;
}

.detail-projet-title-desc {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 42px;
	display: flex;
	align-items: center;
	letter-spacing: -0.025em;
	color: var(--secondary-color);
}

.detail-projet-desc-text {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	line-height: 25px;
	letter-spacing: -0.025em;
	color: var(--secondaryTextColor);
	text-align: justify;
}

.detail-projet-desc-objectif {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 27px;
	letter-spacing: -0.025em;
	color: var(--secondary-color);
}

.detail-projet-desc-info-val {
	color: var(--secondary-color);
	margin-left: 5px;
}

/** Detail Projet Donnees financieres */
.detail-projet-finance-data-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 25px;
	color: var(--secondary-color);
	padding-bottom: 1.3rem;
	border-bottom: 1px solid #eaeaea;
}

.detail-projet-finance-data-donneur-container {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding-right: 10%;
	align-items: center;
	padding: 1rem;
	border-bottom: 1px solid #eaeaea;
}

.detail-projet-finance-data-donneur-avatar-info {
	display: flex;
	align-items: center;
	grid-gap: 10px;
	gap: 10px;
}

.detail-projet-finance-data-donneur-image {
	width: 55px;
	height: 55px;
	border-radius: 50%;
}
.detail-projet-finance-data-donneur-name {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 17px;
	line-height: 20px;
	letter-spacing: 0.2px;
	color: #171d33;
	margin: 0;
}

.detail-projet-finance-data-donneur-date-donnation {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 20px;
	letter-spacing: 0.2px;
	color: rgba(0, 0, 0, 0.7);
	margin: 0;
}

.detail-projet-resume{
	background: var( --primaryColor);
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
	border-radius: 10px;
	max-width: 160%;
}

.detail-projet-finance-data-donneur-montant-donnation {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 17px;
	line-height: 20px;
	letter-spacing: 0.2px;
	color: #171d33;
	margin: 0;
}

/* * Detail projet document */

.link-add-doc-button {
	background: var( --primaryColor);
	border: 1px solid #e8e8e8;
	box-sizing: border-box;
	border-radius: 2px;
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #ababab;
	padding: 1rem;
}

.nav-items-customs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-item-vertical-custom {
  width: 100%;
  /* padding: 2rem 0 0 0; */
}

.nav-link-vertival-custom {
	font-family: "Poppins" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 14px !important;
	line-height: 18px !important;
	color: var(--quinaryTextColor);
	width: 100%;
	display: flex !important;
	grid-gap: 10px;
	gap: 10px;
	align-items: center;
	padding: 1rem 5% 1rem 18% !important;
}

.navbar-vertical-container-icon {
  width: 30px !important;
  background-color: var(--primaryColor) ;
  text-align: center;
  height: 30px !important;
  display: flex;
  align-items: center;
  justify-content: center !important;
  font-size: 14px;
  color: var(--quinaryTextColor);
  border-radius: 12px;
}

.text-dashbord {
  color: var(--quinaryTextColor) !important;
}

#scrollbar {
  height: 100%;
  overflow-y: auto;
}
#scrollbar::-webkit-scrollbar {
  display: none;
}
.contents {
  height: 0px !important;
}
.nav-items-customs .active, .nav-items-customs .active .navbar-vertical-container-icon, .nav-items-customs .active .text-dashbord{
  color: var(--primaryColor)   !important;
  background-color: var(--secondary-color) !important;
}
.containMenu{
  color: var(--quinaryTextColor);
  font-size: 15px;
  font-weight: 500;
  padding: 1rem 5% 1rem 18% ;
}
.component-navbar-mobile-v2{
    
}
.profile-containers {
  background-color: var(--primaryColor);
  padding: 1rem 2rem;
  border-radius: 10px;
  margin-top: 1rem;
}

.admin-container,
.liste-porteur-projet-container {
  background-color: var(--primaryColor);
  padding: 1rem 2rem;
  border-radius: 10px;
  margin-top: 3rem;
}

.bloc-principal-body-content-profile {
  width: calc(100% - 17%);
}

.mon-profil {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: var( --decimalTextColor);
}
.information-personnelle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  text-align: right;
  letter-spacing: -0.025em;
  color: var(--secondary-color);
}
.text-jones {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  text-align: right;
  letter-spacing: -0.025em;
  color: var( --decimalTextColor);
}
.text-admin {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  text-align: right;
  letter-spacing: -0.025em;
  color: var( --decimalTextColor);
  text-align: center;
}
.pp-admin {
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  object-fit: fill;
}


.profile-avatar-container {
  width: 150px !important;
  height: 150px !important;
  overflow: hidden !important;
  position: relative !important;
  margin: 15px auto !important;
  border: 1px solid transparent !important;
  border-radius: 50% !important;
  cursor: pointer !important;
}

.profile-avatar-container img {
  width: 100% !important;
  height: 100% !important;
  display: block !important;
  object-fit: cover !important;
}

.profile-avatar-container span {
  position: absolute !important;
  bottom: -100% !important;
  left: 0 !important;
  width: 100% !important;
  height: 50% !important;
  background: var(--primaryColor)8 !important;
  text-align: center !important;
  font-weight: 400 !important;
  color: var(--secondary-color) !important;
  transition: 0.3 ease-in-out !important;
}

.profile-avatar-container span p {
    color: var(--secondary-color) !important;
}
.profile-avatar-container:hover span {
  bottom: -15% !important;
  
}

.profile-avatar-container span.charge {
  bottom: -15% !important;
}

.profile-avatar-container #file_up {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  opacity: 0 !important;
  cursor: pointer !important;
}
::-webkit-file-upload-button {
  cursor: pointer !important;
}

/* --------------les input dans component profile------------------ */
.form-control-profil {
  position: relative;
  display: block;
  width: 100%;
  height: 50px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  color: var(--quinaryTextColor);
  background-color: var(--primaryColor);
  background-image: none;
  background: var(--primaryColor);
  border: 2px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 10px;
}

.form-input-profile {
  padding-top: 70px;
}
.container-input-address {
  padding-top: 50px;
}

.form-label-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var( --decimalTextColor);
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 6px 0px;
}
.infos-perso-input {
  margin-bottom: 3.5rem !important;
}
.btn-modifier {
  width: 92px;
  height: 38px;
  background: var(--secondary-color);
  border-radius: 4px;
  color: var(--primaryColor);
}

.profile-container {
  background-color: var(--quinaryColor);
  padding: 2rem;
}
.bloc-principal-body-content-profile {
  width: calc(100% - 17%);
}

.profile-container-photo {
  background-color: var(--primaryColor);
  padding: 1rem 2rem;
  border-radius: 10px;
}
.text-edit {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #ababab;
  margin-left: 15px;
}
.icone-edit-profile {
  margin-right: 80px;
}
.profile-container .mon-profil {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: #2d3748;
}
.profile-container .information-personnelle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  text-align: right;
  letter-spacing: -0.025em;
  color: var(--secondary-color);
}
.text-jones {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  text-align: right;
  letter-spacing: -0.025em;
  color: var(--decimalTextColor);
}
.text-admin {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  text-align: right;
  letter-spacing: -0.025em;
  color: var(--decimalTextColor);
  text-align: center;
}
.bloc-principal-body-content-administrateur {
  width: calc(100% - 17%) !important;
  padding-top: 3.5rem;
}
/* --------------les input dans component profile------------------ */
.form-control-profil {
  position: relative;
  display: block;
  width: 100%;
  height: 50px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: var(--quinaryTextColor);
  background-color: var(--primaryColor);
  background-image: none;
  background: var(--primaryColor);
  border: 1px solid var(--quinaryTextColor);
  box-sizing: border-box;
  border-radius: 10px;
}

.form-input-profile {
  padding-top: 20px;
}
.container-input-address {
  padding-top: 50px;
}
/* .text-ajouter-admin {
  background: var(--primaryColor);
  border: 1px solid var(--quinaryTextColor);
  width: 13%;
  border-radius: 2px;
  margin-left: 85%;
} */

.form-label-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--decimalTextColor);
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 6px 0px;
}
.infos-perso-input {
  margin-bottom: 3.5rem !important;
}

/* ------------les buttons---------------- */
.btn-parametre-admin {
  background: var(--primaryColor) !important;
  border: 1px solid var(--quinaryTextColor) !important;
  box-sizing: border-box !important;
  border-radius: 2px !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #ababab !important;
}

.btn-parametre-icon {
  color: var(--secondary-color);
  width: 23px !important;
  height: 23px !important;
  margin-right: 5px;
}

.btn-modifier {
  width: 92px;
  height: 38px;
  background: var(--secondary-color);
  border-radius: 4px;
  color: var(--primaryColor);
}

/* .icone-avatar {
  background: var(--primaryColor);
  border: 1px solid var(--quinaryTextColor);
  border-radius: 2px;
  height: 40px;
  width: 10%;
} */

/* ---------------les modal style------------------ */

.modal-ajouter {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #2d3748;
}

/* -----------responsive------------- */

@media (max-width: 990px) {
  .bloc-principal-body-content-profile {
    width: 100%;
    padding-top: 1rem !important;
  }

  .bloc-principal-body-content-administrateur {
    width: 100%;
    padding-top: 4.5rem !important;
  }
}

.card-faq {
    background: var(--primaryColor);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 0 20px !important;
}
.faq-title {
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 140% !important;
    color: var( --decimalTextColor) !important;
}

.div-faq-boutton {
    display: flex;
    justify-content: center;
    margin: 30px 0px 50px 0px !important;
}

.btn-nouvelle-question {
    background: var(--primaryColor) !important;
    border: 1px solid #E8E8E8 !important;
    box-sizing: border-box !important;
    border-radius: 2px !important;
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #ABABAB !important;
    box-shadow: none !important;
}

.btn-nouvelle-question-icon {
    color: var(--secondary-color);
    width: 23px !important;
    height: 23px !important;
    margin-right: 5px;
}

.faq-card-title {
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 19px !important;
    line-height: 28px !important;
    color: var(--secondary-color);
}

.faq-card-para {
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: var(--quaternaryTextColor) !important;
    margin-top: -5px !important;
}

.faq-consultation-number {
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 32px !important;
    line-height: 48px !important;
    color: var(--quinaryTextColor) !important;
}

.faq-consultation-para {
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 18px !important;
    color: var(--quinaryTextColor) !important;
    margin-top: -5px !important;
}

.faq-edit-icon {
    color: var(--quinaryTextColor) !important;
    width: 32px !important;
    height: 32px !important;
    border-radius: 20px;
    padding: 5px;
    border: 0.5px solid var(--secondary-color) !important;
    box-sizing: border-box;
    outline: none;
}

.faq-trash-icon {
    color: #E53E3E;
    width: 32px !important;
    height: 32px !important;
    border-radius: 20px;
    padding: 5px;
    border: 0.5px solid var(--secondary-color) !important;
    box-sizing: border-box;
    outline: none;
}

.div-card {
    background: var(--primaryColor) !important;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08) !important;
    border-radius: 5px !important;
    margin-bottom: 20px;
    flex-wrap: nowrap;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
}

.div-consultation-icon {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.card-faq-main-content {
    width: 100%;
    margin: auto;
    padding-bottom: 60px;
}

.div-icon {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}


.modal-faq {
    width: 96% !important;
}
.faq-modal-content {
    padding: 10px 15px !important;
}
.modal-faq-header {
    position: relative;
    padding: 20px 0 !important;
}

.faq-modal-footer, .modal-faq-header {
    border: none !important;
}

.titre-question-reponse {
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 18px !important;
    line-height: 27px !important;
    color: #0C0B0B !important;
    text-align: left !important;
    position: absolute;
    left: 10px;
    top: 10px !important;
}

.label-question, .label-reponse{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    color: #0C0B0B;
}

.input-question, .input-reponse {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #ABABAB;
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid #E8E8E8 !important;
}

.close-icon {
    color: #666666 !important;
    width: 14px !important;
    height: 14px !important;
    position: absolute;
    top: -4px !important;
    right: 20px !important;
}
.close-icon:hover {
    color: var(--quinaryTextColor) !important;
}

.btn-envoyer-faq-modal {
    background: var(--secondary-color);
    border-radius: 4px !important;
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 15px !important;
    line-height: 22px !important;
    color: var(--primaryColor) !important;
    outline: none;
}
.btn-envoyer-faq-modal:focus, .btn-retour-faq-modal:focus, .btn-nouvelle-question:focus {
    outline: none !important;
}

.btn-envoyer-faq-modal:hover {
    background: var(--primaryColor);
    color: var(--secondary-color) !important;
    border: 1px solid var(--secondary-color);
}

.btn-retour-faq-modal {
    background: var(--primaryColor);
    border: none;

    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    line-height: 19px !important;
    letter-spacing: 0.045em !important;
    color: #515151 !important;
    text-transform: uppercase;
    outline: none;

}

@media only screen and (max-width: 768px) {
    .card-faq-main-content {
        width: 100%;
    }

    .faq-card-title, .faq-card-para {
        font-size: 12px !important;
    }

    .faq-title {
        font-size: 14px !important;
    }

   

}
.admin-contacts-title {
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 140% !important;
    color: var(--decimalTextColor) !important;
}

.card-contacts-main-content {
    width: 98%;
    margin: 20px auto;
    padding-bottom: 60px;
}

.contact-btn {
    /* background: #F3BB67; */
    border-radius: 20px !important; 
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 13px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: var( --primarytextColor);  
    box-shadow: none !important;
    outline: none !important;
}
.contact-btn-nouveau {
    background: #ee8181 !important;
}

.contact-btn-encours {
    background: #f3bb67d3 !important;
}

.contact-btn-done {
    background: var(--secondary-color) !important;
}

.btn-retour-faq-modal:hover {
    border: 1px solid #E53E3E;
    color: #E53E3E;
}

.div-nouveau-name {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.div-nouveau-name {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.contact-name-para {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: 0.4px;
    color: var(--secondary-color);
}

.contact-modal-para {
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 15px !important;
    line-height: 22px !important;
    letter-spacing: 0.4px !important;
    color: var(--secondary-color) !important;
}

.modal-faq-contact {
    overflow: scroll;
}

.contact-modal-hr {
    width: 96% !important;
}

.contact-modal-form {
    border-top: 1px solid #E0E0E0;
    padding-top: 20px !important;
}

.statut-modal-form {
    padding-top: 20px !important;
}

.contact-card-para {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: 0.4px;
    color: var(--quaternaryTextColor);
    
}

.select-statut {
    height: 45px !important;
    border-radius: 6px !important;
}

@media only screen and (max-width: 768px) {
    .div-nouveau-name {
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
        gap: 10px;
        padding: 10px 0px;
    }

    .contact-btn-nouveau {
        font-size: 8px !important; 
    }

    .contact-name-para {
        font-size: 14px;
    }

    .div-para-contact-modal {
        height: 150px;
        overflow: scroll;
    }
}
.component-investisseur {
    background: var(--quinaryColor);
}
.content-title-page-tb-investisseur {
    width: 100%;
    z-index: 999;
}
.titre-repartition-detail {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: var(--secondary-color);

}
.text-progress-investisseur {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.6);
}
.width-bloc {
    width: 60%;
}
.repartition-pret {
    padding: 50px;
}
.child-table-containe{
    background-color: transparent !important;
}
.table-containe .table >thead{
    background-color: var(--tertiareColore) !important;
}
.containTitreInteresser{
    font-family: "Poppins" !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    color: var(--senaryBorderColor) !important;
    background-color: var(--tertiareColore) !important;
}
.tableInteresser{
    --bs-table-bg: var(--bs-body-bg);
    --bs-table-striped-bg: white;
}
.table td, .table th {
    padding: 23px;
    vertical-align: top;
    border-top: 4px solid var(--tertiareColore);
    font-weight: 400;
    font-size: 13px;
}
.textSecteur{
    color:  var(--secondary-color) !important;
}
.navlink-tabs-prets {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: var(--quinaryTextColor);
}
.navlink-tabs-prets.active {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: var(--primarytextColor) !important;
  background: transparent !important;
  border-bottom: none !important;
}
.nav-item-tabs {
  list-style: none;
}
.btn-debiter-compte {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: var(--secondary-color);
  background: var(--primaryColor);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 0.5rem;
}
.btn-debiter-compte:hover {
  background: var(--secondary-color);
  color: var(--primaryColor) !important;
}
.titre-synthese-list {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: var(--secondary-color);
}
.text-contenu-list-pret {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--nonaryTextColor);
}
.div-content-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.text-contenu-list-pret-color {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--secondary-color);
}
.text-solde-abonnement {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: var(--secondary-color);
}
.margin-top-sidebar-mobile-tabs {
  margin-top: 70%;
}

.sidebar-customisation {
  /* padding: 40px 40px 150px 0; */
  height: 100%;
  max-width: 100%;
  width: 100%;
  background-color: var(--primaryColor);
  z-index: 999;
}

.sidebar-customisation-content {
  width: 100% !important;
}
.nav-link-sidebar-custom {
  font-family: "DM Sans" !important;
  font-style: normal;
  font-weight: normal;
  font-size: 25px !important;
  line-height: 33px !important;
  color: rgba(0, 0, 0, 0.5) !important;
  padding: 15px 20px 15px 0 !important;
  margin-left: 32px !important;
}

.nav-link-sidebar-custom:hover {
  color: var(--secondary-color) !important;
  background-color: transparent !important;
  transition: 0.2s ease;
}
.nav-link-sidebar-custom:focus {
  border: none !important;
}
.nav-link-sidebar-custom.actived {
  color: var(--secondary-color) !important;
  border-left: 2px solid var(--secondary-color) !important;
  font-weight: 800;
  text-decoration: none !important;
}
.nav-sidebar-items-custom {
  margin-top: 30px;
}
.component-sidebar-tableau-bord {
  /* position: fixed; */
  width: 100% !important;
  height: 100%;
}
.nav-link-sidebar-tb-custom {
  font-family: "DM Sans" !important;
  font-style: normal;
  font-weight: normal;
  font-size: 25px !important;
  line-height: 33px !important;
  color: rgba(0, 0, 0, 0.5) !important;
  padding: 15px 50px 15px 50px !important;
  white-space: nowrap;
}
.nav-link-sidebar-tb-custom.actived {
  color: var(--secondary-color) !important;
  /* border-bottom: 2px solid var(--secondary-color) !important; */
  font-weight: 800;
  text-decoration: none !important;
}
.component-sidebar-tableau-bord .nav-item {
  width: 100%;
}
.nav-items-tb-custom {
  width: 100%;
  min-height: 50px;
  flex-wrap: inherit !important;
  overflow: auto;
}
.nav-items-tb-custom::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 1024px) {
  .component-sidebar-tableau-bord {
    height: 100vh;
  }
}

.sidebar-customisation-content {
  padding-top: 30px !important;
  position: relative;
}

.nav.nav-items-custom.bar:before {
  content: "";
  background: #c4c4c4;
  width: 1px;
  position: absolute;
  height: 70vh;
  left: 47px !important;
}

.nav-link-sidebar-custom span {
  padding-left: 15px;
}

.search-box{
    width: 100%;
    position: relative;
    display: flex;
    background: var(--quaternary-color);
  }
  .search-input{
    width: 100%;
    padding: 10px;
    border-radius:10px 0 0 10px ;
    border-right: none;
    outline: none;
    font-size: 20px;
    color: var(--primarytextColor);
    background: none;
    border: none;
  }
  .search-button{
   text-align: center;
  height: 51px;
  width: 40px;
  outline: none;
  cursor: pointer;
   border-radius: 0 10px 10px 0 ;
  border-left: none;
  background: none;
  font-size: 20px;
  
  
  }
  .form-control-transaction {
    background: var(--quaternary-color) !important;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 18px !important;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: -0.025em;
    color: var(--nonaryTextColor);
    border: none !important;
    height: 50px !important;

  }
  .bloc-transaction {
    background: var(--primaryColor);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 60px 20px;
  }
  .text-ou-transaction {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 25px;
    letter-spacing: -0.025em;
    color: var(--primarytextColor);
  }
  .text-gray-transaction {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.025em;
    color: var(--nonaryTextColor);
  }
  .bloc-transaction-flex {
      display: flex;
      justify-content: space-between;
      padding-top: 1rem;
      padding-bottom: 1rem;
  }

.div-compte {
    background: var(--primaryColor);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
}
.text-gray-tb {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.025em;
    color: rgba(0, 0, 0, 0.6);
}
.text-gray-tb-sous-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: -0.025em;
    color: rgba(1, 1, 1, 0.5);
}
.type-action-investisseur {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    color: var(--primarytextColor);

}
.nombre-action-investisseur {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 74px;
    line-height: 111px;
    color: var(--primarytextColor);

}
.margin-top-sidebar-mobile {
    margin-top: 70%;
}
.auth-forget-component {
    min-height: 100vh;
}
.auth-form-forget-title {
	font-family: Poppins;
	font-size: 36px;
	font-style: normal;
	font-weight: 400;
	line-height: 54px;
	text-align: center;
	color: var(--primarytextColor);
}

.auth-form-text {
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	line-height: 25px;
	letter-spacing: 0em;
	text-align: center;
    color: #292929;
}

.chartCard {
    width: 100%;
    height: calc(50vh - 40px);
    display: flex;
    align-items: center;
    justify-content: start;
  }
  .chartBox {
    width: 100%;
    padding: 15px;
    background: var(--grennColor);
  }
  .chartjs-render-monitor{
    width: 100% !important;
    height: 250px !important;
  }
  .chartjs-size-monitor{
    position: unset !important;
  }
  .diagrammeCirculaire{
    display: flex;
    flex-column: reverse;
  }
  .total{
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    font-size: 60px;
    font-weight: 700;
    text-align: center;
    pointer-events: none;
    background: white;
    color: var(--secondary-color);
    padding: 9px;
    border-radius: 50px;
  }
  .investment-details {
    margin: 20px;
    font-family: Arial, sans-serif;
  }
  .investment-details table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .investment-details th, .investment-details td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .investment-details {
    color: var(--secondary-color);
    font-size: 26.83px;
    font-weight: 800;
  }
  .investment-mois{
    font-size: 18px !important;
  }
  .investmentDetail{
    color : var(--tertiodenaryTextColor);
    font-size: 17.58px;
    font-weight: 600;
  }
.dashboard-container {
	background-color: var(--tertiareColore);
	padding: 2rem 0;
	min-height: 100vh;
}
.titreDashboard{
	font-weight: 400;
	font-size: 22px;
	color: var(--senaryBorderColor);
}
.titrePortefeuille{
	font-weight: 600;
	font-size: 17.58px;
}
.dashboard-card {
	background-color: var(--grennColor);
	padding: 1.5rem 1rem;
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 15px;
	width: 100%;
}

.dasboard-card-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 12.31px;
	line-height: 150%;
	color: var(--quinaryTextColor);
	margin: 0;
	margin-bottom: 7px;
}

.dasboard-card-montant {
	font-family: Poppins;
	font-style: normal;
	font-weight: 700;
	font-size: 18.63px;
	line-height: 140%;
	color: var(--secondary-color);
	margin: 0;
}

.dasboard-card-img-container {
	background-color: var(--secondary-color);
	padding: 1rem;
	border-radius: 12px;
	color: var(--primaryColor);
}

.dashboard-chart-container {
	background: var(--primaryColor);
	box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
	border-radius: 15px;
	padding: 1.5rem 2rem;
	margin-top: 3rem;
}

.dashboard-chart-info-filter {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.dashboard-chart-info-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 25px;
	color: #2d3748;
	margin: 0;
	margin-bottom: 10px;
}

.dashboard-chart-info-stat {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: var(--secondary-color);
	margin: 0;
}

.dashboard-chart-filter {
    background: var(--primaryColor);
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.07);
	border-radius: 10px;
    height: 45px;
    position: relative;
}

.dashboard-chart-filter-select {
    background: var(--primaryColor);
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.07);
	border-radius: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.4px;
    color: #252733;
    height: 100%;
    padding: 0 12rem 0 3rem;
    border: none;
    outline: none;
    cursor: pointer;
}

.dashboard-chart-filter-icon {
    position: absolute;
    right: 3px;
    top: 10px;
    color: var(--secondary-color);
}

.profile-containers {
  background-color: var(--primaryColor);
  padding: 1rem 2rem;
  border-radius: 10px;
  margin-top: 5rem;
}

.admin-container,
.liste-porteur-projet-container {
  background-color: var(--primaryColor);
  padding: 1rem 2rem;
  border-radius: 10px;
  margin-top: 3rem;
}

.bloc-principal-body-content-profile {
  width: calc(100% - 17%);
}

.mon-profil {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: var( --decimalTextColor);
}
.information-personnelle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  text-align: right;
  letter-spacing: -0.025em;
  color: var(--secondary-color);
}
.text-jones {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  text-align: right;
  letter-spacing: -0.025em;
  color: var( --decimalTextColor);
}
.text-admin {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  text-align: right;
  letter-spacing: -0.025em;
  color: var( --decimalTextColor);
  text-align: center;
}
.pp-admin {
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  object-fit: fill;
}


.profile-avatar-container {
  width: 150px !important;
  height: 150px !important;
  overflow: hidden !important;
  position: relative !important;
  margin: 15px auto !important;
  border: 1px solid transparent !important;
  border-radius: 50% !important;
  cursor: pointer !important;
}

.profile-avatar-container img {
  width: 100% !important;
  height: 100% !important;
  display: block !important;
  object-fit: cover !important;
}

.profile-avatar-container span {
  position: absolute !important;
  bottom: -100% !important;
  left: 0 !important;
  width: 100% !important;
  height: 50% !important;
  background: var(--primaryColor)8 !important;
  text-align: center !important;
  font-weight: 400 !important;
  color: var(--secondary-color) !important;
  transition: 0.3 ease-in-out !important;
}

.profile-avatar-container span p {
    color: var(--secondary-color) !important;
}
.profile-avatar-container:hover span {
  bottom: -15% !important;
  
}

.profile-avatar-container span.charge {
  bottom: -15% !important;
}

.profile-avatar-container #file_up {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  opacity: 0 !important;
  cursor: pointer !important;
}
::-webkit-file-upload-button {
  cursor: pointer !important;
}

/* --------------les input dans component profile------------------ */
.form-control-profil {
  position: relative;
  display: block;
  width: 100%;
  height: 50px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  color: var(--quinaryTextColor);
  background-color: var(--primaryColor);
  background-image: none;
  background: var(--primaryColor);
  border: 2px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 10px;
}

.form-input-profile {
  padding-top: 70px;
}
.container-input-address {
  padding-top: 50px;
}

.form-label-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var( --decimalTextColor);
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 6px 0px;
}
.infos-perso-input {
  margin-bottom: 3.5rem !important;
}
.btn-modifier {
  width: 92px;
  height: 38px;
  background: var(--secondary-color);
  border-radius: 4px;
  color: var(--primaryColor);
}
.posBTN{
  top: 22px;
  left: 86%;
}
.admin-contacts-title {
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 140% !important;
    color: var(--decimalTextColor) !important;
}

.card-contacts-main-content {
    width: 98%;
    margin: 20px auto;
    padding-bottom: 60px;
}

.contact-btn {
    /* background: #F3BB67; */
    border-radius: 20px !important; 
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 13px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: var( --primarytextColor);  
    box-shadow: none !important;
    outline: none !important;
}
.contact-btn-nouveau {
    background: #ee8181 !important;
}

.contact-btn-encours {
    background: #f3bb67d3 !important;
}

.contact-btn-done {
    background: var(--secondary-color) !important;
}

.btn-retour-faq-modal:hover {
    border: 1px solid #E53E3E;
    color: #E53E3E;
}

.div-nouveau-name {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.div-nouveau-name {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.contact-name-para {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: 0.4px;
    color: var(--secondary-color);
}

.contact-modal-para {
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 15px !important;
    line-height: 22px !important;
    letter-spacing: 0.4px !important;
    color: var(--secondary-color) !important;
}

.modal-faq-contact {
    overflow: scroll;
}

.contact-modal-hr {
    width: 96% !important;
}

.contact-modal-form {
    border-top: 1px solid #E0E0E0;
    padding-top: 20px !important;
}

.statut-modal-form {
    padding-top: 20px !important;
}

.contact-card-para {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: 0.4px;
    color: var(--quaternaryTextColor);
    
}

.select-statut {
    height: 45px !important;
    border-radius: 6px !important;
}

@media only screen and (max-width: 768px) {
    .div-nouveau-name {
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
        gap: 10px;
        padding: 10px 0px;
    }

    .contact-btn-nouveau {
        font-size: 8px !important; 
    }

    .contact-name-para {
        font-size: 14px;
    }

    .div-para-contact-modal {
        height: 150px;
        overflow: scroll;
    }
}
.nav-items-customs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-item-vertical-custom {
  width: 100%;
  /* padding: 2rem 0 0 0; */
}

.nav-link-vertival-custom {
	font-family: "Poppins" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 14px !important;
	line-height: 18px !important;
	color: var(--quinaryTextColor);
	width: 100%;
	display: flex !important;
	grid-gap: 10px;
	gap: 10px;
	align-items: center;
	padding: 1rem 5% 1rem 18% !important;
}

.navbar-vertical-container-icon {
  width: 30px !important;
  background-color: var(--primaryColor) ;
  text-align: center;
  height: 30px !important;
  display: flex;
  align-items: center;
  justify-content: center !important;
  font-size: 14px;
  color: var(--quinaryTextColor);
  border-radius: 12px;
}

.text-dashbord {
  color: var(--quinaryTextColor) !important;
}

#scrollbar {
  overflow-y: scroll;
}
#scrollbar::-webkit-scrollbar {
  width: 6px;
}
#scrollbar::-webkit-scrollbar-track {
	background: var(--primaryColor);
}
#scrollbar::-webkit-scrollbar-thumb {
	background-color: var(--secondary-color);
	border-radius: 20px;
	border: 3px solid transparent;
}
.contents {
  height: 0px !important;
}

.nav-items-customs .active, .nav-items-customs .active .navbar-vertical-container-icon, .nav-items-customs .active .text-dashbord{
  color: var(--primaryColor)   !important;
  background-color: var(--secondary-color) !important;
}

.component-navbar-mobile-v2{
    
}
.projets-container {
	padding: 0 0rem;
    min-height: 100vh;
}

.liste-projets {
	background-color: var(--primaryColor);
	padding: 1rem 2rem;
	border-radius: 10px;
}

.projets-table {
	margin-top: 3rem;
}

.projets-table .menu .active{
	background-color: var(--secondary-color);
	color: var(--primaryColor) !important;
	border-radius: 10px;
	border-bottom: 2px solid var(--secondary-color) !important;
}

.liste-projets-bar {
	display: flex;
	justify-content: space-between;
	padding-right: 1rem;
	align-items: center;
}

.liste-projets-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 25px;
	color: #2d3748;
	margin: 0;
}

.liste-projets-statistiques {
	margin-top: 10px;
}

.icon-check-container {
	background: var(--secondary-color);
	color: var(--primaryColor);
	margin-right: 5px;
	border-radius: 50%;
	padding: 1px 0.3px;
	font-weight: 500;
}

.number-project-text {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 140%;
	color: #a0aec0;
}

.ajouter-projet-link {
	display: flex;
	align-items: center;
	background-color: var(--secondary-color);
	
}

.ajouter-projet-link-icon {
	color: var(--primaryColor);
	/* margin-right: 5px; */
}

.ajouter-projet-link-text,
.ajouter-projet-link:hover {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 16px;
	color: var(--primaryColor);
	text-decoration: none;
}

/************* Add Project Style *****************/

.add-projet-container {
	background-color: var(--quinaryColor);
	padding: 3rem;
}

.add-projet-content,
.detail-projet-content {
	background-color: var(--primaryColor);
	padding: 1.5rem 4rem;
	border-radius: 10px;
}

.add-project-info-icon-title-container {
	display: flex;
	grid-gap: 10px;
	gap: 10px;
}

.add-project-info-stepper {
	display: flex;
	align-items: center;
	margin-bottom: 4rem;
}

.add-project-info {
	display: flex;
	grid-gap: 8px;
	gap: 8px;
}

.add-project-icon {
	color: var(--decimalTextColor);
	line-height: 20px;
	cursor: pointer !important;
	display: inline-block;
}

.add-project-general-title {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 25px;
	color: #2d3748;
	margin: 0;
}

.add-project-component-title {
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 25px;
	color: var(--secondary-color);
	margin: 0;
}

.add-project-stepper {
	display: flex;
	align-items: center;
	grid-gap: 10px;
	gap: 10px;
	justify-content: flex-end;
}

.stepper {
	height: 3px;
	min-width: 60px;
	border-radius: 25%;
	background-color: #c4c4c4;
}

.stepper-active {
	background-color: var(--secondary-color);
}

/******************** Details de base  ************************************************************/
.form-add-project-group {
	margin-bottom: 4rem;
	width: 100%;
}

.form-add-project-label {
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
	color: #2f3a4a;
}

.form-add-project-input {
	background: var( --primaryColor);
	border: 1px solid #e8e8e8;
	box-sizing: border-box;
	border-radius: 2px;
	height: 46px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #2f3a4a;
	width: 90%;
}

.form-add-project-input::placeholder {
	color: #ababab;
}

.form-add-project-input-url {
	width: 95%;
}

.form-group-add-project-dropzone-container {
	width: 95%;
}

.form-add-project-buttons-container {
	display: flex;
	justify-content: flex-end;
	width: 97.2%;
	padding-top: 2rem;
}

.form-add-project-button-next {
	background: var(--secondary-color);
	border-radius: 4px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 22px;
	text-align: center;
	color: var(--primaryColor);
	outline: none;
	padding: 0.9rem 2rem;
}

.form-add-project-button-previous {
	background: transparent;
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 22px;
	text-align: center;
	color: var(--secondary-color);
	margin-right: 2rem;
}
/***########### Input choose file Style ###################***/

.start-label-input-choose-file {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.025em;
	width: 100% !important;
}

.first_label-input-choose-file {
	width: 100% !important;
	display: block;
	color: var(--secondary-color);
	margin-top: 10px;
	margin-bottom: 3px;
}

.second-label-input-choose-file {
	color: #000;
	opacity: 0.5;
}

/***################ Details de base second Step***************/
.form-add-project-check-input-container {
	width: 90%;
	height: 46px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid #e8e8e8;
	box-sizing: border-box;
	padding: 0 1rem;
}
.form-add-project-group-relative {
	position: relative;
}

.form-add-project-unity {
	display: inline-block;
	width: 60px;
	position: absolute;
	line-height: 35px;
	top: 3.5rem;
	right: 15%;
	color: #ababab;
	border-left: 1px solid #e8e8e8;
	padding-left: 7px;
	text-align: center;
	font-size: 12px;
}

.form-add-project-check-input-group {
	display: flex;
	align-items: center !important;
	grid-gap: 5px;
	gap: 5px;
}

.form-add-project-check-input-label {
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	color: #ababab;
	margin: 0 !important;
}

.form-add-project-check-input {
	margin: 0 !important;
	outline: none !important;
	border: 2px solid var(--secondary-color);
	border-radius: 5px;
}

/* *################## Presentation de projet step 3 ###################*/
.form-add-project-input-textarea {
	height: 130px;
	min-height: 130px;
	max-height: 130px;
	outline: none;
	padding: 5px 10px;
	width: 95%;
}
.form-add-project-ckeditor {
	width: 96%;
}

/* *******Media Querie */
@media screen and (max-width: 767px) {
	.add-project-stepper {
		justify-content: center;
		margin-top: 20px;
	}
}

@media screen and (max-width: 497px) {
	.add-project-info {
		flex-direction: column;
	}
	.add-project-component-title {
		text-align: center;
	}

	.stepper {
		min-width: 30px;
	}

	.form-add-project-input {
		font-size: 10px;
		line-height: 20px;
		width: 100%;
		padding-left: 4px;
	}
	.form-add-project-check-input-container {
		width: 100%;
		flex-direction: column;
		align-items: flex-start;
		height: 90px;
		padding: 5px;
	}

	.form-add-project-unity {
		width: 42px;
		font-size: 8px;
	}

	.form-add-project-label {
		font-size: 10px;
	}
	.form-add-project-group {
		padding: 0 !important;
	}
	.table-body-container-remboursement,
	.form-add-project-input-textarea {
		width: 100% !important;
	}

	.form-add-project-ckeditor {
		width: 100% !important;
		padding-right: 0;
	}
}

/**################### 	Detail-projet ############################*/
.detail-projet-info-actions {
	align-items: center;
}

.detail-projet-icon-title-container {
	display: flex;
	align-items: center;
}

.detail-projet-name-projet {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 27px;
	letter-spacing: -0.025em;
	color: var(--decimalTextColor);
}

.detail-projet-text {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 17px;
	letter-spacing: -0.025em;
	color: #737373;
}

.detail-projet-actions {
	display: flex;
	justify-content: flex-end;
}

.detail-projet-text-container {
	padding-left: 2.5rem;
}

.link-detail-projet {
	color: var(--secondary-color);
}

.detail-projet-info-label {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 27px;
	color: var(--secondary-color);
}

.detail-projet-info-val {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 27px;
	color: var(--secondary-color);
}

.detail-projet-resume-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: var(--secondary-color);
}

.detail-projet-resume-progress-text {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.detail-projet-resume-percentage {
	font-family: DM Sans;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 17px;
	color: var(--quinaryTextColor);
}

.detail-projet-resume-solde {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	line-height: 19px;
	color: var(--secondary-color);
}
.detail-projet-resume-objectif-text {
	font-family: DM Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	color: var(--quinaryTextColor);
}

.detail-projet-resume-info-porteur-projet {
	display: flex;
	align-items: center;
	grid-gap: 8px;
	gap: 8px;
}

.detail-projet-resume-avatar-porteur-projet {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	margin: 0;
}

.detail-projet-resume-name-porteur-projet {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 27px;
	letter-spacing: -0.025em;
	color: var(--decimalTextColor);
}

.detail-projet-resume-cat-porteur-projet {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: -0.025em;
	color: var(--decimalTextColor);
}

.detail-projets-tabs-container {
	margin-top: 3rem;
	border-bottom: 1px solid #eaeaea;
	padding-bottom: 0.4rem;
}

.detail-projets-tabs {
	display: flex;
	align-items: center;
	grid-gap: 12px;
	gap: 12px;
}

.detail-projets-tabs-item {
	list-style: none;
	font-family: "DM Sans";
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 21px;
	color: var(--quinaryTextColor);
	cursor: pointer;
	padding: 0 1rem;
}

.detail-projet-desc-content{
	background: var( --primaryColor);
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
	border-radius: 10px;
	max-width: 160%;	  
}

.description .card-header{
	background: transparent;
	cursor: pointer;
}

.description svg{
	font-size: 40px !important;
	cursor: pointer;
}

.detail-projets-tabs-item.active-tab {
	background: var(--secondary-color);
	border-radius: 10px;
	color: var( --primaryColor);
	font-weight: bold;
	padding: 1rem;
}

.detail-projet-title-desc {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 42px;
	display: flex;
	align-items: center;
	letter-spacing: -0.025em;
	color: var(--secondary-color);
}

.detail-projet-desc-text {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	line-height: 25px;
	letter-spacing: -0.025em;
	color: var(--secondaryTextColor);
	text-align: justify;
}

.detail-projet-desc-objectif {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 27px;
	letter-spacing: -0.025em;
	color: var(--secondary-color);
}

.detail-projet-desc-info-val {
	color: var(--secondary-color);
	margin-left: 5px;
}

/** Detail Projet Donnees financieres */
.detail-projet-finance-data-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 25px;
	color: var(--secondary-color);
	padding-bottom: 1.3rem;
	border-bottom: 1px solid #eaeaea;
}

.detail-projet-finance-data-donneur-container {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding-right: 10%;
	align-items: center;
	padding: 1rem;
	border-bottom: 1px solid #eaeaea;
}

.detail-projet-finance-data-donneur-avatar-info {
	display: flex;
	align-items: center;
	grid-gap: 10px;
	gap: 10px;
}

.detail-projet-finance-data-donneur-image {
	width: 55px;
	height: 55px;
	border-radius: 50%;
}
.detail-projet-finance-data-donneur-name {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 17px;
	line-height: 20px;
	letter-spacing: 0.2px;
	color: #171d33;
	margin: 0;
}

.detail-projet-finance-data-donneur-date-donnation {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 20px;
	letter-spacing: 0.2px;
	color: rgba(0, 0, 0, 0.7);
	margin: 0;
}

.detail-projet-resume{
	background: var( --primaryColor);
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
	border-radius: 10px;
	max-width: 160%;
}

.detail-projet-finance-data-donneur-montant-donnation {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 17px;
	line-height: 20px;
	letter-spacing: 0.2px;
	color: #171d33;
	margin: 0;
}

/* * Detail projet document */

.link-add-doc-button {
	background: var( --primaryColor);
	border: 1px solid #e8e8e8;
	box-sizing: border-box;
	border-radius: 2px;
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #ababab;
	padding: 1rem;
}
.quest-pp-block{
	margin-bottom: 15px;
}
.profile-containers {
  background-color: var(--primaryColor);
  padding: 1rem 2rem;
  border-radius: 10px;
  margin-top: 1rem;
}

.admin-container,
.liste-porteur-projet-container {
  background-color: var(--primaryColor);
  padding: 1rem 2rem;
  border-radius: 10px;
  margin-top: 3rem;
}

.bloc-principal-body-content-profile {
  width: calc(100% - 17%);
}

.mon-profil {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: var( --decimalTextColor);
}
.information-personnelle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  text-align: right;
  letter-spacing: -0.025em;
  color: var(--secondary-color);
}
.text-jones {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  text-align: right;
  letter-spacing: -0.025em;
  color: var( --decimalTextColor);
}
.text-admin {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  text-align: right;
  letter-spacing: -0.025em;
  color: var( --decimalTextColor);
  text-align: center;
}
.pp-admin {
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  object-fit: fill;
}


.profile-avatar-container {
  width: 150px !important;
  height: 150px !important;
  overflow: hidden !important;
  position: relative !important;
  margin: 15px auto !important;
  border: 1px solid transparent !important;
  border-radius: 50% !important;
  cursor: pointer !important;
}

.profile-avatar-container img {
  width: 100% !important;
  height: 100% !important;
  display: block !important;
  object-fit: cover !important;
}

.profile-avatar-container span {
  position: absolute !important;
  bottom: -100% !important;
  left: 0 !important;
  width: 100% !important;
  height: 50% !important;
  background: var(--primaryColor)8 !important;
  text-align: center !important;
  font-weight: 400 !important;
  color: var(--secondary-color) !important;
  transition: 0.3 ease-in-out !important;
}

.profile-avatar-container span p {
    color: var(--secondary-color) !important;
}
.profile-avatar-container:hover span {
  bottom: -15% !important;
  
}

.profile-avatar-container span.charge {
  bottom: -15% !important;
}

.profile-avatar-container #file_up {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  opacity: 0 !important;
  cursor: pointer !important;
}
::-webkit-file-upload-button {
  cursor: pointer !important;
}

/* --------------les input dans component profile------------------ */
.form-control-profil {
  position: relative;
  display: block;
  width: 100%;
  height: 50px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  color: var(--quinaryTextColor);
  background-color: var(--primaryColor);
  background-image: none;
  background: var(--primaryColor);
  border: 2px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 10px;
}

.form-input-profile {
  padding-top: 70px;
}
.container-input-address {
  padding-top: 50px;
}

.form-label-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var( --decimalTextColor);
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 6px 0px;
}
.infos-perso-input {
  margin-bottom: 3.5rem !important;
}
.btn-modifier {
  width: 92px;
  height: 38px;
  background: var(--secondary-color);
  border-radius: 4px;
  color: var(--primaryColor);
}

.description{
    background-color: "#E87930";
    width: "300px";
}
.black-text {
    color: #000;
  }
.btn-svplus{
  color: #FFFFFF !important;
  font-size: 11px;
  font-weight: 500;
  background-color: #2B7E6E !important;
  border: 1px solid #2B7E6E ;
  padding: 10px;
  border-radius: 8px;
}
.porteur{
  display: flex;
  justify-content: center;
}
.fs-Texte{
  color: #66666699;
  font-size: 14px;
  border: 1px solid #F68B1E;
  border-radius: 10px;
  padding: 7px 14px;
}
.fs-Textee{
  color: #66666699 !important;
  font-weight: 400 !important;
  font-size: 9px !important;
}
.btn-CV{
  font-weight: 400;
  font-size: 10px;
  color: #FFFFFF;
  background: var(--investisseurBackgroundColor);
  border: solid var(--investisseurBackgroundColor);
  padding: 8px 26px;
}
.table-container .table >thead {
  --bs-table-bg: #F3F7FF;
 
}
.react-bs-container-body .table tbody tr td{
  --bs-table-bg-type: transparent !important;
}
.banner-details-projet {
  background-image: url(/static/media/image2.beda7d2b.png);
  padding: 10%;
  background-size: cover;
  background-repeat: no-repeat;
}
.component-details-projet .img-details {
  width: 100%;
  height: 370px;
}
.colorBorderBottom{
  border-bottom: 5px solid white;
}
.image-card-container {
  margin-bottom: 30px;
}
.link-button {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--secondary-color);
  background: var(--primaryColor);
  padding: 1rem 2rem 1rem 2rem;
  border-radius: 10px;
  border: 1px solid var(--secondary-color);
}
.text-person {
  margin: 12px;
}
.recevoir-person {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--quinaryTextColor);
  margin: 10px;
}
.button-text {
  padding: 15px;
  display: flex;
  align-items: center;
}
.text-person {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  color: var(--secondary-color);
}
.suivre-button {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--primarytextColor);
}
/* .project-funding-type.dette-items {
  background: #2f80ed;
  border: 1px solid #2f80ed;
  box-sizing: border-box;
  border-radius: 4px 0px;
  margin: 0px;
  width: fit-content;
  height: 30px;
} */
/* .project-funding-type.don-items {
  background: #219653;
  border: 1px solid #219653;
  box-sizing: border-box;
  border-radius: 4px 0px;
  margin: 0px;
  width: fit-content;
  height: 30px;
} */
/* .project-funding-type.equity-items {
  background: #f2994a;
  border: 1px solid #f2994a;
  box-sizing: border-box;
  border-radius: 4px 0px;
  margin: 0px;
  width: fit-content;
  height: 30px;
} */
.text-pourcentage {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  color: var(--quinaryTextColor);
}
/* .text-det,
.text-det p {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 10px;
  letter-spacing: -0.025em;
  color: #737373;
} */
.text-lance {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: var(--quinaryTextColor);
}
.text-lance-cloture {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: var(--quinaryTextColor);
}
.color-text {
  color: var(--secondary-color);
}
.text-participez {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: var(--quinaryTextColor);
}


.bordure-deux-cartes {
  border-radius: 8px;
}
.carte-texte {
  align-items: center;
  padding: 5px;
}
.carte-profile-text {
  background: var(--primaryColor);
  border: 0.4px solid var(--tertiaryBorderColor);
  box-sizing: border-box;
  border-radius: 12px;
  transform: rotate(180deg);
  margin-top: 15px;
}
.text-aicha-porteur {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 10px;
  display: flex;
  align-items: center;
  letter-spacing: -0.025em;
  color: var(--quinaryTextColor);
}
.text-pctg-fcfa {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: var(--secondary-color);
}
.text-aicha {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.025em;
  color: var(--tertiodenaryTextColor);
}

/* -----------------ancien css-------------------------- */

.photo-card {
  background: var(--primaryColor);
  box-shadow: 0px 4px 43px var(--primaryBoxShadowColor);
  border-radius: 8px;
}
.text-titre-objectif {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.025em;
  color: var(--primarytextColor);
}
.text-details-bold {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: var(--primarytextColor);
}
.text-details-objectif {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
  color: var(--primarytextColor);
}
.texte-para {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: -0.025em;
  color: var(--quaterioTextColor);
  text-align: justify;
}
.text-aicha-small {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 100;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.025em;

  color: var(--tertiodenaryTextColor);
}
.title-description {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 47px;
  letter-spacing: -0.025em;
}
.bg-whites {
  background: var(--primaryColor);
  box-shadow: 0px 4px 43px var(--primaryBoxShadowColor);
}
.titre-banner-liste-projet {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 42px !important;
  color: var(--secondaryTextColor) !important;
}
.titre-banner-liste-proj {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 42px !important;
  color: var(--primaryColor) !important;
}
.text-detail {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.025em;
  color: var(--quinaryTextColor);
}
.video-detail {
  width: 100% !important;
}
.component-details-projet .card {
  background: var(--primaryColor);
  box-shadow: 0px 4px 20px var(--secondaryBoxShadowColor);
  border-radius: 10px;
  max-width: 160%;
}

.component-details-projet .card-header{
  background: transparent;
  cursor: pointer;
}

/* .component-details-projet .card-icon {
  background: var(--primaryColor);
  box-shadow: 0px 4px 20px var(--secondaryBoxShadowColor);
  border-radius: 10px;
  max-width: 160%;
  margin: 5px;
} */

.component-details-projet svg{
  font-size: 40px !important;
  cursor: pointer;
}
.component-details-projet .card-title {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 26px;
  color: var(--quinaryTextColor);
}
.vjs-poster{
  background: transparent !important;
}
.component-details-projet .icon-social-detail {
  color: var(--primaryColor);
  width: 18px;
  height: 18px;
  margin-top: -16px;
  margin-left: -7px;
}
.component-details-projet .nav > li a {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 26px;
  color: var(--quinaryTextColor) !important;
}
.component-details-projet .nav-pills .nav-link.active {
  background: var(--secondary-color) !important;
  border-radius: 10px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  color: var(--primaryColor) !important;
}
.nav-pills>li {
  float: none !important;
}
.component-details-projet .bloc-list1 .list-group-item {
  border: none !important;
}
.li-bg {
  background: var(--quinaryColor) !important;
}
.text-li-details {
  font-family: "DM Sans" !important;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  align-items: center;
  letter-spacing: -0.025em;
  color: var(--tertiodenaryTextColor);
}
.li-bg {
  background: var(--quinaryColor);
}
.title-detail-h3 {
  font-family: " Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  display: flex;
  align-items: center;
  letter-spacing: -0.025em;
  color: var(--secondary-color);
}
.img-details-li {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}
.nom-title {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: var(--primarytextColor);
}
.sub-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: var(--quinaryTextColor);
}
.text-small-faq {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.025em;
  color: var(--nonaryTextColor);
}
.text-comment-auteur {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: var(--primarytextColor);
}
.bloc-comment-detail p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--nonaryTextColor);
}
.img-icon-video {
  width: 56.67px;
  height: 56.67px;
}
.modal-content-tutorer {
  border-radius: 10px 10px 0px 0px;
}
.modal-tutorer {
  z-index: 9999999999999;
}
.title-montant {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 47px;
  color: var(--primarytextColor);
  text-align: center;
}
.header-contribution {
  background: #bb4411;
  border-radius: 10px 10px 0px 0px;
  height: 60px;
  padding: 1rem;
}
.title-contribution {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.025em;
  color: var(--primaryColor);
  text-align: center;
}
.modal-confirmation .modal-header {
  border-bottom: none !important;
}
.modal-confirmation .modal-footer {
  border-top: none !important;
}
.cursor-p {
  cursor: pointer;
}
.bg-color-selected {
  padding: 15px;
}
/* -----------------modal style----------------- */
.text-securiser {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 54px;
  text-align: center;
  color: var(--primarytextColor);
  padding: 20px;
}
.button-detail-securiser {
  margin-top: 5rem;
  text-align: center;
}
.securiser-btn-green {
  background: var(--secondary-color);
  width: 20%;
  border-radius: 10px;
  color: var(--primaryColor);
  height: 50px;
}
.form-input-telephone {
  border-bottom: 1px solid #e0e0e0;
  margin-top: 5rem;
  width: 30%;
  margin-left: 160px;
}

.text-modal-compte {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin: 22px;
  text-align: justify;
  padding: 32px;
  color: var(--quinaryTextColor);
}

.disabled-btn{
	cursor: not-allowed !important;
}
.pictureInteresser{
  border-radius: 100%;
  max-width: 50px;
  min-width: 50px;
  max-height: 50px;
  margin-left: -12px;
}
@import('../../../../colors.css');
.description{
    background-color: var(--investisseurBackgroundColor);
    width: 300px;
}
.bordureStatut{
    border-radius: 11.34px;
    font-size: 10px;
    font-weight: 500;
}
.projets-info{
    padding: 2% 31px;
}
.containFinancement, .ContainMontant{
    font-size: 12px;
  color: gray;
  font-weight: 700,
}
.ContainMontant{
   color: var(--investisseurBackgroundColor) !important;
}
.ContainMontantColor{
    color: var(--secondary-color) !important;
 }

.project-itemIncubateur{
    font-size: 18px;
    color: #808080;
    font-weight: 700;
}
@media (max-width: 375px) {
	.couv-textual-content {
		font-size: 18px !important;
		padding: 26px 0;
	}
	.social-downloadable-container {
		display: block !important;
		padding-bottom: 6% !important;
	}
	section#how-it-work {
		padding: 160px 50px !important;
	}
	.app-item {
		margin-right: inherit !important;
		margin-top: 12px !important;
	}
	.mobile-phone {
		left: 44% !important;
		height: 106% !important;
		top: 38% !important;
		z-index: 0 !important;
	}
	.fp-section-title {
		margin-bottom: 30px !important;
	}
	.rm-illustration-mobile-phone {
		width: 100%;
		left: 40px !important;
	}
	.rm-textual-container {
		margin-top: 15rem !important;
		padding-top: 80px !important;
		padding-bottom: 25px !important;
	}
	.img-footer-eclipse {
		left: -58% !important;
		top: -130% !important;
	}
	.eclipse-footer-1 {
		width: 141% !important;
	}
	.rec.rec-arrow.rec.rec-arrow-right {
		top: 10% !important;
		right: -4% !important;
	}
	.rec.rec-arrow.rec.rec-arrow-left {
		top: 10% !important;
		right: 5% !important;
	}
	.nav-hamburger {
		right: 18% !important;
	}
	.social-downloadable-img {
		width: 44% !important;
	}
	.slider-section-title {
		font-size: 24px !important;
	}
	.customer-caroussel-item.p-4.position-relative.w-full {
		margin-left: 0 !important;
	}
}

@media (max-width: 576px) and (min-width: 376px) {
	.couv-textual-content {
		font-size: 18px !important;
		padding: 26px 0;
	}
	.social-downloadable-container {
		display: block !important;
		padding-bottom: 6% !important;
	}
	.app-item {
		margin-right: inherit !important;
		margin-top: 12px !important;
	}
	.mobile-phone {
		left: 39% !important;
		height: 148% !important;
		top: 15% !important;
		z-index: 0 !important;
	}
	.fp-section-title {
		margin-bottom: 30px !important;
	}
	.rm-illustration-mobile-phone {
		width: 100%;
		left: 40px !important;
	}
	.rm-textual-container {
		margin-top: 15rem !important;
		padding-bottom: 50px !important;
		padding-top: 200px !important;
	}
	.img-footer-eclipse {
		left: -58% !important;
		top: -205% !important;
	}
	.eclipse-footer-1 {
		width: 141% !important;
	}
	.rec.rec-arrow.rec.rec-arrow-right {
		top: 10% !important;
		right: -4% !important;
	}
	.rec.rec-arrow.rec.rec-arrow-left {
		top: 10% !important;
		right: 5% !important;
	}
	.nav-hamburger {
		right: 18% !important;
	}
	.social-downloadable-img {
		width: 44% !important;
	}
	.slider-section-title {
		font-size: 24px !important;
	}
	.customer-caroussel-item.p-4.position-relative.w-full {
		margin-left: 0 !important;
	}
	section#how-it-work {
		padding-left: 50px !important;
		padding-right: 50px !important;
		padding-top: 250px !important;
		padding-bottom: 70px !important;
	}
	section#our-customers-words {
		padding: 50px 50px !important;
	}
	.contain-carousel {
		padding: 17% !important;
	}
}
@media (max-width: 768px) and (min-width: 575px) {
	.img-footer-eclipse {
		left: -44% !important;
		top: -199% !important;
	}
	.rm-textual-container {
		margin-top: 24rem !important;
	}
	.rm-textual-container p {
		position: absolute;
		bottom: 0 !important;
		top: 79% !important;
		left: 0% !important;
	}
	.eclipse-footer-1 {
		width: 121% !important;
	}
	section#how-it-work {
		padding: 200px 50px !important;
	}
}
@media (min-width: 768px) {
	.nav-hamburger {
		display: none !important;
	}
}
@media (max-width: 768px) {
	.fp-illustration-ellipse {
		display: none !important;
	}
	.fp-illustration-dot-pattern {
		display: none !important;
	}
	.menu-container {
		width: 100% !important;
	}
	.menu-li-item {
		padding-left: 10px !important;
	}
	.menu-link {
		white-space: nowrap !important;
	}
	.menu-ul {
		flex-direction: column;
		align-items: flex-end !important;
	}
	.couv-textual-content {
		font-size: 24px !important;
		padding: 26px 0;
	}
	/* .rm-textual-container {
        margin-top: 24rem !important;
    } */
	.rm-illustration-mobile-phone {
		top: -157% !important;
	}
	.fp-illustration-mobile-phone {
		display: none !important;
	}
	/* section#how-it-work {
        padding: 200px 50px !important;
    } */
	.rec.rec-arrow.rec.rec-arrow-right {
		top: 14% !important;
	}
	.rec.rec-arrow.rec.rec-arrow-left {
		top: 14% !important;
	}
	.menu-ul {
		display: none !important;
	}
}
@media (max-width: 991px) {
	.menu-container {
		width: 83% !important;
	}
	.fluidy-container {
		padding: 10px 50px !important;
	}

	.green-ellipse {
		display: none;
	}
	.dot-pattern {
		display: none;
	}
	.orange-ellipse {
		display: none;
	}
	/* section#how-it-work {
        padding: 250px 50px !important;
    } */
	.hiw-row {
		padding: 70px 0 !important;
	}
	.fp-section-title {
		font-size: 33px !important;
	}

	#found-prestataire {
		padding: 50px 50px !important;
	}
	.rm-illustration-mobile-phone {
		left: 0 !important;
	}
	#receive-mission {
		padding: 50px 50px !important;
	}
	/* section#our-customers-words {
        padding: 50px 50px !important;
    } */
	#slider {
		padding: 25px 50px !important;
	}
	#about {
		padding: 25px 50px !important;
	}
	/* .img-footer-eclipse {
        left: 0 !important;
    } */
	.fp-illustration-mobile-phone {
		width: 100% !important;
	}
	.rm-illustration-dot-pattern {
		display: none !important;
	}
	.rm-illustration-ellipse {
		display: none !important;
	}
	/* .mobile-phone {
        left: 56% !important;
        height: 174% !important;
    } */
	.about-textual-col-item p {
		text-align: justify !important;
	}

	/* .offset-md-2 {
        margin-left: inherit !important;
        offset: none !important;
    } */
	.about-form-col-item {
		display: flex !important;
		justify-content: center !important;
	}
}
@media (max-width: 927px) and (min-width: 769px) {
	.rm-textual-container p {
		top: 18% !important;
		left: 38% !important;
	}
}
@media (max-width: 991px) and (min-width: 768px) {
	.rm-textual-container p {
		top: 17% !important;
		left: 29% !important;
	}
	.couv-textual-content {
		font-size: 38px !important;
	}
	.mobile-phone {
		left: 56% !important;
		height: 174% !important;
	}
	.img-footer-eclipse {
		left: 0 !important;
	}
	section#how-it-work {
		padding: 250px 50px !important;
	}
	section#our-customers-words {
		padding: 50px 50px !important;
	}
}
@media (max-width: 1199px) and (min-width: 992px) {
	.menu-container {
		width: 100% !important;
		padding-left: 4rem !important;
		padding-right: 0rem !important;
	}
	.couv-textual-content {
		font-size: 39px !important;
	}
	.orange-ellipse {
		top: 60% !important;
		right: 2% !important;
		width: 386px !important;
	}
	.mobile-phone {
		top: -24% !important;
		left: 38%;
		height: 705px !important;
	}
	.green-ellipse {
		top: 80% !important;
		width: 231px !important;
	}
	.dot-pattern {
		top: 55% !important;
		left: 44% !important;
	}
	.img-footer-eclipse {
		position: absolute;
		bottom: -18% !important;
		left: -16% !important;
		padding: 0 !important;
	}
	.footer-ellipse-1 {
		bottom: 28% !important;
	}
	.fluidy-container {
		padding: 10px 100px !important;
	}
	section#how-it-work {
		padding-top: 30% !important;
		padding-left: 100px !important;
		padding-right: 100px !important;
		padding-bottom: 200px !important;
	}
	#found-prestataire {
		padding: 50px 100px !important;
	}
	.fp-section-title {
		font-size: 45px !important;
	}
	#receive-mission {
		padding-top: 50px !important;
		padding-bottom: 250px !important;
		padding-left: 100px !important;
		padding-right: 100px !important;
	}
	.row-fp {
		position: relative !important;
	}
	.rm-illustration-dot-pattern {
		position: absolute !important;
		right: 52% !important;
		top: 95% !important;
	}
	.rm-illustration-ellipse {
		position: absolute !important;
		left: 7% !important;
		top: 44% !important;
	}
	.rm-illustration-dot-pattern {
		position: absolute !important;
		right: 43% !important;
		top: 95% !important;
	}
	section#our-customers-words {
		padding: 50px 100px !important;
	}
	.mission-bloc {
		position: absolute !important;
		left: 25% !important;
		top: 30% !important;
	}
	.rm-textual-container {
		width: 100% !important;
	}
	.fp-section-title {
		margin-bottom: 47px !important;
	}
	#slider {
		padding: 25px 100px !important;
	}
	#about {
		padding: 25px 100px !important;
	}
}
@media (max-width: 1580px) and (min-width: 1361px) {
	.orange-ellipse {
		right: 0% !important;
	}
	.green-ellipse {
		top: 84% !important;
	}
}

@media (max-width: 1360px) and (min-width: 1200px) {
	.couv-textual-content {
		font-size: 41px !important;
	}
	.green-ellipse {
		top: 80% !important;
		left: 43% !important;
	}
	.orange-ellipse {
		right: -11% !important;
		top: 71% !important;
	}
	.dot-pattern {
		top: 57% !important;
		left: 47% !important;
	}
	.menu-container {
		width: 63% !important;
	}
	section#how-it-work {
		padding-left: 250px !important;
		padding-right: 250px !important;
		padding-top: 350px !important;
		padding-bottom: 100px !important;
	}
	.fp-section-title {
		font-size: 44px !important;
	}
	.fp-illustration-mobile-phone {
		left: 8% !important;
	}
	.rm-illustration-mobile-phone {
		left: 0% !important;
	}
	.rm-illustration-dot-pattern {
		right: 0% !important;
		top: 129% !important;
	}
	.mission-bloc {
		position: absolute !important;
		left: 27% !important;
	}
	.img-footer-eclipse {
		left: -13% !important;
	}
	.eclipse-footer-1 {
		width: 121% !important;
	}
	#receive-mission {
		padding-top: 51px !important;
		padding-bottom: 352px !important;
		padding-left: 200px !important;
		padding-right: 200px !important;
	}
}

.eclipse-footer-1 {
	width: 100%;
	height: 100%;
}
.img-footer-eclipse {
	position: absolute;
	bottom: -18% !important;
	left: 10%;
	padding: 0 !important;
}
.footer-footer {
	width: 100%;
}
body {
	margin: 0;
	font-family: "Montserrat", sans-serif;
	/* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--primaryColor) !important;
	background: var(--primaryColor) !important;
    overflow-x: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

/* .table-container {
  margin: 15px;
} */
.table-container input.form-control {
	padding: 20px;
	font-family: "Inter", sans-serif;
	font-style: normal;
	padding: 0 30px;
	height: 45px;
	font-weight: normal;
	font-size: 15px;
	line-height: 18px;
	display: flex;
	align-items: center;
	color: var(--quinaryTextColor);
	border: 0;
	background: #f8f8f8;
	border-radius: 55px;
	margin-right: 1%;
	width: 70%;
}
.table-container .react-bs-table-search-form span.input-group-btn button {
	background: var(--secondary-color);
	border-radius: 55px;
	font-family: "Inter", sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 18px;
	display: flex;
	align-items: center;
	color: var(--primaryColor);
	/* width: 34%; */
	border: 1px solid var(--secondary-color);
	transition: 0.2s ease;
	justify-content: center;
	height: 45px;
}
.table-container button#pageDropDown {
	background: var(--secondary-color);
	border-color: var(--secondary-color);
}
.table-container .page-item.active .page-link {
	background: var(--secondary-color) !important;
	border-color: var(--secondary-color) !important;
}
.linkable {
	cursor: pointer !important;
}
.chart-item-title {
	font-family: "Poppins", sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 20px;
	letter-spacing: 0.4px;
	color: #252733;
}
.empty-suggestion {
	color: #ff0000;
	display: block;
	padding: 11px;
	font-weight: 600;
}
.video-viemo .player .vp-title .vp-title-header {
	display: none !important;
}
.error-row {
	width: 100%;
}
.date-counter-container {
	margin: 20px 0;
	color: #1f497d;
	font-weight: 600;
}
.start-date {
	font-weight: 600;
}
span.counter-item {
	margin-left: 18px;
	padding: 12px;
	background: #ececec;
	border-radius: 5px;
}
.goto-forum-item-btn {
	background: #1f497d;
	padding: 14px;
	border-radius: 50px;
	color: var(--primaryColor);
	font-weight: 600;
	margin-top: 5px;
	display: block;
	text-align: center;
	border: 1px solid #1f497d;
}
.goto-forum-item-btn:hover {
	background: transparent;
	color: #1f497d;
	text-decoration: none;
}
.page-item.active .page-link {
	background-color: var(--secondary-color) !important;
	border-color: var(--secondary-color) !important;
	color: var(--primaryColor) !important;
	font-weight: 900;
}
li.page-item a {
	color: #1f497d !important;
}
.page-item:first-child .page-link span {
	color: #1f497d !important;
}
.page-item.disabled .page-link span {
	color: #1f497d !important;
}
.cm-sender-email {
	font-size: 15px;
	font-style: italic;
}
.badge-module-name {
	font-size: 20px;
	font-weight: 600;
	color: #1f497d;
}
.badge-blue {
	background: #1f497d !important;
}
.badge-orange {
	background: #f39200 !important;
}
.badge-green {
	background: #148f77 !important;
}
.aem-badge-enligne.aem-badge {
	color: #8c18e2;
	background: #f2e7fc;
}
.aem-badge-dispo.aem-badge {
	color: #4caf50;
	background: #daf0dc;
}
.aem-badge-termine.aem-badge {
	color: #fe976b;
	background: #fbede7;
}
.theme-background-color {
	background-color: #1f497d !important;
}
.row.contact-support-row {
	margin-bottom: 15px;
}
.gfa-alert-info {
	background: #1f497d33 !important;
	border-color: #1f497d !important;
	color: #1f497d !important;
	text-align: justify !important;
}
.contact-support-link {
	color: #1f497d !important;
	font-weight: 800;
}

img.zoom-img {
	transform: scale(0.9) !important;
	z-index: 9999999999;
	width: 100%;
	/* margin-top: 30% !important; */
}
.image-zoomable-modal .modal-lg {
	min-width: 1000px !important;
}
.zoomTarget {
	cursor: zoom-in !important;
}
img.is-zoomed-image {
	width: 100%;
}
.regenerate-apprenant-password-icon {
	color: var(--primaryColor) !important;
	width: 16px;
}
.gfa-btn-action-primary {
	background: #1f497d !important;
	border-color: #1f497d !important;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}
.gfa-btn-action-primary .regenerate-apprenant-password-icon:hover {
	background: transparent !important;
	border-color: #1f497d !important;
	color: #1f497d;
}
.actions-buttons-container {
	text-align: center;
}
button.btn.btn-success.react-bs-table-csv-btn.hidden-print {
	background: #f39200 !important;
	border-color: #f39200 !important;
	padding: 11px;
	margin-bottom: 9px;
	border-radius: 50px;
}
.user-infos-modal-col {
	border: 1px solid #ececec;
	padding: 15px 6px;
	border-radius: 10px;
	font-size: 18px;
	margin-bottom: 5px;
}
.user-infos-modal-item-label {
	font-weight: 600;
}
.user-infos-modal-item-content {
	color: #1f497d !important;
	font-weight: 700;
}
span.badge-success.gfa-badge-success,
span.badge-danger.gfa-badge-danger {
	padding: 7px;
	font-weight: 600;
	border-radius: 50px;
}
.title h5.quizz-label {
	font-size: 20px;
	font-weight: 600;
	width: 100%;
	margin-left: 15px;
	margin-right: 15px;
	margin-bottom: 20px;
	text-align: initial !important;
}
.link-to-evaluation {
	background: #f39200;
	border-color: #f39200;
	margin-top: 10px;
	width: 100%;
	padding: 10px;
	border-radius: 25px !important;
	font-weight: 600;
}
.link-to-evaluation:hover {
	background: var(--primaryColor);
	color: #f39200;
}
.no-identified-col .gfa-alert-danger {
	text-align: center;
	font-size: 20px;
	font-weight: 600;
}
/* div#g-recaptcha > div {
  width: 100% !important;
}
div#g-recaptcha {
  width: 100% !important;
}
div#g-recaptcha iframe {
  width: 100% !important;
}
div#rc-anchor-container {
  width: 99% !important;
  border-radius: 10px;
} */
.certified-count {
	color: #1f497d !important;
	font-weight: 700;
	font-size: 25px !important;
	display: inline-block;
}
b.orange-colored {
	color: #f39200 !important;
}
a.defaru-linking {
	font-weight: 800;
	color: #1f497d !important;
}
.gfa-image-container {
	height: 250px;
}
.center-paragraphe {
	text-align: center;
}
.gfa-blue-color {
	color: #1f497d !important;
	font-weight: 600 !important;
}
.pdf-created-link-container {
	text-align: center;
	margin-top: 40px;
	margin-bottom: 40px;
	width: 100% !important;
}
.pdf-created-link {
	width: 100% !important;
	background: #f39200;
	padding: 20px;
	color: var(--primaryColor);
	font-weight: 600;
	border-radius: 50px;
	text-decoration: initial;
	border: 1px solid #f39200 !important;
	transition: all 0.5s ease;
}
.pdf-created-link:hover {
	background: var(--primaryColor) !important;
	color: #f39200 !important;
}
.icon-eye {
	width: 16px;
}

/* ************************* New style */
.bloc-principal-body-container {
	display: flex;
	justify-content: flex-end;
}
.bloc-principal-body-content {
	width: calc(100% - 17%);
	padding-top: 5rem;
}
.bloc-principal-body-ajoutI {
	width: calc(100% - 17%);
	padding: 2rem;
	background: white;
	margin: 4.5% 0 0 17%;
}
body {
	background: #ececec;
	margin: 0 !important;
}
.empty-fluidy {
	background: var(--secondary-color) !important;
    color: white !important;
	box-shadow: 0px 4px 15px rgb(0 0 0 / 5%);
}

/* ***Header */

.logo-item-container {
	display: flex;
	align-items: center;
	grid-gap: 34%;
	gap: 34%;
}

.logo-item {
	font-size: 25px;
	color: var(--primarytextColor);
}
.menu-icon-container {
	padding-top: 10px;
	display: flex;
	align-items: center;
}

.logo-secondy {
	font-weight: 800;
}
.login-page-row {
	margin-top: 3%;
	border-radius: 10px;
}
.left-side-col {
	background: #043b63 !important;
	border-bottom-right-radius: 15px;
	border-top-right-radius: 15px;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}
.login-form-container {
	padding: 60px 45px;
}
.login-title-label {
	font-size: 35px;
	color: var(--primarytextColor);
	margin-bottom: 55px;
	font-weight: 500;
}

#login-form .input-with-icon {
	background: #f7f7f7 !important;
	border-top-right-radius: 10px !important;
	border-bottom-right-radius: 10px !important;
}

.alling-header {
	margin-left: -15px;
	margin-right: -15px;
	box-shadow: 0px 4px 15px rgb(0 0 0 / 5%);
}
.hiddenable-navlink-label {
	display: none;
}
.hoverable:hover span.hiddenable-navlink-label {
	display: inline-block;
}
/* .hoverable:hover .navbar-vertical-customisation {
  padding-top: 64px;
} */
.stat-conteny p {
	color: var(--primaryColor);
}
.parent-label-card-statistics {
	font-weight: 800;
}
.child-label-card-statistics {
	margin-top: -1px;
}
.label-stat-counter {
	font-size: 40px;
	font-weight: 600;
	color: var(--primaryColor);
	font-family: Montserrat;
}
.search-form-container {
	position: relative;
  width: 45%;
}
.search-form-content label {
	position: absolute;
	top: 20%;
	left: 4%;
}
input.form-control.search-input {
	width: 250px;
	border-radius: 8px;
}
.headering {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
  padding-right: 2rem;
}
.header-others-items {
	display: flex;
	justify-content: flex-end;
}

.header-form-control-input-search {
	width: 100%;
	height: 40px;
	background: var(--primaryColor);
	border: 1px solid var(--primaryColor);
	border-radius: 8px;
	padding-left: 20%;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.2px;
	color: #252733;
}

.notification-item-container {
  border-right: 1px solid #DFE0EB;
  padding: 0.5rem 10px 0.5rem 0;
  margin: 0;
}

.notification-item-content {
	position: relative;
  cursor: pointer;
}
.has-notification {
	position: absolute;
	display: inline-block;
	top: 0px;
	width: 8px;
	height: 8px;
	background: var(--primaryColor);
	text-align: center;
	color: var(--primaryColor) !important;
	font-weight: 700;
	border-radius: 50%;
	left: 50%;
}
.connected-user-container {
  width: 40%;
  margin: 0;
  display: flex;
  justify-content: flex-end;
}
img.admin-avatar {
  border-radius: 50%;
}
img.user-avatar {
	border-radius: 8px;
}
h6.admin-name {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.2px;
  color: var(--primaryColor);
  margin: 0;
}
svg.search-icony {
	width: 20px;
	height: 20px;
}
.navbar-vertical-customisation-v2:hover span.hiddenable-navlink-label {
	display: inline-block;
}
.page-content-container {
	background: var(--primaryColor);
	border-radius: 4px;
	padding: 20px 30px;
	box-shadow: 0px 4px 15px rgb(0 0 0 / 5%);
}
.add-item-content svg {
	width: 48px;
	height: 48px;
}
.page-first-row-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.page-title {
	font-size: 25px;
	color: var(--primarytextColor);
	font-weight: 600;
}
.adding-item-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 15%;
}
.no-link.adding-item-label {
	display: flex;
	align-items: center;
}
.form-filter {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.export-btn-container {
	width: 50%;
	text-align: right;
}
.export-btn {
	background: var(--primaryColor);
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
	border-radius: 10px;
	border-color: transparent !important;
	width: 29%;
}
.export-btn-label {
	color: var(--primarytextColor);
}
.entreprise {
	color: rgb(0 0 0 / 24%) !important;
}
.action-btn {
	background: transparent;
}
.table-actions-btn-container {
	display: flex;
	align-items: center;
	/* justify-content: space-around; */
}

.breadcrumbs-and-actions-row {
	margin-top: 6%;
	background: var(--primaryColor);
	padding-left: 9%;
	padding-top: 23px;
	padding-bottom: 14px;
}
.breadcrumbs-container svg {
	width: 10px;
}
.brdc-item {
	color: rgba(0, 0, 0, 0.7) !important;
}
.brdc-item:hover {
	text-decoration: none !important;
	color: #01223a !important;
}
.brdc-item.active-brdc-item {
	color: #01223a !important;
	font-weight: 500;
}
.ben-profile-container {
	margin-top: 3%;
}
.profile-infos-ben,
.wifes-infos-container,
.childs-infos-container {
	background: var(--primaryColor);
	border-radius: 3px;
}
.wifes-infos-container {
	margin-bottom: 25px;
}
.wifes-infos-container,
.childs-infos-container {
	padding: 25px 20px;
}
.profile-infos-ben {
	padding: 18px 25px;
	margin-bottom: 20px;
}
.section-light-title {
	font-size: 16px;
	color: #0c0b0b;
}
.profile-avatar {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	background: #c4c4c4;
}
.profile-avatar-container {
	text-align: center;
	margin: 40px 0;
}
.ben-name {
	font-size: 24px;
	color: #0c0b0b;
	text-transform: capitalize;
	font-weight: 500;
}
.ben-others-infos-container {
	display: flex;
	flex-direction: column;
}
.ben-others-infos-item-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 0;
	font-size: 16px;
}
.ben-others-infos-item-value {
	color: #0c0b0b;
	font-weight: 500;
}
.status-header-actions-container {
	display: flex;
	align-items: initial;
	justify-content: space-between;
	/* width: 100%; */
}
.header-actions-container {
	display: flex;
	width: 70%;
	justify-content: space-around;
}
.status-value {
	font-weight: 600;
}
/* .wifes-infos-container .adding-item-container {
  width: 30%;
} */
.easypm-modal-title {
	color: #0c0b0b;
	font-size: 18px;
}
.easypm-modal-header {
	border-bottom: none !important;
	padding-left: 25px;
	padding-right: 25px;
}
.easypm-modal-body {
	padding-left: 25px;
	padding-right: 25px;
}
.crud-form-group label {
	color: #0c0b0b;
	font-size: 13px;
}
.crud-form-control {
	padding: 22px;
	border-radius: 10px;
}
.input-radio-container {
	padding-top: 22px;
	padding-bottom: 60px;
}
.input-radio-content {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.input-radio-item {
	border: 0.5px solid #959595;
	padding: 13px;
	border-radius: 10px;
	width: 40%;
	display: flex;
}

input[type="radio"].input-radio-form-control {
	width: 20px;
	height: 20px;
}

span.input-radio-item-label {
	color: #515151;
	font-size: 13px;
	font-weight: 500;
	letter-spacing: 0.045em;
}
input[type="radio"].input-radio-form-control:checked:after {
	content: "";
	display: inline-block;
	width: 20px;
	height: 20px;
	padding: 3px;
	margin-right: 5px;
	background-clip: content-box;
	border: 1px solid #02a69c;
	background-color: #02a69c;
	border-radius: 50%;
}
input[type="radio"].input-radio-form-control:checked + label:before {
	background-color: #02a69c;
}
.crud-form-action-btn-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.crud-form-action-btn-container button {
	padding: 15px;
}
.crud-submit-btn {
	border-radius: 10px !important;
	background: var(--secondary-color) !important;
	border-color: var(--secondary-color) !important;
}
.crud-submit-btn:hover {
	color: var(--secondary-color) !important;
	background: transparent !important;
}
.cancelleb-btn:hover {
	background: #ff0000 !important;
	border-color: #ff0000 !important;
	border-radius: 10px;
	color: var(--primaryColor);
	font-weight: 600;
}
.cancelleb-btn {
	border-radius: 10px !important;
	background: var(--septenaryTextColor) !important;
	color: var(--primaryColor);
	font-size: 17px !important;
	font-weight: 500;
}
.crud-row {
	padding-top: 10px;
	padding-bottom: 80px;
}
.crud-form-group {
	margin-bottom: 40px;
}
.nameFormatter {
	color: var(--primarytextColor);
	font-weight: 500;
}
.nameFormatter:hover {
	text-decoration: none;
	color: #01223a !important;
}
.crud-form-interm {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.crud-form-group.crud-interm {
	width: 49%;
	position: relative;
}
.customy-label {
	display: inline-block;
	position: absolute;
	right: 4%;
	bottom: 14%;
	font-size: 18px;
	color: #8b8b8b;
}
.crud-col-item.pricing-cruded {
	padding: 15px 30px;
	background: #efefef;
	margin-left: 15px !important;
	margin-right: 15px !important;
	margin-bottom: 40px;
}
.taux-ipm-container,
.plafond-ipm-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
}
.taux-ipm-label,
.plafond-ipm-label {
	color: #0c0b0b;
	font-weight: 400;
}
.taux-ipm-value,
.plafond-ipm-value {
	color: #0c0b0b;
	font-weight: 600;
}
input[type="date" i] {
	padding-right: 5px;
}
.soinsy,
.beny {
	top: 7%;
}
.header-hiddenable-section {
	position: absolute;
	right: 20%;
	background: var(--primaryColor);
	box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
	padding: 18px;
	top: 90%;
	width: 23%;
}
.heder-hiddenable-title {
	color: var(--primarytextColor);
	padding: 7px;
	padding-left: 15px;
}
.header-hiddenable-ul li {
	list-style: none;
	padding: 12px 0;
	border-bottom: 1px solid #ececec;
	padding-left: 15px;
}
.header-hiddenable-ul {
	padding-left: 0;
}
.header-hiddenable-ul li:first-child {
	border-top: 1px solid #ececec;
}
.header-hiddenable-link {
	color: var(--primarytextColor);
	font-weight: 500;
}
.header-hiddenable-section:before {
	content: "";
	width: 0;
	height: 0;
	border-left: 17px solid transparent;
	border-right: 17px solid transparent;
	border-bottom: 20px solid var(--primaryColor);
	position: absolute;
	right: 20%;
	top: -6%;
}
.header-hiddenable-ul li:hover {
	background: rgb(2, 166, 156);
}
.header-hiddenable-ul li:hover .header-hiddenable-ul li a {
	/*     color: var(--primaryColor) !important; */
	text-decoration: none;
}

@keyframes grow {
	0% {
		transform: scale(3);
		opacity: 0;
	}
	50% {
		display: block;
		transform: scale(2);
	}
	100% {
		/* opacity: 1; */
		transform: scale(1);
	}
}
.header-hiddenable-section.animated {
	animation: grow 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
.header-hiddenable-notification-section {
	position: absolute;
	right: 7%;
	background: var(--primaryColor);
	box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
	padding: 10px;
	top: 79%;
	width: 23%;
}
.header-hiddenable-notification-section:after {
	content: "";
	width: 0;
	height: 0;
	border-left: 17px solid transparent;
	border-right: 17px solid transparent;
	border-bottom: 20px solid var(--primaryColor);
	position: absolute;
	right: 45%;
	top: -7%;
}
.header-hiddenable-notification-title {
	padding: 12px 17px;
	border-bottom: 1px solid #ececec;
	margin-left: -15px;
	margin-right: -15px;
	color: var(--primarytextColor);
	font-weight: 500;
	font-size: 12px;
}
.header-hiddenable-notification-li {
	list-style: none;
	padding: 7px 15px;
	border-bottom: 1px solid #ececec;
}
.header-hiddenable-notification-ul {
	padding-left: 0;
	margin-left: -10px;
	margin-right: -10px;
}
.header-hiddenable-notification-li p {
	font-size: 12px;
	color: var(--primarytextColor);
	font-weight: 500;
}
.header-hiddenable-notification-li:last-child {
	border-bottom: none;
}
.header-hiddenable-notification-li span {
	color: #8a8989;
	font-size: 11px;
}
.header-hiddenable-notification-li:hover .notification-item-link p,
.header-hiddenable-notification-li:hover .notification-item-link,
.header-hiddenable-notification-li:hover .notification-item-link span {
	text-decoration: none !important;
	color: #02a69c !important;
}
.notification-item-link:visited,
.notification-item-link:active,
.notification-item-link:focus {
	text-decoration: none !important;
	/* color: #02A69C !important; */
}
.easypm-badge-success {
	background: #28a745 !important;
	padding: 5px 15px !important;
	width: auto !important;
	height: auto !important;
	font-size: 15px !important;
}
.easypm-badge-danger {
	background: #dc3545 !important;
	padding: 5px 15px !important;
	width: auto !important;
	height: auto !important;
	font-size: 15px !important;
}
.nameFormation-noLink {
	white-space: normal;
}
.easypm-tabs-nav-pills-item a.active {
	background: transparent !important;
	color: #02a69c !important;
	font-weight: 600;
	border-bottom: 2px solid #02a69c !important;
	border-radius: 0 !important;
}
.easypm-tabs-nav-pills-item a {
	color: var(--primarytextColor);
	font-size: 15px;
	font-weight: 400;
}
.easypm-tabs-content {
	margin-top: 45px;
}
.easypm-tabs-item .crud-submit-btn {
	width: 100%;
}
.easypm-tabs-nav-pills-item a {
	padding-left: 0;
	margin-right: 35px;
	padding-right: 0;
}
.adding-col-offseting div {
	width: 100%;
}

/* Landing Page Style Niafey */
.fluidy-container {
	padding: 10px 200px;
	background: var(--primaryColor)aef;
}
.fluidy-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.menu-ul {
	list-style: none;
	display: flex;
	justify-content: space-between;
	width: 100%;
}
.menu-container {
	width: 55%;
}
.menu-link {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: var(--primarytextColor);
	cursor: pointer;
}
.menu-link:hover {
	color: #296675 !important;
	text-decoration: none;
}
.couv-textual-content {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 50px;
	padding: 50px 0;
}
.social-downloadable-container {
	display: flex;
}
.app-item {
	margin-right: 15px;
}
.couv-row {
	position: relative;
}
.dot-pattern {
	position: absolute;
	top: 72%;
	left: 45%;
	z-index: 9999;
}
.orange-ellipse {
	position: absolute;
	top: 63%;
	z-index: 99;
	right: 14%;
}
.green-ellipse {
	position: absolute;
	top: 90%;
	left: 46%;
	z-index: 999;
}
.mobile-phone {
	position: absolute;
	top: -13%;
	left: 48%;
	z-index: 99999;
}
#homepage-couv-container {
	padding-bottom: 17%;
}
section#how-it-work {
	padding: 250px 200px;
	background: var(--primaryColor);
}

@media (max-width: 900) {
	.dot-pattern {
		position: absolute;
		top: 72%;
		left: 48%;
		z-index: 9999;
	}
	.green-ellipse {
		position: absolute;
		top: 90%;
		left: 51%;
		z-index: 999;
	}
	.orange-ellipse {
		position: absolute;
		top: 63%;
		z-index: 99;
		right: -14%;
	}
	.mobile-phone {
		position: absolute;
		top: -13%;
		right: -11%;
		z-index: 99999;
	}
	.footer-ellipse-1 {
		position: absolute;
		bottom: -17%;
		z-index: 0;
		left: -19%;
	}
	.footer-ellipse-2 {
		position: absolute;
		bottom: -17%;
		left: -15% !important;
	}
}

h1.section-title {
	text-align: center;
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 36px;
	color: #1d293f;
}

.hiw-container p {
	text-align: center;
}
.hiw-row {
	padding: 70px 0;
}
.hiw-item-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	text-align: center;
	color: #1d293f;
}
.hiw-item-content {
	text-align: center;
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
}
#found-prestataire {
	background: #f7f8fa;
	padding: 50px 200px;
}
.fp-section-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 48px;
	color: #1d293f;
	margin-bottom: 90px;
}
.fp-item-container {
	display: flex;
	align-items: center;
	margin-bottom: 10%;
}
.fp-item-textual-container {
	margin-left: 10%;
}
.fp-item-textual-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	color: #1d293f;
	margin-top: -6%;
	padding-bottom: 5px;
}
.fp-illustration-dot-pattern {
	position: absolute;
	bottom: -11%;
	z-index: 999;
	left: 7%;
}
.fp-illustration-mobile-phone {
	position: absolute;
	bottom: -11%;
	z-index: 99999;
}
.fp-illustration-ellipse {
	position: absolute;
	bottom: -11%;
}
.found-prestataire-container {
	position: relative;
}
#receive-mission {
	background: var(--primaryColor);
	padding: 50px 200px;
	padding-bottom: 18%;
}
.receive-mission-container {
	position: relative;
}
.rm-illustration-ellipse {
	position: absolute;
	left: 20%;
	top: 44%;
}
.rm-illustration-mobile-phone {
	position: absolute;
	left: 89px;
	top: 0%;
}
.rm-illustration-dot-pattern {
	position: absolute;
	right: 23%;
	top: 107%;
	z-index: 999;
}
.rm-col-item {
	padding: 0px 0;
}
.rm-textual-container {
	padding: 200px 0;
}
section#our-customers-words {
	background: #e8f5f8;
	padding: 50px 200px;
	padding-bottom: 18%;
}
.customer-caroussel-item.p-4.position-relative.w-full {
	background: var(--primaryColor);
	border-radius: 10px;
	margin-left: 27px;
}
.customer-caroussel-item-photo-container {
	margin-bottom: 15px;
}
.customer-caroussel-item-words-container {
	margin-bottom: 15px;
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
}
.customer-caroussel-item-authorname-container {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
}
.our-customers-words-container {
	position: relative;
}
.rec.rec-arrow.rec.rec-arrow-left {
	position: absolute;
	top: 0;
	right: 16%;
	border: 0 solid transparent !important;
	border-radius: 0;
	background-color: transparent !important;
	box-shadow: none !important;
}
.rec.rec-arrow.rec.rec-arrow-right {
	position: absolute;
	top: 0;
	right: 10%;
	border: 0 solid transparent !important;
	border-radius: 0;
	background-color: transparent !important;
	box-shadow: none !important;
}
#slider {
	background: var(--primaryColor);
	padding: 25px 200px;
	padding-bottom: 8%;
}
.slider-container {
	position: relative;
}
.slider-section-title {
	text-align: center;
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 36px;
	color: #1d293f;
	padding: 70px 0;
}
.slider-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.carousel-indicators {
	bottom: -10%;
}
.carousel-indicators li {
	background-color: var(--secondary-color) !important;
}
#about {
	background: var(--primaryColor);
	padding: 25px 200px;
}
.about-textual-col-item p {
	text-align: justify;
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	color: #7c8087;
}
.contact-form-container {
	background: var(--primaryColor);
	box-shadow: 0px 15px 50px rgba(170, 170, 170, 0.25);
	border-radius: 5px;
	margin: 100px 0;
	padding: 100px 50px;
}
.contact-form-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 36px;
	color: #1d293f;
	margin-bottom: 50px;
}
.contact-form-input-container {
	margin-bottom: 45px;
}
.contact-form-input {
	border: 0.5px solid #1891ae;
	box-sizing: border-box;
	border-radius: 10px;
}
.contact-form-submit-btn-container {
	margin-top: 30px;
}
.niafey-submit-btn {
	width: 100%;
	background: #ca8d19 !important;
	border-radius: 5px;
	border-color: #ca8d19 !important;
	padding: 15px;
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	color: var(--primaryColor);
}
.niafey-submit-btn:hover {
	background: transparent !important;
	color: #ca8d19 !important;
}
img.footer-ellipse-2 {
	position: absolute;
	bottom: 0;
}
#soft-footer {
	position: relative;
}
.footer-ellipse-1 {
	position: absolute;
	bottom: -17%;
	z-index: 0;
}
.about-form-col-item {
	z-index: 9999;
}
.lp-footer-container {
	text-align: center;
	z-index: 99999;
	position: relative;
}
.follow-us-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	color:var(--primaryColor);
}
.social-media-icon-container {
	display: flex;
	justify-content: center;
}
.social-media-icon-item {
	padding: 0 15px;
}
.copyrights-container {
	margin: 20px 0;
	color:var(--primaryColor);
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
}
.copyrights-container a {
	color:var(--primaryColor);
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
}
.landing-page-container {
	overflow: hidden;
}

.rm-textual-container p {
	text-align: justify;
}

/* ADMIN DASHBOARD STYLE */
.logo-container.dashboard-header-logo img {
	width: 100%;
}
.right-side-col {
	background: var(--primaryColor);
	border-radius: 10px;
}
.logo-connexion {
	width: 100%;
}
.texte-chiffre-admin {
	font-size: 11px !important;
	font-weight: 300 !important;
}
.start-counter {
	position: absolute;
	top: 10px;
	right: 21px;
}
.th-table {
	color: var(--secondary-color) !important;
}
.th-table-admin {
	color: #a0aec0 !important;
}
.table thead th .th-border {
	border-bottom: inherit !important;
}
.text-chiffre {
	font-family: "Sarabun";
	font-style: normal;
	font-weight: 500;
	font-size: 27px;
	line-height: 20px;
	letter-spacing: 0.2px;
	color: #f3a40d;
}
.texte-chiffres-nombre {
	font-family: "Sarabun";
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 18px;
	letter-spacing: 0.2px;
	color: var(--primarytextColor);
}
.icon-type-prestation {
	height: 41px;
	width: px;
	color: var(--secondary-color);
}
.text-category {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 300;
	font-size: 13px;
	line-height: 16px;
	color: #0c0b0b;
}
.category-prestation {
	flex-direction: column;
	align-items: center;
}
.bloc-img-prestation {
	background: #736767;
	width: 79px;
	height: 79px;
}
.shadow-category {
	background: var(--primaryColor);
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
	border-radius: 10px;
	padding: 35px;
}
.sous-titre-categorie {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 29px;
	color: var(--primarytextColor);
}
.bloc-category-inline {
	display: inline-block;
}
.titre-category-prestation {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	/* identical to box height */
	color: var(--secondary-color);
}
.bloc-liste {
	display: inline-block;
}
.bloc-icon {
	border: 1px solid #f3a40d;
	border-radius: 50%;
	padding: 0.5rem;
	color: #c8c8c8;
	width: 40px;
	height: 40px;
	display: inline-block;
}
.input-add-category {
	background: var(--primaryColor);
	border: 1px solid #ca8d19 !important;
	box-sizing: border-box;
	border-radius: 10px;
}
.icon-trash {
	position: absolute;
	top: 9px;
	right: 11px;
	color: #130f40;
}
.input-add-new-category {
	background: #f4f4f4;
	border-radius: 10px;
}
.icon-plus {
	color: #ca8d19;
	position: absolute;
	top: 11px;
	left: 6px;
}
.icon-image {
	left: 15px;
	position: absolute;
	top: 13px;
}
.rs-uploader-text .rs-uploader-trigger-btn {
	padding: 8px 61px;
	text-align: left !important;
}
.img-prestataire {
	border-radius: 50%;
}
.star-icon {
	color: #ca8d19;
	position: absolute;
	bottom: 19px;
	left: -4px;
}
.profil-nom {
	font-family: "Mulish";
	font-style: normal;
	font-weight: bold;
	font-size: 32px;
	line-height: 40px;
	letter-spacing: -0.025em;
	color: var(--primarytextColor);
	text-align: center;
}
.profil-type {
	font-family: "Mulish";
	font-style: normal;
	font-weight: 300;
	font-size: 20px;
	line-height: 25px;
	letter-spacing: -0.025em;
	color: var(--primarytextColor);
	text-align: center;
}
.chiffre-profil {
	font-family: "Sarabun";
	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 20px;
	letter-spacing: 0.2px;
	color: #f3a40d;
}
.bloc-prestataire-profil {
	flex-direction: column;
}
.avis-profil {
	font-family: "Sarabun";
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 18px;
	letter-spacing: 0.2px;
	color: var(--primarytextColor);
}
.bloc-profil-right {
	position: absolute;
	right: -54px;
	top: 201px;
}
.profil-form-control {
	width: 300% !important;
}
.icon-trash2 {
	position: absolute;
	top: 8px;
	left: 125px;
	color: #130f40;
}
.group-text {
	background: #fafafa;
	border: 1px solid #f1f1f1;
	box-sizing: border-box;
	border-radius: 8px;
	padding: 0.5rem;
}
.bloc-text-profil {
	font-family: "Mulish";
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 22px;
	color: #252525 !important;
}
.title-info-profil {
	color: #ca8d19;
}
.texte-gray {
	color: #777777;
}
.input-orange-profil {
	background: var(--primaryColor);
	border: 1px solid #ca8d19;
	box-sizing: border-box;
	border-radius: 10px;
	padding: 1rem;
	margin: 0.5rem;
}
.input-profil {
	background: var(--primaryColor);
	border: 1px solid #d1cece;
	box-sizing: border-box;
	border-radius: 10px;
	padding: 1rem;
	margin: 0.5rem;
}
.color-icon-details {
	color: #ca8d19;
}
.bloc-img-avis {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: #f7f7f7;
}
.nom-profil {
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: rgba(0, 0, 0, 0.7);
}

.text-comment {
	line-height: 151.3%;
	color: rgba(0, 0, 0, 0.7);
	text-align: center;
	font-family: Poppins;
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
}
.title-details-comment {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.2px;
	color: #171d33;
}
.border-top {
	border-top: 1px solid #f2f2f2;
}
.logi-reset-form {
	padding: 51px 45px;
}
#login-form-reset .input-group {
	border-bottom: none !important;
}
.logi-reset-form .span-input-icon {
	border-radius: 10px !important;
}
.titre-page-investir {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 32px;
	line-height: 140%;
	color: #0f111d;
}
.wi-progress {
	width: 50%;
	height: 10px;
}
.progress {
	height: 4px !important;
}
.progress-bar {
	background: var(--secondary-color) !important;
}
/* .progress-bar {
    background-color: #19D7A9;
} */
.table-actions-btn-container {
	width: 70%;
}
.consultation-number {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 32px;
	line-height: 39px;
	/* identical to box height */
	color: #5e5b5b;
}
.consultation-name {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
	/* identical to box height */
	color: #5e5b5b;
}
.reset-form-container input {
	border-top-right-radius: 10px !important;
	border-bottom-right-radius: 10px !important;
}
.reset-form-container {
	padding: 60px;
}
.card-reset-password {
	background: var(--primaryColor);
	padding: 3rem 4rem;
	border-radius: 10px;
}
.reset-password-component input {
	border-top-right-radius: 10px !important;
	border-bottom-right-radius: 10px !important;
}
.date-faq {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height */
	color: rgba(0, 0, 0, 0.8);
}

.avatar-user {
	color: #230b34;
}

.person-color {
	color: var(--secondary-color);
	font-family: DM Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 23px;
}

.hr-size {
	background: #eaeaea;
	transform: matrix(1, 0, 0, -1, 0, 0);
}

.title-list {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 17px;
	line-height: 21px;
	/* identical to box height */
	color: var(--primarytextColor);
}

.sizing-li {
	border-width: 0 0 1px !important;
	width: 60% !important;
}
.login-page-row.admin {
	background: var(--quinaryColor);
	padding: 50px 0;
}
.color-black {
	color: var(--primarytextColor) !important;
	text-decoration: none;
}
.ajout-projet-nav-a.active {
	border-color: none !important;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
	border-color: inherit !important;
	border: none !important;
}

.img-utilisateur {
	border-radius: 50%;
	width: 120px;
	height: 120px;
}
.start-label {
	position: relative;
}
.upload-dropzone-row {
	width: 100% !important;
}
.upload-dropzone-row input.file-input {
	position: absolute;
	top: 5px;
	height: 100px;
	left: 0;
	width: 100%;
	padding: 0;
}
@media (min-width: 768px) {
	.navbar-expand-md .navbar-toggler {
		display: block;
	}
	.navbar-expand-md .navbar-collapse {
		display: block !important;
	}
}

.collapse:not(.show) {
	display: none !important;
}
#navbarNavDropdown .carousel-control-next,
.carousel-control-prev {
	position: inherit !important;
}
.carousel-control-next,
.carousel-control-prev {
	position: inherit !important;
}
.input-financement-projet .bloc-div {
	border: 0.25px solid #959595 !important;
	box-sizing: border-box !important;
	border-radius: 10px !important;
	padding: 1rem !important;
	height: 70px !important;
	font-family: "DM Sans" !important;
	font-style: normal;
	font-weight: bold;
	font-size: 16px !important;
	line-height: 21px !important;
	letter-spacing: -0.025em;
	opacity: 0.7;
	color: var(--quinaryTextColor);
	background: white !important;
	width: 100% !important;
}

.bg-statut-nouveau {
	background: var(--secondary-color);
	text-align: center;
	width: 90%;
	padding: 4px;
	border-radius: 16px;
	font-size: 13px;
	color: white;
	font-weight: 700;
}
.bg-statut-prequalifie {
	background: var(--secondary-color);
	text-align: center;
	width: 90%;
	padding: 4px;
	border-radius: 16px;
	font-size: 13px;
	color: white;
	font-weight: 700;
}
.cv-greening {
	color: var(--secondary-color) !important;
}
.mobile-display {
	display: none;
}
.home-section-container {
	padding: 0 50px;
	margin-bottom: 10%;
}
input[type="checkbox"],
input[type="file"],
input[type="radio"] {
	outline: 5px auto var(--secondary-color);
	outline-offset: -2px;
}

.table-body-container-remboursement table tbody tr > td, .table-body-container-remboursement table thead tr > th {
	line-height: 30px;
}

.table-body-container-remboursement {
	margin: 3rem 0;
	width: 97%;
}

span.caret {
    display: none !important;
}
.modal-backdrop.fade {
	display: none !important;
}

a:hover {
	text-decoration: none !important;
}
.section-content-page-tb-investisseur{
	margin-left: 18% !important;
	margin-top: 3%;
}
.containerTabs{
	margin-top: 7%;
}
.bg-tabs{
	background: var(--primaryColor);
}
.content{
	padding: 2rem 0;
}
.bouton-paraine{
	background-color: red;
}
