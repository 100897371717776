@import url('../../../../colors.css');

.card-faq {
    background: var(--primaryColor);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 0 20px !important;
}
.faq-title {
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 140% !important;
    color: var( --decimalTextColor) !important;
}

.div-faq-boutton {
    display: flex;
    justify-content: center;
    margin: 30px 0px 50px 0px !important;
}

.btn-nouvelle-question {
    background: var(--primaryColor) !important;
    border: 1px solid #E8E8E8 !important;
    box-sizing: border-box !important;
    border-radius: 2px !important;
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #ABABAB !important;
    box-shadow: none !important;
}

.btn-nouvelle-question-icon {
    color: var(--secondary-color);
    width: 23px !important;
    height: 23px !important;
    margin-right: 5px;
}

.faq-card-title {
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 19px !important;
    line-height: 28px !important;
    color: var(--secondary-color);
}

.faq-card-para {
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: var(--quaternaryTextColor) !important;
    margin-top: -5px !important;
}

.faq-consultation-number {
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 32px !important;
    line-height: 48px !important;
    color: var(--quinaryTextColor) !important;
}

.faq-consultation-para {
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 18px !important;
    color: var(--quinaryTextColor) !important;
    margin-top: -5px !important;
}

.faq-edit-icon {
    color: var(--quinaryTextColor) !important;
    width: 32px !important;
    height: 32px !important;
    border-radius: 20px;
    padding: 5px;
    border: 0.5px solid var(--secondary-color) !important;
    box-sizing: border-box;
    outline: none;
}

.faq-trash-icon {
    color: #E53E3E;
    width: 32px !important;
    height: 32px !important;
    border-radius: 20px;
    padding: 5px;
    border: 0.5px solid var(--secondary-color) !important;
    box-sizing: border-box;
    outline: none;
}

.div-card {
    background: var(--primaryColor) !important;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08) !important;
    border-radius: 5px !important;
    margin-bottom: 20px;
    flex-wrap: nowrap;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
}

.div-consultation-icon {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.card-faq-main-content {
    width: 100%;
    margin: auto;
    padding-bottom: 60px;
}

.div-icon {
    display: flex;
    align-items: center;
    gap: 10px;
}


.modal-faq {
    width: 96% !important;
}
.faq-modal-content {
    padding: 10px 15px !important;
}
.modal-faq-header {
    position: relative;
    padding: 20px 0 !important;
}

.faq-modal-footer, .modal-faq-header {
    border: none !important;
}

.titre-question-reponse {
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 18px !important;
    line-height: 27px !important;
    color: #0C0B0B !important;
    text-align: left !important;
    position: absolute;
    left: 10px;
    top: 10px !important;
}

.label-question, .label-reponse{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    color: #0C0B0B;
}

.input-question, .input-reponse {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #ABABAB;
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid #E8E8E8 !important;
}

.close-icon {
    color: #666666 !important;
    width: 14px !important;
    height: 14px !important;
    position: absolute;
    top: -4px !important;
    right: 20px !important;
}
.close-icon:hover {
    color: var(--quinaryTextColor) !important;
}

.btn-envoyer-faq-modal {
    background: var(--secondary-color);
    border-radius: 4px !important;
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 15px !important;
    line-height: 22px !important;
    color: var(--primaryColor) !important;
    outline: none;
}
.btn-envoyer-faq-modal:focus, .btn-retour-faq-modal:focus, .btn-nouvelle-question:focus {
    outline: none !important;
}

.btn-envoyer-faq-modal:hover {
    background: var(--primaryColor);
    color: var(--secondary-color) !important;
    border: 1px solid var(--secondary-color);
}

.btn-retour-faq-modal {
    background: var(--primaryColor);
    border: none;

    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    line-height: 19px !important;
    letter-spacing: 0.045em !important;
    color: #515151 !important;
    text-transform: uppercase;
    outline: none;

}

@media only screen and (max-width: 768px) {
    .card-faq-main-content {
        width: 100%;
    }

    .faq-card-title, .faq-card-para {
        font-size: 12px !important;
    }

    .faq-title {
        font-size: 14px !important;
    }

   

}