@import url('../../../colors.css');

/* *{border: 2px solid red} */
.text-justefy {
	text-align: start !important;
}
.border-vide {
	margin-top: 3em;
	margin-bottom: 3em;
	border-right: 2px dashed var(--secondary-color);
	position: absolute;
	left: 45%;
	position: absolute;
	height: 80%;
}
.marche-plateform-container{
	padding-left: 4rem !important;
    padding-right: 4rem;
    max-width: 1500px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
}
.financement-participatif{
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 75px;
	text-align: center;
	color: var(--secondary-color);
}
.container-financement-participatif {
    background: var(--quaternary-color);
}
.couverture-description-text-color,p{
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 27px;
	color: var(--primarytextColor);
}
.nous-sommes-title{
	font-family: "Poppins";
	font-style: italic;
	font-weight: normal;
	font-size: 14px;
	line-height: 27px;
	color: var(--primarytextColor);
	text-align: center;
	margin-left: 5%;
}
.number {
	position: absolute;
	color: var(--secondary-color);
	font-size: 2em;
	font-weight: bold;
	top: -55px;
	right: -16px;
}

.sub-title-theme {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 21px;
	letter-spacing: -0.025em;
	color: var(--secondary-color);
}
.about-item {
	/* background: #f3f3f4; */
	border: 0.25px solid var(--secondary-color);
	box-sizing: border-box;
	border-radius: 10px;
	border-top: 13px solid var(--secondary-color) !important;
	padding: 2rem 32px 5rem;
	width: 300px;
}
/* .ytp-cued-thumbnail-overlay-image {
	background-image: url(../../../images/detail.png) !important;
} */
.item-process-container-mobile {
	border-radius: 10px;
	padding: 2rem 4px 5rem;
	width: 300px;
	float: left;
	position: relative;
}
.process-finanement-mobile {
	width: 100%;
	min-height: 100px;
	flex-wrap: inherit !important;
	overflow: auto;
}
.num-process-mobile {
	background: var(--primaryColor);
	border-radius: 10px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 29px;
	letter-spacing: 0.26em;
	color: var(--secondary-color);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 60.8px;
	height: 60.8px;
}
.title-mobile-process {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 28px;
	color: var(--primarytextColor);
}
.color-theme {
	color: var(--secondary-color) !important;
}
.about-item-mobile {
	padding: 1rem 32px 1rem 32px !important;
}
.img-qsn {
	width: 100%;
}
.img-height {
	height: 700px;
}
.qsn-img-bottom {
	height: 550px;
}
.text-paragraph {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 165.2%;
	letter-spacing: -0.025em;
	color: var(--primarytextColor);
	text-align: justify;
}
.text-hide {
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-box !important;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	white-space: normal;
}
.aprops-page-title {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 30px;
	line-height: 75px;
	text-align: center;
	margin-top: 0;
	color: var(--quaternaryTextColor);
}
.ccm-section-title {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 43px;
	color: var(--secondary-color);
}
.ccm-content {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 165.2%;
	letter-spacing: -0.025em;
	/* color: var(--quaternaryTextColor); */
	margin-bottom: 45px;
}
.ccm-content p {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 165.2%;
	letter-spacing: -0.025em;
	/* color: var(--quaternaryTextColor); */
}
.ccm-video {
	left: 0;
	right: 0;
	/* margin-bottom: 17%; */
	background: transparent !important;
}
.process-container {
	margin-top: 7%;
}
.process-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 40px;
	text-align: center;
	margin-bottom: 20px;
	color: var(--secondary-color);
	margin-bottom: 5rem;
}
.sub-section-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	line-height: 30px;
	color: var(--secondary-color);
}

/**** Ameliorations ****/
.step-how-it-works-img-container {
	display: flex;
	justify-content: center;
}
.w-70 {
	width: 75%;
}
.step-how-it-works-details-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.step-how-it-works-title {
	font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    color: var(--secondary-color);
}

.step-how-it-works-detail-title {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: bold;
	font-size: 26px;
	line-height: 32px;
	letter-spacing: -0.025em;
	color: var(--secondary-color);
	margin-bottom: 2rem;
	margin-top: 0;
}
.step-how-it-works-detail-title-politique {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 32px;
	letter-spacing: -0.025em;
	color: var(--secondary-color);
	margin-bottom: 2rem;
	margin-top: 0;
}


.step-how-it-works-text {
	font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: var(--quaternaryTextColor);
}

/* type investment */
.type-investment-title-container {
	display: flex;
	align-items: center; 
	gap: 5px;
}
.type-investment-title-number {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 50px;
	letter-spacing: -0.025em;
	color: var(--secondary-color);
	margin-left: 12px;
}
.text-risque{
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 165.2%;
	letter-spacing: -0.025em;
	color: var(--quaternaryTextColor);
}
.works-type-text{
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 165.2%;
	letter-spacing: -0.025em;
	color: var(--quaternaryTextColor);
	list-style: none;
}

.tout-investissement{
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 25px;
	line-height: 51px;
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: -0.025em;
	color: var(--senarioTextColor);
}
.container-investissement-risque{
	background: var(--octonaryColor);
	border: 1px solid var(--quinaryBorderColor);
	box-sizing: border-box;
}

.type-investment-title {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 30px;
	margin-left: 5%;
	letter-spacing: -0.025em;
	color: var(--secondary-color);
	margin:0;
}
.type-investment-sous-title{
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	margin-left: 5%;
	letter-spacing: -0.025em;
	color: var(--secondary-color);
	margin: 13px;
}
.type-investment-title-royal {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	color: var(--secondary-color);
}

.step-how-it-works-type-text-container {
	padding-left: 5rem;
}

.step-how-it-works-type-text {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 27px;
	letter-spacing: -0.025em;
	color: var(--quinaryTextColor);
	text-align: justify;
}
.step-how-it-works-type-text-royal {
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 27px;
	letter-spacing: -0.025em;
	color: var(--quinaryTextColor);
	text-align: justify;
}

.step-works-royaltie-content {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.tab-slide-title {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	color: var(--secondary-color);
}
.table-comparatif th {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	text-align: center;
}

.table-thead {
	--bs-table-bg: var(--secondary-color) !important;
}

.table-comparatif tbody tr th {
	padding-top: 2rem !important;
}

.table-comparatif td {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 25px;
	letter-spacing: -0.025em;
	color: var(--primarytextColor);
	text-align: justify;
}
.table>thead>tr>th{
	color: var(--primaryColor);
}

@media screen and (max-width: 767px) {
	.step-how-it-works-type-text-container {
		padding-left: 0;
	}
	.step-how-it-works-details-container {
		min-height: auto;
	}
	.step-how-it-works-title {
		font-size: 24px;
	}
}
.table-thead tr th:first-child {
    background: var(--primaryColor);
}