@import('../../../../colors.css');
.description{
    background-color: var(--investisseurBackgroundColor);
    width: 300px;
}
.bordureStatut{
    border-radius: 11.34px;
    font-size: 10px;
    font-weight: 500;
}
.projets-info{
    padding: 2% 31px;
}
.containFinancement, .ContainMontant{
    font-size: 12px;
  color: gray;
  font-weight: 700,
}
.ContainMontant{
   color: var(--investisseurBackgroundColor) !important;
}
.ContainMontantColor{
    color: var(--secondary-color) !important;
 }

.project-itemIncubateur{
    font-size: 18px;
    color: #808080;
    font-weight: 700;
}