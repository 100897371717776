.card-choix-pieces{
  background-color: var(--primaryColor);
  padding: 80px;
}

.style-chckbox{
    width: 20px;
    height: 20px;
    border: 1px solid var(--secondary-color) !important;
    box-sizing: border-box;
    position: absolute;
   
    
}
.style-chckbox:checked{
    background: var(--secondary-color) !important;
} 

.style-prev{
  margin: 8px 35px !important;
}

.contenant {
  border: 1px solid grey;
}


.row-content1{
  height: 100vh;
  background: var(--quinaryColor);
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-content1{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80%;
  padding: 0px;
  background: var(--primaryColor);
  box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.07);
}

.titre-choisissez{
  text-align: left !important;
  font-size: 22px !important;
}
