.auth-forget-component {
    min-height: 100vh;
}
.auth-form-forget-title {
	font-family: Poppins;
	font-size: 36px;
	font-style: normal;
	font-weight: 400;
	line-height: 54px;
	text-align: center;
	color: var(--primarytextColor);
}

.auth-form-text {
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	line-height: 25px;
	letter-spacing: 0em;
	text-align: center;
    color: #292929;
}
