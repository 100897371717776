@import url("./colors.css");

@media (max-width: 375px) {
	.couv-textual-content {
		font-size: 18px !important;
		padding: 26px 0;
	}
	.social-downloadable-container {
		display: block !important;
		padding-bottom: 6% !important;
	}
	section#how-it-work {
		padding: 160px 50px !important;
	}
	.app-item {
		margin-right: inherit !important;
		margin-top: 12px !important;
	}
	.mobile-phone {
		left: 44% !important;
		height: 106% !important;
		top: 38% !important;
		z-index: 0 !important;
	}
	.fp-section-title {
		margin-bottom: 30px !important;
	}
	.rm-illustration-mobile-phone {
		width: 100%;
		left: 40px !important;
	}
	.rm-textual-container {
		margin-top: 15rem !important;
		padding-top: 80px !important;
		padding-bottom: 25px !important;
	}
	.img-footer-eclipse {
		left: -58% !important;
		top: -130% !important;
	}
	.eclipse-footer-1 {
		width: 141% !important;
	}
	.rec.rec-arrow.rec.rec-arrow-right {
		top: 10% !important;
		right: -4% !important;
	}
	.rec.rec-arrow.rec.rec-arrow-left {
		top: 10% !important;
		right: 5% !important;
	}
	.nav-hamburger {
		right: 18% !important;
	}
	.social-downloadable-img {
		width: 44% !important;
	}
	.slider-section-title {
		font-size: 24px !important;
	}
	.customer-caroussel-item.p-4.position-relative.w-full {
		margin-left: 0 !important;
	}
}

@media (max-width: 576px) and (min-width: 376px) {
	.couv-textual-content {
		font-size: 18px !important;
		padding: 26px 0;
	}
	.social-downloadable-container {
		display: block !important;
		padding-bottom: 6% !important;
	}
	.app-item {
		margin-right: inherit !important;
		margin-top: 12px !important;
	}
	.mobile-phone {
		left: 39% !important;
		height: 148% !important;
		top: 15% !important;
		z-index: 0 !important;
	}
	.fp-section-title {
		margin-bottom: 30px !important;
	}
	.rm-illustration-mobile-phone {
		width: 100%;
		left: 40px !important;
	}
	.rm-textual-container {
		margin-top: 15rem !important;
		padding-bottom: 50px !important;
		padding-top: 200px !important;
	}
	.img-footer-eclipse {
		left: -58% !important;
		top: -205% !important;
	}
	.eclipse-footer-1 {
		width: 141% !important;
	}
	.rec.rec-arrow.rec.rec-arrow-right {
		top: 10% !important;
		right: -4% !important;
	}
	.rec.rec-arrow.rec.rec-arrow-left {
		top: 10% !important;
		right: 5% !important;
	}
	.nav-hamburger {
		right: 18% !important;
	}
	.social-downloadable-img {
		width: 44% !important;
	}
	.slider-section-title {
		font-size: 24px !important;
	}
	.customer-caroussel-item.p-4.position-relative.w-full {
		margin-left: 0 !important;
	}
	section#how-it-work {
		padding-left: 50px !important;
		padding-right: 50px !important;
		padding-top: 250px !important;
		padding-bottom: 70px !important;
	}
	section#our-customers-words {
		padding: 50px 50px !important;
	}
	.contain-carousel {
		padding: 17% !important;
	}
}
@media (max-width: 768px) and (min-width: 575px) {
	.img-footer-eclipse {
		left: -44% !important;
		top: -199% !important;
	}
	.rm-textual-container {
		margin-top: 24rem !important;
	}
	.rm-textual-container p {
		position: absolute;
		bottom: 0 !important;
		top: 79% !important;
		left: 0% !important;
	}
	.eclipse-footer-1 {
		width: 121% !important;
	}
	section#how-it-work {
		padding: 200px 50px !important;
	}
}
@media (min-width: 768px) {
	.nav-hamburger {
		display: none !important;
	}
}
@media (max-width: 768px) {
	.fp-illustration-ellipse {
		display: none !important;
	}
	.fp-illustration-dot-pattern {
		display: none !important;
	}
	.menu-container {
		width: 100% !important;
	}
	.menu-li-item {
		padding-left: 10px !important;
	}
	.menu-link {
		white-space: nowrap !important;
	}
	.menu-ul {
		flex-direction: column;
		align-items: flex-end !important;
	}
	.couv-textual-content {
		font-size: 24px !important;
		padding: 26px 0;
	}
	/* .rm-textual-container {
        margin-top: 24rem !important;
    } */
	.rm-illustration-mobile-phone {
		top: -157% !important;
	}
	.fp-illustration-mobile-phone {
		display: none !important;
	}
	/* section#how-it-work {
        padding: 200px 50px !important;
    } */
	.rec.rec-arrow.rec.rec-arrow-right {
		top: 14% !important;
	}
	.rec.rec-arrow.rec.rec-arrow-left {
		top: 14% !important;
	}
	.menu-ul {
		display: none !important;
	}
}
@media (max-width: 991px) {
	.menu-container {
		width: 83% !important;
	}
	.fluidy-container {
		padding: 10px 50px !important;
	}

	.green-ellipse {
		display: none;
	}
	.dot-pattern {
		display: none;
	}
	.orange-ellipse {
		display: none;
	}
	/* section#how-it-work {
        padding: 250px 50px !important;
    } */
	.hiw-row {
		padding: 70px 0 !important;
	}
	.fp-section-title {
		font-size: 33px !important;
	}

	#found-prestataire {
		padding: 50px 50px !important;
	}
	.rm-illustration-mobile-phone {
		left: 0 !important;
	}
	#receive-mission {
		padding: 50px 50px !important;
	}
	/* section#our-customers-words {
        padding: 50px 50px !important;
    } */
	#slider {
		padding: 25px 50px !important;
	}
	#about {
		padding: 25px 50px !important;
	}
	/* .img-footer-eclipse {
        left: 0 !important;
    } */
	.fp-illustration-mobile-phone {
		width: 100% !important;
	}
	.rm-illustration-dot-pattern {
		display: none !important;
	}
	.rm-illustration-ellipse {
		display: none !important;
	}
	/* .mobile-phone {
        left: 56% !important;
        height: 174% !important;
    } */
	.about-textual-col-item p {
		text-align: justify !important;
	}

	/* .offset-md-2 {
        margin-left: inherit !important;
        offset: none !important;
    } */
	.about-form-col-item {
		display: flex !important;
		justify-content: center !important;
	}
}
@media (max-width: 927px) and (min-width: 769px) {
	.rm-textual-container p {
		top: 18% !important;
		left: 38% !important;
	}
}
@media (max-width: 991px) and (min-width: 768px) {
	.rm-textual-container p {
		top: 17% !important;
		left: 29% !important;
	}
	.couv-textual-content {
		font-size: 38px !important;
	}
	.mobile-phone {
		left: 56% !important;
		height: 174% !important;
	}
	.img-footer-eclipse {
		left: 0 !important;
	}
	section#how-it-work {
		padding: 250px 50px !important;
	}
	section#our-customers-words {
		padding: 50px 50px !important;
	}
}
@media (max-width: 1199px) and (min-width: 992px) {
	.menu-container {
		width: 100% !important;
		padding-left: 4rem !important;
		padding-right: 0rem !important;
	}
	.couv-textual-content {
		font-size: 39px !important;
	}
	.orange-ellipse {
		top: 60% !important;
		right: 2% !important;
		width: 386px !important;
	}
	.mobile-phone {
		top: -24% !important;
		left: 38%;
		height: 705px !important;
	}
	.green-ellipse {
		top: 80% !important;
		width: 231px !important;
	}
	.dot-pattern {
		top: 55% !important;
		left: 44% !important;
	}
	.img-footer-eclipse {
		position: absolute;
		bottom: -18% !important;
		left: -16% !important;
		padding: 0 !important;
	}
	.footer-ellipse-1 {
		bottom: 28% !important;
	}
	.fluidy-container {
		padding: 10px 100px !important;
	}
	section#how-it-work {
		padding-top: 30% !important;
		padding-left: 100px !important;
		padding-right: 100px !important;
		padding-bottom: 200px !important;
	}
	#found-prestataire {
		padding: 50px 100px !important;
	}
	.fp-section-title {
		font-size: 45px !important;
	}
	#receive-mission {
		padding-top: 50px !important;
		padding-bottom: 250px !important;
		padding-left: 100px !important;
		padding-right: 100px !important;
	}
	.row-fp {
		position: relative !important;
	}
	.rm-illustration-dot-pattern {
		position: absolute !important;
		right: 52% !important;
		top: 95% !important;
	}
	.rm-illustration-ellipse {
		position: absolute !important;
		left: 7% !important;
		top: 44% !important;
	}
	.rm-illustration-dot-pattern {
		position: absolute !important;
		right: 43% !important;
		top: 95% !important;
	}
	section#our-customers-words {
		padding: 50px 100px !important;
	}
	.mission-bloc {
		position: absolute !important;
		left: 25% !important;
		top: 30% !important;
	}
	.rm-textual-container {
		width: 100% !important;
	}
	.fp-section-title {
		margin-bottom: 47px !important;
	}
	#slider {
		padding: 25px 100px !important;
	}
	#about {
		padding: 25px 100px !important;
	}
}
@media (max-width: 1580px) and (min-width: 1361px) {
	.orange-ellipse {
		right: 0% !important;
	}
	.green-ellipse {
		top: 84% !important;
	}
}

@media (max-width: 1360px) and (min-width: 1200px) {
	.couv-textual-content {
		font-size: 41px !important;
	}
	.green-ellipse {
		top: 80% !important;
		left: 43% !important;
	}
	.orange-ellipse {
		right: -11% !important;
		top: 71% !important;
	}
	.dot-pattern {
		top: 57% !important;
		left: 47% !important;
	}
	.menu-container {
		width: 63% !important;
	}
	section#how-it-work {
		padding-left: 250px !important;
		padding-right: 250px !important;
		padding-top: 350px !important;
		padding-bottom: 100px !important;
	}
	.fp-section-title {
		font-size: 44px !important;
	}
	.fp-illustration-mobile-phone {
		left: 8% !important;
	}
	.rm-illustration-mobile-phone {
		left: 0% !important;
	}
	.rm-illustration-dot-pattern {
		right: 0% !important;
		top: 129% !important;
	}
	.mission-bloc {
		position: absolute !important;
		left: 27% !important;
	}
	.img-footer-eclipse {
		left: -13% !important;
	}
	.eclipse-footer-1 {
		width: 121% !important;
	}
	#receive-mission {
		padding-top: 51px !important;
		padding-bottom: 352px !important;
		padding-left: 200px !important;
		padding-right: 200px !important;
	}
}

.eclipse-footer-1 {
	width: 100%;
	height: 100%;
}
.img-footer-eclipse {
	position: absolute;
	bottom: -18% !important;
	left: 10%;
	padding: 0 !important;
}
.footer-footer {
	width: 100%;
}
body {
	margin: 0;
	font-family: "Montserrat", sans-serif;
	/* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--primaryColor) !important;
	background: var(--primaryColor) !important;
    overflow-x: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

/* .table-container {
  margin: 15px;
} */
.table-container input.form-control {
	padding: 20px;
	font-family: "Inter", sans-serif;
	font-style: normal;
	padding: 0 30px;
	height: 45px;
	font-weight: normal;
	font-size: 15px;
	line-height: 18px;
	display: flex;
	align-items: center;
	color: var(--quinaryTextColor);
	border: 0;
	background: #f8f8f8;
	border-radius: 55px;
	margin-right: 1%;
	width: 70%;
}
.table-container .react-bs-table-search-form span.input-group-btn button {
	background: var(--secondary-color);
	border-radius: 55px;
	font-family: "Inter", sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 18px;
	display: flex;
	align-items: center;
	color: var(--primaryColor);
	/* width: 34%; */
	border: 1px solid var(--secondary-color);
	transition: 0.2s ease;
	justify-content: center;
	height: 45px;
}
.table-container button#pageDropDown {
	background: var(--secondary-color);
	border-color: var(--secondary-color);
}
.table-container .page-item.active .page-link {
	background: var(--secondary-color) !important;
	border-color: var(--secondary-color) !important;
}
.linkable {
	cursor: pointer !important;
}
.chart-item-title {
	font-family: "Poppins", sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 20px;
	letter-spacing: 0.4px;
	color: #252733;
}
.empty-suggestion {
	color: #ff0000;
	display: block;
	padding: 11px;
	font-weight: 600;
}
.video-viemo .player .vp-title .vp-title-header {
	display: none !important;
}
.error-row {
	width: 100%;
}
.date-counter-container {
	margin: 20px 0;
	color: #1f497d;
	font-weight: 600;
}
.start-date {
	font-weight: 600;
}
span.counter-item {
	margin-left: 18px;
	padding: 12px;
	background: #ececec;
	border-radius: 5px;
}
.goto-forum-item-btn {
	background: #1f497d;
	padding: 14px;
	border-radius: 50px;
	color: var(--primaryColor);
	font-weight: 600;
	margin-top: 5px;
	display: block;
	text-align: center;
	border: 1px solid #1f497d;
}
.goto-forum-item-btn:hover {
	background: transparent;
	color: #1f497d;
	text-decoration: none;
}
.page-item.active .page-link {
	background-color: var(--secondary-color) !important;
	border-color: var(--secondary-color) !important;
	color: var(--primaryColor) !important;
	font-weight: 900;
}
li.page-item a {
	color: #1f497d !important;
}
.page-item:first-child .page-link span {
	color: #1f497d !important;
}
.page-item.disabled .page-link span {
	color: #1f497d !important;
}
.cm-sender-email {
	font-size: 15px;
	font-style: italic;
}
.badge-module-name {
	font-size: 20px;
	font-weight: 600;
	color: #1f497d;
}
.badge-blue {
	background: #1f497d !important;
}
.badge-orange {
	background: #f39200 !important;
}
.badge-green {
	background: #148f77 !important;
}
.aem-badge-enligne.aem-badge {
	color: #8c18e2;
	background: #f2e7fc;
}
.aem-badge-dispo.aem-badge {
	color: #4caf50;
	background: #daf0dc;
}
.aem-badge-termine.aem-badge {
	color: #fe976b;
	background: #fbede7;
}
.theme-background-color {
	background-color: #1f497d !important;
}
.row.contact-support-row {
	margin-bottom: 15px;
}
.gfa-alert-info {
	background: #1f497d33 !important;
	border-color: #1f497d !important;
	color: #1f497d !important;
	text-align: justify !important;
}
.contact-support-link {
	color: #1f497d !important;
	font-weight: 800;
}

img.zoom-img {
	transform: scale(0.9) !important;
	z-index: 9999999999;
	width: 100%;
	/* margin-top: 30% !important; */
}
.image-zoomable-modal .modal-lg {
	min-width: 1000px !important;
}
.zoomTarget {
	cursor: zoom-in !important;
}
img.is-zoomed-image {
	width: 100%;
}
.regenerate-apprenant-password-icon {
	color: var(--primaryColor) !important;
	width: 16px;
}
.gfa-btn-action-primary {
	background: #1f497d !important;
	border-color: #1f497d !important;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}
.gfa-btn-action-primary .regenerate-apprenant-password-icon:hover {
	background: transparent !important;
	border-color: #1f497d !important;
	color: #1f497d;
}
.actions-buttons-container {
	text-align: center;
}
button.btn.btn-success.react-bs-table-csv-btn.hidden-print {
	background: #f39200 !important;
	border-color: #f39200 !important;
	padding: 11px;
	margin-bottom: 9px;
	border-radius: 50px;
}
.user-infos-modal-col {
	border: 1px solid #ececec;
	padding: 15px 6px;
	border-radius: 10px;
	font-size: 18px;
	margin-bottom: 5px;
}
.user-infos-modal-item-label {
	font-weight: 600;
}
.user-infos-modal-item-content {
	color: #1f497d !important;
	font-weight: 700;
}
span.badge-success.gfa-badge-success,
span.badge-danger.gfa-badge-danger {
	padding: 7px;
	font-weight: 600;
	border-radius: 50px;
}
.title h5.quizz-label {
	font-size: 20px;
	font-weight: 600;
	width: 100%;
	margin-left: 15px;
	margin-right: 15px;
	margin-bottom: 20px;
	text-align: initial !important;
}
.link-to-evaluation {
	background: #f39200;
	border-color: #f39200;
	margin-top: 10px;
	width: 100%;
	padding: 10px;
	border-radius: 25px !important;
	font-weight: 600;
}
.link-to-evaluation:hover {
	background: var(--primaryColor);
	color: #f39200;
}
.no-identified-col .gfa-alert-danger {
	text-align: center;
	font-size: 20px;
	font-weight: 600;
}
/* div#g-recaptcha > div {
  width: 100% !important;
}
div#g-recaptcha {
  width: 100% !important;
}
div#g-recaptcha iframe {
  width: 100% !important;
}
div#rc-anchor-container {
  width: 99% !important;
  border-radius: 10px;
} */
.certified-count {
	color: #1f497d !important;
	font-weight: 700;
	font-size: 25px !important;
	display: inline-block;
}
b.orange-colored {
	color: #f39200 !important;
}
a.defaru-linking {
	font-weight: 800;
	color: #1f497d !important;
}
.gfa-image-container {
	height: 250px;
}
.center-paragraphe {
	text-align: center;
}
.gfa-blue-color {
	color: #1f497d !important;
	font-weight: 600 !important;
}
.pdf-created-link-container {
	text-align: center;
	margin-top: 40px;
	margin-bottom: 40px;
	width: 100% !important;
}
.pdf-created-link {
	width: 100% !important;
	background: #f39200;
	padding: 20px;
	color: var(--primaryColor);
	font-weight: 600;
	border-radius: 50px;
	text-decoration: initial;
	border: 1px solid #f39200 !important;
	transition: all 0.5s ease;
}
.pdf-created-link:hover {
	background: var(--primaryColor) !important;
	color: #f39200 !important;
}
.icon-eye {
	width: 16px;
}

/* ************************* New style */
.bloc-principal-body-container {
	display: flex;
	justify-content: flex-end;
}
.bloc-principal-body-content {
	width: calc(100% - 17%);
	padding-top: 5rem;
}
.bloc-principal-body-ajoutI {
	width: calc(100% - 17%);
	padding: 2rem;
	background: white;
	margin: 4.5% 0 0 17%;
}
body {
	background: #ececec;
	margin: 0 !important;
}
.empty-fluidy {
	background: var(--secondary-color) !important;
    color: white !important;
	box-shadow: 0px 4px 15px rgb(0 0 0 / 5%);
}

/* ***Header */

.logo-item-container {
	display: flex;
	align-items: center;
	gap: 34%;
}

.logo-item {
	font-size: 25px;
	color: var(--primarytextColor);
}
.menu-icon-container {
	padding-top: 10px;
	display: flex;
	align-items: center;
}

.logo-secondy {
	font-weight: 800;
}
.login-page-row {
	margin-top: 3%;
	border-radius: 10px;
}
.left-side-col {
	background: #043b63 !important;
	border-bottom-right-radius: 15px;
	border-top-right-radius: 15px;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}
.login-form-container {
	padding: 60px 45px;
}
.login-title-label {
	font-size: 35px;
	color: var(--primarytextColor);
	margin-bottom: 55px;
	font-weight: 500;
}

#login-form .input-with-icon {
	background: #f7f7f7 !important;
	border-top-right-radius: 10px !important;
	border-bottom-right-radius: 10px !important;
}

.alling-header {
	margin-left: -15px;
	margin-right: -15px;
	box-shadow: 0px 4px 15px rgb(0 0 0 / 5%);
}
.hiddenable-navlink-label {
	display: none;
}
.hoverable:hover span.hiddenable-navlink-label {
	display: inline-block;
}
/* .hoverable:hover .navbar-vertical-customisation {
  padding-top: 64px;
} */
.stat-conteny p {
	color: var(--primaryColor);
}
.parent-label-card-statistics {
	font-weight: 800;
}
.child-label-card-statistics {
	margin-top: -1px;
}
.label-stat-counter {
	font-size: 40px;
	font-weight: 600;
	color: var(--primaryColor);
	font-family: Montserrat;
}
.search-form-container {
	position: relative;
  width: 45%;
}
.search-form-content label {
	position: absolute;
	top: 20%;
	left: 4%;
}
input.form-control.search-input {
	width: 250px;
	border-radius: 8px;
}
.headering {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
  padding-right: 2rem;
}
.header-others-items {
	display: flex;
	justify-content: flex-end;
}

.header-form-control-input-search {
	width: 100%;
	height: 40px;
	background: var(--primaryColor);
	border: 1px solid var(--primaryColor);
	border-radius: 8px;
	padding-left: 20%;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.2px;
	color: #252733;
}

.notification-item-container {
  border-right: 1px solid #DFE0EB;
  padding: 0.5rem 10px 0.5rem 0;
  margin: 0;
}

.notification-item-content {
	position: relative;
  cursor: pointer;
}
.has-notification {
	position: absolute;
	display: inline-block;
	top: 0px;
	width: 8px;
	height: 8px;
	background: var(--primaryColor);
	text-align: center;
	color: var(--primaryColor) !important;
	font-weight: 700;
	border-radius: 50%;
	left: 50%;
}
.connected-user-container {
  width: 40%;
  margin: 0;
  display: flex;
  justify-content: flex-end;
}
img.admin-avatar {
  border-radius: 50%;
}
img.user-avatar {
	border-radius: 8px;
}
h6.admin-name {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.2px;
  color: var(--primaryColor);
  margin: 0;
}
svg.search-icony {
	width: 20px;
	height: 20px;
}
.navbar-vertical-customisation-v2:hover span.hiddenable-navlink-label {
	display: inline-block;
}
.page-content-container {
	background: var(--primaryColor);
	border-radius: 4px;
	padding: 20px 30px;
	box-shadow: 0px 4px 15px rgb(0 0 0 / 5%);
}
.add-item-content svg {
	width: 48px;
	height: 48px;
}
.page-first-row-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.page-title {
	font-size: 25px;
	color: var(--primarytextColor);
	font-weight: 600;
}
.adding-item-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 15%;
}
.no-link.adding-item-label {
	display: flex;
	align-items: center;
}
.form-filter {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.export-btn-container {
	width: 50%;
	text-align: right;
}
.export-btn {
	background: var(--primaryColor);
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
	border-radius: 10px;
	border-color: transparent !important;
	width: 29%;
}
.export-btn-label {
	color: var(--primarytextColor);
}
.entreprise {
	color: rgb(0 0 0 / 24%) !important;
}
.action-btn {
	background: transparent;
}
.table-actions-btn-container {
	display: flex;
	align-items: center;
	/* justify-content: space-around; */
}

.breadcrumbs-and-actions-row {
	margin-top: 6%;
	background: var(--primaryColor);
	padding-left: 9%;
	padding-top: 23px;
	padding-bottom: 14px;
}
.breadcrumbs-container svg {
	width: 10px;
}
.brdc-item {
	color: rgba(0, 0, 0, 0.7) !important;
}
.brdc-item:hover {
	text-decoration: none !important;
	color: #01223a !important;
}
.brdc-item.active-brdc-item {
	color: #01223a !important;
	font-weight: 500;
}
.ben-profile-container {
	margin-top: 3%;
}
.profile-infos-ben,
.wifes-infos-container,
.childs-infos-container {
	background: var(--primaryColor);
	border-radius: 3px;
}
.wifes-infos-container {
	margin-bottom: 25px;
}
.wifes-infos-container,
.childs-infos-container {
	padding: 25px 20px;
}
.profile-infos-ben {
	padding: 18px 25px;
	margin-bottom: 20px;
}
.section-light-title {
	font-size: 16px;
	color: #0c0b0b;
}
.profile-avatar {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	background: #c4c4c4;
}
.profile-avatar-container {
	text-align: center;
	margin: 40px 0;
}
.ben-name {
	font-size: 24px;
	color: #0c0b0b;
	text-transform: capitalize;
	font-weight: 500;
}
.ben-others-infos-container {
	display: flex;
	flex-direction: column;
}
.ben-others-infos-item-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 0;
	font-size: 16px;
}
.ben-others-infos-item-value {
	color: #0c0b0b;
	font-weight: 500;
}
.status-header-actions-container {
	display: flex;
	align-items: initial;
	justify-content: space-between;
	/* width: 100%; */
}
.header-actions-container {
	display: flex;
	width: 70%;
	justify-content: space-around;
}
.status-value {
	font-weight: 600;
}
/* .wifes-infos-container .adding-item-container {
  width: 30%;
} */
.easypm-modal-title {
	color: #0c0b0b;
	font-size: 18px;
}
.easypm-modal-header {
	border-bottom: none !important;
	padding-left: 25px;
	padding-right: 25px;
}
.easypm-modal-body {
	padding-left: 25px;
	padding-right: 25px;
}
.crud-form-group label {
	color: #0c0b0b;
	font-size: 13px;
}
.crud-form-control {
	padding: 22px;
	border-radius: 10px;
}
.input-radio-container {
	padding-top: 22px;
	padding-bottom: 60px;
}
.input-radio-content {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.input-radio-item {
	border: 0.5px solid #959595;
	padding: 13px;
	border-radius: 10px;
	width: 40%;
	display: flex;
}

input[type="radio"].input-radio-form-control {
	width: 20px;
	height: 20px;
}

span.input-radio-item-label {
	color: #515151;
	font-size: 13px;
	font-weight: 500;
	letter-spacing: 0.045em;
}
input[type="radio"].input-radio-form-control:checked:after {
	content: "";
	display: inline-block;
	width: 20px;
	height: 20px;
	padding: 3px;
	margin-right: 5px;
	background-clip: content-box;
	border: 1px solid #02a69c;
	background-color: #02a69c;
	border-radius: 50%;
}
input[type="radio"].input-radio-form-control:checked + label:before {
	background-color: #02a69c;
}
.crud-form-action-btn-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.crud-form-action-btn-container button {
	padding: 15px;
}
.crud-submit-btn {
	border-radius: 10px !important;
	background: var(--secondary-color) !important;
	border-color: var(--secondary-color) !important;
}
.crud-submit-btn:hover {
	color: var(--secondary-color) !important;
	background: transparent !important;
}
.cancelleb-btn:hover {
	background: #ff0000 !important;
	border-color: #ff0000 !important;
	border-radius: 10px;
	color: var(--primaryColor);
	font-weight: 600;
}
.cancelleb-btn {
	border-radius: 10px !important;
	background: var(--septenaryTextColor) !important;
	color: var(--primaryColor);
	font-size: 17px !important;
	font-weight: 500;
}
.crud-row {
	padding-top: 10px;
	padding-bottom: 80px;
}
.crud-form-group {
	margin-bottom: 40px;
}
.nameFormatter {
	color: var(--primarytextColor);
	font-weight: 500;
}
.nameFormatter:hover {
	text-decoration: none;
	color: #01223a !important;
}
.crud-form-interm {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.crud-form-group.crud-interm {
	width: 49%;
	position: relative;
}
.customy-label {
	display: inline-block;
	position: absolute;
	right: 4%;
	bottom: 14%;
	font-size: 18px;
	color: #8b8b8b;
}
.crud-col-item.pricing-cruded {
	padding: 15px 30px;
	background: #efefef;
	margin-left: 15px !important;
	margin-right: 15px !important;
	margin-bottom: 40px;
}
.taux-ipm-container,
.plafond-ipm-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
}
.taux-ipm-label,
.plafond-ipm-label {
	color: #0c0b0b;
	font-weight: 400;
}
.taux-ipm-value,
.plafond-ipm-value {
	color: #0c0b0b;
	font-weight: 600;
}
input[type="date" i] {
	padding-right: 5px;
}
.soinsy,
.beny {
	top: 7%;
}
.header-hiddenable-section {
	position: absolute;
	right: 20%;
	background: var(--primaryColor);
	box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
	padding: 18px;
	top: 90%;
	width: 23%;
}
.heder-hiddenable-title {
	color: var(--primarytextColor);
	padding: 7px;
	padding-left: 15px;
}
.header-hiddenable-ul li {
	list-style: none;
	padding: 12px 0;
	border-bottom: 1px solid #ececec;
	padding-left: 15px;
}
.header-hiddenable-ul {
	padding-left: 0;
}
.header-hiddenable-ul li:first-child {
	border-top: 1px solid #ececec;
}
.header-hiddenable-link {
	color: var(--primarytextColor);
	font-weight: 500;
}
.header-hiddenable-section:before {
	content: "";
	width: 0;
	height: 0;
	border-left: 17px solid transparent;
	border-right: 17px solid transparent;
	border-bottom: 20px solid var(--primaryColor);
	position: absolute;
	right: 20%;
	top: -6%;
}
.header-hiddenable-ul li:hover {
	background: rgb(2, 166, 156);
}
.header-hiddenable-ul li:hover .header-hiddenable-ul li a {
	/*     color: var(--primaryColor) !important; */
	text-decoration: none;
}

@keyframes grow {
	0% {
		transform: scale(3);
		opacity: 0;
	}
	50% {
		display: block;
		transform: scale(2);
	}
	100% {
		/* opacity: 1; */
		transform: scale(1);
	}
}
.header-hiddenable-section.animated {
	animation: grow 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
.header-hiddenable-notification-section {
	position: absolute;
	right: 7%;
	background: var(--primaryColor);
	box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
	padding: 10px;
	top: 79%;
	width: 23%;
}
.header-hiddenable-notification-section:after {
	content: "";
	width: 0;
	height: 0;
	border-left: 17px solid transparent;
	border-right: 17px solid transparent;
	border-bottom: 20px solid var(--primaryColor);
	position: absolute;
	right: 45%;
	top: -7%;
}
.header-hiddenable-notification-title {
	padding: 12px 17px;
	border-bottom: 1px solid #ececec;
	margin-left: -15px;
	margin-right: -15px;
	color: var(--primarytextColor);
	font-weight: 500;
	font-size: 12px;
}
.header-hiddenable-notification-li {
	list-style: none;
	padding: 7px 15px;
	border-bottom: 1px solid #ececec;
}
.header-hiddenable-notification-ul {
	padding-left: 0;
	margin-left: -10px;
	margin-right: -10px;
}
.header-hiddenable-notification-li p {
	font-size: 12px;
	color: var(--primarytextColor);
	font-weight: 500;
}
.header-hiddenable-notification-li:last-child {
	border-bottom: none;
}
.header-hiddenable-notification-li span {
	color: #8a8989;
	font-size: 11px;
}
.header-hiddenable-notification-li:hover .notification-item-link p,
.header-hiddenable-notification-li:hover .notification-item-link,
.header-hiddenable-notification-li:hover .notification-item-link span {
	text-decoration: none !important;
	color: #02a69c !important;
}
.notification-item-link:visited,
.notification-item-link:active,
.notification-item-link:focus {
	text-decoration: none !important;
	/* color: #02A69C !important; */
}
.easypm-badge-success {
	background: #28a745 !important;
	padding: 5px 15px !important;
	width: auto !important;
	height: auto !important;
	font-size: 15px !important;
}
.easypm-badge-danger {
	background: #dc3545 !important;
	padding: 5px 15px !important;
	width: auto !important;
	height: auto !important;
	font-size: 15px !important;
}
.nameFormation-noLink {
	white-space: normal;
}
.easypm-tabs-nav-pills-item a.active {
	background: transparent !important;
	color: #02a69c !important;
	font-weight: 600;
	border-bottom: 2px solid #02a69c !important;
	border-radius: 0 !important;
}
.easypm-tabs-nav-pills-item a {
	color: var(--primarytextColor);
	font-size: 15px;
	font-weight: 400;
}
.easypm-tabs-content {
	margin-top: 45px;
}
.easypm-tabs-item .crud-submit-btn {
	width: 100%;
}
.easypm-tabs-nav-pills-item a {
	padding-left: 0;
	margin-right: 35px;
	padding-right: 0;
}
.adding-col-offseting div {
	width: 100%;
}

/* Landing Page Style Niafey */
.fluidy-container {
	padding: 10px 200px;
	background: var(--primaryColor)aef;
}
.fluidy-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.menu-ul {
	list-style: none;
	display: flex;
	justify-content: space-between;
	width: 100%;
}
.menu-container {
	width: 55%;
}
.menu-link {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: var(--primarytextColor);
	cursor: pointer;
}
.menu-link:hover {
	color: #296675 !important;
	text-decoration: none;
}
.couv-textual-content {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 50px;
	padding: 50px 0;
}
.social-downloadable-container {
	display: flex;
}
.app-item {
	margin-right: 15px;
}
.couv-row {
	position: relative;
}
.dot-pattern {
	position: absolute;
	top: 72%;
	left: 45%;
	z-index: 9999;
}
.orange-ellipse {
	position: absolute;
	top: 63%;
	z-index: 99;
	right: 14%;
}
.green-ellipse {
	position: absolute;
	top: 90%;
	left: 46%;
	z-index: 999;
}
.mobile-phone {
	position: absolute;
	top: -13%;
	left: 48%;
	z-index: 99999;
}
#homepage-couv-container {
	padding-bottom: 17%;
}
section#how-it-work {
	padding: 250px 200px;
	background: var(--primaryColor);
}

@media (max-width: 900) {
	.dot-pattern {
		position: absolute;
		top: 72%;
		left: 48%;
		z-index: 9999;
	}
	.green-ellipse {
		position: absolute;
		top: 90%;
		left: 51%;
		z-index: 999;
	}
	.orange-ellipse {
		position: absolute;
		top: 63%;
		z-index: 99;
		right: -14%;
	}
	.mobile-phone {
		position: absolute;
		top: -13%;
		right: -11%;
		z-index: 99999;
	}
	.footer-ellipse-1 {
		position: absolute;
		bottom: -17%;
		z-index: 0;
		left: -19%;
	}
	.footer-ellipse-2 {
		position: absolute;
		bottom: -17%;
		left: -15% !important;
	}
}

h1.section-title {
	text-align: center;
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 36px;
	color: #1d293f;
}

.hiw-container p {
	text-align: center;
}
.hiw-row {
	padding: 70px 0;
}
.hiw-item-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	text-align: center;
	color: #1d293f;
}
.hiw-item-content {
	text-align: center;
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
}
#found-prestataire {
	background: #f7f8fa;
	padding: 50px 200px;
}
.fp-section-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 48px;
	color: #1d293f;
	margin-bottom: 90px;
}
.fp-item-container {
	display: flex;
	align-items: center;
	margin-bottom: 10%;
}
.fp-item-textual-container {
	margin-left: 10%;
}
.fp-item-textual-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	color: #1d293f;
	margin-top: -6%;
	padding-bottom: 5px;
}
.fp-illustration-dot-pattern {
	position: absolute;
	bottom: -11%;
	z-index: 999;
	left: 7%;
}
.fp-illustration-mobile-phone {
	position: absolute;
	bottom: -11%;
	z-index: 99999;
}
.fp-illustration-ellipse {
	position: absolute;
	bottom: -11%;
}
.found-prestataire-container {
	position: relative;
}
#receive-mission {
	background: var(--primaryColor);
	padding: 50px 200px;
	padding-bottom: 18%;
}
.receive-mission-container {
	position: relative;
}
.rm-illustration-ellipse {
	position: absolute;
	left: 20%;
	top: 44%;
}
.rm-illustration-mobile-phone {
	position: absolute;
	left: 89px;
	top: 0%;
}
.rm-illustration-dot-pattern {
	position: absolute;
	right: 23%;
	top: 107%;
	z-index: 999;
}
.rm-col-item {
	padding: 0px 0;
}
.rm-textual-container {
	padding: 200px 0;
}
section#our-customers-words {
	background: #e8f5f8;
	padding: 50px 200px;
	padding-bottom: 18%;
}
.customer-caroussel-item.p-4.position-relative.w-full {
	background: var(--primaryColor);
	border-radius: 10px;
	margin-left: 27px;
}
.customer-caroussel-item-photo-container {
	margin-bottom: 15px;
}
.customer-caroussel-item-words-container {
	margin-bottom: 15px;
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
}
.customer-caroussel-item-authorname-container {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
}
.our-customers-words-container {
	position: relative;
}
.rec.rec-arrow.rec.rec-arrow-left {
	position: absolute;
	top: 0;
	right: 16%;
	border: 0 solid transparent !important;
	border-radius: 0;
	background-color: transparent !important;
	box-shadow: none !important;
}
.rec.rec-arrow.rec.rec-arrow-right {
	position: absolute;
	top: 0;
	right: 10%;
	border: 0 solid transparent !important;
	border-radius: 0;
	background-color: transparent !important;
	box-shadow: none !important;
}
#slider {
	background: var(--primaryColor);
	padding: 25px 200px;
	padding-bottom: 8%;
}
.slider-container {
	position: relative;
}
.slider-section-title {
	text-align: center;
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 36px;
	color: #1d293f;
	padding: 70px 0;
}
.slider-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.carousel-indicators {
	bottom: -10%;
}
.carousel-indicators li {
	background-color: var(--secondary-color) !important;
}
#about {
	background: var(--primaryColor);
	padding: 25px 200px;
}
.about-textual-col-item p {
	text-align: justify;
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	color: #7c8087;
}
.contact-form-container {
	background: var(--primaryColor);
	box-shadow: 0px 15px 50px rgba(170, 170, 170, 0.25);
	border-radius: 5px;
	margin: 100px 0;
	padding: 100px 50px;
}
.contact-form-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 36px;
	color: #1d293f;
	margin-bottom: 50px;
}
.contact-form-input-container {
	margin-bottom: 45px;
}
.contact-form-input {
	border: 0.5px solid #1891ae;
	box-sizing: border-box;
	border-radius: 10px;
}
.contact-form-submit-btn-container {
	margin-top: 30px;
}
.niafey-submit-btn {
	width: 100%;
	background: #ca8d19 !important;
	border-radius: 5px;
	border-color: #ca8d19 !important;
	padding: 15px;
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	color: var(--primaryColor);
}
.niafey-submit-btn:hover {
	background: transparent !important;
	color: #ca8d19 !important;
}
img.footer-ellipse-2 {
	position: absolute;
	bottom: 0;
}
#soft-footer {
	position: relative;
}
.footer-ellipse-1 {
	position: absolute;
	bottom: -17%;
	z-index: 0;
}
.about-form-col-item {
	z-index: 9999;
}
.lp-footer-container {
	text-align: center;
	z-index: 99999;
	position: relative;
}
.follow-us-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	color:var(--primaryColor);
}
.social-media-icon-container {
	display: flex;
	justify-content: center;
}
.social-media-icon-item {
	padding: 0 15px;
}
.copyrights-container {
	margin: 20px 0;
	color:var(--primaryColor);
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
}
.copyrights-container a {
	color:var(--primaryColor);
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
}
.landing-page-container {
	overflow: hidden;
}

.rm-textual-container p {
	text-align: justify;
}

/* ADMIN DASHBOARD STYLE */
.logo-container.dashboard-header-logo img {
	width: 100%;
}
.right-side-col {
	background: var(--primaryColor);
	border-radius: 10px;
}
.logo-connexion {
	width: 100%;
}
.texte-chiffre-admin {
	font-size: 11px !important;
	font-weight: 300 !important;
}
.start-counter {
	position: absolute;
	top: 10px;
	right: 21px;
}
.th-table {
	color: var(--secondary-color) !important;
}
.th-table-admin {
	color: #a0aec0 !important;
}
.table thead th .th-border {
	border-bottom: inherit !important;
}
.text-chiffre {
	font-family: "Sarabun";
	font-style: normal;
	font-weight: 500;
	font-size: 27px;
	line-height: 20px;
	letter-spacing: 0.2px;
	color: #f3a40d;
}
.texte-chiffres-nombre {
	font-family: "Sarabun";
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 18px;
	letter-spacing: 0.2px;
	color: var(--primarytextColor);
}
.icon-type-prestation {
	height: 41px;
	width: px;
	color: var(--secondary-color);
}
.text-category {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 300;
	font-size: 13px;
	line-height: 16px;
	color: #0c0b0b;
}
.category-prestation {
	flex-direction: column;
	align-items: center;
}
.bloc-img-prestation {
	background: #736767;
	width: 79px;
	height: 79px;
}
.shadow-category {
	background: var(--primaryColor);
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
	border-radius: 10px;
	padding: 35px;
}
.sous-titre-categorie {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 29px;
	color: var(--primarytextColor);
}
.bloc-category-inline {
	display: inline-block;
}
.titre-category-prestation {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	/* identical to box height */
	color: var(--secondary-color);
}
.bloc-liste {
	display: inline-block;
}
.bloc-icon {
	border: 1px solid #f3a40d;
	border-radius: 50%;
	padding: 0.5rem;
	color: #c8c8c8;
	width: 40px;
	height: 40px;
	display: inline-block;
}
.input-add-category {
	background: var(--primaryColor);
	border: 1px solid #ca8d19 !important;
	box-sizing: border-box;
	border-radius: 10px;
}
.icon-trash {
	position: absolute;
	top: 9px;
	right: 11px;
	color: #130f40;
}
.input-add-new-category {
	background: #f4f4f4;
	border-radius: 10px;
}
.icon-plus {
	color: #ca8d19;
	position: absolute;
	top: 11px;
	left: 6px;
}
.icon-image {
	left: 15px;
	position: absolute;
	top: 13px;
}
.rs-uploader-text .rs-uploader-trigger-btn {
	padding: 8px 61px;
	text-align: left !important;
}
.img-prestataire {
	border-radius: 50%;
}
.star-icon {
	color: #ca8d19;
	position: absolute;
	bottom: 19px;
	left: -4px;
}
.profil-nom {
	font-family: "Mulish";
	font-style: normal;
	font-weight: bold;
	font-size: 32px;
	line-height: 40px;
	letter-spacing: -0.025em;
	color: var(--primarytextColor);
	text-align: center;
}
.profil-type {
	font-family: "Mulish";
	font-style: normal;
	font-weight: 300;
	font-size: 20px;
	line-height: 25px;
	letter-spacing: -0.025em;
	color: var(--primarytextColor);
	text-align: center;
}
.chiffre-profil {
	font-family: "Sarabun";
	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 20px;
	letter-spacing: 0.2px;
	color: #f3a40d;
}
.bloc-prestataire-profil {
	flex-direction: column;
}
.avis-profil {
	font-family: "Sarabun";
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 18px;
	letter-spacing: 0.2px;
	color: var(--primarytextColor);
}
.bloc-profil-right {
	position: absolute;
	right: -54px;
	top: 201px;
}
.profil-form-control {
	width: 300% !important;
}
.icon-trash2 {
	position: absolute;
	top: 8px;
	left: 125px;
	color: #130f40;
}
.group-text {
	background: #fafafa;
	border: 1px solid #f1f1f1;
	box-sizing: border-box;
	border-radius: 8px;
	padding: 0.5rem;
}
.bloc-text-profil {
	font-family: "Mulish";
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 22px;
	color: #252525 !important;
}
.title-info-profil {
	color: #ca8d19;
}
.texte-gray {
	color: #777777;
}
.input-orange-profil {
	background: var(--primaryColor);
	border: 1px solid #ca8d19;
	box-sizing: border-box;
	border-radius: 10px;
	padding: 1rem;
	margin: 0.5rem;
}
.input-profil {
	background: var(--primaryColor);
	border: 1px solid #d1cece;
	box-sizing: border-box;
	border-radius: 10px;
	padding: 1rem;
	margin: 0.5rem;
}
.color-icon-details {
	color: #ca8d19;
}
.bloc-img-avis {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: #f7f7f7;
}
.nom-profil {
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: rgba(0, 0, 0, 0.7);
}

.text-comment {
	line-height: 151.3%;
	color: rgba(0, 0, 0, 0.7);
	text-align: center;
	font-family: Poppins;
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
}
.title-details-comment {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.2px;
	color: #171d33;
}
.border-top {
	border-top: 1px solid #f2f2f2;
}
.logi-reset-form {
	padding: 51px 45px;
}
#login-form-reset .input-group {
	border-bottom: none !important;
}
.logi-reset-form .span-input-icon {
	border-radius: 10px !important;
}
.titre-page-investir {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 32px;
	line-height: 140%;
	color: #0f111d;
}
.wi-progress {
	width: 50%;
	height: 10px;
}
.progress {
	height: 4px !important;
}
.progress-bar {
	background: var(--secondary-color) !important;
}
/* .progress-bar {
    background-color: #19D7A9;
} */
.table-actions-btn-container {
	width: 70%;
}
.consultation-number {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 32px;
	line-height: 39px;
	/* identical to box height */
	color: #5e5b5b;
}
.consultation-name {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
	/* identical to box height */
	color: #5e5b5b;
}
.reset-form-container input {
	border-top-right-radius: 10px !important;
	border-bottom-right-radius: 10px !important;
}
.reset-form-container {
	padding: 60px;
}
.card-reset-password {
	background: var(--primaryColor);
	padding: 3rem 4rem;
	border-radius: 10px;
}
.reset-password-component input {
	border-top-right-radius: 10px !important;
	border-bottom-right-radius: 10px !important;
}
.date-faq {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height */
	color: rgba(0, 0, 0, 0.8);
}

.avatar-user {
	color: #230b34;
}

.person-color {
	color: var(--secondary-color);
	font-family: DM Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 23px;
}

.hr-size {
	background: #eaeaea;
	transform: matrix(1, 0, 0, -1, 0, 0);
}

.title-list {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 17px;
	line-height: 21px;
	/* identical to box height */
	color: var(--primarytextColor);
}

.sizing-li {
	border-width: 0 0 1px !important;
	width: 60% !important;
}
.login-page-row.admin {
	background: var(--quinaryColor);
	padding: 50px 0;
}
.color-black {
	color: var(--primarytextColor) !important;
	text-decoration: none;
}
.ajout-projet-nav-a.active {
	border-color: none !important;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
	border-color: inherit !important;
	border: none !important;
}

.img-utilisateur {
	border-radius: 50%;
	width: 120px;
	height: 120px;
}
.start-label {
	position: relative;
}
.upload-dropzone-row {
	width: 100% !important;
}
.upload-dropzone-row input.file-input {
	position: absolute;
	top: 5px;
	height: 100px;
	left: 0;
	width: 100%;
	padding: 0;
}
@media (min-width: 768px) {
	.navbar-expand-md .navbar-toggler {
		display: block;
	}
	.navbar-expand-md .navbar-collapse {
		display: block !important;
	}
}

.collapse:not(.show) {
	display: none !important;
}
#navbarNavDropdown .carousel-control-next,
.carousel-control-prev {
	position: inherit !important;
}
.carousel-control-next,
.carousel-control-prev {
	position: inherit !important;
}
.input-financement-projet .bloc-div {
	border: 0.25px solid #959595 !important;
	box-sizing: border-box !important;
	border-radius: 10px !important;
	padding: 1rem !important;
	height: 70px !important;
	font-family: "DM Sans" !important;
	font-style: normal;
	font-weight: bold;
	font-size: 16px !important;
	line-height: 21px !important;
	letter-spacing: -0.025em;
	opacity: 0.7;
	color: var(--quinaryTextColor);
	background: white !important;
	width: 100% !important;
}

.bg-statut-nouveau {
	background: var(--secondary-color);
	text-align: center;
	width: 90%;
	padding: 4px;
	border-radius: 16px;
	font-size: 13px;
	color: white;
	font-weight: 700;
}
.bg-statut-prequalifie {
	background: var(--secondary-color);
	text-align: center;
	width: 90%;
	padding: 4px;
	border-radius: 16px;
	font-size: 13px;
	color: white;
	font-weight: 700;
}
.cv-greening {
	color: var(--secondary-color) !important;
}
.mobile-display {
	display: none;
}
.home-section-container {
	padding: 0 50px;
	margin-bottom: 10%;
}
input[type="checkbox"],
input[type="file"],
input[type="radio"] {
	outline: 5px auto var(--secondary-color);
	outline-offset: -2px;
}

.table-body-container-remboursement table tbody tr > td, .table-body-container-remboursement table thead tr > th {
	line-height: 30px;
}

.table-body-container-remboursement {
	margin: 3rem 0;
	width: 97%;
}

span.caret {
    display: none !important;
}
.modal-backdrop.fade {
	display: none !important;
}

a:hover {
	text-decoration: none !important;
}
.section-content-page-tb-investisseur{
	margin-left: 18% !important;
	margin-top: 3%;
}
.containerTabs{
	margin-top: 7%;
}
.bg-tabs{
	background: var(--primaryColor);
}
.content{
	padding: 2rem 0;
}
.bouton-paraine{
	background-color: red;
}