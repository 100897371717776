@import url('../../../../colors.css');
  .chartCard {
    width: 100%;
    height: calc(50vh - 40px);
    display: flex;
    align-items: center;
    justify-content: start;
  }
  .chartBox {
    width: 100%;
    padding: 15px;
    background: var(--grennColor);
  }
  .chartjs-render-monitor{
    width: 100% !important;
    height: 250px !important;
  }
  .chartjs-size-monitor{
    position: unset !important;
  }
  .diagrammeCirculaire{
    display: flex;
    flex-column: reverse;
  }
  .total{
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    font-size: 60px;
    font-weight: 700;
    text-align: center;
    pointer-events: none;
    background: white;
    color: var(--secondary-color);
    padding: 9px;
    border-radius: 50px;
  }
  .investment-details {
    margin: 20px;
    font-family: Arial, sans-serif;
  }
  .investment-details table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .investment-details th, .investment-details td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .investment-details {
    color: var(--secondary-color);
    font-size: 26.83px;
    font-weight: 800;
  }
  .investment-mois{
    font-size: 18px !important;
  }
  .investmentDetail{
    color : var(--tertiodenaryTextColor);
    font-size: 17.58px;
    font-weight: 600;
  }