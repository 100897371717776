.titrevalidationidentite {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 42px;
  align-items: center;
  letter-spacing: -0.025em;
  color: #010101;
  padding-top: 40px;
  padding-bottom: 4px;
}
.text-identite {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  letter-spacing: -0.025em;

  color: rgba(1, 1, 1, 0.5);
}
.titreverificationidentite {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 42px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.025em;

  color: #010101;
}
.btnnonidentifie {
  padding: 15px;
  background: var(--primaryColor);
  border: 1px solid rgba(254, 24, 24, 0.7);
  box-sizing: border-box;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: rgba(254, 24, 24, 0.7);
}
.id-validation-container.m-5 {
  display: flex;
  align-items: center;
}
.id-validation-btn-status-container {
  width: 30%;
}
.closing {
  padding-right: 15px;
}
.id-validation-status-textual-container p {
  margin: 0;
}
.texte-non-identifie {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 13px;
  text-align: justify;
  letter-spacing: -0.025em;
  width: 20px;
  color: rgba(1, 1, 1, 0.5);
}
.btnvalidation {
  padding: 18px 23px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 10px !important;
  line-height: 21px;
  color: var(--primaryColor);
  padding: 18px 43px;
  background: var(--secondary-color);
  border-radius: 10px;
  border: 1px solid var(--secondary-color);
}
.btnvalidation:hover {
  background: var(--primaryColor);

  color: var(--secondary-color);

  text-decoration: none;
}
.gab {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  letter-spacing: -0.025em;
  padding-left: 20px;
  color: rgba(1, 1, 1, 0.7);
}
.content-identite {
  background: white;
}
