.child-table-container {
	background-color: white;
}

.name-email-group span {
	display: block;
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
}

.name-email-group span.name {
	color: #2d3748;
}

.name-email-group span.email {
	color: #718096;
}

.table-actions-container {
    display: flex;
    align-items: center;
}

.cell-style {
    display: flex ;
    align-items: center;
}
