.black-text {
    color: #000;
  }
.btn-svplus{
  color: #FFFFFF !important;
  font-size: 11px;
  font-weight: 500;
  background-color: #2B7E6E !important;
  border: 1px solid #2B7E6E ;
  padding: 10px;
  border-radius: 8px;
}
.porteur{
  display: flex;
  justify-content: center;
}
.fs-Texte{
  color: #66666699;
  font-size: 14px;
  border: 1px solid #F68B1E;
  border-radius: 10px;
  padding: 7px 14px;
}
.fs-Textee{
  color: #66666699 !important;
  font-weight: 400 !important;
  font-size: 9px !important;
}
.btn-CV{
  font-weight: 400;
  font-size: 10px;
  color: #FFFFFF;
  background: var(--investisseurBackgroundColor);
  border: solid var(--investisseurBackgroundColor);
  padding: 8px 26px;
}
.table-container .table >thead {
  --bs-table-bg: #F3F7FF;
 
}
.react-bs-container-body .table tbody tr td{
  --bs-table-bg-type: transparent !important;
}