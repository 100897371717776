
.component-front-register-infos-perso-seven{
  /* padding: 200px; */
background: var(--primaryColor);
border-radius: 10px;  
}

.champ-texte{

border: 0.25px solid rgba(6, 72, 184, 0.2);

background: var(--primaryColor);
box-sizing: border-box;
}
.style-text-RIB{

font-family: 'DM Sans';
font-style: normal;
font-weight: normal;
font-size: 13px;
line-height: 17px;
width:100%;
color: rgba(0, 0, 0, 0.5);

}


.style-text-RIB2{

font-family: 'DM Sans';
font-style: normal;
font-weight: normal;
font-size: 13px;
line-height: 17px;
color: rgba(0, 0, 0, 0.5);

}

.bloc-div-register{
width: 100%;
display: flex;
justify-content: center;
align-items: center;
}

.main-content{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 80%;
padding: 300px;
background: var(--primaryColor);
box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.07);
}

.row-content{
height: 100vh;
background: var(--quinaryColor);
display: flex;
justify-content: center;
align-items: center;
}


@media only screen and (max-width: 600px) {
.col-space{
  display: none;
}

.main-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 10px;
  /* overflow: scroll; */
  background: var(--primaryColor);
  box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.07);
}

.row-content{
  height: 100vh;
  background: var(--quinaryColor);
  display: flex;
  justify-content: center;
  align-items: center;
}

.bloc-div-register{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
}

  

}

@media only screen and (min-width: 600px) {


}

@media only screen and (min-width: 768px) {
.bloc-div-register{
  width: 170%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -40px;
}
}

@media only screen and (min-width: 992px) {

}

@media only screen and (min-width: 1440px) {
.bloc-div-register{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
}
}
