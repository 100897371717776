body {
    margin-bottom: 24px;
}

label {
    margin-right: 3px;
}
.wj-title {
    display: none;
}
.wj-flexchart .wj-gridline {
    stroke: #EBEDF0 !important;
    
}
.wj-legend {
    position: absolute;
    bottom: 0;
}

.graphProgression{
    margin: auto;
    margin-right: 26px;
    margin-left: 10px;
}