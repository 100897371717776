.investir-banner {
	background-color: var(--tertiareColore) !important;
	margin-bottom: 60px;
}

/* .imgContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}
.imgContainer img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%
} */

.button1 {
	background-color: var(--secondary-color) !important;
	border: 1px solid var(--secondary-color) !important;
	color: var(--primaryColor) !important;
	margin-right: 6px;
}

.button1:hover {
	background-color: var(--tertiareColore) !important;
	border: 1px solid var(--secondary-color) !important;
	color: #000 !important;
	text-decoration: none;
}

.button2 {
	background-color: var(--tertiareColore) !important;
	border: solid 1px var(--secondary-color) !important;
	color: #000 !important;
}
.button2:hover {
	background-color: var(--secondary-color) !important;
	border: solid 1px var(--secondary-color) !important;
	color: var(--primaryColor) !important;
	text-decoration: none;
}

.button-container > div > p {
	padding-right: 335px;
}

.afterBanner > div > h2 {
	text-align: center;
	color: #0f111d;
	font-weight: 700;
	line-height: 44.8px;
	margin: auto;
	padding-bottom: 60px;
}

.caroussel {
	margin-top: 60px;
	height: 355px;
}

.afterCaroussel {
	margin-bottom: 10%;
}

.afterCaroussel > div > h2 {
	color: #000;
	line-height: 35px;
	font-weight: 500;
	text-align: center;
	margin: auto;
	padding: 20px;
}

.afterCarousselCard {
	display: flex;
	margin: auto 5px;
	margin-bottom: 10px;
}

.afterCarousselCard > div {
	margin: auto;
}

.afterCaroussel2 {
	margin-bottom: 120px;
}

.afterCaroussel2 > div > h2 {
	font-size: 32px;
	font-weight: 700;
	line-height: 48px;
	color: #000;
	padding-bottom: 96px;
	margin: auto;
}

.afterCarousselCard2Element {
	box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 43px;
	/* width: 30rem; */
	border-radius: 10px;
	margin: auto;
}

.afterCarousselCard2Element > div img {
	float: left;
	position: absolute;
	border-radius: 50%;
	bottom: 46%;
	left: -3%;
	z-index: 1000;
}

.afterCarousselCard2Element > div {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	position: relative;
}

.afterCarousselCard2Element > div > div h3 {
	font-size: 18px;
	font-weight: 500;
	line-height: 32px;
	color: rgba(0, 0, 0, 1);
}

.afterCarousselCard2Element > div > div h4 {
	font-size: 15px;
	font-weight: 400;
	line-height: 20px;
	color: rgba(0, 0, 0, 0.5);
}

.afterCarousselCard2Element > p {
	font-size: 14px;
	line-height: 15px;
	font-weight: 500;
	color: #000;
	padding: 15px;
	text-align: justify;
}

.afterCarousselCard2 > div:nth-child(1),
.afterCarousselCard2 > div:nth-child(2),
.afterCarousselCard2 > div:nth-child(3) {
	margin-top: 85px;
}
.afterCarousselCard > div:nth-child(1),
.afterCarousselCard > div:nth-child(2),
.afterCarousselCard > div:nth-child(3) {
	margin-top: 2.5rem;
}

.afterCaroussel2 {
	margin-left: 1%;
}

@media (min-width: 320px) {
	.afterCaroussel > div > h2 {
		color: #000;
		line-height: 35px;
	}

	.investir-banner > div > div > p,
	.afterBanner > div > p {
		font-size: 80%;
		font-weight: 700;
		color: #000 !important;
	}
	.afterCarousselCard2Element {
		margin: auto 1rem;
	}
	.bannerPar1 {
		padding-top: 72px;
		padding-bottom: 49px;
		padding-left: 10px;
		white-space: nowrap;
	}

	.button-container {
		display: flex;
		margin-top: 20px;
		font-size: 50%;
		line-height: 32px;
		text-align: center;
		font-weight: 500;
	}

	.button1,
	.button2 {
		padding: 2px 10px;
		border-radius: 4px;
	}

	.afterBanner > div > h2 {
		font-size: 22px;
	}

	.afterCaroussel2 > div > h2 {
		font-size: 1.5rem;
		font-weight: 700;
		line-height: 48px;
	}
}

@media (min-width: 440px) {
	.afterCaroussel > div > h2 {
		color: #000;
		line-height: 35px;
	}
	.afterCarousselCard2Element > div > div h3 {
		font-size: 18px;
		font-weight: 500;
		line-height: 32px;
		color: rgba(0, 0, 0, 1);
	}

	.afterCarousselCard2Element > div > div h4 {
		font-size: 15px;
		font-weight: 400;
		line-height: 20px;
		color: rgba(0, 0, 0, 0.5);
	}

	.afterCarousselCard2Element > p {
		font-size: 14px;
		line-height: 18px;
		font-weight: 500;
		color: #000;
		padding: 15px;
		text-align: justify;
	}

	.afterCarousselCard2 > div:nth-child(1),
	.afterCarousselCard2 > div:nth-child(2),
	.afterCarousselCard2 > div:nth-child(3) {
		margin-top: 85px;
	}

	.afterCarousselCard2Element {
		margin: auto 10%;
	}
	.afterCaroussel2 > div > h2 {
		font-size: 32px;
		font-weight: 700;
		line-height: 48px;
	}
}

@media (min-width: 577px) {
	.investir-banner > div > div > p,
	.afterBanner > div > p {
		font-size: 95%;
		font-weight: 700;
		color: #000 !important;
	}

	.bannerPar1 {
		padding-top: 72px;
		padding-bottom: 49px;
		padding-left: 10px;
		white-space: nowrap;
	}

	.imgContainer {
		background-size: cover;
	}
}

@media (min-width: 768px) {
	.investir-banner > div > div > p,
	.afterBanner > div > p {
		font-size: 18px;
		font-weight: 700;
		color: #000 !important;
	}

	.bannerPar1 {
		padding-top: 72px;
		padding-bottom: 49px;
		padding-left: 55px;
		white-space: nowrap;
	}

	.button-container {
		display: flex;
		margin-top: 35px;
		font-size: 10px;
		line-height: 32px;
		text-align: center;
		font-weight: 500;
	}

	.afterBanner > div > h2 {
		font-size: 28px;
	}

	/* .afterCaroussel > div > h2 {
        font-size: 20px;
        line-height: 38px;
    } */

	.afterCarousselCard > div:nth-child(1),
	.afterCarousselCard > div:nth-child(2) {
		margin-right: -27px;
	}

	.afterCarousselCard2 > div:nth-child(1),
	.afterCarousselCard2 > div:nth-child(2),
	.afterCarousselCard2 > div:nth-child(3) {
		margin-top: 0;
	}

	.afterCarousselCard2 > div:nth-child(1),
	.afterCarousselCard2 > div:nth-child(2) {
		margin-right: 4%;
	}

	.afterCarousselCard2Element {
		margin: auto auto;
	}

	.afterCarousselCard2Element > div > div h3 {
		font-size: 20px;
		font-weight: 500;
		line-height: 32px;
	}

	.afterCarousselCard2Element > div > div h4 {
		font-size: 12px;
		font-weight: 400;
		line-height: 20px;
	}

	.afterCarousselCard2Element > p {
		font-size: 11px;
		line-height: 15px;
		font-weight: 500;
		color: #000;
		padding: 15px;
		text-align: justify;
	}

	.afterCarousselCard2Element > div img {
		float: left;
		position: absolute;
		border-radius: 50%;
		bottom: 50%;
		left: 2%;
		z-index: 1000;
		width: 70px;
		height: 70px;
	}

	.afterCarousselCard2 > div:nth-child(1),
	.afterCarousselCard2 > div:nth-child(2) {
		margin-right: -27px;
		margin: auto;
	}
}

@media (min-width: 1024px) {
	.investir-banner > div > div > p,
	.afterBanner > div > p {
		font-size: 20px;
		font-weight: 700;
		color: #000 !important;
	}

	.bannerPar1 {
		padding-top: 72px;
		padding-bottom: 49px;
		padding-left: 100px;
		white-space: nowrap;
	}

	.button-container {
		display: flex;
		margin-top: 35px;
		font-size: 15px;
		line-height: 32px;
		text-align: center;
		font-weight: 500;
	}

	.afterBanner > div > h2 {
		font-size: 26px;
	}

	/* .afterCaroussel > div > h2 {
        font-size: 28px;
        line-height: 48px;
    } */

	.afterCarousselCard > div:nth-child(1),
	.afterCarousselCard > div:nth-child(2) {
		margin-right: -27px;
	}

	.afterCarousselCard2Element > div > div h3 {
		font-size: 20px;
		font-weight: 500;
		line-height: 32px;
	}

	.afterCarousselCard2Element > div > div h4 {
		font-size: 12px;
		font-weight: 400;
		line-height: 20px;
	}

	.afterCarousselCard2Element > p {
		font-size: 11px;
		line-height: 15px;
		font-weight: 500;
		color: #000;
		padding: 15px;
		text-align: justify;
	}

	.afterCarousselCard2Element > div img {
		float: left;
		position: absolute;
		border-radius: 50%;
		bottom: 46%;
		left: -3%;
		z-index: 1000;
		width: 90px;
		height: 90px;
	}
}

@media (min-width: 1440px) {
	.imgContainer {
		background-size: contain;
	}

	.investir-banner > div > div > p,
	.afterBanner > div > p {
		font-size: 32px;
		font-weight: 700;
		color: #000 !important;
	}

	.bannerPar1 {
		padding-top: 72px;
		padding-bottom: 49px;
		padding-left: 150px;
		white-space: nowrap;
	}

	.button-container {
		display: flex;
		margin-top: 35px;
		font-size: 18px;
		line-height: 32px;
		text-align: center;
		font-weight: 500;
	}

	.button1,
	.button2 {
		padding: 12px 24px;
		border-radius: 4px;
	}

	.afterBanner > div > h2 {
		font-size: 32px;
	}

	/* .afterCaroussel > div > h2 {
        font-size: 28px;
        line-height: 48px;
    } */

	.afterCarousselCard > div:nth-child(1),
	.afterCarousselCard > div:nth-child(2) {
		margin-right: -27px;
	}

	.afterCarousselCard2Element > div > div h3 {
		font-size: 25px;
		font-weight: 500;
		line-height: 37.5px;
	}

	.afterCarousselCard2Element > div > div h4 {
		font-size: 18px;
		font-weight: 400;
		line-height: 24px;
	}

	.afterCarousselCard2Element > p {
		font-size: 14px;
		line-height: 23.82px;
		font-weight: 500;
		color: #000;
		padding: 15px;
		text-align: justify;
	}

	.afterCarousselCard2Element > div img {
		width: 105px;
		height: 105px;
	}
}

.section-container {
	margin-top: 0px;
	margin-bottom: 50px;
}
.qr-card-container {
	padding: 20px;
	background: var(--primaryColor);
	box-shadow: 0px 4px 43px rgba(0, 0, 0, 0.08);
}
.qr-card-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 19px;
	line-height: 28px;
	text-align: center;
	height: 60px;
    margin-left: 0;
    margin-top: 0;
}
.qr-card-textual-container,
.qr-card-textual-container p {
	height: 250px;
	font-family: Poppins;
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 24px;
	text-align: justify;
	color: var(--primarytextColor);
}
.qr-card-btn-container {
	text-align: center;
}
.qr-card-btn {
	background: var(--secondary-color) !important;
	border-radius: 4px !important;
	padding: 15px !important;
	border-color: var(--secondary-color) !important;
	font-family: DM Sans !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 18px !important;
	line-height: 175% !important;
}
.qr-card-col {
	margin-bottom: 30px;
}
.investir-page-couverture-container {
    background: var(--tertiareColore);
}
.investir-couverture-left-side {
	padding: 3% 0;
}
.investir-couverture-textual-container,
.investir-couverture-textual-container p {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 140%;
	color: #0f111d;
	margin-bottom: 22px;
}
.investir-couverture-btn-container {
    display: flex;
    margin-top: 3%;
}
.preference-link-container {
	padding-right: 25px;
}
.preference-link-btn {
	background: var(--secondary-color) !important;
	border-radius: 4px !important;
	border-color: var(--secondary-color) !important;
	padding: 13px !important;
	font-family: DM Sans !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 15px !important;
	line-height: 20px !important;
}
.preference-link-btn:hover {
	background: transparent !important;
	color: var(--secondary-color) !important;
}
.opportunities-link-btn {
	background: transparent !important;
	border-radius: 4px !important;
	border-color: var(--secondary-color) !important;
	padding: 13px !important;
	font-family: DM Sans !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 15px !important;
	line-height: 20px !important;
	color: var(--primarytextColor) !important;
}
.opportunities-link-btn:hover {
	background: var(--secondary-color) !important;
	color: var(--primaryColor) !important;
}
.offer-section-title-container{
	margin:6rem auto;
}
.offer-section-title {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 32px;
	line-height: 44px;
	text-align: center;
	color: var(--secondary-color);
}
.funding-donors-title {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	line-height: 28px;
	text-align: center;
	margin-bottom: 50px;
}
.funding-donors-section-container {
	padding-bottom: 90px;
}

/* Ameliorations */

.investir-offer-section-container {
	margin-top: 3rem;
	margin-bottom: 5rem;
}

.investir-offer-card,
.offer-card-header,
.offer-card-body {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.offer-card-profile-container {
	background-color: var(--tertiareColore);
	width: 100px;
	height: 100px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.offer-card-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 31px;
	text-align: center;
	color: var(--primarytextColor);
	margin-left: 0;
	margin-top: 1.3rem;
	margin-bottom: 1.3rem;
}

.offer-card-text {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 25px;
	text-align: center;
	color: var(--primarytextColor);
}

.offer-card-numero-container {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.offer-card-numero {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 22px;
	line-height: 31px;
	text-align: center;
	color: var(--secondary-color);
}

.offer-card-numero::after {
	content: "";
	display: block;
	height: 2px;
	width: 16px;
	background-color: var(--secondary-color);
	position: absolute;
	bottom: 13px;
}
.offer-card-numero-after-small::after {
	left: -4px;
}

.offer-card-numero-after-medium::after {
	left: -2px;
}

.participate-project-finance-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 36px;
	line-height: 44px;
	text-align: center;
	color: #0f111d;
	text-align: center !important;
	margin-bottom: 5rem;
}
.participate-project-finance-step {
	margin-bottom: 4rem;
}
.participate-project-finance-step-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 24px;
	text-align: center;
	color: var(--primarytextColor);
	margin-left: 0;
}

.participate-project-finance-step-text-container {
	padding-left: 2rem;
}

.participate-project-finance-step-text {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: var(--primarytextColor);
	text-align: justify;
}

.participate-project-finance-button-container {
    margin: 6rem 0;
    display: flex;
    justify-content: center;
}

.participate-project-finance-button  {
    background-color: var(--secondary-color);
	border: 1px solid var(--secondary-color);
    padding: 1.1rem 2.3rem;
    border-radius: 4px;
	font-family: DM Sans;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 175%;
	text-align: center;
	color: var(--primaryColor);
    text-transform: uppercase;
    text-decoration: none;
	transition: all ease 1s;

}

.participate-project-finance-button:hover {
	background-color: var(--primaryColor);
	color: var(--secondary-color);
    text-decoration: none;
}
.funding-donors-row{
	margin: 7rem auto;
}

@media screen and (max-width:767px) {

	.offer-section-title-container{
		margin:5rem auto;
	}
	.offer-section-title, participate-project-finance-title {
		font-size: 26px;
		line-height: 40px;
	}
}
.ccm-section-container {
    background: #F6F8F9;
    padding-top: 3%;
    padding-bottom: 3%;
}
.titre-ccm-investir {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 140%;
    text-align: center;
    color: var(--secondary-color);
}