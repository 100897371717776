.nav-items-customs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-item-vertical-custom {
  width: 100%;
  /* padding: 2rem 0 0 0; */
}

.nav-link-vertival-custom {
	font-family: "Poppins" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 14px !important;
	line-height: 18px !important;
	color: var(--quinaryTextColor);
	width: 100%;
	display: flex !important;
	gap: 10px;
	align-items: center;
	padding: 1rem 5% 1rem 18% !important;
}

.navbar-vertical-container-icon {
  width: 30px !important;
  background-color: var(--primaryColor) ;
  text-align: center;
  height: 30px !important;
  display: flex;
  align-items: center;
  justify-content: center !important;
  font-size: 14px;
  color: var(--quinaryTextColor);
  border-radius: 12px;
}

.text-dashbord {
  color: var(--quinaryTextColor) !important;
}

#scrollbar {
  overflow-y: scroll;
}
#scrollbar::-webkit-scrollbar {
  width: 6px;
}
#scrollbar::-webkit-scrollbar-track {
	background: var(--primaryColor);
}
#scrollbar::-webkit-scrollbar-thumb {
	background-color: var(--secondary-color);
	border-radius: 20px;
	border: 3px solid transparent;
}
.contents {
  height: 0px !important;
}

.nav-items-customs .active, .nav-items-customs .active .navbar-vertical-container-icon, .nav-items-customs .active .text-dashbord{
  color: var(--primaryColor)   !important;
  background-color: var(--secondary-color) !important;
}
