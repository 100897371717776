.redirection-project {
    font-family: Poppins;
    font-style: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: gray !important;
    padding-top: 14px !important;
    padding-bottom: 12px !important;
}

.redirection-project:hover {
    font-weight: 700 !important;
}