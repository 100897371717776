.soumettre-projet h1 {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 30px;
	line-height: 75px;
	text-align: center;
	letter-spacing: -0.025em;
	color: var(--primarytextColor);
}

.se-financer {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.formulaire-de-depot-dossier {
	padding: 4rem 0;
}

.soumettre-projet input[type="radio"] {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	border: 1px solid var(--secondary-color) !important;
	box-sizing: border-box;
	outline: none;
	background-color: var(--primaryColor);
	padding: 5px !important;
}

.soumettre-projet input[type="radio"]:checked {
	background-color: var(--secondary-color) !important;
}

.soumettre-projet small {
	margin-top: 5px;
}

.soumettre-projet input[type="text"],
.soumettre-projet input[type="url"],
.soumettre-projet input[type="date"],
.soumettre-projet input[type="email"],
.soumettre-projet input[type="tel"],
.soumettre-projet input[type="number"] {
	width: 100%;
	height: 55px;
	border: 0.25px solid #959595;
	box-sizing: border-box;
	filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
	border-radius: 10px;
	color: var(--primarytextColor);
	padding-left: 5%;
}

.soumettre-projet input[type="text"]::placeholder,
.soumettre-projet input[type="url"]::placeholder {
	font-family: Poppins;
	font-style: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.025em;
	color: rgba(0, 0, 0, 0.5);
}

.soumettre-projet h5 {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 23px;
	letter-spacing: -0.025em;
	color: rgba(0, 0, 0, 0.7);
}

.soumettre-projet label {
	font-family: DM Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 23px;
	letter-spacing: -0.025em;
	color: rgba(0, 0, 0, 0.7);
}

.soumettre-projet textarea {
	width: 100%;
	height: 150px;
	min-height: 159px;
	max-height: 159px;
	border: 0.5px solid #959595;
	box-sizing: border-box;
	filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.08));
	border-radius: 10px;
	outline: none;
	padding-left: 2%;
}

.soumettre-projet small {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 15px;
	letter-spacing: -0.025em;
	color: rgba(0, 0, 0, 0.7);
}

.soumettre-projet h3{
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 27px;
	letter-spacing: -0.025em;
	color: var(--primarytextColor);
}
.label-title {
	font-family: Poppins !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 18px !important;
	line-height: 27px !important;
	letter-spacing: -0.025em !important;
	color: var(--primarytextColor) !important;
}

.soumettre-projet .form-textarea-group {
	margin-bottom: 4rem;
}

.button-suivant,
.button-soumettre {
	padding: 12px 40px;
	filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.05));
	border-radius: 10px;
	background: var(--secondary-color);
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	line-height: 19px;
	text-align: center;
	color: var(--primaryColor);
	text-transform: uppercase;
}

.button-precedent {
	font-family: DM Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	line-height: 19px;
	text-align: center;
	color: var(--secondary-color);
	background-color: var(--primaryColor);
	outline: none;
	margin-right: 2rem;
	text-transform: uppercase;
}

.modal-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 36px;
	line-height: 54px;
	text-align: center;
	color: var(--primarytextColor);
}

.button-mettre-en-ligne {
	width: 152px;
	height: 57px;
	background: var(--secondary-color);
	border-radius: 10px;
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: var(--primaryColor);
	flex: none;
	order: 0;
	flex-grow: 0;
	margin: 0px 10px;
}

.button-date-lancement {
	width: 295px;
	height: 57px;
	background: var(--primaryColor);
	border: 1px solid var(--secondary-color);
	box-sizing: border-box;
	border-radius: 10px;
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: var(--secondary-color) !important;
	flex: none;
	order: 0;
	flex-grow: 0;
	margin: 0px 10px;
}

.form-group-container {
	margin-bottom: 5rem;
}

.form-title {
	margin-bottom: 3rem;
}

.modal-de-soumission-container {
	width: 656px !important;
	height: 527px;
}



.calendrier {
	width: 100%;
}

.calendrier h2 {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.085em;
	text-transform: uppercase;
	color: rgba(0, 0, 0, 0.7);
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding-bottom: 1rem;
	text-align: center;
}
.calendrier .date-selectionne {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding: 1rem 0;
	margin-bottom: 2rem;
}

.calendrier p {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	color: rgba(0, 0, 0, 0.7);
	text-align: center !important;
	margin: 0;
}

.day {
	font-size: 25px;
	line-height: 28px;
}

.calendrier-de-react-datepicker {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding: 0;
	display: flex;
	justify-content: center;
	margin-bottom: 5rem;
}
.calendrier-de-react-datepicker input {
	border: 0 !important;
	display: inline-flex !important;
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 18px;
	color: rgba(0, 0, 0, 0.5);
	margin: 0;
}

.heures-lancement-container {
	padding: 3%;
}

.heures-lancement-container h3 {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 17px;
	color: rgba(0, 0, 0, 0.7);
}

.heures-lancement {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 5rem;
}

.heure-lancement-item {
	background: #cccccc;
	border: 0.5px solid #737272;
	box-sizing: border-box;
	border-radius: 20px;
	width: 58px;
	height: 35px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	color: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 1rem;
	margin-right: 1rem;
	cursor: pointer;
}

.heures-lancement-active {
	background: var(--secondary-color);
	color: var(--primaryColor);
}

.button-container {
	display: flex;
	justify-content: flex-end;
	padding: 0 25%;
}

.button-enregistrer {
	padding: 1rem;
	background: var(--secondary-color);
	border-radius: 10px;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: var(--primaryColor);
}

@media screen and (max-width:1100px) {
	/* .calendrier-container {
		padding: 0 35%;
	} */

	.button-container {
		padding: 0 35%;
	}

	/* .heures-lancement-container {
		padding: 0 30%;
	} */
}

@media screen and (max-width:767px) {
	/* .calendrier-container {
		padding: 0 25%;
	} */

	.button-container {
		padding: 0 20%;
	}

	/* .heures-lancement-container {
		padding: 0 15%;
	} */
}
