.block-mot-de-passe{
	background-color: var(--primaryColor);
	padding: 0 2rem;
}
.button-mot-de-passe,
.button-mot-de-passe:hover,
.button-mot-de-passe:focus {
	padding: 20px;
	background: var(--secondary-color);
	border-radius: 10px;
	margin-top: 3rem;
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: var(--primaryColor);
	text-transform: uppercase;
}

.form-label-custom {
    border-bottom: 1px solid #E0E0E0;
	margin-top: 3rem;
}

.form-label-custom label {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 19px;
	text-align: center;
	color: rgba(0, 0, 0, 0.6);
}

