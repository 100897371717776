@import url("../../../../colors.css");

.search-box{
    width: 100%;
    position: relative;
    display: flex;
    background: var(--quaternary-color);
  }
  .search-input{
    width: 100%;
    padding: 10px;
    border-radius:10px 0 0 10px ;
    border-right: none;
    outline: none;
    font-size: 20px;
    color: var(--primarytextColor);
    background: none;
    border: none;
  }
  .search-button{
   text-align: center;
  height: 51px;
  width: 40px;
  outline: none;
  cursor: pointer;
   border-radius: 0 10px 10px 0 ;
  border-left: none;
  background: none;
  font-size: 20px;
  
  
  }
  .form-control-transaction {
    background: var(--quaternary-color) !important;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 18px !important;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: -0.025em;
    color: var(--nonaryTextColor);
    border: none !important;
    height: 50px !important;

  }
  .bloc-transaction {
    background: var(--primaryColor);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 60px 20px;
  }
  .text-ou-transaction {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 25px;
    letter-spacing: -0.025em;
    color: var(--primarytextColor);
  }
  .text-gray-transaction {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.025em;
    color: var(--nonaryTextColor);
  }
  .bloc-transaction-flex {
      display: flex;
      justify-content: space-between;
      padding-top: 1rem;
      padding-bottom: 1rem;
  }