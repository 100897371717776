.graphText {
    display: flex;
    justify-content: space-between;
}

.graphPara {
    margin-left: 10px;
}

.graphPara > p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;

    color: var(--primarytextColor);
}

.mes-projets {
    padding-top: 86px;
    padding-bottom: 100px;

}

.selection-progression {
    display: flex;
    justify-content: flex-end;
}

.selection-progression > select {
    height: 60px;
}

