@import url("../../../../colors.css");

.profile-container {
  background-color: var(--quinaryColor);
  padding: 2rem;
}
.bloc-principal-body-content-profile {
  width: calc(100% - 17%);
}

.profile-container-photo {
  background-color: var(--primaryColor);
  padding: 1rem 2rem;
  border-radius: 10px;
}
.text-edit {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #ababab;
  margin-left: 15px;
}
.icone-edit-profile {
  margin-right: 80px;
}
.profile-container .mon-profil {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: #2d3748;
}
.profile-container .information-personnelle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  text-align: right;
  letter-spacing: -0.025em;
  color: var(--secondary-color);
}
.text-jones {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  text-align: right;
  letter-spacing: -0.025em;
  color: var(--decimalTextColor);
}
.text-admin {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  text-align: right;
  letter-spacing: -0.025em;
  color: var(--decimalTextColor);
  text-align: center;
}
.bloc-principal-body-content-administrateur {
  width: calc(100% - 17%) !important;
  padding-top: 3.5rem;
}
/* --------------les input dans component profile------------------ */
.form-control-profil {
  position: relative;
  display: block;
  width: 100%;
  height: 50px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: var(--quinaryTextColor);
  background-color: var(--primaryColor);
  background-image: none;
  background: var(--primaryColor);
  border: 1px solid var(--quinaryTextColor);
  box-sizing: border-box;
  border-radius: 10px;
}

.form-input-profile {
  padding-top: 20px;
}
.container-input-address {
  padding-top: 50px;
}
/* .text-ajouter-admin {
  background: var(--primaryColor);
  border: 1px solid var(--quinaryTextColor);
  width: 13%;
  border-radius: 2px;
  margin-left: 85%;
} */

.form-label-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--decimalTextColor);
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 6px 0px;
}
.infos-perso-input {
  margin-bottom: 3.5rem !important;
}

/* ------------les buttons---------------- */
.btn-parametre-admin {
  background: var(--primaryColor) !important;
  border: 1px solid var(--quinaryTextColor) !important;
  box-sizing: border-box !important;
  border-radius: 2px !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #ababab !important;
}

.btn-parametre-icon {
  color: var(--secondary-color);
  width: 23px !important;
  height: 23px !important;
  margin-right: 5px;
}

.btn-modifier {
  width: 92px;
  height: 38px;
  background: var(--secondary-color);
  border-radius: 4px;
  color: var(--primaryColor);
}

/* .icone-avatar {
  background: var(--primaryColor);
  border: 1px solid var(--quinaryTextColor);
  border-radius: 2px;
  height: 40px;
  width: 10%;
} */

/* ---------------les modal style------------------ */

.modal-ajouter {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #2d3748;
}

/* -----------responsive------------- */

@media (max-width: 990px) {
  .bloc-principal-body-content-profile {
    width: 100%;
    padding-top: 1rem !important;
  }

  .bloc-principal-body-content-administrateur {
    width: 100%;
    padding-top: 4.5rem !important;
  }
}
