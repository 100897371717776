

.champ-texte-tel{
box-sizing: border-box;
margin-top: 30px;
background: var(--primaryColor);
border: 0.25px solid rgba(6, 72, 184, 0.5);
width: 437px;
height: auto;
font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 13px;
line-height: 19px;
color: rgba(0, 0, 0, 0.5);
}



.color-sms{
color: var(--primarytextColor);
}

.main-content{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 80%;
padding: 0px;
background: var(--primaryColor);
box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.07);
}

.row-content{
height: 100vh;
background: var(--quinaryColor);
display: flex;
justify-content: center;
align-items: center;
}

.title-inscription{
font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 25px;
line-height: 42px;
color: var(--primarytextColor);
}



@media only screen and (max-width: 600px) {
.champ-texte-tel{
  width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
.champ-texte-tel{
  width: 100%;
  height: auto;
  
  }
}
