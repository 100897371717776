/* @import url('../../../colors.css'); */
.component-front-home {
	background-color: var(--primaryColor) !important;
	box-sizing: border-box;
}
.content-view-start {
	position: relative;
}
.ips {
	position: absolute;
	left: 300px;
	height: 500px;
	top: 300px;
	z-index: 199;
}
.hauteurCarousel{
	height: 55rem;
}
.rec.rec-arrow.rec.rec-arrow-right, .rec.rec-arrow.rec.rec-arrow-left{
	display: none;
}
.marginhome {
	margin-bottom: 150px;
}
.titlepatrimoine {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 37px;
	line-height: 55px;
	color: var(--primarytextColor);
}
.texte-patrimoine {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 32px;
	line-height: 140%;
	color: var(--secondaryTextColor);
}
.titre-image-illustration {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 37px;
	line-height: 55px;
	color: var(--primarytextColor);
}
.texte-image-illustration {
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 27px;
	letter-spacing: -0.025em;
	color: var(--quinaryTextColor);
}
.imgpatrimoine {
	margin-top: -110px;
}
.bloc-div-img-right {
	margin-top: -11%;
}
.topimg {
	position: absolute;
	right: 51px;
	top: -34px;
	width: 100px;
	background: var(--primaryColor);
	box-shadow: 0px 13px 34px var(--secondary-color);
	border-radius: 20px;
	padding: 30px;
}

.topimg2 {
	position: absolute;
	left: 55px;
	top: -48px;
	width: 100px;
	background: var(--primaryColor);
	box-shadow: 0px 13px 34px var(--secondary-color);
	border-radius: 20px;
	padding: 30px;
}
.newleter {
	background: var(--tertiaryColor);
	padding-top: 2rem;
	padding-bottom: 2rem;
}
.imgnewleter {
	/* opacity: 0.2; */
	padding: 15px 0;
}
.titrenewleter {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 33.203px;
	line-height: 44px;
	color: var(--quaternaryTextColor);
}
/* .texte-newleter {
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 21.25px;
	line-height: 28px;
	color: #141b2d;
} */

.inputnewleter {
	background: var(--primaryColor);
	border: 1px solid var(--primaryBorderColor);
	box-sizing: border-box;
	box-shadow: inset 0px 1px 5px var(--primaryBoxShadowColor);
	border-radius: 4px;
	height: 48px;
}
.btn-newleter {
	border: 1px solid var(--secondary-color) !important;
	box-sizing: border-box;
	border-radius: 4px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 29px;
	color: var(--secondary-color);
}
.titrepartenaire {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	letter-spacing: 0.505em;
	color: var(--secondary-color);
}
.textepartenaire {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 32px;
	line-height: 21px;
	color: var(--secondaryTextColor);
}
.txtsurlignepartenaire {
	color: var(--secondary-color);
}
.imgpartenaire {
	width: 100%;
	padding: 50px;
}
/* .imgpartenaireparent {
	background: var(--primaryColor);
	box-shadow: 0px 0px 0px 1px var(--tertiaryBoxShadowColor);
	border-radius: 5px;
	margin-bottom: 60px;
	box-sizing: border-box;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
} */

.titrefaq {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 32px;
	line-height: 140%;
	margin: auto;
	color: var(--secondaryTextColor);
	width: 100px;
}
/* .titrefaqquestion {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 36px;
	letter-spacing: -0.025em;
	color: #010101;
} */
/* .faqtexte {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.025em;
	color: #2d2d2d;
} */
/* .faqtextecontent {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.025em;
	text-align: justify;
	color: #2d2d2d;
} */

.home-section-title {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 44px;
	line-height: 140%;
	text-align: center;
	color: var(--secondary-color);
}
.dev-textual-title {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 30px;
	color: var(--primarytextColor);
	margin-left: 0 !important;
}

.dev-textual-content {
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 27px;
	text-align: justify;
	color: var(--quinaryTextColor);
}
.home-section-row {
	display: flex !important;
	align-items: center !important;
}

.dev-illustration {
	position: relative;
}
.dev-image {
	box-shadow: 0px 8px 104px var(--secondaryBoxShadowColor);
	border-radius: 20px;
}
.dev-image1 {
	position: absolute;
	left: 0;
	top: 0;
}
.dev-image2 {
	position: absolute;
	top: -113px;
	left: -8%;
}
.illustration-icon {
	position: absolute;
	top: -30px;
	right: -8%;
	width: 15%;
	background: var(--primaryColor);
	box-shadow: 0px 13px 34px var(--primaryIconColor);
	border-radius: 20px;
	padding: 13px;
}
.illustration-icon-lefty {
	position: absolute;
	left: -8%;
	top: -40px;
	width: 15%;
	background: var(--primaryColor);
	box-shadow: 0px 13px 34px var(--primaryIconColor);
	border-radius: 20px;
	padding: 13px;
}
.newsletter-section-container {
	background: var(--tertiaryColor);
	padding: 40px 100px;
	margin-top: 8%;
	margin-bottom: 4%;
}
.newsletter-row {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}
.newsletter-input-label {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 33.203px;
	line-height: 44px;
	color: var(--quaternaryTextColor);
	margin: 8px 0;
}

.newsletter-textual-body {
	margin-bottom: 25px;
}
.newsletter-form-body {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.newsletter-input-group {
	width: 50%;
	padding-right: 10px;
}
.newsletter-input-form-control {
	background: var(--primaryColor) !important;
	border: 1px solid var(--primaryBorderColor) !important;
	box-sizing: border-box;
	box-shadow: inset 0px 1px 5px var(--primaryBoxShadowColor) !important;
	border-radius: 4px !important;
	padding: 20px 15px !important;
	font-family: Poppins !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 16px !important;
	line-height: 27px !important;
	color: var(--senaryTextColor) !important;
	height: 48px !important;
}
.newsletter-submit-btn {
	padding: 9px 20px !important;
	border-color: var(--secondary-color) !important;
	box-sizing: border-box !important;
	border-radius: 4px !important;
	background: transparent !important;
	color: var(--secondary-color) !important;
	font-family: Poppins !important;
	font-style: normal !important;
	font-weight: bold !important;
	font-size: 16px !important;
	line-height: 24px !important;
}
.newsletter-submit-btn:hover {
	background: var(--secondary-color) !important;
	color: var(--primaryColor) !important;
}
.newsletter-icon {
	width: 80%;
}
.partenaire-section-description {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 140%;
}
.partenaire-section-container {
	padding-top: 5%;
}
.partenaire-card-content {
	margin-bottom: 30px;
}
.partenaire-logo-container {
	background: var(--primaryColor);
	box-shadow: 0px 0px 0px 1px var(--tertiaryBoxShadowColor);
	border-radius: 5px;
	padding: 25px;
	text-align: center;
}
.partenaire-logo {
	height: 40px;
}
.partenaire-logo-col {
	margin-bottom: 40px;
}
/* .faq-section-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 32px;
	line-height: 140%;
	text-align: center;
} */
/* .faq-subtitle {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 36px;
	display: flex;
	align-items: center;
	letter-spacing: -0.025em;
	color: #010101;
} */
.faq-section-container {
	padding-bottom: 100px;
}
/* .faq-item-title {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.025em;
	color: #2d2d2d;
} */
.container.faq-section-container p {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 300;
	font-size: 12px;
	line-height: 22px;
	text-align: justify;
}
/* .faq-action-btn-container {
	text-align: right;
} */
/* .faq-link {
	background: var(--secondary-color) !important;
	border-radius: 10px !important;
	font-family: Poppins !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 20px !important;
	line-height: 30px !important;
	color: var(--primaryColor) !important;
	padding: 10px 45px !important;
	border-color: var(--secondary-color) !important;
} */
/* .faq-link:hover {
	background: transparent !important;
	color: var(--secondary-color) !important;
} */
.section-a-propos-home {
	padding-top: 4%;
	padding-bottom: 4%;
}
/* .text-faq-accueil {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.025em;
	color: #2d2d2d;
} */

/************ Home-project-section ************/

.sous-titre-ccm {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 23px;
    line-height: 34px;
    letter-spacing: -0.025em;
    color: var(--secondary-color);
}
.text-num-ccm {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.025em;
    text-transform: uppercase;
    color: var(--secondary-color);

}
.texte-ccm-home {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 140%;
    color: var(--secondaryTextColor);

}
.btn-en-savoir-plus {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: var(--secondary-color);

}
.btn-en-savoir-plus:hover {
    text-decoration: none;
    color: var(--secondary-color);
    font-weight: bold;
}
.disabled-btn{
	cursor: not-allowed !important;
}
.textEnregistrer{
	font-weight: 700;
	font-size: 29.06px;
	text-align: center;
	color: var(--quaternaryTextColor);
	padding: 0 0 3% 0;
}
.raise-money-container-home{
	background: var(--tertiaryColor);
}
.rec-item-wrapper{
	height: 387px;
    padding: 20px !important;
}
.containerTitreCarousel{
	color: var(--quaternaryTextColor);
	font-weight: 700;
	font-size: 14px;
}
.containerDescCarousel{
	color: var(--quaternaryTextColor);
	font-weight: 400;
	font-size: 10px;
}
