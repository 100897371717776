@import url("../../../../colors.css");

.projets-container {
	padding: 0 0rem;
    min-height: 100vh;
}

.liste-projets {
	background-color: var(--primaryColor);
	padding: 1rem 2rem;
	border-radius: 10px;
}

.projets-table {
	margin-top: 3rem;
}

.projets-table .menu .active{
	background-color: var(--secondary-color);
	color: var(--primaryColor) !important;
	border-radius: 10px;
	border-bottom: 2px solid var(--secondary-color) !important;
}

.liste-projets-bar {
	display: flex;
	justify-content: space-between;
	padding-right: 1rem;
	align-items: center;
}

.liste-projets-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 25px;
	color: #2d3748;
	margin: 0;
}

.liste-projets-statistiques {
	margin-top: 10px;
}

.icon-check-container {
	background: var(--secondary-color);
	color: var(--primaryColor);
	margin-right: 5px;
	border-radius: 50%;
	padding: 1px 0.3px;
	font-weight: 500;
}

.number-project-text {
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 140%;
	color: #a0aec0;
}

.ajouter-projet-link {
	display: flex;
	align-items: center;
	background-color: var(--secondary-color);
	
}

.ajouter-projet-link-icon {
	color: var(--primaryColor);
	/* margin-right: 5px; */
}

.ajouter-projet-link-text,
.ajouter-projet-link:hover {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 16px;
	color: var(--primaryColor);
	text-decoration: none;
}

/************* Add Project Style *****************/

.add-projet-container {
	background-color: var(--quinaryColor);
	padding: 3rem;
}

.add-projet-content,
.detail-projet-content {
	background-color: var(--primaryColor);
	padding: 1.5rem 4rem;
	border-radius: 10px;
}

.add-project-info-icon-title-container {
	display: flex;
	gap: 10px;
}

.add-project-info-stepper {
	display: flex;
	align-items: center;
	margin-bottom: 4rem;
}

.add-project-info {
	display: flex;
	gap: 8px;
}

.add-project-icon {
	color: var(--decimalTextColor);
	line-height: 20px;
	cursor: pointer !important;
	display: inline-block;
}

.add-project-general-title {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 25px;
	color: #2d3748;
	margin: 0;
}

.add-project-component-title {
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 25px;
	color: var(--secondary-color);
	margin: 0;
}

.add-project-stepper {
	display: flex;
	align-items: center;
	gap: 10px;
	justify-content: flex-end;
}

.stepper {
	height: 3px;
	min-width: 60px;
	border-radius: 25%;
	background-color: #c4c4c4;
}

.stepper-active {
	background-color: var(--secondary-color);
}

/******************** Details de base  ************************************************************/
.form-add-project-group {
	margin-bottom: 4rem;
	width: 100%;
}

.form-add-project-label {
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
	color: #2f3a4a;
}

.form-add-project-input {
	background: var( --primaryColor);
	border: 1px solid #e8e8e8;
	box-sizing: border-box;
	border-radius: 2px;
	height: 46px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #2f3a4a;
	width: 90%;
}

.form-add-project-input::placeholder {
	color: #ababab;
}

.form-add-project-input-url {
	width: 95%;
}

.form-group-add-project-dropzone-container {
	width: 95%;
}

.form-add-project-buttons-container {
	display: flex;
	justify-content: flex-end;
	width: 97.2%;
	padding-top: 2rem;
}

.form-add-project-button-next {
	background: var(--secondary-color);
	border-radius: 4px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 22px;
	text-align: center;
	color: var(--primaryColor);
	outline: none;
	padding: 0.9rem 2rem;
}

.form-add-project-button-previous {
	background: transparent;
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 22px;
	text-align: center;
	color: var(--secondary-color);
	margin-right: 2rem;
}
/***########### Input choose file Style ###################***/

.start-label-input-choose-file {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.025em;
	width: 100% !important;
}

.first_label-input-choose-file {
	width: 100% !important;
	display: block;
	color: var(--secondary-color);
	margin-top: 10px;
	margin-bottom: 3px;
}

.second-label-input-choose-file {
	color: #000;
	opacity: 0.5;
}

/***################ Details de base second Step***************/
.form-add-project-check-input-container {
	width: 90%;
	height: 46px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid #e8e8e8;
	box-sizing: border-box;
	padding: 0 1rem;
}
.form-add-project-group-relative {
	position: relative;
}

.form-add-project-unity {
	display: inline-block;
	width: 60px;
	position: absolute;
	line-height: 35px;
	top: 3.5rem;
	right: 15%;
	color: #ababab;
	border-left: 1px solid #e8e8e8;
	padding-left: 7px;
	text-align: center;
	font-size: 12px;
}

.form-add-project-check-input-group {
	display: flex;
	align-items: center !important;
	gap: 5px;
}

.form-add-project-check-input-label {
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	color: #ababab;
	margin: 0 !important;
}

.form-add-project-check-input {
	margin: 0 !important;
	outline: none !important;
	border: 2px solid var(--secondary-color);
	border-radius: 5px;
}

/* *################## Presentation de projet step 3 ###################*/
.form-add-project-input-textarea {
	height: 130px;
	min-height: 130px;
	max-height: 130px;
	outline: none;
	padding: 5px 10px;
	width: 95%;
}
.form-add-project-ckeditor {
	width: 96%;
}

/* *******Media Querie */
@media screen and (max-width: 767px) {
	.add-project-stepper {
		justify-content: center;
		margin-top: 20px;
	}
}

@media screen and (max-width: 497px) {
	.add-project-info {
		flex-direction: column;
	}
	.add-project-component-title {
		text-align: center;
	}

	.stepper {
		min-width: 30px;
	}

	.form-add-project-input {
		font-size: 10px;
		line-height: 20px;
		width: 100%;
		padding-left: 4px;
	}
	.form-add-project-check-input-container {
		width: 100%;
		flex-direction: column;
		align-items: flex-start;
		height: 90px;
		padding: 5px;
	}

	.form-add-project-unity {
		width: 42px;
		font-size: 8px;
	}

	.form-add-project-label {
		font-size: 10px;
	}
	.form-add-project-group {
		padding: 0 !important;
	}
	.table-body-container-remboursement,
	.form-add-project-input-textarea {
		width: 100% !important;
	}

	.form-add-project-ckeditor {
		width: 100% !important;
		padding-right: 0;
	}
}

/**################### 	Detail-projet ############################*/
.detail-projet-info-actions {
	align-items: center;
}

.detail-projet-icon-title-container {
	display: flex;
	align-items: center;
}

.detail-projet-name-projet {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 27px;
	letter-spacing: -0.025em;
	color: var(--decimalTextColor);
}

.detail-projet-text {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 17px;
	letter-spacing: -0.025em;
	color: #737373;
}

.detail-projet-actions {
	display: flex;
	justify-content: flex-end;
}

.detail-projet-text-container {
	padding-left: 2.5rem;
}

.link-detail-projet {
	color: var(--secondary-color);
}

.detail-projet-info-label {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 27px;
	color: var(--secondary-color);
}

.detail-projet-info-val {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 27px;
	color: var(--secondary-color);
}

.detail-projet-resume-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: var(--secondary-color);
}

.detail-projet-resume-progress-text {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.detail-projet-resume-percentage {
	font-family: DM Sans;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 17px;
	color: var(--quinaryTextColor);
}

.detail-projet-resume-solde {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	line-height: 19px;
	color: var(--secondary-color);
}
.detail-projet-resume-objectif-text {
	font-family: DM Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	color: var(--quinaryTextColor);
}

.detail-projet-resume-info-porteur-projet {
	display: flex;
	align-items: center;
	gap: 8px;
}

.detail-projet-resume-avatar-porteur-projet {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	margin: 0;
}

.detail-projet-resume-name-porteur-projet {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 27px;
	letter-spacing: -0.025em;
	color: var(--decimalTextColor);
}

.detail-projet-resume-cat-porteur-projet {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: -0.025em;
	color: var(--decimalTextColor);
}

.detail-projets-tabs-container {
	margin-top: 3rem;
	border-bottom: 1px solid #eaeaea;
	padding-bottom: 0.4rem;
}

.detail-projets-tabs {
	display: flex;
	align-items: center;
	gap: 12px;
}

.detail-projets-tabs-item {
	list-style: none;
	font-family: "DM Sans";
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 21px;
	color: var(--quinaryTextColor);
	cursor: pointer;
	padding: 0 1rem;
}

.detail-projet-desc-content{
	background: var( --primaryColor);
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
	border-radius: 10px;
	max-width: 160%;	  
}

.description .card-header{
	background: transparent;
	cursor: pointer;
}

.description svg{
	font-size: 40px !important;
	cursor: pointer;
}

.detail-projets-tabs-item.active-tab {
	background: var(--secondary-color);
	border-radius: 10px;
	color: var( --primaryColor);
	font-weight: bold;
	padding: 1rem;
}

.detail-projet-title-desc {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 42px;
	display: flex;
	align-items: center;
	letter-spacing: -0.025em;
	color: var(--secondary-color);
}

.detail-projet-desc-text {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	line-height: 25px;
	letter-spacing: -0.025em;
	color: var(--secondaryTextColor);
	text-align: justify;
}

.detail-projet-desc-objectif {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 27px;
	letter-spacing: -0.025em;
	color: var(--secondary-color);
}

.detail-projet-desc-info-val {
	color: var(--secondary-color);
	margin-left: 5px;
}

/** Detail Projet Donnees financieres */
.detail-projet-finance-data-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 25px;
	color: var(--secondary-color);
	padding-bottom: 1.3rem;
	border-bottom: 1px solid #eaeaea;
}

.detail-projet-finance-data-donneur-container {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding-right: 10%;
	align-items: center;
	padding: 1rem;
	border-bottom: 1px solid #eaeaea;
}

.detail-projet-finance-data-donneur-avatar-info {
	display: flex;
	align-items: center;
	gap: 10px;
}

.detail-projet-finance-data-donneur-image {
	width: 55px;
	height: 55px;
	border-radius: 50%;
}
.detail-projet-finance-data-donneur-name {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 17px;
	line-height: 20px;
	letter-spacing: 0.2px;
	color: #171d33;
	margin: 0;
}

.detail-projet-finance-data-donneur-date-donnation {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 20px;
	letter-spacing: 0.2px;
	color: rgba(0, 0, 0, 0.7);
	margin: 0;
}

.detail-projet-resume{
	background: var( --primaryColor);
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
	border-radius: 10px;
	max-width: 160%;
}

.detail-projet-finance-data-donneur-montant-donnation {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 17px;
	line-height: 20px;
	letter-spacing: 0.2px;
	color: #171d33;
	margin: 0;
}

/* * Detail projet document */

.link-add-doc-button {
	background: var( --primaryColor);
	border: 1px solid #e8e8e8;
	box-sizing: border-box;
	border-radius: 2px;
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #ababab;
	padding: 1rem;
}
.quest-pp-block{
	margin-bottom: 15px;
}