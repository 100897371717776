@import url("../../../colors.css");

.editprofilebackground {
	height: auto;
}
.containeredite {
	background: white !important;
}
.component-mes-projet-tableau-de-bord {
	background: var(--quinaryColor) !important;
}
.imgedit {
	display: flex;
	width: 180px;
	height: 180px;
	background: white;
}
.imgeditcontent {
	display: flex;
	justify-content: center;
	margin-bottom: 50px;
	background: white;
}
.inputformedit {
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 10px;
	text-align: start;
	color: var( --quaternaryTextColor);
	border-bottom: 1px solid #e0e0e0;
	border-left: none;
	border-top: none;
	border-right: none;
	background: none;
}
.choix {
	width: 46px;
	height: 40px;
	background: #f3f8fe;
	border: 1px solid var(--secondary-color);
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 19px;
	text-align: center;
}

.btnformedits {
	border: 1px solid var(--secondary-color);
	background-color: var(--secondary-color);
	border-radius: 10px;
	color: var(--primaryColor);
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 21px;
	padding: 1rem 2rem;
}
.btnformedits:hover {
	background: var(--primaryColor);
	color: var(--secondary-color);
}
.civilite {
	margin-top: 16px !important;
	margin-right: 3rem !important;
}
.civilite-btn-radio {
	opacity: 0;
	position: absolute;
	width: 100%;
	padding: 20px !important;
	left: -4%;
	bottom: 0;
}
/*  */
.civilite-btn-radio-container {
	display: flex;
	width: 50%;
}
.civilite-btn-radio-item {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 15px;
	background: #f3f8fe;
	width: 50px;
	border: 1px solid var(--secondary-color);
	box-sizing: border-box;
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 19px;
	color: var(--secondary-color);
	position: relative;
}
.civilite-btn-radio-item label,
.civilite-btn-radio-item input {
	margin: 0;
	font-weight: 400 !important;
}
.civilite-btn-radio-item.checked-civilite {
	background: var(--secondary-color) !important;
	color: var(--primaryColor);
}
.user-avatar-img-container {
	margin: 30px 0;
	position: relative;
}
.edit-avatar-btn {
	left: 0;
	top: 0;
	opacity: 0;
	position: absolute;
	opacity: 0;
	width: 100%;
	height: 100%;
}
.user-avatar-label {
	font-family: Poppins;
	font-size: 10px;
	font-style: normal;
	font-weight: 500;
	line-height: 23px;
	color: var(--primaryColor);
	z-index: 999;
	position: absolute;
	top: 45%;
	left: 25%;
}
.overload {
	position: absolute;
	top: 0;
	width: 100%;
	text-align: center;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
	border: 1px dashed var(--secondary-color);
	opacity: 0;
}
.user-avatar-img-container:hover .overload {
	opacity: 1;
}
.user-avatar-img  {
	width: 200px;
	height: 200px;
}
.edit-avatar-btn:hover {
	text-decoration: none !important;
}

.paragraph-mail-info {
	font-family: DM Sans;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 17px;
	letter-spacing: 0px;
	text-align: left;
	color: var(--primarytextColor);
	opacity: 0.5;
}
