@import url("../../../../colors.css");

.navlink-tabs-prets {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: var(--quinaryTextColor);
}
.navlink-tabs-prets.active {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: var(--primarytextColor) !important;
  background: transparent !important;
  border-bottom: none !important;
}
.nav-item-tabs {
  list-style: none;
}
.btn-debiter-compte {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: var(--secondary-color);
  background: var(--primaryColor);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 0.5rem;
}
.btn-debiter-compte:hover {
  background: var(--secondary-color);
  color: var(--primaryColor) !important;
}
.titre-synthese-list {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: var(--secondary-color);
}
.text-contenu-list-pret {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--nonaryTextColor);
}
.div-content-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.text-contenu-list-pret-color {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--secondary-color);
}
.text-solde-abonnement {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: var(--secondary-color);
}
.margin-top-sidebar-mobile-tabs {
  margin-top: 70%;
}
