@import url('../../../colors.css');

* {
	appearance: none;
}

.alimenter-son-compte-element {
	display: flex;
	justify-content: center;
	background-color: var(--septanaryColor);
	padding-top: 4rem;
	padding-bottom: 7rem;
}

/* .component-details .img-details {
	height: 100px !important;
} */

.alimenter-son-compte-element input[type="radio"] {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	border: 1px solid var(--secondary-color) !important;
	box-sizing: border-box;
	outline: none;
	background-color: var(--primaryColor);
	padding: 5px !important;
}

.alimenter-son-compte-element input[type="radio"]:checked, .alimenter-son-compte-element input[type="radio"]:focus {
	background-color: var(--secondary-color) !important;
}

.choisir-montant,
.mode-paiement {
	width: 631px;
	height: 581px;
	background-color: var(--primaryColor);
	border-radius: 10px;
}

.detail-paiement {
	width: 631px;
	/* height: 581px; */
	background-color: var(--primaryColor);
	border-radius: 10px;
	position: relative;
}

@media screen and (max-width: 767px) {
	.choisir-montant,
	.mode-paiement {
		width: 300px;
	}
	.choisir-montant h2,
	.mode-paiement h2,
	.detail-paiement {
		font-size: 17px !important;
	}
}
.choisir-montant h2,
.mode-paiement h2,
.detail-paiement h2 {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 36px;
	line-height: 54px;
	text-align: center;
	color: var(--primaryTextColor);
}

.choisir-montant label {
	font-weight: 400;
	font-size: 15px;
	line-height: 22.5px;
	font-style: normal;
}

.choisir-montant .form-group-choisir-montant {
	width: 100%;
	border-bottom: 1px solid var(--quaternaryBorderColor);
}

.choisir-montant input.input-choisir-montant {
	width: 100%;
	font-size: 15px;
	line-height: 22.5px;
}
.button-alimenter-son-compte {
	border-radius: 5px;
	border: 1px solid var(--secondary-color);
	width: 130px;
	height: 40px;
	font-family: "Poppins", sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.75px;
	transition: 0.3s ease-in-out;
	outline: none;
}

.button-alimenter-son-compte:focus {
	outline: none;
}
.button-alimenter-son-compte-suivant {
	background: var(--secondary-color);
	color: var(--primaryColor);
}

.button-alimenter-son-compte-precedent {
	color: var(--secondary-color);
	background-color: var(--primaryColor);
}

.button-alimenter-son-compte-precedent:hover,
.button-alimenter-son-compte-precedent:focus {
	color: var(--secondary-color);
}

.button-alimenter-son-compte-suivant:hover {
	color: var(--primaryColor);
}

.mode-paiement-buttons {
	margin-top: 15rem;
}


.mode-paiement-label {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 22px;
	opacity: 0.7;
	margin: 0;
}

.small-radio {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 15px;
	opacity: 0.3;
	margin: 0;
}

.detail-paiement h5 {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 27px;
}


.modal-text {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 19px;
	opacity: 0.7;
}

.text-modal-dark {
	opacity: 1;
	font-weight: 600;
}

.mode-paiement-modal-button-annuler {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
}

.detail-paiement-carte {
	display: flex;
	align-items: center;
	padding: 7px 10px;
	border-radius: 5px;
	margin-bottom: 1rem;
	cursor: pointer;
}

.input-hidden-radio {
	position: absolute;
	left: -9999px;
  }
  input[type=radio]:checked ~ label {
	color: var(--primaryColor);
	background-color: var(--secondary-color);
	padding: 10px;
	border-radius: 10px;
  }
  